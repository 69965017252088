<template>
  <div>
    <p align="right" style="margin-bottom:9px;">
      <el-link @click="edit(0)" type="primary">新增供应商</el-link>
    </p>

    <el-table :data="vendor" border style="width: 100%">
      <el-table-column prop="name" label="名称" width="300"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link @click="edit(scope.row.id)" type="primary">编辑</el-link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button type="text" @click="sendVendorId(scope.row.id)"
            >开票信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination.total"
      background
      layout="prev, pager, next"
      @current-change="search()"
      :total="pagination.total"
      :current-page.sync="pagination.page"
      :page-size.sync="pagination.perPage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiSearchVendor } from '@/api/purchase'

export default {
  components: {},
  created () {
    this.search()
  },
  data () {
    return {
      vendor: [],
      pagination: {
        total: 0,
        perPage: 15,
        page: 1
      }
    }
  },
  methods: {
    search () {
      apiSearchVendor({}, this.pagination.page, this.pagination.perPage).then(
        res => {
          this.vendor = res.data
          this.pagination.total = res.total
        }
      )
    },
    edit (id) {
      this.$router.push({
        name: 'purchase-vendor-detail',
        query: {
          id: id
        }
      })
    },
    sendVendorId (id) {
      this.$emit('send-vendorid', id)
    }
  }
}
</script>

<style></style>
