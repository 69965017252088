<template>
  <div class="reimbursement-detail">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>交付单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <div>
      <el-row class="content-row">
        <p class="row-header">交付单</p>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          单号
          <span style="margin-left: 40px">{{ orderDetail.id }}</span>
        </el-col>
        <el-col :span="6">
          供应商
          <span style="margin-left: 40px">{{
            orderDetail.vendor ? orderDetail.vendor.name : '无'
          }}</span>
        </el-col>
        <el-col :span="6">
          金额
          <span style="margin-left: 40px"
            >{{ orderDetail.amount }} {{ orderDetail._currency }}</span
          >
        </el-col>
        <el-col :span="6">
          状态
          <span style="margin-left: 40px">{{ orderDetail._step }}</span>
        </el-col>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          类型
          <span style="margin-left: 40px">{{ orderDetail._type }}</span>
        </el-col>
        <el-col :span="6">
          备注
          <span style="margin-left: 40px">{{ orderDetail.remark }}</span>
        </el-col>
      </el-row>
      <br />

      <!--明细 -->
      <el-tabs v-model="tablvue">
        <el-tab-pane label="报销明细" name="first">
          <reimbur-detail-tab
            :orderDetailItem="orderDetailItem"
          ></reimbur-detail-tab>
        </el-tab-pane>
        <el-tab-pane label="开票明细" name="second">
          <invoice-detail-tab
            :invoiceDetailList="invoiceDetailList"
            :id="orderDetail.id"
            type="1"
          ></invoice-detail-tab>
        </el-tab-pane>
        <el-tab-pane label="开票资料" name="third">
          <invoice-info-tab
            :invoiceInfoList="invoiceInfoList"
          ></invoice-info-tab>
        </el-tab-pane>
      </el-tabs>
      <br />

      <!--报销明细 -->
      <!-- <el-row class="content-row">
        <p class="row-header">报销明细</p>
      </el-row>

      <div class="item-info" v-for="item in orderDetailItem" :key="item.id">

        <el-divider></el-divider>
        <el-row class="content-row">
          <el-col :span="6">
            采购单
            <span style="margin-left: 40px">{{item.order_id}}</span>
          </el-col>
          <el-col :span="6">
            预付比例 -->
      <!-- <span style="margin-left: 40px">{{item._prepay_ratio}}</span> -->
      <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">{{item._prepay_ratio}}</span>
            <span v-else style="margin-left: 40px">/</span>
          </el-col>
          <el-col :span="6">
            类型
            <span style="margin-left: 40px">{{item._type}}</span>
          </el-col>
          <el-col :span="6">
            来源
            <span style="margin-left: 40px">{{item.source_id}}<span v-if="item.remark">({{item.remark}})</span></span>
          </el-col>
        </el-row>
        <el-row class="content-row">
          <el-col :span="6">
            请款 -->
      <!-- <span style="margin-left: 40px">{{item.prepayment_amount}}</span> -->
      <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">{{item.prepayment_amount}}</span>
            <span v-else style="margin-left: 40px">/</span>
          </el-col>
          <el-col :span="6">
            已冲账 -->
      <!-- 已冲账/未冲账
            <span style="margin-left: 40px">
              {{item.prepayment_used_amount}}
              /{{item.prepayment_unused_amount? item.prepayment_unused_amount : '无'}}
            </span> -->
      <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">{{item.prepayment_used_amount}}</span>
            <span v-else style="margin-left: 40px">/</span>
          </el-col>
          <el-col :span="6">
            本次冲账 -->
      <!-- <span style="margin-left: 40px">{{item.prepay_using_amount}}</span> -->
      <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">{{item.prepay_using_amount}}</span> -->
      <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">￥{{ item.amount | usingamount(item.prepay_ratio)}} (挂账 ￥{{item.prepayment_amount - item.prepayment_used_amount}})</span>
            <span v-else style="margin-left: 40px">/</span>
          </el-col>
          <el-col :span="6">
            本次应付 -->
      <!-- <span style="margin-left: 40px">{{item.payment_amount ? item.payment_amount: '无'}}</span> -->
      <!-- <span style="margin-left: 40px">{{item.amount ? item.amount: '无'}}</span>
          </el-col>
        </el-row> -->

      <!-- <el-row class="content-row">
          <el-col :span="6" :offset="18">
            <el-button style="float: right" type="text" @click="addOtherFee(item.id)">+新增其他款项</el-button>
          </el-col>
        </el-row> -->

      <!-- <el-table :data="item.item" stripe border style="width: 100%" show-summary :summary-method="getSummaries">
          <el-table-column label="货品" >
            <template v-slot="scope"  >
              <el-row type="flex" v-if="scope.row.id">
                <img width="80" height="80" :src="scope.row._product_cover" />
                <div style="margin-left: 10px;">
                  <p>{{ scope.row._product_name }}</p>
                  <p>{{ scope.row._product_mfn }}</p>
                </div>
              </el-row>
              <el-row v-else> {{scope.row.remark}}</el-row>
            </template>
          </el-table-column> -->

      <!-- <el-table-column prop="purchase_order" label="采购单"></el-table-column> -->
      <!-- <el-table-column prop="qty" label="采购总数"></el-table-column>
          <el-table-column label="采购单价">
            <template v-slot="scope">
              <span v-if="scope.row.id">{{ scope.row.purchese_price}} {{scope.row._currency}}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="qty" label="报销数量"></el-table-column>
          <el-table-column prop="purchese_priceqty" label="报销金额">
            <template slot-scope="scope">
                {{ (scope.row.purchese_price)*(scope.row.qty) | numFilter }}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="本次应付"></el-table-column>
        </el-table> -->
    </div>

    <br />
    <!-- <el-row class="content-row">
          <el-col :span="6">
            <div>
              <el-button v-show="orderDetail.step==1" type="primary" @click="createOrder">创建报销单</el-button>
              <el-button v-show="orderDetail.step==1" type="primary" @click="addToOrder">添加至报销单</el-button>
            </div>
          </el-col>
      </el-row> -->

    <!-- 创建报销单 dialog-->
    <create-reimburse-order-dialog
      :ids="ids"
      :propsDat="propsDat"
      :lastRecord="lastRecord"
      @create-end="orderDetail.step = 1"
      ref="create"
    />
    <!-- 添加至报销单 dialog-->
    <add-to-reimburse-order-dialog
      :ids="ids"
      :propsDat="propsDat"
      @create-end="orderDetail.step = 1"
      ref="add-to-reimburse-order-dialog"
    />
    <!-- 其他费用 dialog-->
    <add-other-fee-dialog
      :orderId="id"
      :purchaseId="purchae_id"
      @create-end="reimbursementDetail"
      ref="add-other-fee-dialog"
    />
    <!-- </div> -->
  </div>
</template>
<script>
import * as reimbursementApi from '@/api/reimbursement'
import createReimburseOrderDialog from './create-reimburse-order-dialog'
import addToReimburseOrderDialog from './add-to-reimburse-order-dialog'
import addOtherFeeDialog from './add-other-fee-dialog'
import reimburDetailTab from './reimbur-detail-tab'
import invoiceDetailTab from './invoice-detail-tab'
import invoiceInfoTab from './invoice-info-tab'
export default {
  components: {
    createReimburseOrderDialog,
    addToReimburseOrderDialog,
    addOtherFeeDialog,
    reimburDetailTab,
    invoiceDetailTab,
    invoiceInfoTab
  },
  filters: {
    numFilter (value) {
      const realVal = parseFloat(value).toFixed(2)
      return realVal
    },
    usingamount (amount, prepayRatio) {
      const ratio = parseFloat(prepayRatio)
      const realVal = (amount / (1 - ratio) - amount).toFixed(2)
      return realVal
    }
  },
  data () {
    return {
      orderDetail: {},
      orderDetailItem: [],
      ids: [],
      id: null,
      purchae_id: null,

      invoiceDetailList: [],
      invoiceInfoList: [],

      tablvue: 'first',
      propsDat: null,
      lastRecord: null
    }
  },
  created () {
    this.reimbursementDetail()
  },
  mounted () {
    this.ids[0] = this.$route.query.id
    this.id = this.$route.query.id
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      // eslint-disable-next-line
      const purchese_priceList = data.map(item => Number(item.purchese_price))
      const qtyList = data.map(item => Number(item.qty))
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        }
        if (column.property === 'purchese_priceqty') {
          sums[index] = 0
          for (let i = 0; i < purchese_priceList.length; i++) {
            sums[index] += purchese_priceList[i] * qtyList[i]
          }
        }
      })

      return sums
    },
    reimbursementDetail () {
      reimbursementApi
        .reimbursementDetail({ id: this.$route.query.id })
        .then(res => {
          this.orderDetail = res.order
          this.orderDetailItem = res.reimburse_detail
          this.invoiceDetailList = res.invoice_detail
          this.invoiceInfoList = res.invoice_data

          this.propsDat = {
            vendor_name: res.order.vendor.name,
            object_id: res.order.vendor.id,
            currency: res.order.currency
          }
          this.lastRecord = res.order.vendor.last_payment_record
        })
    },
    createOrder () {
      if (this.propsDat == null) {
        return false
      }
      this.$refs.create.show()
    },

    addToOrder () {
      if (this.propsDat == null) {
        return false
      }
      this.$refs['add-to-reimburse-order-dialog'].show()
    },
    // 其他费用
    addOtherFee (id) {
      if (this.id == null) {
        alert('Id is null')
        return false
      }
      this.purchae_id = id
      this.$refs['add-other-fee-dialog'].show()
    }
  }
}
</script>

<style>
.content-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.content-row .row-header {
  font-size: 16px;
  font-weight: bold;
}
</style>
