import request from '../config/request'

export const trackingList = data => {
  return request('purchase/tracking', 'get', data, true)
}

export const trackingDeail = data => {
  return request('purchase/tracking/detail', 'get', data, true)
}
export const saveTracking = data => {
  return request('purchase/tracking/save', 'post', data)
}

export const saveTrackRemark = data => {
  return request('purchase/tracking/saveRemark', 'post', data)
}

export const updateTracking = data => {
  return request('purchase/tracking/updateOne', 'post', data)
}
