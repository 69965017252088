import { writeFile, utils } from 'xlsx'

export function outputXLSX ({ headers = [], rows = [] }, fileName = 'download.xlsx') {
  const wb = utils.book_new()
  const wsData = [
    headers,
    ...rows
  ]
  const ws = utils.aoa_to_sheet(wsData)
  utils.book_append_sheet(wb, ws, '1')
  console.log(wb, ws)
  writeFile(wb, fileName)
}

// const responseType = {
//   headers: string[],
//   rows: string[][],
//   total: number,
//   index: number
// }
