<template>
  <div class="prepayment-index">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>预付款</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-tabs v-model="search.step" @tab-click="prepaymentList()">
      <el-tab-pane
        v-for="item in tab"
        :key="item.key"
        :label="item.val"
        :name="item.key + ''"
      ></el-tab-pane>
    </el-tabs>

    <el-row class="content-row">
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysTeymConfig._vendor"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            class="search-bar"
            style="width:120px;"
            v-model="search.order_id"
            placeholder="采购单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:80px;"
            v-model="search.currency"
            placeholder="币种"
          >
            <el-option
              v-for="item in sysTeymConfig._currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            class="search-bar"
            style="width:120px;"
            v-model="search.payment_id"
            placeholder="请款单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            class="search-bar"
            style="width:120px;"
            v-model="search.remark"
            placeholder="备注"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="prepaymentList">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div align="right">
      <el-button
        type="primary"
        @click.prevent="createPrepayment()"
        :disabled="search.step > 1"
        size="mini"
        >到货请款</el-button
      >
    </div>

    <el-row class="content-row">
      <el-table
        row-key="id"
        ref="table"
        :data="table"
        stripe
        border
        style="width: 100%"
        @selection-change="pickAll"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="pickRow"
          v-if="search.step == 1"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column prop="purchase_id" label="采购单"></el-table-column>
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount }} {{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="_type" label="类型"></el-table-column>
        <el-table-column prop="payment_id" label="请款单号"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-row>

    <div style="margin:9px 0" v-if="search.step == 1">
      <el-button type="primary" @click="createPayment" size="mini"
        >创建请款单</el-button
      >
      <el-button type="primary" @click="addToPayment" size="mini"
        >添加至请款单</el-button
      >
    </div>

    <el-row class="content-row">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[15, 20, 30, 50, 100, 200]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.num_per_page"
      ></el-pagination>
    </el-row>

    <create-payment-dialog
      ref="create-payment-dialog"
      :ids="form.id"
      :vendor="form._vendor"
      @paymentRes="prepaymentList"
    />
    <add-to-payment-dialog
      ref="addToPaymentDialog"
      :ids="form.id"
      :vendor="form._vendor"
      @paymentRes="prepaymentList"
    />
  </div>
</template>
<script>
import { getEarnestFinance } from '@/api/purchaseFinanceNew'
// import { getVendorSimple } from '@/api/common.js'
import createPaymentDialog from './create-payment-dialog'
import addToPaymentDialog from './add-to-payment-dialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    createPaymentDialog,
    addToPaymentDialog
  },
  data () {
    return {
      // 数据部署设置基于界面模块
      tab: [],
      search: {
        // 接口参数
        vendor_id: '',
        order_id: '',
        step: '1',
        payment_id: '',
        currency: '',
        page: 1,
        num_per_page: 15,
        total: 0,
        start: '',
        end: '',
        remark: '',
        // 非接口参数使用前缀“_”
        _date: null
      },
      table: [],
      form: {
        id: [],
        _vendor: null
      },
      // Kane
      sysTeymConfig: {
        _vendor: [],
        _currency: []
      },
      ids: [],
      selectedList: [],
      datList: [],
      vendor: [],
      currency: [],
      stepList: [],
      vendorName: undefined,
      date: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.tab = this.sysConfig.FinancePaymentEarnestItem.step
    this.sysTeymConfig._vendor = this.sysConfig.vendorList
    this.sysTeymConfig._currency = this.sysConfig.Common.currency
    this.prepaymentList()
  },
  methods: {
    // api接口获取数据
    prepaymentList () {
      this.form._vendor = null
      getEarnestFinance(this.search).then(res => {
        // console.log(res.data)
        this.table = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.num_per_page = res.per_page
      })
    },

    pickAll (array) {
      if (array.length) {
        if (!this.form._vendor) {
          this.form._vendor = {
            currency: array[0].currency,
            vendor_id: array[0].vendor_id,
            name: array[0]._vendor,
            payment_record: array[0].last_payment_record
          }
        }
        this.form.id = []
        for (let i = 0; i < array.length; i++) {
          if (this.pickRow(array[i], 0)) {
            this.form.id.push(array[i].id)
          }
          this.$refs.table.toggleRowSelection(
            array[i],
            this.pickRow(array[i], 0)
          )
        }
        return
      }
      this.form._vendor = null
    },
    pickRow (row, index) {
      const obj = this.form._vendor
      if (obj) {
        row.checked =
          obj.currency === row.currency && obj.vendor_id === row.vendor_id
        return row.checked
      }
      return true
    },

    createPayment () {
      if (!this.form._vendor) {
        return alert('未选择预付款。')
      }
      this.$refs['create-payment-dialog'].show(0, {})
    },
    addToPayment () {
      this.$refs.addToPaymentDialog.show(this.form.id, this.form._vendor)
    },
    // 跳轉到申請預付款
    createPrepayment () {
      this.$router.push({ name: 'prepaymentCreateNew' })
    },
    handleSelectionChange (val) {
      this.selectedList = val
      this.vendorName = val[0] && val[0]._vendor
      this.ids = val.map(item => item.id)
    },

    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.prepaymentList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.num_per_page = pagesize
      this.prepaymentList()
    }
  }
}
</script>

<style>
.content-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
