<template>
  <div>
    <el-row>
      <el-col :span="3" :offset="10">
        <el-button type="primary" size="small" @click="downloadLink()"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table border stripe style="width:100%" :data="invoiceDetailList">
        <el-table-column prop="purchase" label="采购单号"></el-table-column>
        <el-table-column
          prop="_invoice_type"
          label="开票类型"
        ></el-table-column>
        <el-table-column prop="inbound_id" label="采购入库单"></el-table-column>
        <el-table-column prop="inbound_date" label="入库日期"></el-table-column>
        <el-table-column prop="warehouse" label="入库仓库"></el-table-column>
        <el-table-column prop="user" label="人员"></el-table-column>
        <el-table-column prop="vendor" label="供应商"></el-table-column>
        <el-table-column prop="purchase_user" label="采购员"></el-table-column>
        <el-table-column prop="_product_mfn" label="产品编码"></el-table-column>
        <el-table-column prop="_product_name" label="品名"></el-table-column>
        <el-table-column
          prop="_product_customs.description"
          label="开票品名"
        ></el-table-column>
        <el-table-column prop="purchese_qty" label="单位"></el-table-column>
        <el-table-column prop="qty" label="交付（报销）数"></el-table-column>
        <el-table-column prop="purchase_price" label="采购价"></el-table-column>
        <el-table-column prop="amount" label="交付金额"></el-table-column>
        <el-table-column
          prop="amount_no_tax"
          label="不含税金额"
        ></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  props: ['invoiceDetailList', 'id'],
  methods: {
    downloadLink () {
      const url =
        baseUrl +
        'finance/purchase/reimbursement/exportInvoiceDetail?token=' +
        localStorage.getItem('token') +
        '&id=' +
        this.id
      window.open(url)
    }
  }
}
</script>
