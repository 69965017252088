<template>
  <el-dialog
    title="创建入库单"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closedone"
    width="800"
  >
    <el-tag type="warning">入库单创建后不可更改。确定创建？</el-tag>
    <el-form label-position="left">
      <el-form-item label-width="10px">
        <el-input
          id="file"
          v-model="formdata.excel"
          name="file"
          type="file"
        ></el-input>
      </el-form-item>
      <el-form-item label-width="10px" label>
        <p>
          下载
          <el-link type="primary" :href="downloadTemplate" target="_blank"
            >入库清单模板.xls</el-link
          >
        </p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { inboundTransferPlan } from '@/api/warehouse'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        id: '',
        excel: ''
      }
    }
  },
  computed: {
    // 导出excel模板
    downloadTemplate () {
      return (
        'https://sd.resource.soffeedesign.net/tpl/wms-调拨单-入库单模版.xlsx?t=' +
        localStorage.getItem('token')
      )
      //   return (
      //     baseUrl +
      //     'wms/transferPlan/inboundTpl?token=' +
      //     localStorage.getItem('token')
      //   );
    }
  },
  methods: {
    show (id) {
      this.visible = true
      this.formdata.id = id
    },
    closedone (done) {
      this.formdata = {}
      done()
    },
    hide () {
      this.visible = false
      this.formdata = {}
    },
    handleConfirm () {
      const formdat = new FormData()
      formdat.append('id', this.formdata.id)
      formdat.append('excel', document.getElementById('file').files[0])
      inboundTransferPlan(formdat)
        .then(data => {
          this.$emit('create-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
