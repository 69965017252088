<template>
  <el-dialog title="添加至报销单" :visible.sync="visible" width="1200px">
    <el-form label-position="left">
      <el-table
        :data="datList"
        ref="multipleTable"
        stripe
        border
        style="width: 100%"
        @current-change="currentSelectionChange"
      >
        <el-table-column label="报销单号">
          <template v-slot="scope">
            <el-radio v-model="radio" :label="scope.row.id + ''">{{
              scope.row.id
            }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="vendor_name" label="供应商"></el-table-column>
        <el-table-column label="收款信息">
          <template slot-scope="scope">
            <span>{{ scope.row.bank_name }}</span>
            <br />
            <span>{{ scope.row.bank_account }} （{{ scope.row.bank }}）</span>
          </template>
        </el-table-column>
        <el-table-column label="请款总金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount }}{{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deadline" label="付款期限"></el-table-column>
      </el-table>
    </el-form>

    <span slot="footer">
      <el-button size="small" type="primary" @click="addToOrder"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as reimbursementApi from '@/api/reimbursement'
import { Message } from 'element-ui'

export default {
  props: ['propsDat', 'ids'],
  data () {
    return {
      visible: false,
      radio: '',
      datList: [],
      selectObj: null,
      propsInfo: this.propsDat || {}
    }
  },
  watch: {
    propsDat: function (val) {
      if (val == null) return false
      this.propsInfo = val
      this.orderList()
    }
  },
  created () {
    this.orderList()
  },

  methods: {
    orderList () {
      const data = {
        num_per_page: 1000,
        step: 0
      }
      reimbursementApi.reimbursementOrder(data).then(res => {
        this.datList = res.data.filter(item => {
          return (
            item.object_id === this.propsInfo.object_id &&
            item.currency === this.propsInfo.currency
          )
        }) // 无分页
      })
    },
    addToOrder () {
      // console.log(this.propsInfo)
      // return false
      if (this.selectObj === null) {
        Message({
          type: 'error',
          message: '请选择一项'
        })
        return false
      }

      const data = {
        reimbursement_id: this.selectObj.id,
        ids: this.ids
      }
      reimbursementApi.addToreimbursementOrder(data).then(res => {
        this.$emit('create-end')
        this.hide()
      })
    },
    currentSelectionChange (row) {
      this.radio = row.id + ''
      this.selectObj = row
    },

    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
