import types from './types'
// import * as rootController from '../api/rootController'

const mutations = {
  [types.ADMIN_LOGIN] (state) {
    state.logined = true
  },
  [types.ADMIN_LOGOUT] (state) {
    state.logined = false
  },
  setMenu (state, menu) {
    state.menu = menu
  },
  [types.GETSYS_CONFIG] (state, data) {
    state.sysConfig = data
  }
}

export default mutations
