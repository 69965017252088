<template>
  <div>
    <el-row>
      <el-col :span="3" :offset="15">
        <el-button type="text" size="small" @click="downloadLink()"
          >下载开票资料</el-button
        >
      </el-col>
    </el-row>
    <div v-for="(item, index) in invoiceInfoList" :key="index">
      <h3>{{ item.clearance }}</h3>
      <el-table border stripe style="width:100%" :data="item.items">
        <el-table-column prop="name" label="开票品名"></el-table-column>
        <el-table-column prop="qty" label="数量"></el-table-column>
        <el-table-column prop="unit" label="单位"></el-table-column>
        <el-table-column prop="amount" label="交付金额"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  props: ['invoiceInfoList', 'id'],
  methods: {
    downloadLink () {
      const url =
        baseUrl +
        'purchase/finance/invoice/order/exportTicketInfo?token=' +
        localStorage.getItem('token') +
        '&id=' +
        this.id
      window.open(url)
    }
  }
}
</script>
