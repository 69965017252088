<template>
    <el-dialog
      title="清算数据"
      :visible.sync="isShow"
      :before-close="closedone"
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form label-position="left" label-width="120px">
        <el-form-item label="账期id">{{ rowdata.id}}</el-form-item>
        <el-form-item label="ec">{{ rowdata._ec }}</el-form-item>
        <el-form-item label="币种">{{ rowdata.OriginalCurrency}}</el-form-item>
        <el-form-item label="月份">
          <el-date-picker
            type="month"
            value-format="yyyyMM"
            v-model="FormData.month"
            placeholder="选择年月"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="金额">
          <el-input type="text" style="width: 220px" v-model="FormData.amount" placeholder="清算金额">
          </el-input>
        </el-form-item>
        <el-form-item><el-button type="primary" @click.prevent="subLiquidation">保存</el-button></el-form-item>
      </el-form>
    </el-dialog>
</template>

<script>
import { setFinanceLiquidation } from '@/api/financeAmazon'
export default {
  name: 'liquidation-dialog',
  data: function () {
    return {
      rowdata: {},
      isShow: false,
      FormData: {
        finance_id: '',
        ec_id: '',
        currency: '',
        month: '',
        amount: ''
      },
      pickerOptions: {
        disabledDate (time) {
          const currentDate = new Date()
          const currentMonth = currentDate.getMonth()
          const currentYear = currentDate.getFullYear()

          // 禁用当前月之后的日期
          return time.getFullYear() > currentYear || (time.getFullYear() === currentYear && time.getMonth() >= currentMonth)
        }
      }
    }
  },
  methods: {
    show (data) {
      this.rowdata = data
      this.isShow = true
      this.FormData.finance_id = data.id
      this.FormData.ec_id = data.ec_id
      this.FormData.currency = data.OriginalCurrency
      this.FormData.month = data.liquidation ? data.liquidation.liq_month : ''
      this.FormData.amount = data.liquidation ? data.liquidation.amount : ''
    },
    closedone (done) {
      this.rowdata = {}
      done()
    },
    subLiquidation () {
      setFinanceLiquidation(this.FormData).then(data => {
        this.isShow = false
        this.$emit('setSucc')
      })
    }
  }
}
</script>
