<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item>库存帐</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-row">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-date-picker
            v-model="search.yearMonth"
            type="month"
            value-format="yyyyMM"
            placeholder="选择年月"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.mfn" placeholder="MFN"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.saas" placeholder="主体" clearable>
            <el-option
              v-for="item in sysConfig.Common.saas"
              :key="item.key"
              :value="item.val.id"
              :label="item.val.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getproductCostlist()"
            >搜索</el-button
          >
          <el-button type="primary" @click="downloadLink()">导出下载</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" border :data="productCostlist">
        <el-table-column label="主体">
          <template slot-scope="scope">
            {{ getSaasName(scope.row.saas) }}
          </template>
        </el-table-column>
        <el-table-column prop="_product_mfn" label="MFN"></el-table-column>
        <el-table-column prop="_product_name" label="品名"></el-table-column>
        <el-table-column prop="price" label="成本价"></el-table-column>
        <el-table-column prop="time" label="账期"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
import * as productCostApi from '@/api/inventoryMangement'
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
export default {
  data () {
    return {
      search: {
        saas: 1,
        mfn: ''
      },
      productCostlist: [],
      pageData: {
        current_page: 1,
        per_page: 10
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getproductCostlist()
  },
  methods: {
    getSaasName (value) {
      const self = this
      const saas = self.sysConfig.Common.saas
      for (let i = 0; i < saas.length; i++) {
        if (saas[i].val.id === value) {
          return saas[i].val.name
        }
      }
    },
    getproductCostlist () {
      const self = this
      const listJsondata = {
        mfn: self.search.mfn,
        time: self.search.yearMonth,
        saas: self.search.saas,
        per_page: self.pageData.per_page,
        page: self.pageData.current_page
      }
      productCostApi
        .getproductCostList(listJsondata)
        .then(data => {
          self.productCostlist = data.data
          self.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getproductCostlist()
    },
    changeSizeHandler (size) {
      this.pageData.per_page = size
      this.getproductCostlist()
    },
    downloadLink () {
      if (this.search.mfn === '') {
        const url =
          baseUrl +
          'finance/inventory/exportProductCost?token=' +
          localStorage.getItem('token') +
          '&time=' +
          this.search.yearMonth +
          '&saas=' +
          this.search.saas
        window.open(url)
      } else {
        const url =
          baseUrl +
          'finance/inventory/exportProductCost?token=' +
          localStorage.getItem('token') +
          '&time=' +
          this.search.yearMonth +
          '&saas=' +
          this.search.saas +
          '&mfn=' +
          this.search.mfn
        window.open(url)
      }
    }
  }
}
</script>
