<template>
  <el-dialog title="新增/编辑开票信息" width="500px" :visible.sync="visible">
    <el-row>
      <el-tag type="danger"
        >务必核实确保开票信息准确无误，否则可能无法与供应商完成结算</el-tag
      >
    </el-row>
    <el-row>
      <el-form
        :inline="true"
        :model="invoicedata"
        label-width="150px"
        size="small"
      >
        <el-form-item label="公司名称">
          <el-input v-model="invoicedata.company"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="invoicedata.no"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="invoicedata.addr"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="invoicedata.tel"></el-input>
        </el-form-item>
        <el-form-item label="开户银行">
          <el-input v-model="invoicedata.bank"></el-input>
        </el-form-item>
        <el-form-item label="对公账户">
          <el-input v-model="invoicedata.card"></el-input>
        </el-form-item>
        <el-form-item label="设为默认">
          <el-switch
            v-model="invoicedata.top"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开"
            inactive-text="关"
            class="taxswitch"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer">
      <el-button @click="saveInvoice()">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as vendorApi from '@/api/purchase'
export default {
  data () {
    return {
      visible: false,
      invoicedata: {
        vendor_id: '',
        id: ''
      }
    }
  },
  methods: {
    show (vendorId, invoiceJson) {
      console.log(invoiceJson)
      this.invoicedata = invoiceJson
      this.invoicedata.vendor_id = vendorId
      this.visible = true
    },
    saveInvoice () {
      vendorApi
        .saveInvoice(this.invoicedata)
        .then(data => {
          this.$emit('save-success-invoice')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
