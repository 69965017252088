<template>
  <div class="prepayment-order-detail">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>请款管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <div>
      <el-row class="content-row">
        <el-col :span="5">
          <span style="font-size: 16px;  font-weight: bold; ">请款单详情</span>
        </el-col>
        <el-col :span="3" :offset="13">
          <el-button
            type="text"
            @click="$refs['edit-payment-dialog'].show(1, orderDetail)"
            v-if="orderDetail.step === 1"
            >编辑</el-button
          >
        </el-col>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          请款单号
          <span style="margin-left: 40px">{{ orderDetail.id }}</span>
        </el-col>
        <el-col :span="6">
          供应商
          <span style="margin-left: 40px">{{ orderDetail._vendor }}</span>
        </el-col>
        <el-col :span="6">
          总金额
          <span style="margin-left: 40px"
            >{{ orderDetail.amount }} {{ orderDetail._currency }}</span
          >
        </el-col>
        <el-col :span="6">
          状态
          <span style="margin-left: 40px">{{ orderDetail._step }}</span>
        </el-col>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          收款单位
          <span style="margin-left: 40px">{{ orderDetail.bank_name }}</span>
        </el-col>
        <el-col :span="6">
          开户行
          <span style="margin-left: 40px">{{ orderDetail.bank }}</span>
        </el-col>
        <el-col :span="6">
          账号
          <span style="margin-left: 40px">{{ orderDetail.bank_account }}</span>
        </el-col>
        <el-col :span="6">
          付款期限
          <span style="margin-left: 40px">{{ orderDetail.deadline }}</span>
        </el-col>
      </el-row>
      <el-row class="content-row">
        <el-col :span="8">
          备注
          <span style="margin-left: 40px">{{ orderDetail.remark }}</span>
        </el-col>
      </el-row>
      <el-row class="content-row">
        <el-button
          type="primary"
          size="mini"
          @click="onSubmit"
          v-if="orderDetail.step == 1"
          >提交财务审核</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="deleteOrder()"
          v-if="orderDetail.step == 1"
          >删除请款单</el-button
        >
      </el-row>

      <el-table :data="orderDetail.item" stripe border style="width: 100%">
        <el-table-column prop="purchase_id" label="采购单"></el-table-column>
        <el-table-column prop="_type" label="类型">
          <!-- <template slot-scope="scope">
            <span>{{ scope.row.amount}} {{scope.row._currency}}</span>
          </template> -->
        </el-table-column>
        <el-table-column label="请款金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount }} {{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </div>
    <edit-payment-dialog
      ref="edit-payment-dialog"
      @success-edit="prepaymentOrderDetail"
    />
  </div>
</template>
<script>
import {
  getEarnestFinanceOrderDetail,
  submitOrderFinance
} from '@/api/purchaseFinanceNew'
import { mapGetters } from 'vuex'
import editPaymentDialog from './create-payment-dialog'
export default {
  components: {
    editPaymentDialog
  },
  data () {
    return {
      orderDetail: {},
      dialogVisible: false
    }
  },

  computed: {
    ...mapGetters(['sysConfig'])
    // stepFirst() {
    //   return this.sysConfig.FinancePurchasePrepayment.step[0].key;
    // }
  },

  created () {
    this.prepaymentOrderDetail()
  },
  methods: {
    onSubmit () {
      this.$confirm('是否继续？', '提交审核', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        submitOrderFinance({ id: this.$route.query.id }).then(res => {
          this.prepaymentOrderDetail()
        })
      })
    },
    prepaymentOrderDetail () {
      getEarnestFinanceOrderDetail({ id: this.$route.query.id }).then(res => {
        this.orderDetail = res
      })
    }
    // deleteOrder() {
    //   this.$confirm('提示', { title: '删除请款单', message: '是否确认删除该请款单?' })
    //   .then(_ => {
    //     financeApi.deletePrepaymentOrder({ id: this.$route.query.id })
    //     .then((data) => {
    //       this.prepaymentOrderDetail()
    //     }).catch((err) => {
    //       console.log(err)
    //     })
    //   }).catch(_ => {})
    // }
  }
}
</script>

<style>
.content-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
