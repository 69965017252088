<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>WMS</el-breadcrumb-item>
        <el-breadcrumb-item>海外仓</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>库存调拨</h3>
      </el-col>
      <el-col :span="2" :offset="15">
        <el-button
          type="text"
          size="small"
          @click="
            $refs['edit-allot-dialog'].show(
              detaildata.id,
              true,
              detaildata._warehouse_from,
              detaildata._warehouse_to,
              detaildata.date,
              detaildata.remark
            )
          "
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="5"> ID: &nbsp;&nbsp;{{ detaildata.id }} </el-col>
      <el-col :span="6" :offset="1">
        流程：&nbsp;&nbsp;{{ detaildata._step }}
      </el-col>
      <el-col :span="5" :offset="1">
        计划日期：&nbsp;&nbsp;{{ detaildata.date }}
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="5">
        调出仓库： &nbsp;&nbsp;{{ detaildata._warehouse_from }}
      </el-col>
      <el-col :span="6" :offset="1">
        调入仓库： &nbsp;&nbsp;{{ detaildata._warehouse_to }}
      </el-col>
      <el-col :span="5" :offset="1">
        创建时间： &nbsp;&nbsp;{{ detaildata.created_at }}
      </el-col>
      <el-col :span="5" :offset="1">
        最后更新：&nbsp;&nbsp;{{ detaildata.updated_at }}
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="5">
        出库单号:
        <span v-if="detaildata.outbound">{{ detaildata.outbound }}</span>
        <span v-else
          ><el-button type="text" @click="createOutbound(detaildata.id)"
            >创建出库单</el-button
          ></span
        >
      </el-col>
      <el-col :span="5" :offset="1">
        入库单号：
        <span v-if="detaildata.inbound">{{ detaildata.inbound }}</span>
        <span v-else
          ><el-button
            type="text"
            @click="$refs['create-inbound-dialog'].show(detaildata.id)"
            >创建入库单</el-button
          ></span
        >
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="3">
        <el-button
          type="primary"
          size="mini"
          v-if="detaildata.step === 4"
          @click="confirm(detaildata.id)"
          >同步至WMS调拨出入库单</el-button
        >
      </el-col>
      <el-col :span="3" :offset="3">
        <el-button
          type="primary"
          size="mini"
          v-if="detaildata.step === 3"
          @click="confirm(detaildata.id)"
          >审核</el-button
        >
      </el-col>
      <el-col :span="3" :offset="1">
        <el-button
          type="primary"
          size="mini"
          v-if="detaildata.step === 3"
          @click="deleteOrder(detaildata.id)"
          >删除</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-tabs v-model="tabvalue">
        <el-tab-pane label="明细" name="明细">
          <div v-for="(item, index) in detaildata.detail" :key="index">
            <h4>{{ item.title }}</h4>
            <el-table border stripe width="100%" :data="item.data">
              <el-table-column label="SKU" prop="sku"></el-table-column>
              <el-table-column label="仓库SKU" prop="_sku"></el-table-column>
              <el-table-column label="Qty" prop="qty"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    @click="
                      $refs['edit-detail-dialog'].show(detaildata.id, scope.row)
                    "
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="汇总" name="汇总">
          <el-table border stripe width="100%" :data="detaildata.collect">
            <el-table-column label="仓库SKU" prop="_sku"></el-table-column>
            <el-table-column label="Qty" prop="qty"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <create-inbound-dialog
      ref="create-inbound-dialog"
      @create-success="getdetail()"
    ></create-inbound-dialog>
    <edit-allot-dialog
      ref="edit-allot-dialog"
      @edit-success="getdetail()"
    ></edit-allot-dialog>
    <edit-detail-dialog
      ref="edit-detail-dialog"
      @edit-item-success="getdetail()"
    ></edit-detail-dialog>
  </div>
</template>
<script>
import {
  getTransferPlanDetail,
  outboundTransferPlan,
  confirmPlan,
  delPlan
} from '@/api/warehouse'
import createInboundDialog from './create-inbound-dialog'
import editAllotDialog from './create-allot-dialog'
import editDetailDialog from './edit-detail-dialog'
export default {
  components: {
    createInboundDialog,
    editAllotDialog,
    editDetailDialog
  },
  data () {
    return {
      detaildata: {},
      id: this.$route.params.id,
      tabvalue: '明细'
    }
  },
  created () {
    this.getdetail()
  },
  methods: {
    getdetail () {
      getTransferPlanDetail({ id: this.id })
        .then(data => {
          this.detaildata = data
          console.log(this.detaildata)
        })
        .catch(err => {
          console.log(err)
        })
    },
    createOutbound (id) {
      const self = this
      self
        .$confirm('确定创建出库单？创建后不可修改。')
        .then(_ => {
          outboundTransferPlan({ id: id })
            .then(data => {
              this.getdetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    confirm (id) {
      const self = this
      if (this.detaildata.step === 3) {
        self
          .$confirm('提示', {
            title: '审核',
            message:
              '审核操作将暂时释放明细中的借入库存，并在入库时自动将借入库存计入入库仓库的库存中。确定审核通过?'
          })
          .then(_ => {
            confirmPlan({ id: id })
              .then(data => {
                this.getdetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      } else if (this.detaildata.step === 4) {
        self
          .$confirm('提示', {
            title: '审核',
            message: '指定的调拨单将同步至WMS，该操作不可撤销。确定执行操作?'
          })
          .then(_ => {
            confirmPlan({ id: id })
              .then(data => {
                this.getdetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      }
    },
    deleteOrder (id) {
      const self = this
      self
        .$confirm('确定删除该调拨单吗？')
        .then(_ => {
          delPlan({ id: id })
            .then(data => {
              self.$router.push({
                path: '/oversea/allot-plan'
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
