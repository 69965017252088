<template>
  <div>
    <h2>Stripe 收款</h2>
    <el-row>
      所有支付即刻生效，都将汇入app@soffeedesign.com帐号；<br />
      该功能仅用于完成支付，不记录支付流水、不涉及财务对账。
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form
          ref="form"
          :model="form"
          :rules="formRules"
          label-position="left"
        >
          <el-form-item style="padding-left: 14px;" label="Card Number">
            <div ref="card-number"></div>
          </el-form-item>
          <el-form-item
            style="padding-left: 14px;"
            label="Name on Credit Card"
            prop="name"
          >
            <el-input placeholder="" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item style="padding-left: 14px;" label="Expiration Date">
            <div ref="card-expiry"></div>
          </el-form-item>
          <el-form-item style="padding-left: 14px;" label="CVC">
            <div ref="card-cvc"></div>
          </el-form-item>
          <el-form-item label="支付金额">
            <el-input
              placeholder="请输入支付金额"
              v-model="form.amount"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" :offset="4">
        <el-button type="primary" @click="stripepay()" :disabled="flagbutton"
          >pay${{ form.amount }}</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import cardRules from '@/api/cardRules'
import { Notification } from 'element-ui'
import { stripepay } from '@/api/amazon'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      form: {
        name: '',
        amount: ''
      },
      flagbutton: false,
      formRules: cardRules
      // key: 'pk_test_Ri3mOlLLPVQPHRCRXnjHfexA',
      // stripe: Stripe('pk_test_Ri3mOlLLPVQPHRCRXnjHfexA')
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    stripe () {
      return Stripe(this.sysConfig.Common.stripe.pk)
    }
  },
  mounted () {
    this.createElements()
  },
  methods: {
    stripepay () {
      this.flagbutton = true
      this.formValidate().then(
        valid => {
          if (valid) {
            let tokenData = {
              name: this.form.name
            }
            this.stripe.createToken(this.cardElement, tokenData).then(
              res => {
                if (res.error) {
                  Notification.error({
                    message: res.error.message,
                    duration: 1500
                  })
                  this.flagbutton = false
                } else {
                  const token = res.token
                  stripepay({ token: token, amount: this.form.amount })
                    .then(data => {
                      console.log(data)
                      Notification.success({
                        message: data + '支付成功',
                        duration: 1500
                      })
                      this.flagbutton = false
                    })
                    .catch(err => {
                      console.log(err)
                      this.flagbutton = false
                    })
                }
              },
              err => {
                console.log(err), (this.flagbutton = false)
              }
            )
          } else {
            console.log(err)
            this.flagbutton = false
          }
        },
        () => {
          console.log(err)
          this.flagbutton = false
        }
      )
    },
    createElements () {
      // const stripe = Stripe(this.key)
      let elements = this.stripe.elements()

      let style = {
        base: { '::placeholder': { color: '#bbbec4' }, fontSize: '12px' }
      }
      let classes = { base: 'stripe-base', invalid: 'stripe-invalid' }
      let cardNumber = elements.create('cardNumber', {
        style,
        classes,
        placeholder: ''
      })
      let cardExpiry = elements.create('cardExpiry', {
        style,
        classes,
        placeholder: 'month / year'
      })
      let cardCvc = elements.create('cardCvc', {
        style,
        classes,
        placeholder: ''
      })

      this.cardElement = cardNumber

      cardNumber.mount(this.$refs['card-number'])
      cardExpiry.mount(this.$refs['card-expiry'])
      cardCvc.mount(this.$refs['card-cvc'])
    },
    /**
     * 验证card信息
     */
    formValidate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(valid)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss">
/* stripe style */
.stripe-invalid {
  border: 1px solid #ed3f14 !important;
  &:hover {
    border: 1px solid #ed3f14 !important;
  }
}

.stripe-base {
  display: inline-block;
  width: 100%;
  height: 32px;
  line-height: 1.5 !important;
  padding: 6px 7px;
  font-size: 12px !important;
  border: 1px solid #dddee1;
  border-radius: 4px;
  color: #495060;
  background-color: #fff;
  background-image: none;
  position: relative;
  cursor: text;
  transition: border 0.2s ease-in-out, background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  &:hover {
    border-color: #57a3f3;
  }
  input {
    line-height: 1.5 !important;
    font-size: 12px !important;
  }
}
</style>
