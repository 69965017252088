<template>
  <el-dialog title="导出产品" :visible.sync="exportVisible" width="500px">
    <el-form
      size="small"
      :model="exportdata"
      label-width="74px"
      label-position="left"
      ref="exportForm"
      id="exportForm"
    >
      <el-row>
        <el-col :span="16">
          <el-form-item label="MFN" prop="mfn">
            <el-input
              type="textarea"
              v-model="exportdata.mfnarea"
              placeholder="输入要导出的产品，一行一个MFN，如无MFN则导出所有产品"
              :rows="8"
              style="width:350px"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <label>导出内容</label>
        </el-col>

        <el-col :span="4" :offset="2">
          <el-checkbox v-model="exportdata.base" label="基础内容"></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox
            v-model="exportdata.album"
            label="相册图片"
          ></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox v-model="exportdata.ext" label="扩展属性"></el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" :offset="6">
          <el-checkbox v-model="exportdata.caiji" label="材积"></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox
            v-model="exportdata.baoguan"
            label="报关资料"
          ></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox v-model="exportdata.qg" label="清关资料"></el-checkbox>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" type="primary" @click="downloadLink"
        >导出商品</el-button
      >
      <el-button size="small" @click="hide">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { baseUrl } from '../../../config/env'
// import * as productapi from '../../../api/product';
export default {
  data () {
    return {
      exportdata: {
        mfnarea: '',
        base: false,
        album: false,
        ext: false
      },
      exportVisible: false
    }
  },
  methods: {
    show () {
      this.exportVisible = true
    },
    hide () {
      this.exportVisible = false
    },
    downloadLink () {
      const dataexport = {
        mfn: this.exportdata.mfnarea.split('\n').join(','),
        album: Number(this.exportdata.album),
        ext: Number(this.exportdata.ext),
        base: Number(this.exportdata.base)
      }

      // if (this.exportdata.mfnarea.length == 0) {
      //   this.$alert('请输入MFN')
      //   return false
      // }

      const queryStr = Object.keys(dataexport)
        .reduce((ary, key) => {
          if (dataexport[key]) {
            ary.push(
              encodeURIComponent(key) +
                '=' +
                encodeURIComponent(dataexport[key])
            )
          }
          return ary
        }, [])
        .join('&')

      const url =
        baseUrl +
        'product/io/export?token=' +
        localStorage.getItem('token') +
        '&' +
        `${queryStr}`
      window.open(url)
      // return url.substring(0, url.length - 1);
    }
  }
}
</script>
