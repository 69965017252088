<!-- 货代单详情 -->
<template>
  <div class="forward-order">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>货品库存</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>货单运单</h3>
      </el-col>
      <el-col :span="5" :offset="15">
        <el-button
          type="text"
          @click="$refs['edit-forward-dialog'].show(1)"
          :disabled="orderdetail.step === 2"
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-form
        :model="orderdetail"
        label-width="90px"
        size="small"
        label-position="left"
      >
        <el-row>
          <el-col :span="5">
            <el-form-item label="ID:" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.id }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="Name：" size="small">
              &nbsp;&nbsp;{{ orderdetail.name }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="业务员：" size="small">
              &nbsp;&nbsp;{{ orderdetail.user_name }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="仓库：" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail._warehouse_from }}~{{
                orderdetail.warehouse_name
              }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="出口退税：" size="small">
              &nbsp;&nbsp; &nbsp;&nbsp;
              <span v-if="orderdetail.is_tax_refund === true">是</span>
              <span v-else>否</span>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="运输方式:" size="small">
              &nbsp;&nbsp;{{ orderdetail._transport_type }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1" size="small">
            <el-form-item label="预计发货:">
              &nbsp;&nbsp;{{ orderdetail.date
              }}<el-tag size="mini">{{ orderdetail._step }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="备注:" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-button
        type="primary"
        size="small"
        @click="$refs['confirm-forward-dialog'].show()"
        v-if="orderdetail.step === 1"
        >确认</el-button
      >
      <el-button
        type="danger"
        size="small"
        @click="deleteTransportDetail"
        v-if="orderdetail.step === 1"
        >删除</el-button
      >
    </el-row>
    <el-row style="margin-top: 10px">
      <el-tabs v-model="tabvalues" @tab-click="handleClick">
        <el-tab-pane label="明细" name="first">
          <forward-order-detail
            :orderdetail="orderdetail"
            @detail-success="change"
          ></forward-order-detail>
        </el-tab-pane>
        <el-tab-pane label="报关资料" name="second">
          <forward-order-clearance
            :clearanceItem="clearanceItem"
            :orderdetail="orderdetail"
            @clearance-success="change"
          ></forward-order-clearance>
        </el-tab-pane>
        <el-tab-pane label="同步入库单" name="third">
          <el-row>
            <el-col :span="3">
              <h3>海外仓入库单</h3>
            </el-col>
            <el-col :span="5" :offset="3">
              <el-button
                type="text"
                @click="
                  $refs['create-oversea-dialog'].show(
                    orderdetail.is_to_fba,
                    orderid
                  )
                "
                v-show="forwarddatalength === 0"
                >创建</el-button
              >
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form :model="orderdetail" label-width="100px" size="small">
                <el-form-item label="入库单单号:" size="small">
                  <!-- <el-input v-model='forwarddata.id' :disabled="true"></el-input> -->
                  <span
                    v-for="(item, index) in orderdetail._shipment"
                    :key="index"
                    >{{ item }},</span
                  >
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <edit-ware-warrant ref="edit-ware-warrant-dialog"></edit-ware-warrant>
    <confirm-forward-dialog
      ref="confirm-forward-dialog"
      :fowardid="orderid"
      :status="orderdetail.status"
      @confirmsuccess="change"
    ></confirm-forward-dialog>
    <delete-forward-order-detail-dialog
      ref="delete-forward-order-detail-dialog"
      @deletesuccess="change"
    ></delete-forward-order-detail-dialog>
    <edit-forward-dialog
      ref="edit-forward-dialog"
      :orderDetail="orderdetail"
      :selectItem="selectItem"
      @change="change"
    ></edit-forward-dialog>
    <create-oversea-dialog
      ref="create-oversea-dialog"
      @create-oversea-success="change()"
    ></create-oversea-dialog>
  </div>
</template>
<script>
import editWareWarrant from './edit-warehouse-warrant'
import * as forwardNewApi from '@/api/forwardNew2021'
import confirmForwardDialog from './confirm-forward-dialog.vue'
import deleteForwardOrderDetailDialog from './delete-forward-order-detail-dialog'
import editForwardDialog from './create-forward-dialog'
import createOverseaDialog from './create-oversea-dialog'
import forwardOrderDetail from './forward-order-detail'
import forwardOrderClearance from './forward-order-clearance'

export default {
  components: {
    editWareWarrant,
    editForwardDialog,
    confirmForwardDialog,
    deleteForwardOrderDetailDialog,
    createOverseaDialog,
    forwardOrderDetail,
    forwardOrderClearance
  },
  data () {
    return {
      orderid: this.$route.query.order_id,
      orderdetail: {
        item: []
      },
      clearanceItem: [],
      detaillist: [],
      forwarddatalength: 0, // 海外入库单数组长度
      selectedList: [],
      selectItem: [],
      tabvalues: 'first',
      showtype: 1
    }
  },
  created () {
    this.getOrdertail()
  },
  methods: {
    getOrdertail () {
      const self = this
      forwardNewApi
        .getforwardNewOrderDetail({ id: self.orderid })
        .then(data => {
          self.orderdetail = data
          self.forwarddatalength = data._shipment.length
        })
        .catch(err => {
          console.log(err)
        })
    },
    getClearanceItems () {
      forwardNewApi
        .getforwardClearanceItems({ id: this.orderid })
        .then(data => {
          this.clearanceItem = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleClick (tab, event) {
      if (tab.name === 'second') {
        this.getClearanceItems()
      }
    },
    handleSelectionChange (val) {
      this.detaillist = []
      val.forEach(item => {
        this.detaillist.push(item.label)
      })
    },
    //  删除货代单
    deleteTransportDetail () {
      const self = this
      self
        .$confirm('提示', {
          title: '删除货代单',
          message: '删除货代单的同时，所有明细将退回至货代计划,请确认是否删除'
        })
        .then(_ => {
          forwardNewApi
            .deleteforwardNewOrder({ id: self.orderdetail.id })
            .then(data => {
              self.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //  选中checkbox项的回调
    change () {
      this.getOrdertail()
      this.getClearanceItems()
    },
    // 创建海外入库单
    setShipment () {
      const self = this
      const jsonData = {
        id: self.orderid
      }
      self
        .$confirm('提示', {
          title: '创建海外入库单',
          message:
            '目前FBA仓库无法追踪入库数量差异，暂时无法自动创建报损单和调整单。 海外仓入库单创建后不可更改，确定创建？'
        })
        .then(_ => {
          forwardNewApi
            .setShipment(jsonData)
            .then(data => {
              console.log(data)
              this.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
