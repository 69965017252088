<!-- 确认货代单 -->
<template>
  <el-dialog title="货代单确认" :visible.sync="visible" width="500px">
    <h3 style="color: red">货代单确认后不可撤销，确认操作吗？</h3>
    <el-checkbox v-model="checked"
      >为可发数量不足的明细补录“采购入库单”（仅限RMB结算的采购单）</el-checkbox
    >
    <br />

    <el-table
      style="margin-top:30px"
      :data="warehouseList"
      v-show="checked"
      @current-change="selectOneVendor"
    >
      <el-table-column label="选择">
        <template v-slot="scope">
          <el-radio v-model="radio" :label="scope.row.id + ''">{{
            scope.row.id
          }}</el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="仓库名称"></el-table-column>
    </el-table>
    <span slot="footer">
      <el-button size="small" @click.prevent="confirmforward" type="primary"
        >确定</el-button
      >
      <el-button size="small" @click="hide" type="default">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as forwardOrderAPI from '../../../api/forwardOrder'
import { mapGetters } from 'vuex'

export default {
  props: ['fowardid'],
  data () {
    return {
      checked: false,
      visible: false,
      radio: '',
      warehouse: null,
      conformdata: {
        id: this.fowardid,
        warehouse: null
      },
      warehouseList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },

  created () {
    this.warehouseList = this.sysConfig.warehouselist.filter(item => {
      return item.country === '10'
    })
  },
  methods: {
    selectOneVendor (row) {
      this.radio = row.id + ''
      this.conformdata.warehouse = row.id
    },
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    confirmforward () {
      if (this.checked === true) {
        if (this.conformdata.warehouse === null) {
          this.$alert('请选择仓库！')
          return false
        }
      } else {
        this.conformdata.warehouse = null
      }

      forwardOrderAPI
        .confirmTransportOrder(this.conformdata)
        .then(data => {
          this.$emit('confirmsuccess')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
