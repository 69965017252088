<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-if="ec_id === 3">wayfair</el-breadcrumb-item>
        <el-breadcrumb-item v-else-if="ec_id === 4">shopify</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨详情</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>库存调拨</h3>
      </el-col>
      <el-col :span="3" :offset="18">
        <el-button
          type="text"
          @click="
            $refs['edit-allot-dialog'].show(
              allotdetail.id,
              true,
              allotdetail.warehouse_from,
              allotdetail.warehouse_to,
              allotdetail.date,
              allotdetail.remark
            )
          "
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :span="6"> ID: &nbsp;&nbsp; {{ allotdetail.id }} </el-col>
      <el-col :span="5" :offset="1">
        流程: &nbsp;&nbsp;{{ allotdetail._step }}
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :span="6">
        仓库: &nbsp;&nbsp; {{ allotdetail._warehouse_from }} -
        {{ allotdetail._warehouse_to }}
      </el-col>
      <el-col :span="5" :offset="1">
        计划日期: &nbsp;&nbsp; {{ allotdetail.date }}
      </el-col>
      <el-col :span="5" :offset="1">
        创建时间: &nbsp;&nbsp; {{ allotdetail.created_at }}
      </el-col>
      <el-col :span="5" :offset="1">
        最后更新: &nbsp;&nbsp; {{ allotdetail.updated_at }}
      </el-col>
    </el-row>
    <el-row style="margin-top: 15px">
      <el-col :span="2">
        <el-button
          type="primary"
          size="mini"
          v-if="allotdetail.step === 1"
          @click="confirmAllot()"
          >确定提交</el-button
        >
      </el-col>
      <el-col :span="2" :offset="1">
        <el-button
          type="primary"
          size="mini"
          v-if="allotdetail.step === 1"
          @click="deleteAllot()"
          >删除</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-col :span="3" :offset="6">
        <el-button
          type="text"
          size="small"
          @click="$refs['import-detail-dialog'].show(allotdetail.id)"
          >导入明细</el-button
        >
      </el-col>
    </el-row>
    <el-row >
      <el-table border stripe width="100%" :data="allotdetail.item">
        <el-table-column label="平台SKU" prop="sku"></el-table-column>
        <el-table-column label="仓库SKU" prop="_sku"></el-table-column>
        <el-table-column label="Qty" prop="qty"></el-table-column>
      </el-table>
    </el-row>
    <import-detail-dialog
      ref="import-detail-dialog"
      @success-import-allot="getTransferDetail()"
    ></import-detail-dialog>
    <edit-allot-dialog
      ref="edit-allot-dialog"
      @edit-success="getTransferDetail()"
    ></edit-allot-dialog>
  </div>
</template>
<script>
import {
  getSalesTransferDetail,
  confirmSalesTransfer,
  deleteSalesTransfer
} from '@/api/amazon'
import importDetailDialog from './import-detail-dialog'
import editAllotDialog from './create-allot-dialog'
export default {
  components: {
    importDetailDialog,
    editAllotDialog
  },
  data () {
    return {
      allotdetail: {},
      id: this.$route.params.id,
      ec_id: this.$route.params.ec_id
    }
  },
  created () {
    this.getTransferDetail()
  },
  methods: {
    getTransferDetail () {
      getSalesTransferDetail({ id: this.id })
        .then(data => {
          this.allotdetail = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmAllot () {
      this.$confirm('提示', {
        title: '确认调拨单',
        message: '确定提交调拨单？'
      })
        .then(_ => {
          confirmSalesTransfer({ id: this.id })
            .then(data => {
              this.getTransferDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    deleteAllot () {
      this
        .$confirm('提示', { title: '删除调拨单', message: '确定删除调拨单？' })
        .then(_ => {
          deleteSalesTransfer({ id: this.id })
            .then(data => {
              this.$router.push({
                path: '/wayfair/inventory/allot'
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
