// 财务 仓库单据
import request from '../config/request'
//  采购出入库明细
export const getStockOutInDetail = data => {
  Array.isArray(data.warehouse_id) &&
    (data.warehouse_id = data.warehouse_id.join(','))
  return request('finance/wms/purchaseInOut', 'get', data, true).then(data => {
    return data
  })
}
//  调拨出入库明细
export const getAllotOutInDetail = data => {
  Array.isArray(data.warehouse_id) &&
    (data.warehouse_id = data.warehouse_id.join(','))
  return request('finance/wms/transferIO', 'get', data, true).then(data => {
    return data
  })
}
//  调拨统计
export const getAllotCountlist = data => {
  Array.isArray(data.warehouse_id) &&
    (data.warehouse_id = data.warehouse_id.join(','))
  return request('finance/wms/transferSum', 'get', data).then(data => {
    return data
  })
}
//  销售出入库明细
export const getSalesOutInList = data => {
  Array.isArray(data.warehouse_id) &&
    (data.warehouse_id = data.warehouse_id.join(','))
  return request('finance/wms/salesIO', 'get', data).then(data => {
    return data
  })
}
//  调整报损明细
export const getbreakageAdjustList = data => {
  Array.isArray(data.warehouse_id) &&
    (data.warehouse_id = data.warehouse_id.join(','))
  return request('finance/wms/adjustment', 'get', data).then(data => {
    return data
  })
}
