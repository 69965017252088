<template>
  <div>
    <img
      class="imghour"
      :src="
        cover
          ? cover
          : 'http://sd-storage-qiniu.soffeedesign.net/material/img/no-picture.jpg'
      "
      align="left"
      style="padding-bottom:1pc;margin-right:1pc"
      width="80"
      height="80"
    />
    <p style="line-height:1.3;">{{ title }}</p>
    <span
      style="font-size:10px;background:#409EFF;color:#fff;padding:1px 3px;border-radius: 3px;"
      >{{ mfn }}</span
    >
    <br />
    <span
      style="font-size:10px;background:#409EFF;color:#fff;padding:1px 3px;border-radius: 3px;"
      v-if="sku"
      >{{ sku }}</span
    >
  </div>
</template>
<script>
export default {
  props: ['cover', 'title', 'mfn', 'sku']
}
</script>
<style scoped>
.imghour:hover {
  transform: scale(10.4, 10.4);
}
</style>
