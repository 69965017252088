<template>
  <div>
    <el-dialog title="编辑仓库" :visible.sync="ui.display" width="40%">
      <el-form
        :model="dat"
        :rules="rules"
        inline
        size="small"
        label-width="100px"
        ref="warehouseedit"
      >
        <el-form-item prop="name" label="名称:">
          <el-input
            v-model="dat.name"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item prop="no" label="NO:">
          <el-input v-model="dat.no" autocomplete="off" size="small"></el-input>
        </el-form-item>

        <el-form-item prop="type" label="类型：">
          <el-select v-model="dat.type" size="small">
            <el-option
              v-for="item in sysConfig.WMSWarehouse.type"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="country" label="国家：">
          <el-select v-model="dat.country" size="small">
            <el-option
              v-for="item in sysConfig.Common.country"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="postcode" label="邮编:">
          <el-input
            v-model="dat.postcode"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item prop="province" label="省/州:">
          <el-input
            v-model="dat.province"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item prop="city" label="市:">
          <el-input
            v-model="dat.city"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="具体地址：">
          <el-input
            v-model="dat.addr"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="电话：">
          <el-input
            v-model="dat.tel"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系人：">
          <el-input
            v-model="dat.manager"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系人电话：">
          <el-input
            v-model="dat.phone"
            autocomplete="off"
            size="small"
            style="width:280px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="启用：">
          <el-switch
            style="display: block"
            v-model="dat.is_on"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text
            inactive-text
          ></el-switch>
        </el-form-item>

        <el-form-item prop="sass" label="账套设置:">
          <el-select
            v-model="dat.saas"
            placeholder="请选择账套"
            style="width:280px;"
          >
            <el-option
              v-for="item in sysConfig.Common.saas"
              :key="item.key"
              :value="item.val.id"
              :label="item.val.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save()" v-bind:disabled="!ui.edit"
          >保存</el-button
        >
        <el-button @click="close()" v-bind:disabled="!ui.edit">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiSaveWarehouse } from '@/api/wms'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['sysConfig'])
  },
  data () {
    return {
      ui: {
        width: '150',
        display: false,
        edit: true
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        country: [{ required: true, message: '请选择国家', trigger: 'blur' }],
        saas: [{ required: true, message: '请选择账套', trigger: 'blur' }]
      },
      dat: {}
    }
  },

  methods: {
    save: function () {
      const self = this
      self.$refs.warehouseedit.validate(valid => {
        if (valid) {
          this.ui.edit = false
          apiSaveWarehouse(this.dat).then(res => {
            this.$parent.lsWarehouse()
            this.close()
          })
        }
      })
    },

    close: function () {
      this.ui.display = false
    },

    open: function (dat = null) {
      this.ui.display = true
      this.ui.edit = true
      this.dat = dat
        ? JSON.parse(JSON.stringify(dat))
        : {
          id: 0,
          name: '',
          country: '',
          province: '',
          city: '',
          addr: '',
          tel: '',
          manager: '',
          phone: '',
          is_on: true,
          saas: ''
        }
    }
  }
}
</script>

<style></style>
