<template>
  <el-dialog
    title="Shipment 确认"
    :visible.sync="visible"
    width="800px"
    :show-close="true"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-tag v-if="step === '2'" style="margin-bottom: 20px">
      确定提交该Shipment？
    </el-tag>
    <el-tag v-if="step === '3'" style="margin-bottom: 20px">
      如果是SP自发，编辑模式务必设置为“不限制”，并在Amazon后台完成后续操作。
      确定提交当前 InboundShipment？
    </el-tag>
    <el-form size="small" label-width="120px">
      <el-form-item label="编辑模式">
        <el-select v-model="source">
          <el-option
            v-for="item in sysConfig.Common.SDK_MWS_FBAInboundShipment[0].val"
            :key="item.key"
            :label="item.val"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="物流状态" v-if="step === '3'">
        <el-select v-model="status">
          <el-option
            v-for="item in sysConfig.Common.SDK_MWS_FBAInboundShipment[1].val"
            :key="item.key"
            :label="item.val"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="confirm()" size="small">创建</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { confirmShipment } from '@/api/fbaship'
export default {
  data () {
    return {
      visible: false,
      step: '',
      source: '',
      status: '',
      shipmentId: ''
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (shipmentId, step) {
      this.visible = true
      this.step = step
      this.shipmentId = shipmentId
    },
    confirm () {
      const jsondata = {
        ShipmentId: this.shipmentId,
        status: this.status,
        source: this.source
      }
      confirmShipment(jsondata)
        .then(data => {
          this.$emit('success-confirm')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      this.step = ''
      this.source = ''
      this.status = ''
      done()
    },
    hide () {
      this.step = ''
      this.source = ''
      this.status = ''
      this.shipmentId = ''
      this.visible = false
    }
  }
}
</script>
