<template>
  <el-dialog
    title="Transport"
    :visible.sync="visible"
    width="1000px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-tabs v-model="tabvalue">
      <el-tab-pane label="Transport" name="first" :disabled="true">
        <el-row>
          <h4>Shipping method</h4>
        </el-row>
        <el-row :gutter="16" style="margin-bottom: 50px">
          <el-col :span="8">
            <el-radio
              v-model="ShipmentType"
              label="SP"
              style="line-height: 30px"
              >Small parcel delivery (SPD)<br />
              I'm shipping individual boxes</el-radio
            >
          </el-col>
          <el-col :span="8" :offset="2">
            <el-radio
              v-model="ShipmentType"
              label="LTL"
              style="line-height: 30px"
              >Less than truckload (LTL)<br />
              I'm shipping pallets; shipment at least 150 lb.</el-radio
            >
          </el-col>
        </el-row>
        <el-row>
          <h4>Shipping method</h4>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="8">
            <el-radio
              v-model="IsPartnered"
              :label="true"
              style="line-height: 30px"
              >Amazon-Partnered Carrier<br />
              Deeply-discounted shipping with easy tracking</el-radio
            >
          </el-col>
          <el-col :span="8" :offset="2">
            <el-radio v-model="IsPartnered" :label="false"
              >Other carrier</el-radio
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 100px">
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="small" @click="changeTab()"
              >下一步</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane
        label="Transport （SPD自提） 1+3"
        name="second"
        :disabled="true"
        v-if="ShipmentType === 'SP' && IsPartnered === true"
      >
        <el-row>
          <el-form
            size="small"
            :model="PartneredSmallParcelData"
            :inline="true"
            id="spdtrue"
          >
            <el-row>
              <el-form-item label="Carrier Name" label-width="120">
                <el-select v-model="PartneredSmallParcelData.CarrierName">
                  <el-option
                    v-for="item in shipServiceParam.CARRIER_NAME
                      .PartneredSmallParcelDataInput[country]"
                    :key="item.key"
                    :value="item.val"
                    :label="item.val"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="包裹清单" label-width="120">
                <el-input
                  id="file"
                  v-model="PartneredSmallParcelData.PackingList"
                  name="file"
                  type="file"
                ></el-input>
                <!-- <el-upload
                          :action="importInboundOrder"
                          :limit="1"
                          name="file"
                          :multiple="false"
                          :on-success="onUploadSuccess"
                          :before-upload="handleBeforeUpload"
                          :on-error="onUploadError"
                          :auto-upload="false"
                          :show-file-list="true"
                          ref="upload"
                          :data="ruledatasp"
                          >
                          <el-button size="small" type="primary">选取excel文件</el-button>
                      </el-upload> -->
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label-width="20px" label>
                <p>
                  <el-link
                    type="primary"
                    :href="downloadTemplate"
                    target="_blank"
                    >下载材积模板</el-link
                  >
                  务必注意：此操作将彻底清空先前的所有数据。
                </p>
              </el-form-item>
            </el-row>
          </el-form>
        </el-row>
        <el-row>
          <el-col :span="3">
            <el-button type="primary" size="small" @click="tabvalue = 'first'"
              >上一步</el-button
            >
          </el-col>
          <el-col :span="3" :offset="18">
            <el-button
              type="primary"
              size="small"
              @click="saveTransportContent()"
              >确认保存</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane
        label="Transport （LTL自提）2+3"
        name="third"
        :disabled="true"
        v-if="ShipmentType === 'LTL' && IsPartnered === true"
      >
        <el-row>
          <el-form
            size="small"
            :inline="true"
            label-width="80px"
            :model="PartneredLtlData"
          >
            <el-row>
              <el-form-item label="联系人 *" prop="Name">
                <el-input
                  placeholder="姓名"
                  v-model="PartneredLtlData.Contact.Name"
                ></el-input>
              </el-form-item>
              <el-form-item prop="Phone">
                <el-input
                  placeholder="电话"
                  v-model="PartneredLtlData.Contact.Phone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="Fax">
                <el-input
                  placeholder="传真"
                  v-model="PartneredLtlData.Contact.Fax"
                ></el-input>
              </el-form-item>
              <el-form-item prop="Email">
                <el-input
                  placeholder="邮箱"
                  v-model="PartneredLtlData.Contact.Email"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="总箱数 *" prop="BoxCount">
                <el-input-number
                  v-model="PartneredLtlData.BoxCount"
                ></el-input-number>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="重量级">
                <el-select v-model="PartneredLtlData.SellerFreightClass">
                  <el-option
                    v-for="item in shipServiceParam.FREIGHT_CLASS"
                    :key="item.key"
                    :label="item.val"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="自提日期" prop="FreightReadyDate">
                <el-date-picker
                  v-model="PartneredLtlData.FreightReadyDate"
                  type="date"
                  placeholder="请选择日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="货值">
                <el-input
                  v-model="PartneredLtlData.SellerDeclaredValue.Value"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-select
                  placeholder="币种"
                  v-model="PartneredLtlData.SellerDeclaredValue.CurrencyCode"
                >
                  <el-option
                    v-for="item in shipServiceParam.CURRENCY_CODE"
                    :key="item.key"
                    :label="item.val"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="托盘材积">
                <el-input
                  id="fileT"
                  v-model="PartneredLtlData.PalletList"
                  name="file"
                  type="file"
                ></el-input>
                <!-- <el-upload
                          :action="importInboundOrder"
                          :limit="1"
                          name="file"
                          :multiple="false"
                          :on-success="onUploadSuccess"
                          :before-upload="handleBeforeUpload"
                          :on-error="onUploadError"
                          :auto-upload="false"
                          :show-file-list="true"
                          ref="upload"
                          :data="ruledataLTL"
                          >
                          <el-button size="small" type="primary">选取excel文件</el-button>
                          </el-upload> -->
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label-width="20px" label>
                <p>
                  <el-link
                    type="primary"
                    :href="downloadTemplate"
                    target="_blank"
                    >下载材积模板</el-link
                  >
                  务必注意：此操作将彻底清空先前的所有数据。
                </p>
              </el-form-item>
            </el-row>
          </el-form>
        </el-row>
        <el-row>
          <el-col :span="3">
            <el-button type="primary" size="small" @click="tabvalue = 'first'"
              >上一步</el-button
            >
          </el-col>
          <el-col :span="3" :offset="18">
            <el-button
              type="primary"
              size="small"
              @click="saveTransportContent()"
              >确认保存</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane
        label="Transport （SPD 自发货） 1+4"
        name="fourth"
        :disabled="true"
        v-if="ShipmentType === 'SP' && IsPartnered === false"
      >
        <el-row>
          <el-form
            :inline="true"
            label-width="120px"
            size="small"
            :model="NonPartneredSmallParcelData"
          >
            <el-row>
              <el-form-item label="Carrier Name">
                <el-select v-model="NonPartneredSmallParcelData.CarrierName">
                  <el-option
                    v-for="item in shipServiceParam.CARRIER_NAME
                      .NonPartneredSmallParcelData[country]"
                    :key="item.key"
                    :value="item.val"
                    :label="item.val"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="Tracking Number">
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="NonPartneredSmallParcelData.PackageList"
                  placeholder="一行一个快递单号 快递单号总数必须和箱规中的箱数完全一致"
                ></el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </el-row>
        <el-row>
          <el-col :span="3">
            <el-button type="primary" size="small" @click="tabvalue = 'first'"
              >上一步</el-button
            >
          </el-col>
          <el-col :span="3" :offset="18">
            <el-button type="primary" size="small" @click="saveTransportContent"
              >确认保存</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane
        label="Transport （LTL 自发货） 2+4"
        name="firth"
        :disabled="true"
        v-if="ShipmentType === 'LTL' && IsPartnered === false"
      >
        <el-row>
          <el-form
            :inline="true"
            label-width="120px"
            size="small"
            :model="NonPartneredLtlData"
            :rules="ruleltlfalse"
            ref="LTLfalse"
          >
            <el-row>
              <el-form-item label="Carrier Name">
                <el-select v-model="NonPartneredLtlData.CarrierName">
                  <el-option
                    v-for="item in shipServiceParam.CARRIER_NAME
                      .NonPartneredLtlData[country]"
                    :key="item.key"
                    :value="item.val"
                    :label="item.val"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="ProNumber" prop="ProNumber">
                <el-input
                  type="text"
                  v-model="NonPartneredLtlData.ProNumber"
                  placeholder="7-10位 长度"
                ></el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </el-row>
        <el-row>
          <el-col :span="3">
            <el-button type="primary" size="small" @click="tabvalue = 'first'"
              >上一步</el-button
            >
          </el-col>
          <el-col :span="3" :offset="18">
            <el-button
              type="primary"
              size="small"
              @click="saveTransportContent()"
              >确认保存</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
import * as fbaAPI from '@/api/fbaship'
export default {
  data () {
    var proNumberValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入7~10位长度的ProNumber'))
      } else if (value.length < 7) {
        callback(new Error('你输入的长度位数小于7'))
      } else if (value.length > 10) {
        callback(new Error('你输入的长度位数大于10'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      ShipmentId: '',
      ShipmentType: '',
      IsPartnered: true,
      tabvalue: 'first',
      country: '',
      shipServiceParam: {},
      PartneredSmallParcelData: {},
      PartneredLtlData: {
        Contact: {},
        SellerDeclaredValue: {}
      },
      NonPartneredSmallParcelData: {},
      NonPartneredLtlData: {},
      ruleltlfalse: {
        ProNumber: [
          { required: true, validator: proNumberValidator, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    downloadTemplate () {
      let tpl = ''
      if (this.ShipmentType === 'SP' && this.IsPartnered === true) {
        tpl = 'PackingList'
      } else if (this.ShipmentType === 'LTL' && this.IsPartnered === true) {
        tpl = 'PalletList'
      }
      return (
        baseUrl +
        'fba/inboundShipment/excel?token=' +
        localStorage.getItem('token') +
        '&tpl=' +
        tpl
      )
    }
  },
  created () {
    this.getShipServicesParam()
  },
  methods: {
    show (tocountry, ShipmentId, detailshipService) {
      this.visible = true
      this.country = tocountry
      this.ShipmentId = ShipmentId
      this.IsPartnered = detailshipService.IsPartnered
      this.ShipmentType = detailshipService.ShipmentType
      if (detailshipService.TransportDetails.PartneredLtlData) {
        this.PartneredLtlData.Contact =
          detailshipService.TransportDetails.PartneredLtlData.Contact
      }
      console.log(this.PartneredLtlData.Contact)
      this.getShipServicesParam()
    },
    changeTab () {
      if (this.ShipmentType === 'SP' && this.IsPartnered === true) {
        this.tabvalue = 'second'
      } else if (this.ShipmentType === 'LTL' && this.IsPartnered === true) {
        this.tabvalue = 'third'
      } else if (this.ShipmentType === 'SP' && this.IsPartnered === false) {
        this.tabvalue = 'fourth'
      } else if (this.ShipmentType === 'LTL' && this.IsPartnered === false) {
        this.tabvalue = 'firth'
      }
    },
    getShipServicesParam () {
      fbaAPI
        .getshippingServicesParam()
        .then(data => {
          this.shipServiceParam = data.OPTION
          // this.shipServiceParam.CARRIER_NAME.PartneredSmallParcelDataInput = data.OPTION.CARRIER_NAME.PartneredSmallParcelDataInput
          // this.shipServiceParam.CARRIER_NAME.NonPartneredSmallParcelData = data.OPTION.CARRIER_NAME.NonPartneredSmallParcelData
          // this.shipServiceParam.CARRIER_NAME.NonPartneredLtlData = data.OPTION.CARRIER_NAME.NonPartneredLtlData
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveTransportContent () {
      if (this.ShipmentType === 'SP' && this.IsPartnered === true) {
        const formdata = new FormData()
        formdata.append('ShipmentId', this.ShipmentId)
        formdata.append('IsPartnered', this.IsPartnered)
        formdata.append('ShipmentType', this.ShipmentType)
        formdata.append(
          'TransportDetails[PartneredSmallParcelData][CarrierName]',
          this.PartneredSmallParcelData.CarrierName
        )
        formdata.append('file', document.getElementById('file').files[0])
        // let jsonData = {
        //     ShipmentId: this.ShipmentId,
        //     IsPartnered: this.IsPartnered,
        //     ShipmentType: this.ShipmentType,
        //     TransportDetails: {
        //         PartneredSmallParcelData: {
        //             CarrierName: this.PartneredSmallParcelData.CarrierName,
        //             PackageList: this.PartneredSmallParcelData.PackingList
        //         }
        //     }
        // }
        fbaAPI
          .setTransportContent(formdata)
          .then(data => {
            this.$emit('save-transport-success')
            this.visible = false
          })
          .catch(err => {
            console.log(err)
          })
      } else if (this.ShipmentType === 'LTL' && this.IsPartnered === true) {
        const formdata = new FormData()
        formdata.append('ShipmentId', this.ShipmentId)
        formdata.append('IsPartnered', Number(this.IsPartnered))
        formdata.append('ShipmentType', this.ShipmentType)
        formdata.append(
          'TransportDetails[PartneredLtlData][Contact][Name]',
          this.PartneredLtlData.Contact.Name
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][Contact][Phone]',
          this.PartneredLtlData.Contact.Phone
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][Contact][Fax]',
          this.PartneredLtlData.Contact.Fax
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][Contact][Email]',
          this.PartneredLtlData.Contact.Email
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][BoxCount]',
          this.PartneredLtlData.BoxCount
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][SellerFreightClass]',
          this.PartneredLtlData.SellerFreightClass
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][FreightReadyDate]',
          this.PartneredLtlData.FreightReadyDate
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][SellerDeclaredValue][Value]',
          this.PartneredLtlData.SellerDeclaredValue.Value
        )
        formdata.append(
          'TransportDetails[PartneredLtlData][SellerDeclaredValue][CurrencyCode]',
          this.PartneredLtlData.SellerDeclaredValue.CurrencyCode
        )
        formdata.append('file', document.getElementById('fileT').files[0])
        fbaAPI
          .setTransportContent(formdata)
          .then(data => {
            this.$emit('save-transport-success')
            this.visible = false
          })
          .catch(err => {
            console.log(err)
          })
      } else if (this.ShipmentType === 'SP' && this.IsPartnered === false) {
        const jsonData = {
          ShipmentId: this.ShipmentId,
          IsPartnered: this.IsPartnered,
          ShipmentType: this.ShipmentType,
          TransportDetails: {
            NonPartneredSmallParcelData: {
              CarrierName: this.NonPartneredSmallParcelData.CarrierName,
              PackageList: this.NonPartneredSmallParcelData.PackageList.split(
                '\n'
              )
            }
          }
        }
        fbaAPI
          .setTransportContent(jsonData)
          .then(data => {
            this.$emit('save-transport-success')
            this.visible = false
          })
          .catch(err => {
            console.log(err)
          })
      } else if (this.ShipmentType === 'LTL' && this.IsPartnered === false) {
        this.$refs.LTLfalse.validate(valid => {
          if (valid) {
            const jsonData = {
              ShipmentId: this.ShipmentId,
              IsPartnered: this.IsPartnered,
              ShipmentType: this.ShipmentType,
              TransportDetails: {
                NonPartneredLtlData: {
                  CarrierName: this.NonPartneredLtlData.CarrierName,
                  ProNumber: this.NonPartneredLtlData.ProNumber
                }
              }
            }
            fbaAPI
              .setTransportContent(jsonData)
              .then(data => {
                this.$emit('save-transport-success')
                this.visible = false
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
      }
    },

    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('save-transport-success')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    },

    closedone (done) {
      this.visible = false
      this.country = ''
      this.tabvalue = 'first'
      this.ShipmentType = ''
      this.IsPartnered = ''
      this.ShipmentType = ''
      done()
    }
  }
}
</script>
