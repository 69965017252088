<template>
  <div class="create-prepayment">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'prepayment' }"
          >预付款</el-breadcrumb-item
        >
        <el-breadcrumb-item>到货请款</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-tabs v-model="activeName">
      <el-tab-pane label="1 导入Excel" name="first">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="80px"
          style="width:600px"
        >
          <!-- <el-form-item prop="vendorId" label="供应商">
            <el-select v-model="ruleForm.vendorId" placeholder="选择供应商" filterable clearable>
              <el-option
                v-for="item in vendorList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="currency" label="币种">
            <el-select v-model="ruleForm.currency" placeholder="选择币种" filterable clearable>
              <el-option
                v-for="item in currencyList"
                :key="item.key"
                :value="item.key"
                :label="item.val"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="文件" ref="excel" prop="file">
            <el-upload
              :action="uploadUrl"
              :limit="1"
              name="excel"
              :multiple="false"
              :on-success="onUploadSuccess"
              :before-upload="handleBeforeUpload"
              :on-error="onUploadError"
              :auto-upload="false"
              :show-file-list="true"
              ref="upload"
              :on-change="chUploadFile"
              :before-remove="rmUploadFile"
            >
              <el-button size="mini" type="primary">选取excel文件</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item>
            务必保证上传文件的模板格式符合要求，
            <el-link
              type="primary"
              :href="tplOfPurchaseItemToPrepayment"
              target="_blank"
              >点击下载模板。</el-link
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleConfirm()" size="mini"
              >上传</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="2 确认创建预付款" disabled name="second">
        <el-row>
          <div v-for="(item, index) in responDat.detail" :key="index">
            <el-row>
              <el-col :span="5">采购单： &nbsp;&nbsp;{{ item.id }}</el-col>
              <el-col :span="5" :offset="1"
                >供应商： &nbsp;&nbsp;{{ item.vendor }}</el-col
              >
            </el-row>
            <el-row>
              <el-col :span="5"
                >预付比例： &nbsp;&nbsp;{{ item.prepay_ratio }}</el-col
              >
              <el-col :span="5" :offset="1"
                >总采购额： &nbsp;&nbsp;{{ item.goods_total }}</el-col
              >
              <el-col :span="5" :offset="1"
                >已请款： &nbsp;&nbsp;{{ item.prepay_amount }}</el-col
              >
              <el-col :span="5" :offset="1"
                >可请款： &nbsp;&nbsp;{{ item.prepayable_amount }}</el-col
              >
            </el-row>
            <el-row >
              <el-table stripe border :data="item.item" width="100%">
                <el-table-column label="品名编号">
                  <template slot-scope="scope">
                    <product-info-in-table-cell
                      :cover="scope.row.product.cover"
                      :title="scope.row.product.name"
                      :mfn="scope.row.product.mfn"
                    ></product-info-in-table-cell>
                  </template>
                </el-table-column>
                <el-table-column prop="qty" label="交付数"></el-table-column>
                <el-table-column prop="price" label="单价"></el-table-column>
                <el-table-column
                  prop="goods_total"
                  label="货值"
                ></el-table-column>
                <el-table-column
                  prop="amount"
                  label="请款金额"
                ></el-table-column>
              </el-table>
            </el-row>
          </div>
        </el-row>
        <el-row style="margin-top: 10px;">
          <el-input v-model="remark" placeholder="预付款备注"></el-input>
        </el-row>
        <el-row style="margin-top: 10px;">
          <el-button type="primary" size="mini" @click="createPrepayForGoods()"
            >确认生成预付款</el-button
          >
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import productInfoInTableCell from '@/components/common/product-info-table-cell'
import { baseUrl } from '@/config/env.js'
import { mapGetters } from 'vuex'
import { submitPrepayForGoods } from '@/api/purchaseFinanceNew'
export default {
  components: {
    productInfoInTableCell
  },
  data () {
    return {
      activeName: 'first',
      vendorList: [],
      currencyList: [],
      ruleForm: {
        vendorId: '',
        currency: '',
        file: null
      },
      remark: '',
      // rules: {
      //   vendorId: [{ required: true, trigger: 'change' }],
      //   currency: [{ required: true, trigger: 'change' }],
      //   file: [{ required: true, trigger: 'change' }]
      // },

      lastPayData: {},
      responDat: {}
      // responseDat: {
      //   cache: '',
      //   report: {
      //     excel: [],
      //     vendor: {},
      //   }
      // },
    }
  },
  created () {
    this.responDat = ''
  },
  mounted () {
    this.responDat = ''
    this.currencyList = this.sysConfig.Common.currency
    this.vendorList = this.sysConfig.vendorList
  },

  computed: {
    ...mapGetters(['sysConfig']),
    tplOfPurchaseItemToPrepayment () {
      return 'https://sd.resource.soffeedesign.net/tpl/采购-到货请款模版.xls?v=1'
    },
    //
    uploadUrl () {
      return (
        baseUrl +
        'purchase/finance/earnest/submitPrepayForGoods?token=' +
        localStorage.getItem('token')
      )
    }
  },
  methods: {
    handleConfirm () {
      this.$refs.upload.submit()
    },
    createPrepayForGoods () {
      submitPrepayForGoods({
        cache: this.responDat.cache,
        remark: this.remark
      })
        .then(data => {
          this.$router.push({ path: '/stock/financeNew/prepayment' })
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$notify({ message: response.msg })
      this.responDat = response.dat
      this.activeName = 'second'
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    },

    chUploadFile () {
      this.ruleForm.file = true
      this.$refs.excel.clearValidate()
    },

    rmUploadFile () {
      this.ruleForm.file = null
    }
  }
}
</script>
<style>
.title-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.title-row span {
  margin-left: 40px;
}
.bottom-line {
  padding: 10px 10px;
  border-bottom: 1px solid gray;
}
</style>
