import request from '../config/request'

/** 公用接口 */

// 仓库列表simple
export const getWarehouseSimple = () => {
  return request('wms/warehouse', 'get', { simple: true }, true)
}

// 供应商列表simple
export const getVendorSimple = () => {
  return request('purchase/vendor', 'get', { simple: 1 }, true)
}
