<template>
  <div>
    <el-row>
      <el-col :span="4" :offset="18">
        <el-button
          type="primary"
          size="small"
          @click.prevent="$refs['create-invoice-dialog'].show(vendorid, {})"
          >新增开票信息</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table stripe width="100%" border :data="invoiceData">
        <el-table-column label="公司名称">
          <template slot-scope="scope">
            {{ scope.row.company }}
            <el-tag v-if="scope.row.top === 1">默认</el-tag> <br />
            {{ scope.row.no }}
          </template>
        </el-table-column>
        <el-table-column label="地址/电话">
          <template slot-scope="scope">
            {{ scope.row.addr }} <br />
            {{ scope.row.tel }}
          </template>
        </el-table-column>
        <el-table-column label="开户行" prop="bank"></el-table-column>
        <el-table-column label="对公账号" prop="card"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="$refs['create-invoice-dialog'].show(vendorid, scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="delinvoice(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <create-invoice-dialog
      ref="create-invoice-dialog"
      @save-success-invoice="getVendorInvoice()"
    ></create-invoice-dialog>
  </div>
</template>
<script>
import * as vendorApi from '@/api/purchase'
import createInvoiceDialog from './create-invoice-dialog'
export default {
  props: ['vendorid'],
  components: {
    createInvoiceDialog
  },
  data () {
    return {
      invoiceData: []
    }
  },
  watch: {
    vendorid: function () {
      this.getVendorInvoice()
    }
  },
  created () {
    // this.getVendorInvoice()
  },
  methods: {
    getVendorInvoice () {
      vendorApi
        .getVendorDetail({ id: this.vendorid })
        .then(data => {
          this.invoiceData = data.invoice
        })
        .catch(err => {
          console.log(err)
        })
    },
    delinvoice (id) {
      this.$confirm('确认是否删除该条开票信息？')
        .then(_ => {
          vendorApi
            .delInvoice({ id: id })
            .then(data => {
              this.getVendorInvoice()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
