<template>
  <el-dialog title="编辑/新增货代单" :visible.sync="visible" width="600px">
    <el-form
      :model="order"
      label-width="120px"
      ref="thisForm"
      label-position="left"
      :rules="rules"
    >
      <el-form-item prop="name" size="small" label="名称">
        <el-input v-model="order.name" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item prop="warehouse_id" size="small" label="目的仓库">
        <el-select v-model="order.warehouse_id" style="width:220px ">
          <el-option
            v-for="item in warehouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="transport_type" size="small" label="发货方式">
        <el-select v-model="order.transport_type" style="width:220px ">
          <el-option
            v-for="item in transportList"
            :key="item.key"
            :label="item.val"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="date" size="small" label="预计发货日期">
        <el-date-picker
          v-model="order.date"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="remark" size="small" label="备注">
        <el-input v-model="order.remark" type="textarea" rows="5"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click.prevent="save" type="primary"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as forwardApi from '@/api/forward'
import { mapGetters } from 'vuex'
import * as forwardOrderAPI from '../../../api/forwardOrder'

export default {
  props: ['selectItem', 'orderDetail'],
  data () {
    return {
      visible: false,
      //  ifEdit值为0新建货代单 值为1属于编辑修改货代单 值为2属于移动到新货代单明细
      ifEdit: 0,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      order: {},
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        transport_id: [{ required: true, trigger: 'change' }],
        warehouse_id: [{ required: true, trigger: 'change' }],
        date: [{ required: true, trigger: 'blur' }]
      },
      warehouseList: [],
      transportList: []
    }
  },
  created () {
    this.warehouseList = this.sysConfig.warehouselist
    this.transportList = this.sysConfig.Common.transport_type
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    save () {
      if (this.ifEdit === 0) {
        const data = {
          order: this.order,
          item: this.selectItem
        }

        this.$refs.thisForm.validate(valid => {
          if (valid) {
            forwardApi.createForward(data).then(res => {
              this.hide()
              this.$emit('change')
            })
          } else {
            return false
          }
        })
      } else if (this.ifEdit === 1) {
        //  编辑货代单
        this.$refs.thisForm.validate(valid => {
          if (valid) {
            forwardOrderAPI
              .saveTransportOrderDetail(this.order)
              .then(data => {
                this.hide()
                this.$emit('change')
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            return false
          }
        })
      } else if (this.ifEdit === 2) {
        // 移至新货代单
        const JSondata = {
          from: this.orderDetail.id,
          order: this.order,
          item: this.selectItem
        }
        console.log(this.selectItem)
        this.$refs.thisForm.validate(valid => {
          if (valid) {
            forwardOrderAPI
              .moveTransportOrderDetailItem(JSondata)
              .then(data => {
                this.hide()
                this.$emit('change')
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            return false
          }
        })
      }
    },
    show (id = null) {
      this.visible = true
      if (id === 1) {
        this.ifEdit = 1
        this.order = {
          id: this.orderDetail.id,
          name: this.orderDetail.name,
          warehouse_id: this.orderDetail.warehouse_id,
          transport_type: this.orderDetail.transport_type,
          date: this.orderDetail.date,
          remark: this.orderDetail.remark
        }
      }
      if (id === 2) {
        this.ifEdit = 2
      }
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
