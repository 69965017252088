<template>
  <el-dialog
    title="上传箱规"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-form label-width="80px">
      <el-form-item label-width="80px" label="箱规">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="excel"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
          :data="ruledata"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="20px" label>
        <p>
          <el-link type="primary" :href="downloadTemplate" target="_blank"
            >下载材积模板</el-link
          >
          务必注意：此操作将彻底清空先前的所有数据；不上传默认使用采购箱规。
        </p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
export default {
  data () {
    return {
      visible: false,
      ShipmentId: ''
    }
  },
  computed: {
    downloadTemplate () {
      return (
        baseUrl +
        'fba/inboundShipment/excel?token=' +
        localStorage.getItem('token') +
        '&tpl=CartonContent'
      )
    },
    importInboundOrder () {
      return (
        baseUrl +
        'fba/inboundShipment/uploadCartonContent?token=' +
        localStorage.getItem('token')
      )
    },
    ruledata () {
      return {
        ShipmentId: this.ShipmentId
      }
    }
  },
  methods: {
    show (ShipmentId) {
      this.visible = true
      this.ShipmentId = ShipmentId
    },
    closedone (done) {
      done()
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('success-import-fbacarton')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
