import request from '../config/request'
export const getforwardNewList = data => {
  return request('logistics/plan/search', 'get', data).then(data => {
    return data
  })
}
export const createOrder = data => {
  return request('logistics/plan/createOrder', 'post', data).then(data => {
    return data
  })
}
export const addToOrder = data => {
  return request('logistics/plan/addToOrder', 'post', data).then(data => {
    return data
  })
}
export const editplanRemark = data => {
  return request('logistics/plan/updateRemarks', 'post', data).then(data => {
    return data
  })
}
//  货代单列表
export const getforwardNewOrderList = data => {
  return request('logistics/order/search', 'get', data, true).then(data => {
    return data
  })
}
//  货代单详情
export const getforwardNewOrderDetail = data => {
  return request('logistics/order/detail', 'get', data, true).then(data => {
    return data
  })
}
//  编辑货代单
export const saveforwardNewOrder = data => {
  return request('logistics/order/save', 'post', data, true).then(data => {
    return data
  })
}
//  删除货代单
export const deleteforwardNewOrder = data => {
  return request('logistics/order/delete', 'post', data, true).then(data => {
    return data
  })
}

export const deleteforwardNewOrderDetailItem = data => {
  return request('logistics/order/item/delete', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const moveforwardNewOrderDetailItem = data => {
  return request('logistics/order/item/move', 'post', data, true).then(data => {
    return data
  })
}

export const editforwardNewOrderDetailItem = data => {
  return request('logistics/order/item/edit', 'post', data, true).then(data => {
    return data
  })
}

export const confirmOrder = data => {
  return request('logistics/order/confirm', 'post', data).then(data => {
    return data
  })
}

export const setShipment = data => {
  return request('logistics/order/setShipment', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const delPlan = data => {
  return request('logistics/plan/delPlan', 'post', data, true).then(data => {
    return data
  })
}
