<template>
  <el-dialog title="编辑/新增货代单" :visible.sync="visible" width="600px">
    <el-form
      :model="order"
      label-width="120px"
      ref="thisForm"
      label-position="left"
      :rules="rules"
    >
      <el-form-item prop="name" size="small" label="名称">
        <el-input v-model="order.name" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item prop="warehouse_from" size="small" label="出发仓库">
        <el-select v-model="order.warehouse_from" style="width:220px ">
          <el-option
            v-for="item in warehouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="warehouse_id" size="small" label="目的仓库">
        <el-select v-model="order.warehouse_id" style="width:220px ">
          <el-option
            v-for="item in warehouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="transport_type" size="small" label="发货方式">
        <el-select v-model="order.transport_type" style="width:220px ">
          <el-option
            v-for="item in transportList"
            :key="item.key"
            :label="item.val"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="date" size="small" label="预计发货日期">
        <el-date-picker
          v-model="order.date"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="出口退税" prop="is_tax_refund">
        <el-switch
          v-model="order.is_tax_refund"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关"
          class="taxswitch"
        >
        </el-switch>
      </el-form-item>
      <el-form-item prop="remark" size="small" label="备注">
        <el-input v-model="order.remark" type="textarea" rows="5"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click.prevent="save" type="primary"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as forwardNewApi from '@/api/forwardNew'
import { mapGetters } from 'vuex'

export default {
  props: ['selectItem', 'orderDetail'],
  data () {
    return {
      visible: false,
      //  ifEdit值为0新建货代单 值为1属于编辑修改货代单 值为2属于移动到新货代单明细
      ifEdit: 0,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      order: {},
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        transport_id: [{ required: true, trigger: 'change' }],
        warehouse_from: [{ required: true, trigger: 'change' }],
        warehouse_id: [{ required: true, trigger: 'change' }],
        date: [{ required: true, trigger: 'blur' }]
      },
      warehouseList: [],
      transportList: []
    }
  },
  created () {
    this.warehouseList = this.sysConfig.warehouselist
    this.transportList = this.sysConfig.Common.transport_type
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    save () {
      const self = this
      if (self.ifEdit === 0) {
        const data = {
          order: this.order,
          item: this.selectItem
        }

        this.$refs.thisForm.validate(valid => {
          if (valid) {
            forwardNewApi.createOrder(data).then(res => {
              this.hide()
              this.$emit('change')
            })
          } else {
            return false
          }
        })
      } else if (self.ifEdit === 1) {
        //  编辑货代单
        this.$refs.thisForm.validate(valid => {
          if (valid) {
            forwardNewApi
              .saveforwardNewOrder(self.order)
              .then(data => {
                this.hide()
                this.$emit('change')
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            return false
          }
        })
      }
      // } else if (self.ifEdit === 2) {
      //   // 移至新货代单
      //   let JSondata = {
      //     from: self.orderDetail.id,
      //     order: self.order,
      //     item: self.selectItem,
      //   };
      //   console.log(self.selectItem)
      //   this.$refs['thisForm'].validate(valid => {
      //     if (valid) {
      //       forwardNewApi.moveforwardNewOrderDetailItem(JSondata)
      //         .then(data => {
      //           this.hide();
      //           this.$emit('change');
      //         })
      //         .catch(err => {
      //           console.log(err);
      //         });
      //     } else {
      //       return false;
      //     }
      //   });
      // }
    },
    show (id = null) {
      this.visible = true
      if (id === 1) {
        this.ifEdit = 1
        this.order = {
          id: this.orderDetail.id,
          name: this.orderDetail.name,
          warehouse_from: this.orderDetail.warehouse_from,
          warehouse_id: this.orderDetail.warehouse_id,
          transport_type: this.orderDetail.transport_type,
          date: this.orderDetail.date,
          is_tax_refund: this.orderDetail.is_tax_refund,
          remark: this.orderDetail.remark
        }
      }
      if (id === 2) {
        this.ifEdit = 2
      }
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
