import request from '@/config/request'

export const getPurchaseList = data => {
  return request('dataCenter/purchase/ls', 'get', data)
}
export const getPurchaseDetail = data => {
  return request('dataCenter/purchase/items', 'get', data)
}

export const getSaleOutList = data => {
  return request('dataCenter/wmsOrder/saleOut', 'get', data)
}
export const getdisposal = data => {
  return request('dataCenter/wmsOrder/disposal', 'get', data)
}
export const getmallStats = data => {
  return request('dataCenter/sales/mallStats', 'get', data)
}
export const getskuStats = data => {
  return request('dataCenter/sales/skuStats', 'get', data)
}
export const getrateOfReturn = data => {
  return request('dataCenter/sales/rateOfReturn', 'get', data)
}
export const geterrorLogs = data => {
  return request('dataCenter/sales/errorLogs', 'get', data)
}
export const sethandleLog = data => {
  return request('dataCenter/sales/handleLog', 'post', data)
}
