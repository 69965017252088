<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>FBA</el-breadcrumb-item>
        <el-breadcrumb-item>Inbound Shipment</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs">
      <el-tabs v-model="tabvalue">
        <el-tab-pane label="Basic Info" name="first">
          <el-row>
            <el-col :span="6">
              <h4>Inbound Shipment</h4>
            </el-col>
            <el-col :span="1" :offset="13">
              <el-button
                type="text"
                @click="$refs['edit-shipment-dialog'].show(detaildata)"
                >编辑</el-button
              >
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button type="text" @click="deleteShipment()">删除</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-form>
              <el-row>
                <el-col :span="5">
                  <el-form-item label="ID">
                    &nbsp;&nbsp;{{ detaildata.ShipmentId }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Name">
                    &nbsp;&nbsp;{{ detaildata.ShipmentName }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Status">
                    &nbsp;&nbsp;{{ detaildata._status }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Destination">
                    &nbsp;&nbsp;{{ detaildata.DestinationFulfillmentCenterId }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item label="Label Type">
                    &nbsp;&nbsp;{{ detaildata.LabelPrepType }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="原厂包装">
                    &nbsp;&nbsp;{{ detaildata.AreCasesRequired }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item label="仓库">
                    &nbsp;&nbsp;{{ detaildata._from }}~{{ detaildata._to }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="电商">
                    &nbsp;&nbsp;{{ detaildata.ec_no }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="流程">
                    &nbsp;&nbsp;{{ detaildata._step
                    }}<el-button type="text" @click="resetStatus()">{{
                      detaildata._status
                    }}</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="最后更新">
                    &nbsp;&nbsp;{{ detaildata.updated_at }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="备注">
                    &nbsp;&nbsp;{{ detaildata.remark }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="
                $refs['confirm-shipment-dialog'].show(
                  detaildata.ShipmentId,
                  step
                )
              "
              >确认提交</el-button
            >
          </el-row>
          <el-row>
            <el-col :span="6">
              <h4>Address From</h4>
            </el-col>
          </el-row>
          <el-row>
            <el-form>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Name">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.Name }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="CountryCode">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.CountryCode }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="State(Province)">
                    &nbsp;&nbsp;{{
                      detaildata.ShipFromAddress.StateOrProvinceCode
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="City">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.City }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Address">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.AddressLine1 }}
                    <el-tag
                      >{{ detaildata.ShipFromAddress.PostalCode }}
                    </el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>
          <el-row>
            <h4>Address To</h4>
          </el-row>
          <el-row>
            <el-form>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Name">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.Name }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="CountryCode">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.CountryCode }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="State(Province)">
                    &nbsp;&nbsp;{{
                      detaildata.ShipToAddress.StateOrProvinceCode
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="City">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.City }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Address">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.AddressLine1 }}
                    <el-tag>{{ detaildata.ShipToAddress.PostalCode }} </el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>
          <el-row>
            <h4>Item</h4>
          </el-row>
          <el-row>
            <el-table border stripe :data="detaildata.item">
              <el-table-column label="SKU" prop="SellerSKU"></el-table-column>
              <el-table-column label="库存" prop="inventory"></el-table-column>
              <el-table-column
                label="QTY"
                prop="QuantityShipped"
              ></el-table-column>
              <el-table-column
                label="采购装箱率"
                prop="purchase_qty_ctn"
              ></el-table-column>
              <el-table-column
                label="装箱率"
                prop="QuantityInCase"
              ></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Shipping Service" name="second" :disabled="true">
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <edit-shipment-dialog
      ref="edit-shipment-dialog"
      @edit-success="getfbaDetail()"
    ></edit-shipment-dialog>
    <confirm-shipment-dialog
      ref="confirm-shipment-dialog"
      @success-confirm="getfbaDetail()"
    ></confirm-shipment-dialog>
  </div>
</template>
<script>
import * as fbashipAPI from '@/api/fbaship'
import editShipmentDialog from './edit-shipment-dialog'
import confirmShipmentDialog from './confirm-shipment-dialog'
export default {
  components: {
    editShipmentDialog,
    confirmShipmentDialog
  },
  data () {
    return {
      tabvalue: 'first',
      fbaid: this.$route.params.id,
      step: this.$route.params.step,
      detaildata: {}
    }
  },
  created () {
    this.getfbaDetail()
  },
  methods: {
    getfbaDetail () {
      fbashipAPI
        .getShipmentDetail({ ShipmentId: this.fbaid })
        .then(data => {
          this.detaildata = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    resetStatus () {
      this.$confirm('提示', {
        title: '重置status状态',
        message: '确定重置入库单状态？'
      })
        .then(_ => {
          fbashipAPI
            .resetfbaStatus({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.$router.push({
                path: '/FBA/inbound-shipment',
                query: {
                  step: this.step
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    deleteShipment () {
      this.$confirm('提示', {
        title: '删除shipment',
        message: '删除后，相应的数量将移出计划统计,确定删除当前InboundShipment?'
      })
        .then(_ => {
          fbashipAPI
            .deleteShipment({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.$router.push({
                path: '/FBA/inbound-shipment',
                query: {
                  step: this.step
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    confirmShipment () {
      this.$confirm('提示', {
        title: '提交Shipment',
        message: '确定提交当前InboundShipment?'
      })
        .then(_ => {
          fbashipAPI
            .confirmShipment({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.getfbaDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
