import request from '../config/request'

export const apiSearchVendor = function (param = {}, page = 1, perPage = 20) {
  param.page = page
  param.num_per_page = perPage
  return request('purchase/vendor', 'get', param, true)
}

export const apiGetVendor = function (id) {
  return request('purchase/vendor/detail', 'get', { id: id }, true)
}

export const apiSaveVendor = function (dat) {
  return request('purchase/vendor/detail/save', 'post', dat, true)
}

export const saveInvoice = data => {
  return request('purchase/vendor/detail/saveInvoice', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const delInvoice = data => {
  return request('purchase/vendor/detail/delInvoice', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const getVendorDetail = data => {
  return request('purchase/vendor/detail', 'get', data, true).then(data => {
    return data
  })
}
