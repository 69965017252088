<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>补货报告</el-breadcrumb-item>
        <el-breadcrumb-item>策略设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h3>策略说明</h3>
    </el-row>
    <el-row>
      <el-form size="small" label-width="120px" label-position="left">
        <el-form-item label="名称">
          <el-input v-model="detaildata.name"></el-input>
        </el-form-item>
        <el-form-item label="电商站点">
          <el-select v-model="detaildata.ec_id">
            <el-option
              v-for="item in sysConfig.Common.ec"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用">
          <el-switch
            v-model="detaildata.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开"
            inactive-text="关"
            class="taxswitch"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="Oversize">
          <el-switch
            v-model="detaildata.size"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开"
            inactive-text="关"
            class="taxswitch"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="销量排名基准">
          <el-select v-model="detaildata.rk">
            <el-option
              v-for="item in sysConfig.ReplenishmentAmzSale.rk"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            :rows="5"
            type="textarea"
            v-model="detaildata.remark"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="3" :offset="2">
        <el-button type="primary" size="small" @click="saveStrategy()"
          >保存</el-button
        >
      </el-col>
    </el-row>
    <el-row v-if="id">
      <el-col :span="3">
        <h3>分组</h3>
      </el-col>
      <el-col :span="3" :offset="15">
        <el-button type="text" @click="addconfig()">新增</el-button>
      </el-col>
    </el-row>
    <el-row  v-if="id">
      <el-table border stripe :data="detaildata.config">
        <el-table-column label="销售排名占比(不超过100%)" width="200px">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.start"
              type="number"
              size="mini"
              class="inputm"
            ></el-input>
            <el-input
              v-model="scope.row.end"
              type="number"
              size="mini"
              class="inputm"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="维持销售天数" width="100px">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.days"
              type="number"
              class="inputm"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="权重（7日 / 14日 / 30日 / 60日 / 180日）"
          width="500px"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.day7"
              type="number"
              class="inputm"
            ></el-input>
            <el-input
              v-model="scope.row.day14"
              type="number"
              class="inputm"
            ></el-input>
            <el-input
              v-model="scope.row.day30"
              type="number"
              class="inputm"
            ></el-input>
            <el-input
              v-model="scope.row.day60"
              type="number"
              class="inputm"
            ></el-input>
            <el-input
              v-model="scope.row.day180"
              type="number"
              class="inputm"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteconfig(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row v-if="id">
      <el-col :span="3" :offset="2">
        <el-button type="primary" size="small" @click="saveStrategy()"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getstrategyDetail, addstrategy, alterstartegy } from '@/api/amazon'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      id: this.$route.params.id,
      detaildata: {
        name: '',
        ec_id: '',
        status: false,
        size: 0,
        rk: '',
        remark: ''
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    if (this.id) {
      this.getDetaildata()
    }
  },
  methods: {
    getDetaildata () {
      getstrategyDetail({ id: this.id })
        .then(data => {
          this.detaildata = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    saveStrategy () {
      if (!this.id) {
        addstrategy(this.detaildata)
          .then(data => {
            this.$router.push({
              path: '/replenish/strategy'
            })
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        alterstartegy(this.detaildata)
          .then(data => {
            this.getDetaildata()
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    deleteconfig (index) {
      this.$confirm('提示', {
        title: '删除分组明细行',
        message: '确认是否删除该行明细'
      })
        .then(_ => {
          this.detaildata.config.splice(index, 1)
          this.saveStrategy()
        })
        .catch(_ => {})
    },
    addconfig () {
      if (this.detaildata.config) {
        this.detaildata.config.push({
          start: 0,
          end: 0,
          days: 0,
          day7: 0,
          day14: 0,
          day30: 0,
          day60: 0,
          day180: 0
        })
      }
    }
  }
}
</script>
<style scoped>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
.inputm {
  width: 80px;
}
</style>
