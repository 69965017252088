import request from '../config/request'

/** 交付单 */
export const reimbursement = data => {
  return request('purchase/finance/reimbursement', 'get', data, true)
}
/** 交付单详情 */
export const reimbursementDetail = data => {
  return request('purchase/finance/reimbursement/detailJfd', 'get', data, true)
}
/** 创建报销单 */
export const createReimbursementOrder = data => {
  return request(
    'purchase/finance/reimbursement/createOrder',
    'post',
    data,
    true
  )
}

/** 交付单·添加至报销单 */
export const addToreimbursementOrder = data => {
  return request('purchase/finance/reimbursement/addToOrder', 'post', data)
}
/** 交付单·添加其他費用 */
export const addToreimbursementOtherFee = data => {
  return request('purchase/finance/reimbursement/detail/addFee', 'post', data)
}

/** 报销单列表 */
export const reimbursementOrder = data => {
  return request('purchase/finance/reimbursement/order', 'get', data, true)
}
/** 报销单明细 */
export const reimbursementOrderDetail = data => {
  return request(
    'purchase/finance/reimbursement/order/detailBxd',
    'get',
    data,
    true
  )
}
/** 报销单提交审核 */
export const reimbursementOrderDetailConfirm = data => {
  return request(
    'purchase/finance/reimbursement/order/detail/confirm',
    'post',
    data
  )
}
