export default [
  // 仓库单据 - 采购出入库明细
  {
    path: '/finance/warehouse-receipts/procurement-in-out',
    name: 'finance_warehouse-receipts_procurement-in-out',
    component: () => import('@/view/finance/warehouse-receipts/procurement-in-out/View.vue')
  },
  // 仓库单据 - 调拨出入库明细
  {
    path: '/finance/warehouse-receipts/allot-in-out',
    name: 'finance_warehouse-receipts_allot-in-out',
    component: () => import('@/view/finance/warehouse-receipts/allot-in-out/View.vue')
  },
  // 仓库单据 - 调整报损明细
  {
    path: '/finance/warehouse-receipts/breakage-adjustment',
    name: 'finance_warehouse-receipts_breakage-adjustment',
    component: () => import('@/view/finance/warehouse-receipts/breakage-adjustment/View.vue')
  },
  // 仓库单据 - 销售进出仓明细
  {
    path: '/finance/warehouse-receipts/sales-in-out',
    name: 'finance_warehouse-receipts_sales-in-out',
    component: () => import('@/view/finance/warehouse-receipts/sales-in-out/View.vue')
  },
  // 库存 - 月度库存结余
  {
    path: '/finance/inventory/monthly-balance',
    name: 'finance_inventory_monthly-balance',
    component: () => import('@/view/finance/inventory/monthly-balance/View')
  },
  // 采购管理 - 请款审核
  {
    path: '/finance/purchase/expenses-requisition',
    name: 'finance_purchase_expenses-requisition',
    component: () => import('@/view/finance/purchase/expenses-requisition/View')
  },
  // 采购管理 - 请款审核 详情
  {
    path: '/finance/purchase/expenses-requisition/:id',
    name: 'finance_purchase_expenses-requisition_detail',
    component: () => import('@/view/finance/purchase/expenses-requisition-detail/View')
  }
]
