import request from '@/config/request'

export const getPfinanceInvoice = data => {
  return request('purchase/finance/invoice', 'get', data, true).then(data => {
    return data
  })
}
// 获取供应商最后一次开票信息
export const getLastInvoiceRecord = data => {
  return request(
    'purchase/finance/invoice/lastInvoiceRecord',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}

export const createInvoice = data => {
  return request('purchase/finance/invoice/create', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const getInvoiceOrder = data => {
  return request('purchase/finance/invoice/order', 'get', data, true).then(
    data => {
      return data
    }
  )
}
// 追加至报销单
export const appendInvoice = data => {
  return request('purchase/finance/invoice/append', 'post', data, true).then(
    data => {
      return data
    }
  )
}
// 获取报销单详情
export const getInvoiceOrderDetail = data => {
  return request(
    'purchase/finance/invoice/order/detail',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}

export const rebackDeliver = data => {
  return request(
    'purchase/finance/invoice/order/reBack',
    'post',
    data,
    true
  ).then(data => {
    return data
  })
}

// 报销提交财务审核
export const submitInvoiceOrderFinance = data => {
  return request('purchase/finance/invoice/order/submit', 'post', data, true)
}
// 获取开票明细
export const getOrderTicketDetail = data => {
  return request(
    'purchase/finance/invoice/order/ticketDetail',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}
// 获取开票资料
export const getOrderTicketInfo = data => {
  return request(
    'purchase/finance/invoice/order/ticketInfo',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}
// 获取预付款列表
export const getEarnestFinance = data => {
  return request('purchase/finance/earnest/ls', 'get', data, true)
}
// 创建请款单
export const createEarnestFinance = data => {
  return request('purchase/finance/earnest/createOrder', 'post', data, true)
}
// 追加至请款单
export const appendEarnestFinance = data => {
  return request('purchase/finance/earnest/appendOrder', 'post', data, true)
}

// 请款单列表
export const getEarnestFinanceOrderLs = data => {
  return request('purchase/finance/earnest/orderLs', 'get', data, true)
}
// 请款单明细
export const getEarnestFinanceOrderDetail = data => {
  return request('purchase/finance/earnest/orderDetail', 'get', data, true)
}
// 编辑请款单
export const editEarnestFinance = data => {
  return request('purchase/finance/earnest/editOrder', 'post', data, true)
}
// 请款提交财务审核
export const submitOrderFinance = data => {
  return request('purchase/finance/earnest/orderSubmit', 'post', data, true)
}
// 到货请款，确认生成预付款
export const submitPrepayForGoods = data => {
  return request(
    'purchase/finance/earnest/submitPrepayForGoods/check',
    'post',
    data,
    true
  )
}

export const prepaymentSimple = data => {
  const param = {
    simple: 1,
    vendor_id: data.vendor_id,
    currency: data.currency,
    step: 1
  }
  return request('purchase/finance/earnest/orderLs', 'get', param, true)
}
