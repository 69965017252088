<template>
  <el-dialog
    title="报关单"
    :visible.sync="visible"
    width="600px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-tag type="waring">报关单仅允许上传一次，务必确保数据正确。</el-tag>
    <el-form label-position="left">
      <el-form-item label-width="10px">
        <el-upload
          :action="actions"
          :limit="500"
          multiple
          name="excel"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          :data="QiniuData"
          ref="upload"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="10px" label>
        <p>
          <el-link type="primary" :href="downloadTemplate" target="_blank"
            >下载上传模板</el-link
          >
          报关单数据涉及报销和出口退税，务必认真操作。
        </p>
      </el-form-item>
    </el-form>
    <!-- <el-row>
            <div v-loading="loading"  :element-loading-text="loadtext">
                {{dataresult}}
            </div>
      </el-row> -->
    <span slot="footer">
      <el-button size="small" @click="hide">取 消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >上 传</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  data () {
    return {
      formdata: {},
      visible: false,
      // QiniuData: {
      //     key: '',
      //     token: '',
      //     data: {}
      // },
      // actions: 'http://upload.qiniup.com',
      QiniuData: {
        id: ''
      },
      forwardId: '',
      excelname: []
    }
  },
  computed: {
    actions () {
      return (
        baseUrl +
        'logistics/order/detail/clearance/upload?v=5&token=' +
        localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return (
        'https://sd.resource.soffeedesign.net/tpl/报关单模版.xlsx?t=' +
        localStorage.getItem('token')
      )
    }
  },
  // mounted() {
  //   this.getQiniuYunToken()
  // },
  methods: {
    show (id) {
      this.forwardId = id
      this.QiniuData.id = id
      this.visible = true
    },
    // getQiniuYunToken() {
    //      ApiQiniuService
    //     .getToken('logistics-clearance-info')
    //     .then((res) => {
    //         this.QiniuData.token = res.token
    //         // this.actions = `${res.domain}${res.key}`
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // },
    closedone (done) {
      this.hide()
      this.excelname = []
      this.forwardId = ''
      done()
    },
    hide () {
      this.$refs.upload.clearFiles()
      this.visible = false
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import')
      this.$notify({ message: response.msg })
      // this.excelname.push(response.key)
      // if (this.excelname.length == fileList.length) {
      //     uploadClearance({ id: this.forwardId, excel: this.excelname })
      //     .then((data) => {
      //         this.$emit('sunccess-import')
      //     }).catch((err) => {
      //         console.log(err)
      //     })
      // }
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      //  this.QiniuData.data = file;
      //  this.QiniuData.key = `${file.name}`;
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
