<template>
  <el-dialog title="审核" :visible.sync="visible" width="500px">
    <el-form>
      <el-form-item>
        <p>确定审核通过该采购单吗？</p>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="pushTransportPlan">产品需返工</el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="hide">取 消</el-button>
      <el-button size="small" type="primary" @click="confirmStock()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { confirmStockDetail } from '@/api/stockOrder'
export default {
  data () {
    return {
      visible: false,
      pushTransportPlan: false,
      stockcode: ''
    }
  },
  methods: {
    show (stockcode) {
      this.visible = true
      this.stockcode = stockcode
    },
    hide () {
      this.visible = false
    },
    confirmStock () {
      confirmStockDetail({
        id: this.stockcode,
        pushTransportPlan: this.pushTransportPlan
      })
        .then(data => {
          this.$emit('confirmSuccess')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
