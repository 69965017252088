<!-- // 采购出入库-->
<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item>单据查询</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in sysConfig.WMSOrderCore.type"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        >
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <out-in-list :type="tabvalue"></out-in-list>
  </div>
</template>
<script>
import outInList from './out-in-list'
import { mapGetters } from 'vuex'
export default {
  name: 'wareOutIn',
  components: {
    outInList
  },
  data () {
    return {
      tabvalue: sessionStorage.getItem('wareOrderTablvue') || '1'
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    handleClick () {
      sessionStorage.setItem('wareOrderTablvue', this.tabvalue)
    }
  }
}
</script>
