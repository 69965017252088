<template>
  <div>
    <div v-for="item in reimbusementdetailList" :key="item.id">
      <el-row>
        <el-form>
          <el-row :gutter="16">
            <el-col :span="6">
              <el-form-item label="采购单">
                &nbsp;&nbsp;{{ item.order_id }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="预付比例">
                &nbsp;&nbsp;{{ item._prepay_ratio }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="采购入库单">
                &nbsp;&nbsp;{{ item.source_id }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="备注">
                &nbsp;&nbsp;{{ item.remark }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="6">
              <el-form-item label="请款总额">
                &nbsp;&nbsp;{{ item.prepayment_amount }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="已冲账">
                &nbsp;&nbsp;{{ item.prepayment_used_amount }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报销总金额">
                &nbsp;&nbsp;{{ item.total_worth | numFilter }}
              </el-form-item>
              <!-- <el-form-item label="本次冲账">
                      &nbsp;&nbsp; -->
              <!-- &nbsp;&nbsp;{{ item.prepay_using_amount}} -->
              <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">￥{{ item.amount | usingamount(item.prepay_ratio)}} (挂账 ￥{{item.prepayment_amount - item.prepayment_used_amount}})</span>
                      <span v-else style="margin-left: 40px">/</span>
                    </el-form-item> -->
            </el-col>
            <el-col :span="6">
              <el-form-item label="本次应付">
                &nbsp;&nbsp;￥{{ item.amount }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
      <div>
        <el-table
          stripe
          style="width:100%"
          :data="item.item"
          border
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            prop="_product_name,_product_mfn"
            label="货品"
            width="250"
          >
            <!-- <template slot-scope="scope">
                  {{scope.row._product_name}} <br>
                  {{scope.row._product_mfn}}
                </template> -->
            <template v-slot="scope">
              <el-row type="flex" v-if="scope.row.id">
                <img width="80" height="80" :src="scope.row._product_cover" />
                <div style="margin-left: 10px;">
                  <p>{{ scope.row._product_name }}</p>
                  <p>{{ scope.row._product_mfn }}</p>
                </div>
              </el-row>
              <el-row v-else> {{ scope.row.remark }}</el-row>
            </template>
          </el-table-column>
          <el-table-column
            prop="purchese_qty"
            label="采购总数"
          ></el-table-column>
          <el-table-column
            prop="purchese_price"
            label="采购单价"
          ></el-table-column>
          <el-table-column prop="qty" label="报销数量"></el-table-column>
          <el-table-column prop="purchese_priceqty" label="报销金额">
            <template slot-scope="scope">
              {{ (scope.row.purchese_price * scope.row.qty) | numFilter }}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="本次应付"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['reimbusementdetailList'],
  filters: {
    numFilter (value) {
      return parseFloat(value).toFixed(2)
    },
    usingamount (amount, prepayRatio) {
      const ratio = parseFloat(prepayRatio)
      return (amount / (1 - ratio) - amount).toFixed(2)
    }
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      const purchesePriceList = data.map(item => Number(item.purchese_price))
      const qtyList = data.map(item => Number(item.qty))
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        }
        if (column.property === 'purchese_priceqty') {
          sums[index] = 0
          for (let i = 0; i < purchesePriceList.length; i++) {
            sums[index] += purchesePriceList[i] * qtyList[i]
          }
          sums[index] = sums[index].toFixed(2)
        }
      })

      return sums
    }
  }
}
</script>
