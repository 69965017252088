import store from '@/store'
import TYPE from './type'
const commonHeaderExplain = '系统将导出符合时间条件的所有数据'

// 表头数据与列表数据接口相同， per_page字段传负数
export const CONFIG_MAP = {
  // F01 F02采购出入库
  [TYPE.FINANCE_PROCUREMENT_IN_OUT]: {
    url: '/finance/wms/purchaseInOut',
    // 头部说明
    headerExplain: '系统将下载限定条件内所有采购出入库明细',
    // 步骤一的可选项参数
    // fields: [
    //   { label: '名称', key: 'test', attrs: null, defaultValue: '', widget: 'input' }
    // ],
    filename: 'wms采购出入库明细.xlsx'
  },
  // E06A 调拨出入库
  [TYPE.FINANCE_ALLOT_IN_OUT]: {
    url: '/finance/wms/transferIO',
    // 头部说明
    headerExplain: '系统将下载限定条件内所有调拨出入库明细',
    filename: '调拨出入库明细.xlsx'
  },
  [TYPE.FINANCE_BREAKAGE_ADJUSTMENT]: {
    url: '/finance/wms/adjustment',
    headerExplain: '系统将下载限定条件内所有调整、报损明细',
    filename: 'wms调整报损明细.xlsx'
  },
  [TYPE.FINANCE_SALES_IN_OUT]: {
    url: '/finance/wms/salesIO',
    headerExplain: '系统将下载限定条件内所有销售出入库明细',
    filename: 'wms销售出入库明细.xlsx'
  },
  [TYPE.SALES_WAYFAIR_ORDER_DETAIL]: {
    url: '/sales/wayfair/exportDetail',
    headerExplain: commonHeaderExplain,
    filename: 'wayfair销售订单明细.xlsx',
    fields: [
      {
        label: '最后更新时间',
        key: 'updated_at',
        attrs: {
          'value-format': 'yyyy-MM-dd',
          'start-placeholder': '开始日期',
          'end-placeholder': '结束日期',
          clearable: true
        },
        defaultValue: [],
        widget: 'daterange'
      },
      {
        label: '订单时间',
        key: 'po_datetime',
        attrs: {
          'value-format': 'yyyy-MM-dd',
          'start-placeholder': '开始日期',
          'end-placeholder': '结束日期',
          clearable: true
        },
        defaultValue: [],
        widget: 'daterange'
      }
    ]
  },
  [TYPE.SALES_WAYFAIR_STOCK_OUT_RECORD]: {
    url: '/sales/wayfair/exportShipment',
    headerExplain: commonHeaderExplain,
    filename: 'wayfair出库记录.xlsx',
    fields: [
      {
        label: '最后更新时间',
        key: 'updated_at',
        attrs: {
          'value-format': 'yyyy-MM-dd',
          'start-placeholder': '开始日期',
          'end-placeholder': '结束日期',
          clearable: true
        },
        defaultValue: [],
        widget: 'daterange'
      },
      {
        label: '订单时间',
        key: 'po_datetime',
        attrs: {
          'value-format': 'yyyy-MM-dd',
          'start-placeholder': '开始日期',
          'end-placeholder': '结束日期',
          clearable: true
        },
        defaultValue: [],
        widget: 'daterange'
      }
    ]
  },
  // 月度库存结余
  [TYPE.FINANCE_MONTHLY_BALANCE]: {
    url: '/finance/wms/exportBalance',
    headerExplain: commonHeaderExplain,
    filename: '月度库存结余.xlsx',
    fields: [
      {
        label: '年月',
        key: 'term',
        attrs: {
          'value-format': 'yyyyMM',
          placeholder: '选择月份'
        },
        defaultValue: null,
        widget: 'month'
      },
      {
        label: '公司主体',
        key: 'saas',
        attrs: {
          placeholder: '公司主体',
          clearable: true
        },
        defaultValue: null,
        widget: 'select',
        options: {
          attrs: null,
          // 该字段可能为一个异步函数 或 数组
          dataSource: store.getters.sysConfig.Common === undefined ? '' : store.getters.sysConfig.Common.saas,
          keyFactory: item => item.key,
          // 使用函数返回所需的 key value label
          vlFactory (item) {
            return {
              value: item.val.id,
              label: item.val.name
            }
          }
        }
      }
    ]
  },
  // Wayfair退货单
  [TYPE.SALES_WAYFAIR_REFUND]: {
    url: '/sales/wayfair/lsRefund',
    headerExplain: commonHeaderExplain,
    filename: 'wayfair退货单.xlsx'
  },
  [TYPE.SALES_WAYFAIR_ORDER]: {
    url: '/sales/wayfair/exportDetail',
    headerExplain: '即将下载该流程内符合检索条件的所有订单明细。',
    filename: 'wayfair销售订单.xlsx'
  },
  // 数据中心 采购单
  [TYPE.DCENTER_PURCHASE_ORDER]: {
    url: '/finance/wms/purchaseOrder',
    headerExplain: commonHeaderExplain,
    filename: '数据中心采购单.xlsx'
  },
  // 数据中心 采购明细
  [TYPE.DCENTER_PURCHASE_DETAIL]: {
    url: '/finance/wms/purchaseItem',
    headerExplain: commonHeaderExplain,
    filename: '数据中心采购明细.xlsx'
  },
  // 财务 亚马逊账期明细-出货
  [TYPE.AMZ_FINANCE_SHIPMENT]: {
    url: '/finance/amz/shipmentExport',
    headerExplain: '即将下载符合检索条件的出货明细。',
    filename: '账期明细-shipment.xlsx'
  },
  // 财务 亚马逊账期明细-退货
  [TYPE.AMZ_FINANCE_REFUND]: {
    url: '/finance/amz/refundExport',
    headerExplain: '即将下载符合检索条件的退货明细。',
    filename: '账期明细-refund.xlsx'
  },
  // 财务 亚马逊账期明细-赔偿
  [TYPE.AMZ_FINANCE_ADJUSTMENT]: {
    url: '/finance/amz/adjustmentExport',
    headerExplain: '即将下载符合检索条件的赔偿明细。',
    filename: '账期明细-adjustment.xlsx'
  },
  // wms 运营催单福州仓发货到海外仓
  [TYPE.WMS_LOGISTICS_APPLY]: {
    url: '/logistics/plan/applyList',
    headerExplain: '即将下载符合检索条件的运营催单明细。',
    filename: '运营催单明细.xlsx'
  },
  [TYPE.PURCHASE_TRACKING_LIST]: {
    url: '/purchase/tracking',
    headerExplain: '即将下载符合检索条件的采购交货进度',
    filename: '采购交货进度.xlsx'
  },
  [TYPE.SALES_LOGISTICS_PLAN]: {
    url: '/logistics/plan/toPlanList',
    headerExplain: '即将下载符合检索条件的待催单明细',
    filename: '待催单明细.xlsx'
  }
}
