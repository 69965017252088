<template>
  <el-dialog
    title="编辑报关资料"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-tag style="margin-bottom: 10px;"
      >产品的商编不同，品名一定也不同，反之，品名不同则商编可能相同。务必注意，当前货代单中所有相同MFN的报关资料都将被修改</el-tag
    >
    <el-form size="small" label-position="left" label-width="120px">
      <el-form-item label="货代单:">
        {{ formdata.id }}/{{ formdata.name }}
      </el-form-item>
      <el-form-item label="MFN:">
        {{ formdata.mfn }}
      </el-form-item>
      <el-form-item label="商编:">
        <el-input v-model="formdata.hs_code"></el-input>
      </el-form-item>
      <el-form-item label="报关品名:">
        <el-input v-model="formdata.new_name"></el-input>
      </el-form-item>
      <el-form-item label="单位:">
        <el-input v-model="formdata.unit"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click.prevent="save" type="primary"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { saveClearance } from '@/api/forwardNew2021'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        id: '',
        name: '',
        product_name: '',
        new_name: '',
        hs_code: '',
        unit: ''
      }
    }
  },
  methods: {
    show (clearanceItem, orderdetail) {
      console.log(clearanceItem, orderdetail)
      this.formdata.id = orderdetail.id
      this.formdata.name = orderdetail.name
      this.formdata.mfn = clearanceItem.mfn
      this.$set(this.formdata, 'hs_code', clearanceItem.hs_code)
      this.$set(this.formdata, 'unit', clearanceItem.unit)
      // this.formdata.hs_code = clearanceItem.hs_code
      // this.formdata.unit = clearanceItem.unit
      this.visible = true
    },
    closedone (done) {
      this.hide()
      done()
    },
    hide () {
      this.formdata = {}
      this.visible = false
    },
    save () {
      const jsondata = {
        id: this.formdata.id,
        mfn: this.formdata.mfn,
        new_name: this.formdata.new_name,
        hs_code: this.formdata.hs_code,
        unit: this.formdata.unit
      }
      saveClearance(jsondata)
        .then(data => {
          this.$emit('clearance-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
