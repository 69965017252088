<template>
  <div>
    <el-row
      v-for="(rowattribute, rowIndex) in rowattributelist"
      :key="rowIndex"
    >
      <el-col
        :span="7"
        v-for="(attribute, index) in rowattribute"
        :key="attribute.id"
        :offset="1"
        style="margin-bottom:20px;"
      >
        <el-card class="attcard">
          <div slot="header" class="attbute">
            <el-checkbox
              v-model="attribute.on"
              @change="changeCheckbox(rowIndex, index)"
              >{{ attribute.name }}</el-checkbox
            >
          </div>
          <div
            v-show="attribute.on"
            v-for="attbudetail in attribute.sub"
            :key="attbudetail.id"
          >
            <el-form size="mini">
              <el-form-item :label="attbudetail.name" size="mini">
                <el-input v-model="attbudetail.dat" width="100px"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6" :offset="2">
        <el-button type="primary" @click.prevent="saveProductAttinfo"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as productapi from '../../../api/product'
export default {
  props: ['initalAttribute'],
  data () {
    return {
      checked: ''
    }
  },
  computed: {
    attributelist () {
      return this.initalAttribute.ext
    },
    rowattributelist () {
      const arr = []
      if (this.attributelist) {
        for (var i = 0; i < Math.ceil(this.attributelist.length / 3); i++) {
          arr[i] = []
          for (var j = i * 3; j < (i + 1) * 3; j++) {
            if (this.attributelist[j]) {
              arr[i].push(this.attributelist[j])
            }
          }
        }
      }
      return arr
    }
  },
  methods: {
    saveProductAttinfo () {
      const productAttribute = {}
      productAttribute.ext = this.attributelist
      productAttribute.mfn = this.initalAttribute.mfn
      productapi
        .saveProductAttribute(productAttribute)
        .then(res => {
          this.$emit('attsuccess')
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeCheckbox (rowIndex, index) {
      console.log(this.rowattributelist[rowIndex][index].on)
    }
  }
}
</script>
<style scoped>
.attcard {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
