<template>
  <el-dialog
    title="借入库存"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
  >
    <el-row>
      <el-tag>注意：借出方有权在需要时，单方面收回借出的库存。</el-tag>
    </el-row>
    <el-row>
      <el-form size="small" label-width="120px">
        <el-form-item label="借入方">
          &nbsp;&nbsp;{{ search.debtor }}
        </el-form-item>
        <el-form-item label="借出方">
          <el-select v-model="search.ec_id">
            <el-option
              v-for="item in ecList"
              :key="item.key"
              :label="item.val"
              :disabled="item.disabled"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="效验码">
          <el-input v-model="search.vefify_code"></el-input>
        </el-form-item>
        <el-form-item label="产品&数量">
          <el-input
            id="file"
            v-model="search.excel"
            name="file"
            type="file"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="20px" label>
          <p>
            <el-link type="primary" :href="downloadTemplate" target="_blank"
              >下载文件Excel模板</el-link
            >
          </p>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer">
      <el-button type="primary" size="small" @click="borrowPost()"
        >确定提交</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { borrowWayfair, getSalesEcList } from '@/api/amazon'
import md5 from 'js-md5'
export default {
  data () {
    return {
      visible: false,
      search: {},
      ecList: [],
      bec_id: ''
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    downloadTemplate () {
      return (
        'https://sd.resource.soffeedesign.net/tpl/销售-库存借还模版.xlsx?t=' +
        localStorage.getItem('token')
        // baseUrl +
        // 'sales/product/borrowInventoryExcelTpl?token=' +
        // sessionStorage.getItem('token')
      )
    }
  },
  created () {
    getSalesEcList({ type: 0 })
      .then(data => {
        this.ecList = data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    show (becid) {
      this.visible = true
      this.bec_id = becid
      for (let i = 0; i < this.ecList.length > 0; i++) {
        if (this.bec_id === this.ecList[i].key) {
          this.search.debtor = this.ecList[i].val
        }
      }
      this.ecList = this.ecList.map(item => {
        if (item.key !== this.bec_id) {
          return item
        } else {
          item.disabled = true
          return item
        }
      })
    },
    hide () {
      this.visible = false
    },
    borrowPost () {
      const self = this
      const formdata = new FormData()
      formdata.append('ec_id', self.bec_id)
      formdata.append('vefify_code', md5(self.search.vefify_code))
      formdata.append('ec_from', self.search.ec_id)
      formdata.append('excel', document.getElementById('file').files[0])
      borrowWayfair(formdata)
        .then(data => {
          this.$emit('borrow-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
