<template>
  <div class="reimbursement-detail">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>报销单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <div>
      <el-row class="content-row">
        <p class="row-header">报销单</p>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          单号
          <span style="margin-left: 40px">{{ orderDetail.id }}</span>
        </el-col>
        <el-col :span="6">
          供应商
          <span style="margin-left: 40px">{{ orderDetail.vendor_name }}</span>
        </el-col>
        <el-col :span="6">
          金额
          <span style="margin-left: 40px"
            >{{ orderDetail.amount }} {{ orderDetail._currency }}</span
          >
        </el-col>
        <el-col :span="6">
          状态
          <span style="margin-left: 40px">{{ orderDetail._step }}</span>
        </el-col>
      </el-row>
      <br />

      <el-row class="content-row">
        <p class="row-header">支付信息</p>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          收款单位
          <span style="margin-left: 40px">{{ orderDetail.bank_name }}</span>
        </el-col>
        <el-col :span="6">
          开户行
          <span style="margin-left: 40px">{{ orderDetail.bank }}</span>
        </el-col>
        <el-col :span="6">
          账号
          <span style="margin-left: 40px">{{ orderDetail.bank_account }} </span>
        </el-col>
      </el-row>
      <el-row class="content-row">
        <el-col :span="6">
          应付金额
          <span style="margin-left: 40px">{{ orderDetail.amount }}</span>
        </el-col>
        <el-col :span="6">
          付款期限
          <span style="margin-left: 40px">{{ orderDetail.deadline }}</span>
        </el-col>
      </el-row>
      <br />

      <!--明细 -->
      <el-tabs v-model="tablvue">
        <el-tab-pane label="报销明细" name="first">
          <reimbur-detail-tab
            :orderDetailItem="orderDetailItem"
          ></reimbur-detail-tab>
        </el-tab-pane>
        <el-tab-pane label="开票明细" name="second">
          <invoice-detail-tab
            :invoiceDetailList="invoiceDetailList"
            :id="orderDetail.id"
            type="2"
          ></invoice-detail-tab>
        </el-tab-pane>
        <el-tab-pane label="开票资料" name="third">
          <invoice-info-tab
            :invoiceInfoList="invoiceInfoList"
          ></invoice-info-tab>
        </el-tab-pane>
      </el-tabs>
      <br />
      <el-row class="content-row">
        <el-col :span="6">
          <div>
            <el-button
              v-show="orderDetail.step == 1"
              type="primary"
              @click="onSubmit"
              >提交财务审核</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as reimbursementApi from '@/api/reimbursement'
import reimburDetailTab from './reimbur-detail-tab'
import invoiceDetailTab from './invoice-detail-tab'
import invoiceInfoTab from './invoice-info-tab'
export default {
  components: {
    reimburDetailTab,
    invoiceDetailTab,
    invoiceInfoTab
  },
  data () {
    return {
      orderDetail: {},
      orderDetailItem: [],
      invoiceDetailList: [],
      invoiceInfoList: [],
      id: null,
      tablvue: 'first'
    }
  },

  created () {
    this.reimbursementDetail()
  },
  mounted () {
    this.id = this.$route.query.id
  },
  methods: {
    reimbursementDetail () {
      reimbursementApi
        .reimbursementOrderDetail({ id: this.$route.query.id })
        .then(res => {
          this.orderDetail = res.order
          this.orderDetailItem = res.reimburse_detail
          this.invoiceDetailList = res.invoice_detail
          this.invoiceInfoList = res.invoice_data
        })
    },
    onSubmit () {
      this.$confirm('是否提交财务审核？请确认')
        .then(_ => {
          reimbursementApi
            .reimbursementOrderDetailConfirm({ id: this.id })
            .then(res => {
              this.orderDetail.step += 1
            })
        })
        .catch(_ => {})
    }
  }
}
</script>

<style>
.content-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.content-row .row-header {
  font-size: 16px;
  font-weight: bold;
}
</style>
