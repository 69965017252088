<!-- 货代单详情 -->
<template>
  <div class="forward-order">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>货品库存</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>货单运单</h3>
      </el-col>
      <el-col :span="5" :offset="15">
        <el-button
          type="text"
          @click="$refs['edit-forward-dialog'].show(1)"
          :disabled="orderdetail.step === 2"
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-form :model="orderdetail" label-width="90px" size="small">
        <el-row>
          <el-col :span="5">
            <el-form-item label="ID" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.id }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="name：" size="small">
              &nbsp;&nbsp;{{ orderdetail.name }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="业务员：" size="small">
              &nbsp;&nbsp;{{ orderdetail.user_name }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="仓库：" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail._warehouse_from }}~{{
                orderdetail.warehouse_name
              }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="出口退税：" size="small">
              &nbsp;&nbsp; &nbsp;&nbsp;
              <span v-if="orderdetail.is_tax_refund === true">是</span>
              <span v-else>否</span>
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="运输方式" size="small">
              &nbsp;&nbsp;{{ orderdetail._transport_type }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1" size="small">
            <el-form-item label="预计发货">
              &nbsp;&nbsp;{{ orderdetail.date
              }}<el-tag size="mini">{{ orderdetail._step }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="备注" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-button
        type="primary"
        size="small"
        @click="$refs['confirm-forward-dialog'].show()"
        v-if="orderdetail.step === 1"
        >确认</el-button
      >
      <el-button
        type="danger"
        size="small"
        @click="deleteTransportDetail"
        v-if="orderdetail.step === 1"
        >删除</el-button
      >
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>海外仓入库单</h3>
      </el-col>
      <el-col :span="5" :offset="3">
        <el-button
          type="text"
          @click="
            $refs['create-oversea-dialog'].show(orderdetail.is_to_fba, orderid)
          "
          v-show="forwarddatalength === 0"
          >创建</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <el-form :model="orderdetail" label-width="100px" size="small">
          <el-form-item label="入库单单号:" size="small">
            <!-- <el-input v-model='forwarddata.id' :disabled="true"></el-input> -->
            <span v-for="(item, index) in orderdetail._shipment_sn" :key="index"
              >{{ item }},</span
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>明细</h3>
      </el-col>
      <el-col :span="3" :offset="18">
        <el-button type="text" size="small" @click="downloadExcel('item')"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        ref="warrantDetailTable"
        :data="orderdetail.item"
        border
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column
          prop="_product_name,_product_mfn"
          label="货品"
          width="220"
        >
          <template slot-scope="scope">
            <product-info-in-table-cell
              :cover="scope.row._product_cover"
              :title="scope.row._product_name"
              :mfn="scope.row._product_mfn"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column
          prop="qty_ctn"
          label="装箱率"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="ctn_width,ctn_length,ctn_height,ctn_weight"
          label="外箱材积"
          width="180"
        >
          <template slot-scope="scope">
            <span v-if="scope.row._product_box_package_dimension != null">
              {{ scope.row._product_box_package_dimension.length }} *
              {{ scope.row._product_box_package_dimension.width }} *
              {{ scope.row._product_box_package_dimension.height }} <br />
              <span
                v-if="scope.row._product_box_package_dimension.g_weight != null"
              >
                {{ scope.row._product_box_package_dimension.g_weight }}kg<br />
              </span>
            </span>
            {{ scope.row.ctn_volume_m }}m<sup>3</sup>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="箱标" width="120">
          <template slot-scope="scope">
            {{ scope.row.label }} <br />
            <el-tag
              v-if="
                scope.row._product_box_package_dimension != null &&
                  scope.row._product_box_package_dimension.sellpack > 1
              "
              >套{{ scope.row._product_box_package_dimension.sellpack }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="purchase_order_id"
          label="采购单"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="ship_date"
          label="交期"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="expected_date,expected_ctn"
          label="预计到货"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.expected_date }}/{{ scope.row.expected_ctn }}箱
          </template>
        </el-table-column>
        <el-table-column
          prop="unplanned_ctn,shipable_ctn"
          label="未排/可发"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.unplanned_ctn }}/{{ scope.row.shipable_ctn }}
          </template>
        </el-table-column>
        <el-table-column prop="x" label="发货数" width="200">
          <template slot-scope="scope">
            {{ scope.row.x }}
            <p
              v-if="
                scope.row._product_box_package_dimension != null &&
                  scope.row._product_box_package_dimension.g_weight != null
              "
            >
              {{
                (scope.row._product_box_package_dimension.g_weight *
                  scope.row.x)
                  | numFilter
              }}
              kg
            </p>
            <p>{{ (scope.row.ctn_volume_m * scope.row.x) | numFilter }} ㎡</p>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row
      style="margin-top:20px; margin-bottom:30px;"
      v-show="orderdetail.step === 1"
    >
      <el-col :span="2">
        <el-button
          type="primary"
          size="small"
          @click="
            $refs['edit-forward-order-detail-dialog'].show(
              orderdetail.item,
              orderdetail.id
            )
          "
          >编辑</el-button
        >
      </el-col>
      <el-col :span="2" :offset="1">
        <el-dropdown>
          <el-button type="primary" size="small">
            移动<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="
                $refs['move-forward-order-detail-dialog'].show(
                  orderdetail.item,
                  orderdetail.id,
                  '2'
                )
              "
              >移至其他货代单</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="
                $refs['move-forward-order-detail-dialog'].show(
                  orderdetail.item,
                  orderdetail.id,
                  '1'
                )
              "
              >移至新货代单</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2" :offset="1">
        <el-button
          type="danger"
          size="small"
          @click="
            $refs['delete-forward-order-detail-dialog'].show(
              orderdetail.item,
              orderdetail.id
            )
          "
          >删除</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>报关单</h3>
      </el-col>
      <el-col :span="5" :offset="13">
        <el-button type="text" size="small" @click="downloadExcel('warrant')"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        ref="warrantDetailTable"
        :data="orderdetail.customs"
        border
      >
        <el-table-column
          prop="hs_code"
          label="商品编号"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="product_name"
          label="品名"
          mini-width="35%"
        ></el-table-column>
        <el-table-column prop="unit" label="单位" width="100"></el-table-column>
        <el-table-column
          prop="info"
          label="规格型号"
          mini-width="35%"
        ></el-table-column>
        <el-table-column prop="weight" label="净重" mini-width="10%">
          <template slot-scope="scope"> {{ scope.row.weight }}kg </template>
        </el-table-column>
        <el-table-column
          prop="qty"
          label="数量"
          mini-width="10%"
        ></el-table-column>
        <el-table-column
          prop=" _product_customs.declaration_price"
          label="单价"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="currency"
          label="货币"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="总价"
          width="100"
        ></el-table-column>
      </el-table>
    </el-row>
    <edit-ware-warrant ref="edit-ware-warrant-dialog"></edit-ware-warrant>
    <confirm-forward-dialog
      ref="confirm-forward-dialog"
      :fowardid="orderid"
      :status="orderdetail.status"
      @confirmsuccess="change"
    ></confirm-forward-dialog>
    <edit-forward-order-detail-dialog
      ref="edit-forward-order-detail-dialog"
      @editsuccess="change"
    ></edit-forward-order-detail-dialog>
    <move-forward-order-detail-dialog
      ref="move-forward-order-detail-dialog"
      @movesuccess="change"
    ></move-forward-order-detail-dialog>
    <delete-forward-order-detail-dialog
      ref="delete-forward-order-detail-dialog"
      @deletesuccess="change"
    ></delete-forward-order-detail-dialog>
    <edit-forward-dialog
      ref="edit-forward-dialog"
      :orderDetail="orderdetail"
      :selectItem="selectItem"
      @change="change"
    ></edit-forward-dialog>
    <create-oversea-dialog
      ref="create-oversea-dialog"
      @create-oversea-success="change()"
    ></create-oversea-dialog>
  </div>
</template>
<script>
import editWareWarrant from './edit-warehouse-warrant'
import * as forwardNewApi from '@/api/forwardNew'
import confirmForwardDialog from './confirm-forward-dialog.vue'
import editForwardOrderDetailDialog from './edit-forward-order-detail-dialog'
import moveForwardOrderDetailDialog from './move-forward-order-detail-dialog'
import deleteForwardOrderDetailDialog from './delete-forward-order-detail-dialog'
import editForwardDialog from './create-forward-dialog'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
import createOverseaDialog from './create-oversea-dialog'
import { baseUrl } from '@/config/env'

export default {
  components: {
    editWareWarrant,
    editForwardDialog,
    productInfoInTableCell,
    confirmForwardDialog,
    editForwardOrderDetailDialog,
    moveForwardOrderDetailDialog,
    deleteForwardOrderDetailDialog,
    createOverseaDialog
  },
  filters: {
    numFilter (value) {
      return parseFloat(value).toFixed(2)
    }
  },
  data () {
    return {
      orderid: this.$route.query.order_id,
      orderdetail: {
        item: []
      },
      detaillist: [],
      forwarddatalength: 0, // 海外入库单数组长度
      selectedList: [],
      selectItem: [],
      showtype: 1
    }
  },
  created () {
    this.getOrdertail()
  },
  methods: {
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'x') {
          if (this.orderdetail.item.length > 0) {
            const sumvolume = this.orderdetail.item.map(item => {
              return {
                id: item.id,
                weightsum:
                  item.x * item._product_box_package_dimension.g_weight,
                volumesum: item.x * item.ctn_volume_m
              }
            })
            let weightcount = 0
            let volumecount = 0
            for (let i = 0; i < sumvolume.length; i++) {
              if (sumvolume[i].id === 0) {
                if (sumvolume[i].weightsum > 0) {
                  weightcount += sumvolume[i].weightsum
                }
                if (sumvolume[i].volumesum > 0) {
                  volumecount += sumvolume[i].volumesum
                }
              }
            }
            sums[index] =
              weightcount.toFixed(2) + 'kg,' + volumecount.toFixed(2) + '㎡'
          } else {
            sums[index] = '0'
          }
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    downloadExcel (type) {
      let url =
        baseUrl +
        'logistics/order/detail/download?token=' +
        localStorage.getItem('token')
      if (type === 'item') {
        url += '&id=' + this.orderid
      } else if (type === 'warrant') {
        // 导出报关
        url += '&id=' + this.orderid + '&mode=' + 'customer'
        console.log(url)
      }
      window.open(url)
    },
    getOrdertail () {
      const self = this
      forwardNewApi
        .getforwardNewOrderDetail({ id: self.orderid })
        .then(data => {
          self.orderdetail = data
          self.forwarddatalength = data._shipment_sn.length
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSelectionChange (val) {
      this.detaillist = []
      val.forEach(item => {
        this.detaillist.push(item.label)
      })
    },
    //  删除货代单
    deleteTransportDetail () {
      const self = this
      self
        .$confirm('提示', {
          title: '删除货代单',
          message: '删除货代单的同时，所有明细将退回至货代计划,请确认是否删除'
        })
        .then(_ => {
          forwardNewApi
            .deleteforwardNewOrder({ id: self.orderdetail.id })
            .then(data => {
              self.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //  选中checkbox项的回调
    change () {
      this.getOrdertail()
    },
    // 创建海外入库单
    setShipment () {
      const self = this
      const jsonData = {
        id: self.orderid
      }
      self
        .$confirm('提示', {
          title: '创建海外入库单',
          message:
            '目前FBA仓库无法追踪入库数量差异，暂时无法自动创建报损单和调整单。 海外仓入库单创建后不可更改，确定创建？'
        })
        .then(_ => {
          forwardNewApi
            .setShipment(jsonData)
            .then(data => {
              console.log(data)
              this.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
