<template>
  <div class="tracking-list">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>WMS</el-breadcrumb-item>
        <el-breadcrumb-item>仓库设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-tabs v-model="tab_id" @tab-click="clickTab">
      <el-tab-pane label="已启用" name="1"></el-tab-pane>
      <el-tab-pane label="未启用" name="0"></el-tab-pane>
    </el-tabs>
    <el-row style="margin-top:20px">
      <el-col :span="20">
        <el-select
          v-model="type"
          placeholder="选择类型"
          @change="changeType"
          clearable
        >
          <el-option
            v-for="item in sysConfig.WMSWarehouse.type"
            :label="item.val"
            :value="item.key"
            :key="item.key"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button
          style="float:right;"
          type="primary"
          plain
          @click="editWarehouse(null)"
          >新增仓库</el-button
        >
      </el-col>
    </el-row>

    <el-row style="margin-top:20px">
      <el-table :data="warehouse_dat" border>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="no" label="NO"></el-table-column>
        <el-table-column label="地区">
          <template v-slot="scope">
            {{ scope.row._country }} / {{ scope.row.province }} /
            {{ scope.row.city }}
          </template>
        </el-table-column>
        <el-table-column label="具体地址">
          <template v-slot="scope">
            {{ scope.row.addr }} / {{ scope.row.tel }}
          </template>
        </el-table-column>
        <el-table-column label="联系人">
          <template v-slot="scope">
            {{ scope.row.manager }} / {{ scope.row.phone }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="类型" prop="type">
        </el-table-column> -->
        <el-table-column>
          <template v-slot="scope">
            <el-button type="text" @click="editWarehouse(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <warehouse-editor ref="warehouse-editor"></warehouse-editor>
  </div>
</template>
<script>
import { apiWarehouseLs } from '@/api/wms'
import warehouseEditor from './warehouse-editor'
import { mapGetters } from 'vuex'

export default {
  components: {
    warehouseEditor
  },

  computed: {
    ...mapGetters(['sysConfig'])
  },

  data: function () {
    return {
      tab_id: '1',
      tab: [{}],
      type: '',
      warehouse_dat: []
    }
  },

  created () {
    this.lsWarehouse()
  },

  methods: {
    changeType (val) {
      this.type = val
      this.lsWarehouse()
    },
    lsWarehouse: function (page = 1, perPage = 20) {
      apiWarehouseLs(this.tab_id, page, perPage, this.type).then(res => {
        this.warehouse_dat = res.data
        // let lp = new $laravelPage()
        // let test = lp.simpleData(this.warehouse_dat, ['id', 'name']);
        // console.log(test)
      })
    },

    editWarehouse: function (obj) {
      // this.warehouse_detail = obj
      this.$refs['warehouse-editor'].open(obj)
      // console.log(this.warehouse_editor)
    },

    clickTab: function () {
      this.lsWarehouse()
    }
  }
}
</script>

<style></style>
