<template>
  <div class="product-attribute">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">产品管理</el-breadcrumb-item>
        <el-breadcrumb-item>扩展属性</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row style="margin:40px 20px 0 20px">
      <el-form
        size="small"
        :model="attribute"
        label-width="74px"
        label-position="left"
        ref="attribute"
        id="attribute"
        :rules="rules"
        style="width: 300px"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="attribute.name"></el-input>
        </el-form-item>
        <el-form-item label="字段" prop="id">
          <el-input v-model="attribute.id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="addAttribute"
            >保存</el-button
          >
          <el-button type="default" size="small" @click="emptyAttribute"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row  style="margin-top:60px">
      <el-table stripe border :data="showTable" ref="AttributeTable">
        <el-table-column label="属性" prop="name"></el-table-column>
        <el-table-column label="字段名" prop="id"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="$refs['attdetail'].show(scope.row)"
              >查看</el-button
            >
            <el-button type="danger" size="mini" @click="delt(scope.row._id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :hide-on-single-page="false"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.attributelist.length"
      ></el-pagination>
    </el-row>
    <attribute-detail ref="attdetail"></attribute-detail>
  </div>
</template>

<script>
import * as productapi from '../../../api/product'
import attributeDetail from './attribute-detail'

export default {
  components: {
    attributeDetail
  },
  data () {
    var validateFirletter = (rule, value, callback) => {
      const Firletter = /^[a-zA-Z][a-zA-Z0-9_]*$/
      if (!value) {
        callback(new Error('请输入属性字段名'))
      } else if (Firletter.test(value)) {
        callback()
      } else {
        callback(new Error('请输入以字母开头，字母数字下划线组成的字段名'))
      }
    }
    return {
      attributelist: [],
      pagesize: 10,
      currentPage: 1,
      attribute: {},
      rules: {
        name: [{ required: true, message: '请输入属性名称', trigger: 'blur' }],
        id: [{ required: true, validator: validateFirletter, trigger: 'blur' }]
      }
    }
  },
  computed: {
    showTable () {
      return this.attributelist.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      )
    }
  },
  created () {
    this.getAttribute()
  },
  methods: {
    getAttribute () {
      productapi
        .getAttribute()
        .then(data => {
          this.attributelist = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
    },
    handleSizeChange (pagesize) {
      this.pagesize = pagesize
    },
    addAttribute () {
      const self = this
      self.$refs.attribute.validate(valid => {
        if (valid) {
          const attriFormdata = new FormData()
          attriFormdata.append('name', self.attribute.name)
          attriFormdata.append('id', self.attribute.id)
          productapi
            .saveAttribute(attriFormdata)
            .then(res => {
              self.attribute.pid = res.pid
              self.attribute.name = null
              self.attribute.id = null
              self.getAttribute()
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    emptyAttribute () {
      this.attribute = {}
    },
    delt (row) {
      console.log(row)
    }
  }
}
</script>
