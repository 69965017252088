<template>
  <el-dialog
    title="导出产品信息"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closedone"
    width="800"
  >
    <el-form size="small">
      <el-row>
        <el-col :span="4">
          <label>导出内容</label>
        </el-col>

        <el-col :span="4" :offset="2">
          <el-checkbox v-model="exportdata.base" label="基础内容"></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox
            v-model="exportdata.album"
            label="相册图片"
          ></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox v-model="exportdata.ext" label="扩展属性"></el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" :offset="6">
          <el-checkbox v-model="exportdata.caiji" label="材积"></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox
            v-model="exportdata.baoguan"
            label="报关资料"
          ></el-checkbox>
        </el-col>
        <el-col :span="4" :offset="2">
          <el-checkbox v-model="exportdata.qg" label="清关资料"></el-checkbox>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button size="small" type="primary" @click="downloadLink()"
        >导出商品</el-button
      >
      <el-button size="small" @click="hide">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  data () {
    return {
      visible: false,
      exportdata: {},
      mfnArray: [],
      ec_id: ''
    }
  },
  methods: {
    show (mfnarray, ecid) {
      this.visible = true
      this.mfnArray = mfnarray
      this.ec_id = ecid
    },
    closedone (done) {
      done()
    },
    hide () {
      this.visible = false
    },
    downloadLink () {
      const url =
        baseUrl +
        'sales/product/exportPro?token=' +
        localStorage.getItem('token') +
        '&mfn=' +
        this.mfnArray.join(',') +
        '&ec_id=' +
        this.ec_id
      window.open(url)
    }
  }
}
</script>
