<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>报损理赔单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search">
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholde="开始日期"
            end-placeholde="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="search.warehouse_id"
            placeholder="选择仓库"
            clearable
          >
            <el-option
              v-for="item in sysConfig.warehouselist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="downLoadLink()"
            >下载</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
export default {
  data () {
    return {
      search: {
        _date: '',
        warehouse_id: '',
        start: '',
        end: ''
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    downLoadLink () {
      const url =
        baseUrl +
        'finance/report/downloadLossClaim?token=' +
        localStorage.getItem('token') +
        '&startDate=' +
        this.search.start +
        '&endDate=' +
        this.search.end +
        '&warehouse_id=' +
        this.search.warehouse_id
      window.open(url)
    }
  }
}
</script>
