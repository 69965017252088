<template>
  <el-dialog
    title="创建调拨单"
    :visible.sync="visible"
    width="500"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-form
      size="small"
      label-position="left"
      label-width="120"
      :model="formdata"
      :rules="rules"
      ref="allotform"
    >
      <el-form-item label="调出仓库" prop="warehouse_from_id">
        <el-select
          placeholder="请选择调出仓库"
          v-model="formdata.warehouse_from_id"
          :disabled="editflag"
        >
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="formdata.warehouse_to_id === item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调入仓库" prop="warehouse_to_id">
        <el-select
          placeholder="请选择调入仓库"
          v-model="formdata.warehouse_to_id"
          :disabled="editflag"
        >
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="formdata.warehouse_from_id === item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="计划日期" prop="date">
        <el-date-picker
          v-model="formdata.date"
          type="date"
          placeholder="请选择计划日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :rows="5"
          v-model="formdata.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="createallot()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { createSalesTransfer, editSalesTransfer } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        warehouse_from_id: '',
        warehouse_to_id: '',
        date: '',
        remark: ''
      },
      pickerOptions: {
        // 限制交货日期不让选择今天以前的
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      },
      rules: {
        warehouse_from_id: [
          { required: true, message: '请选择调出仓库', trigger: 'blur' }
        ],
        warehouse_to_id: [
          { required: true, message: '请选择调出仓库', trigger: 'blur' }
        ],
        date: [{ required: true, message: '请选择计划日期', trigger: 'blur' }]
      },
      ec_id: '',
      editflag: false
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (ecid, editflag, warehouseFrom, warehouseTo, date, remark) {
      this.ec_id = ecid
      this.editflag = editflag
      this.formdata.warehouse_from_id = warehouseFrom
      this.formdata.warehouse_to_id = warehouseTo
      this.formdata.date = date
      this.formdata.remark = remark
      this.visible = true
    },
    hide () {
      this.formdata = {}
      this.visible = false
    },
    createallot () {
      if (this.editflag) {
        const jsondata = {
          id: this.ec_id,
          date: this.formdata.date,
          remark: this.formdata.remark
        }
        editSalesTransfer(jsondata)
          .then(data => {
            this.$emit('edit-success')
            this.hide()
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$refs.allotform.validate(valid => {
          if (valid) {
            const jsondata = {
              warehouse_from: this.formdata.warehouse_from_id,
              warehouse_to: this.formdata.warehouse_to_id,
              date: this.formdata.date,
              ec_id: this.ec_id,
              remark: this.formdata.remark
            }
            createSalesTransfer(jsondata)
              .then(data => {
                this.$emit('create-success')
                this.hide()
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
      }
    },
    closedone (done) {
      done()
    }
  }
}
</script>
