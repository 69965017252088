<template>
  <el-dialog
    title="创建FBAInboundShipment"
    :visible.sync="visible"
    width="600px"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-tag>提示：创建Plan后，相应产品数量将记入“计划中”。</el-tag>
    <el-row style="margin-top: 20px;">
      <el-form size="small">
        <el-form-item label="装箱率向下取整:">
          <el-switch
            v-model="formdata.floor"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开"
            inactive-text="关"
            class="taxswitch"
          >
          </el-switch>
          <p>默认不开启则装箱率向上取整</p>
        </el-form-item>
        <el-form-item label="补货仓库设置:">
          <el-select v-model="formdata.warehouse_id">
            <el-option
              v-for="item in sysConfig.warehouselist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="强制发货:">
          <el-switch
            v-model="formdata.forceShip"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开"
            inactive-text="关"
            class="taxswitch"
          >
          </el-switch>
          <p>数量少于采购装箱率的将被取消.</p>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table border stripe width="100%" :data="forcepdtList">
        <el-table-column label="sku" prop="sku"></el-table-column>
        <el-table-column label="装箱率" prop="qty_in_case"></el-table-column>
        <el-table-column label="补货数量" prop="qty"></el-table-column>
      </el-table>
    </el-row>
    <span slot="footer">
      <el-button type="primary" size="small" @click="createFBAShipment()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { createShipment } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        floor: false,
        forceShip: false
      },
      ec_id: '',
      items: [],
      forcepdtList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (items, ecId) {
      this.ec_id = ecId
      this.visible = true
      this.items = items
    },
    closedone (done) {
      this.formdata = {}
      done()
    },
    hide () {
      this.visible = false
    },
    createFBAShipment () {
      // this.forcepdtList = [{ sku: '19V01119001' }, { sku: '19H-BH198E3Z2' }]
      // this.$emit('success-create', this.forcepdtList)
      // this.hide()
      const jsondata = {
        ec_id: this.ec_id,
        warehouse_id: this.formdata.warehouse_id,
        floor: this.formdata.floor,
        forceShip: this.formdata.forceShip,
        items: this.items
      }
      createShipment(jsondata)
        .then(data => {
          this.forcepdtList = data
          this.$emit('success-create', this.forcepdtList)
          this.formdata = {}
          //    this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
