<!-- 采购单材积更新 -->
<template>
  <el-dialog title="材积更新" :visible.sync="visible" width="1200px">
    <el-table
      stripe
      style="width:100%"
      @selection-change="handleSelectionChange"
      :data="stockdetaillist"
    >
      <el-table-column type="selection" width="36"> </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-popover
            ref="popover"
            placement="right-start"
            trigger="hover"
            :visible-arrow="false"
          >
            <img
              v-if="scope.row.cover"
              :src="scope.row.cover"
              style="width: 100px; height: 100px;"
            />
            <img
              v-else
              src="/static/no-photo.jpg"
              style="width: 100px; height: 100px;"
            />
            <img
              slot="reference"
              v-if="scope.row.cover"
              :src="scope.row.cover"
              style="width: 60px;height: 60px;border: 1px solid #F9FAFC;vertical-align: middle; margin: 5px"
            />
            <img
              slot="reference"
              v-else
              src="/static/no-photo.jpg"
              style="width: 60px;height: 60px;border: 1px solid #F9FAFC;vertical-align: middle; margin: 5px"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="product.name,product.mfn" label="货品" width="160">
        <template slot-scope="scope">
          {{ scope.row.product.mfn }} <br />
          {{ scope.row.product.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="ctn_length,product.ctn_package_dimension.length"
        label="外箱长"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.ctn_length }}<br />
          <div v-if="scope.row.product.ctn_package_dimension">
            {{ scope.row.product.ctn_package_dimension.length }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ctn_width,product.ctn_package_dimension.width"
        label="外箱宽"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.ctn_width }}<br />
          <div v-if="scope.row.product.ctn_package_dimension">
            {{ scope.row.product.ctn_package_dimension.width }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ctn_height,product.ctn_package_dimension.height"
        label="外箱高"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.ctn_height }}<br />
          <div v-if="scope.row.product.ctn_package_dimension">
            {{ scope.row.product.ctn_package_dimension.height }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ctn_weight,product.ctn_package_dimension.weight"
        label="外箱重"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.ctn_weight }}<br />
          <div v-if="scope.row.product.ctn_package_dimension">
            {{ scope.row.product.ctn_package_dimension.weight }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="box_length,box_package_dimension.length"
        label="内盒长"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.box_length }}<br />
          <div v-if="scope.row.product.box_package_dimension">
            {{ scope.row.product.box_package_dimension.length }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="box_width,product.box_package_dimension.width"
        label="内盒宽"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.box_width }}<br />
          <div v-if="scope.row.product.box_package_dimension">
            {{ scope.row.product.box_package_dimension.width }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="box_height,product.box_package_dimension.height"
        label="内盒高"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.box_height }}<br />
          <div v-if="scope.row.product.box_package_dimension">
            {{ scope.row.product.box_package_dimension.height }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="box_weight,product.box_package_dimension.weight"
        label="内盒重"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.box_weight }}<br />
          <div v-if="scope.row.product.box_package_dimension">
            {{ scope.row.product.box_package_dimension.weight }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button size="small" @click="changeProductInfo"
        >同步至商品信息</el-button
      >
      <el-button size="small" type="primary" @click="hide()">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as stockOrder from '../../../api/stockOrder'

export default {
  props: ['stockdetaillist'],
  data () {
    return {
      visible: false,
      volumeproductlist: []
    }
  },

  methods: {
    show () {
      console.log(11111)
      this.visible = true
    },
    handleSelectionChange (val) {
      this.volumeproductlist = []
      val.forEach(item => {
        this.volumeproductlist.push(item.id)
      })
    },
    changeProductInfo () {
      const volumeproductformdata = new FormData()
      if (this.volumeproductlist.length > 0) {
        for (let i = 0; i < this.volumeproductlist.length; i++) {
          volumeproductformdata.append(`id[${i}]`, this.volumeproductlist[i])
        }
      } else {
        volumeproductformdata.append('id', [])
      }
      stockOrder
        .syncDimenssionToProdict(volumeproductformdata)
        .then(data => {
          this.$emit('successvolumeupdate')
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
