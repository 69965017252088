<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>费用&成本</el-breadcrumb-item>
        <el-breadcrumb-item>导出采购成本</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h4>导出采购成本</h4>
    </el-row>
    <el-row>
      <el-tag type="info"
        >每个月1日月结时更新一次采购成本；如果月结失败未完成，则无法导出采购成本，即只有月结成功完结才能导出。</el-tag
      >
    </el-row>
    <el-row style="margin-top: 10px;">
      <el-button type="primary" size="small" @click="downLoad()"
        >导出下载</el-button
      >
    </el-row>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  methods: {
    downLoad () {
      const url =
        baseUrl +
        'wms/feeAndCost/exportPurchaseCost?token=' +
        localStorage.getItem('token')
      window.open(url)
    }
  }
}
</script>
