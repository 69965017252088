<!-- // 应付款-->
<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务管理</el-breadcrumb-item>
        <el-breadcrumb-item>应付款</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in sysConfig.FinancePaymentBillOrder.step"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        >
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <payable-bill-list :step="tabvalue"></payable-bill-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import payableBillList from './payable-bill-list'
export default {
  components: {
    payableBillList
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  data () {
    return {
      tabslist: [
        {
          key: 1,
          val: '未付款'
        },
        {
          key: 2,
          val: '已付款'
        }
      ],
      tabvalue: '1'
    }
  }
}
</script>
