<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/wms/warehouse/stock-out-in' }"
          >单据查询</el-breadcrumb-item
        >
        <el-breadcrumb-item v-show="type === '-3'"
          >调拨出库单</el-breadcrumb-item
        >
        <el-breadcrumb-item v-show="type === '3'"
          >调拨入库单</el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-row>
    <h2 v-show="type === '-3'">调拨出库单</h2>
    <h2 v-show="type === '3'">调拨入库单</h2>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单号">
              &nbsp;&nbsp;{{ outInDetailData.id
              }}<el-tag>{{ outInDetailData._id }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="人员">
              &nbsp;&nbsp;{{ outInDetailData._user_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="流程">
              &nbsp;&nbsp;{{ outInDetailData._step
              }}<el-tag size="mini">状态</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="最后更新时间">
              &nbsp;&nbsp;{{ outInDetailData.updated_at }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="调出仓库">
              &nbsp;&nbsp;<span>{{ outInDetailData._warehouse_from }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="调入仓库">
              &nbsp;&nbsp;{{ outInDetailData._warehouse_to }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="出库单" v-if="type === '3'">
              &nbsp;&nbsp;{{ outInDetailData._warehouse_from__id }}
            </el-form-item>
            <el-form-item label="入库单" v-if="type === '-3'">
              &nbsp;&nbsp;{{ outInDetailData._warehouse_to__id }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="第三方单号">
              &nbsp;&nbsp;{{ outInDetailData.no }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-form-item label="备注">
            &nbsp;&nbsp;{{ outInDetailData.remark }}
          </el-form-item>
        </el-row>
        <el-row :gutter="16">
          <el-button
            type="primary"
            size="small"
            @click="confirmTransferOut()"
            v-if="type === '-3' && outInDetailData.step === 2"
            >发出确认</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="confirmTransferIn()"
            v-if="type === '3'"
            >到货确认</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="5" :offset="18">
        <el-dropdown>
          <el-button type="primary" size="small">导入导出</el-button>
          <i class="el-icon-arrow-down el-icon--right"></i>
          <el-dropdown-menu>
            <el-dropdown-item
              @click.native="
                $refs['allot-out-import-dialog'].show(outInDetailData.id)
              "
              >导入明细</el-dropdown-item
            >
            <el-dropdown-item @click.native="downloadLink"
              >导出明细</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="outInDetailData.item" border>
        <el-table-column prop="_product_mfn" label="产品编号"></el-table-column>
        <el-table-column prop="_product_name" label="品名"></el-table-column>
        <el-table-column prop="_step" label="单位"></el-table-column>
        <el-table-column prop="qty_in_ctn" label="装箱率"></el-table-column>
        <el-table-column prop="ctn" label="箱数"></el-table-column>
        <el-table-column prop="pcs" label="散装数"></el-table-column>
        <el-table-column prop="total_qty" label="总数"></el-table-column>
      </el-table>
    </el-row>
    <allot-out-import-dialog
      ref="allot-out-import-dialog"
      @success-import-allot-out="getOutInDetail()"
    ></allot-out-import-dialog>
  </div>
</template>
<script>
import * as warehouseApi from '@/api/warehouse'
import { baseUrl } from '../../../config/env'
import allotOutImportDialog from './allot-out-import-dialog'
export default {
  components: {
    allotOutImportDialog
  },
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      outInDetailData: {}
    }
  },
  created () {
    this.getOutInDetail()
  },
  methods: {
    getOutInDetail () {
      const self = this
      warehouseApi
        .getwmsOrderDetail({ id: self.id })
        .then(data => {
          self.outInDetailData = data
          console.log(self.outInDetailData)
        })
        .catch(err => {
          console.log(err)
        })
    },
    //   确认调拨出库单
    confirmTransferOut () {
      const self = this
      self
        .$confirm('提示', {
          title: '发出确认',
          message:
            '调拨出库单确认发出后，将自动生成目标仓库的调拨入库单（状态待收货），确认发出?'
        })
        .then(_ => {
          warehouseApi
            .confirmWmsTransferOut({ id: self.id })
            .then(data => {
              this.getOutInDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //   确认调拨入库单
    confirmTransferIn () {
      const self = this
      self
        .$confirm('提示', {
          title: '到货确认',
          message:
            '部分仓库单据系统会自动追踪并完成入库，请避免重复操作。 确认要操作已到货入库?'
        })
        .then(_ => {
          warehouseApi
            .confirmWmsTransferIn({ id: self.id })
            .then(data => {
              this.getOutInDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    downloadLink () {
      const url =
        baseUrl +
        'wms/order/exportOneOrderItems?token=' +
        localStorage.getItem('token') +
        '&id=' +
        this.outInDetailData.id
      window.open(url)
    }
  }
}
</script>
