import request from '../config/request'

export const login = data => request('guest/login', 'post', data)

export const getUserInfo = () => {
  return request('me/info', 'get', null, true).then(data => {
    return data
  })
}

export const getSysConfig = () => {
  return request('config', 'get', null, true).then(data => {
    return data
  })
}

export const getWarehouselist = () => {
  return request('wms/warehouse', 'get', null, true).then(data => {
    return data
  })
}

export const saveUserInfo = data => {
  request('me/info/save', 'post', data)
}

export const getVendorlist = data => {
  return request('purchase/vendor', 'get', data).then(data => {
    return data
  })
}

export const getVendorlistSimply = () => {
  let dat = getVendorlist()
  dat = dat.data
  // 未设计过滤处理 该方案待定
  return dat
}

// 获取用户信息
export const getUserApi = data => {
  return request('user/ls', 'get', data)
}
