<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/wms/warehouse/stock-out-in' }"
          >单据查询</el-breadcrumb-item
        >
        <el-breadcrumb-item v-show="type === '-4'"
          >销售出库单</el-breadcrumb-item
        >
        <el-breadcrumb-item v-show="type === '4'"
          >销售入库单</el-breadcrumb-item
        >
      </el-breadcrumb>
    </el-row>
    <h2 v-show="type === '-4'">销售出库单</h2>
    <h2 v-show="type === '4'">销售入库单</h2>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单号">
              &nbsp;&nbsp;{{ outInDetailData.id
              }}<el-tag>{{ outInDetailData._id }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="人员">
              &nbsp;&nbsp;{{ outInDetailData._user_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="流程">
              &nbsp;&nbsp;{{ outInDetailData._step
              }}<el-tag size="mini">状态</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="最后更新时间">
              &nbsp;&nbsp;{{ outInDetailData.updated_at }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="出仓仓库">
              &nbsp;&nbsp;<span>{{ outInDetailData._warehouse_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外部单号">
              &nbsp;&nbsp;<span>{{ outInDetailData.no }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外部平台">
              &nbsp;&nbsp;<span>{{ outInDetailData.platform }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-form-item label="备注">
            &nbsp;&nbsp;{{ outInDetailData.remark }}
          </el-form-item>
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="2">
        <el-button
          type="primary"
          size="mini"
          v-show="type === '-4'"
          @click="confirmSalesOut"
          v-if="outInDetailData.step === 1"
          >确认出库</el-button
        >
      </el-col>
      <el-col :span="2" :offset="2">
        <el-button
          type="primary"
          size="mini"
          v-show="type === '4'"
          @click="deleteOrderItems"
          v-if="outInDetailData.step < 9"
          >删除</el-button
        >
      </el-col>
      <el-col :span="5" :offset="8">
        <el-button
          type="text"
          @click="$refs['saleorder-out-import-dialog'].show(outInDetailData.id)"
          v-if="outInDetailData.step === 1"
          >导入明细</el-button
        >
      </el-col>
    </el-row>
    <el-row  style="margin-top: 10px;">
      <el-table stripe style="width:100%" :data="outInDetailData.item" border>
        <el-table-column prop="_product_mfn" label="产品编号"></el-table-column>
        <el-table-column prop="_product_name" label="品名"></el-table-column>
        <el-table-column prop="_step" label="单位"></el-table-column>
        <el-table-column prop="qty_in_ctn" label="装箱率"></el-table-column>
        <el-table-column prop="ctn" label="箱数"></el-table-column>
        <el-table-column prop="pcs" label="散装数"></el-table-column>
        <el-table-column prop="total_qty" label="总数"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-row>
    <saleorder-out-import-dialog
      ref="saleorder-out-import-dialog"
      @success-import-saleorderout="getOutInDetail()"
    ></saleorder-out-import-dialog>
  </div>
</template>
<script>
import * as warehouseApi from '@/api/warehouse'
import saleorderOutImportDialog from './saleorder-out-import-dialog'
export default {
  components: {
    saleorderOutImportDialog
  },
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      outInDetailData: {}
    }
  },
  created () {
    this.getOutInDetail()
  },
  methods: {
    getOutInDetail () {
      const self = this
      warehouseApi
        .getwmsOrderDetail({ id: self.id })
        .then(data => {
          self.outInDetailData = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    //   确认销售出库单
    confirmSalesOut () {
      const self = this
      self
        .$confirm('提示', { title: '销售出库确认', message: '确认出库操作?' })
        .then(_ => {
          warehouseApi
            .confirmSaleOrderOut({ id: self.id })
            .then(data => {
              this.getOutInDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    deleteOrderItems () {
      const self = this
      self
        .$confirm('提示', {
          title: '销售入库删除',
          message: '订单数据可能由系统自动生成，务必谨慎操作。确认删除该订单'
        })
        .then(_ => {
          warehouseApi
            .deleteSaleItem({ id: self.id })
            .then(data => {
              this.$router.push('/wms/warehouse/stock-out-in')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
