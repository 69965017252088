<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/wms/warehouse/stock-out-in' }"
          >单据查询</el-breadcrumb-item
        >
        <el-breadcrumb-item v-show="type === '1'">采购入库</el-breadcrumb-item>
        <el-breadcrumb-item v-show="type === '-1'">采购出库</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="5">
        <h2 v-show="type === '1'">采购入库单</h2>
        <h2 v-show="type === '-1'">采购出库单</h2>
      </el-col>
      <el-col :span="3" :offset="15">
        <!-- <button v-print="'#detail'">打印</button> -->
      </el-col>
    </el-row>
    <div id="detail">
      <el-row>
        <el-form>
          <el-row :gutter="16">
            <el-col :span="6">
              <el-form-item label="单号">
                &nbsp;&nbsp;{{ outInDetailData.id
                }}<el-tag>{{ outInDetailData._id }}</el-tag>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="供应商">
                &nbsp;&nbsp;{{ outInDetailData._vendor }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="采购单号">
                &nbsp;&nbsp;{{ outInDetailData.no }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="6">
              <el-form-item label="仓库">
                &nbsp;&nbsp;<span>{{ outInDetailData._warehouse_name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="人员">
                &nbsp;&nbsp;{{ outInDetailData._user_name }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="流程">
                &nbsp;&nbsp;{{ outInDetailData._step }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="最后更新时间">
                &nbsp;&nbsp;{{ outInDetailData.updated_at }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16" v-if="outInDetailData.remark">
            <el-form-item label="备注">
              &nbsp;&nbsp;{{ outInDetailData.remark }}
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
      <el-row>
        <el-button
          type="primary"
          size="small"
          @click="confirmStockIn"
          v-if="outInDetailData.step === 2"
          >到货确认</el-button
        >
      </el-row>
      <el-row style="margin-top: 10px">
        <el-table stripe style="width:100%" :data="outInDetailData.item" border>
          <el-table-column prop="_product_mfn" label="编号"></el-table-column>
          <el-table-column prop="_product_name" label="品名"></el-table-column>
          <el-table-column prop="_step" label="单位"></el-table-column>
          <el-table-column prop="qty_in_ctn" label="装箱率"></el-table-column>
          <el-table-column prop="ctn" label="箱数"></el-table-column>
          <el-table-column prop="pcs" label="散装数"></el-table-column>
          <el-table-column prop="total_qty" label="总数"></el-table-column>
        </el-table>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as warehouseApi from '../../../api/warehouse'
export default {
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      outInDetailData: {}
    }
  },
  created () {
    this.getOutInDetail()
  },
  methods: {
    getOutInDetail () {
      const self = this
      warehouseApi
        .getwmsOrderDetail({ id: self.id })
        .then(data => {
          self.outInDetailData = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmStockIn () {
      this.$confirm('提示', {
        title: '到货确认',
        message:
          '注意:入库单，大部分仓库单据系统会自动追踪并完成入库，请谨慎操作。该操作不可撤销，确定执行？'
      })
        .then(_ => {
          warehouseApi
            .confirmPurchaseIn({ id: self.id })
            .then(data => {
              this.getOutInDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
