export default [
  // Wayfair 订单
  {
    path: '/amazon/wayfair/sales',
    name: 'sales_wayfair_order',
    meta: { keepAlive: false },
    component: () => import('@/view/sales/wayfair/order/View.vue')
  },
  {
    path: '/amazon/wayfair/sales/detail/:po',
    component: () => import('@/view/sales/wayfair/order/sale-detail.vue'),
    name: 'wayfairSalesDetail'
  },
  // wayfair 退货单
  {
    path: '/sales/wayfair/refund',
    name: 'sales_wayfair_refund',
    component: () => import('@/view/sales/wayfair/refund/View.vue')
  }
]
