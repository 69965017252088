import request from '../config/request'
//  待交付查询列表
export const getreceivedSearchList = data => {
  return request(
    'finance/purchase/order/receivedSearch',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}
