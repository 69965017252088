import { httpClient } from '@/core'

// 获取wayfair退货单列表
export function getWaifairRefundList (params) {
  return httpClient.get('/sales/wayfair/lsRefund', params)
}

// 获取wayfair实时库存
export function getWayfairRealTimeInventory (params) {
  return httpClient.get('/sales/inventory/ls', params)
}

export function cancelOrder (params) {
  return httpClient.post('/sales/wayfair/detail/cancel', params)
}

export function lockInventory (params) {
  return httpClient.post('/sales/inventory/lock', params)
}
/**
 * 编辑明细
 * @param {*} data
 * @returns
 */
export function editWayfairRefundRecord (data) {
  return httpClient.post('/sales/wayfair/editRefund', data)
}

/**
 * 删除退货单
 * @param {*} id
 * @returns
 */
export function deleteWayfairRefund (id) {
  return httpClient.post('/sales/wayfair/delRefund', { id })
}

/**
 * 获取同po的所有物流记录
 * @param {*} po
 * @returns
 */
export function getWayfairExpressNoByPo () {
  const { run, cancel } = httpClient.cGet()
  return {
    run: (po) => run('/sales/wayfair/lsExpressNo', { po }),
    cancel
  }
}

/**
 * 新增单条退货记录
 * @param {*} data
 * @returns
 */
export function addWayfairRefundRecord (data) {
  return httpClient.post('/sales/wayfair/createRefund', data)
}

/**
 * 更新运单号
 * @param {*} data
 * @returns
 */
export function editWayfairExpressNo (data) {
  return httpClient.post('/sales/wayfair/editExpressNo', data)
}

export function setPaymentConfirm (data) {
  return httpClient.post('/sales/wayfair/paymentConfirm', data)
}

export function setReturned (data) {
  return httpClient.post('/sales/wayfair/setReturned', data)
}

export function allReturn (data) {
  return httpClient.get('/sales/wayfair/allReturn', data)
}
