<template>
  <div>
    <el-row class="content-row">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-input v-model="search.stockId" placeholder="采购单"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysConfig.vendorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getreceivedSearchList"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="receivedList" border>
        <el-table-column prop="_product_name,_product_mfn" label="货品">
          <template slot-scope="scope">
            <product-info-in-table-cell
              :cover="scope.row._product_cover"
              :title="scope.row._product_name"
              :mfn="scope.row._product_mfn"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column prop="qty,unreceived_qty" label="采购总数/待交付数">
          <template slot-scope="scope">
            {{ scope.row.qty }}/{{ scope.row.unreceived_qty }}
          </template>
        </el-table-column>
        <el-table-column prop="order_id" label="采购单"></el-table-column>
        <el-table-column prop="ship_date" label="交期"></el-table-column>
        <el-table-column prop="vendor_name" label="供应商"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as turnoverqueryApi from '../../../../api/turnoverquery'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
export default {
  components: {
    productInfoInTableCell
  },
  props: ['on'],
  data () {
    return {
      search: {},
      pageDat: {
        per_page: 10,
        current_page: 1
      },
      receivedList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    // 待交付列表接口参数
    listJsondata () {
      return {
        on: this.on,
        order_id: this.search.stockId,
        vendor_id: this.search.vendor_id,
        data_start: this.search.start,
        data_end: this.search.end,
        page: this.pageDat.current_page,
        per_page: this.pageDat.per_page
      }
    }
  },
  watch: {
    on: function () {
      this.getreceivedSearchList()
    }
  },
  created () {
    this.getreceivedSearchList()
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getreceivedSearchList () {
      turnoverqueryApi
        .getreceivedSearchList(this.listJsondata)
        .then(data => {
          this.receivedList = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getreceivedSearchList()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getreceivedSearchList()
    }
  }
}
</script>
