<template>
  <el-dialog
    title="选择 FBA Inbound Shipment"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="800px"
  >
    <el-form label-position="left" size="small">
      <el-form-item label="装箱率向下取整:">
        <el-switch
          v-model="floor"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关"
          class="taxswitch"
        >
        </el-switch>
        <p>默认不开启则装箱率向上取整</p>
      </el-form-item>
      <el-form-item label="强制发货:">
        <el-switch
          v-model="forceShip"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关"
          class="taxswitch"
        >
        </el-switch>
        <p>数量少于采购装箱率的将被取消.</p>
      </el-form-item>
      <el-table
        :data="datList"
        ref="multipleTable"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        @current-change="currentSelectionChange"
      >
        <el-table-column>
          <template v-slot="scope">
            <el-radio :label="scope.row.id + ''" v-model="radio">{{
              scope.row.name
            }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="ShipmentId"> </el-table-column>
        <el-table-column label="发出仓库" prop="_from"> </el-table-column>
        <el-table-column label="From Address">
          <template slot-scope="scope">
            {{ scope.row.ShipFromAddress.Name }} <br />
            {{ scope.row.ShipFromAddress.CountryCode }}-{{
              scope.row.ShipFromAddress.StateOrProvinceCode
            }}-{{ scope.row.ShipFromAddress.City }},{{
              scope.row.ShipFromAddress.PostalCode
            }}
          </template>
        </el-table-column>
        <el-table-column label="FBA Center ID" prop=""> </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="addFbaShipment()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { getfbaInboundShipmentList, addappendToShipment } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      datList: [],
      ShipmentId: '',
      floor: false,
      forceShip: false,
      items: [],
      ec_id: ''
    }
  },
  created () {
    getfbaInboundShipmentList({
      ec_id: this.ec_id
    })
      .then(data => {
        this.datList = data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    show (items, ecId) {
      this.ec_id = ecId
      getfbaInboundShipmentList({
        ec_id: this.ec_id
      })
        .then(data => {
          this.datList = data
        })
        .catch(err => {
          console.log(err)
        })
      this.visible = true
      this.items = items
    },
    closedone (done) {
      done()
    },
    hide () {
      this.visible = false
    },
    currentSelectionChange (row) {
      this.ShipmentId = row.ShipmentId
    },
    addFbaShipment () {
      const jsondata = {
        ec_id: this.ec_id,
        ShipmentId: this.ShipmentId,
        floor: this.floor,
        forceShip: this.forceShip,
        items: this.items
      }
      addappendToShipment(jsondata)
        .then(data => {
          this.$emit('success-add')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
