<template>
  <el-dialog
    title="移动至其他货代单/移动并创建新货代单"
    :visible.sync="visible"
    width="1280px"
    :before-close="closeDialog"
  >
    <el-tabs v-model="tabactiveName">
      <el-tab-pane label="1设置移动数量" name="first">
        <el-table
          stripe
          :data="orderdetailItem"
          border
          @selection-change="handleSelectionChange"
          @select="handleSelect"
          @select-all="handleSelectall"
        >
          <el-table-column
            type="selection"
            width="30"
            :selectable="checkboxflag"
            disable="true"
          >
          </el-table-column>
          <el-table-column
            prop="_product_name,_product_mfn"
            label="货品"
            width="250"
          >
            <template slot-scope="scope">
              <product-info-in-table-cell
                :cover="scope.row._product_cover"
                :title="scope.row._product_name"
                :mfn="scope.row._product_mfn"
              ></product-info-in-table-cell>
            </template>
          </el-table-column>
          <el-table-column
            prop="qty_ctn"
            label="装箱率"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="ctn_width,ctn_length,ctn_height,ctn_weight"
            label="外箱材积"
            width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.ctn_width }} * {{ scope.row.ctn_length }} *
              {{ scope.row.ctn_height }} <br />
              {{ scope.row.ctn_weight }}kg<br />
              {{ scope.row.ctn_volume_m }}m<sup>3</sup>
            </template>
          </el-table-column>
          <el-table-column prop="label" label="箱标" width="120">
            <template slot-scope="scope">
              {{ scope.row.label }} <br />
              <el-tag
                v-if="scope.row._product_box_package_dimension.sellpack > 1"
                >套{{
                  scope.row._product_box_package_dimension.sellpack
                }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="purchase_order_id"
            label="采购单"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="ship_date"
            label="交期"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="expected_date,expected_ctn"
            label="预计到货"
            width="130"
          >
            <template slot-scope="scope">
              {{ scope.row.expected_date }}/{{ scope.row.expected_ctn }}箱
            </template>
          </el-table-column>
          <el-table-column
            prop="unplanned_ctn,shipable_ctn"
            label="未排/可发"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.unplanned_ctn }}/{{ scope.row.shipable_ctn }}
            </template>
          </el-table-column>
          <el-table-column label="发货数" width="120">
            <template v-slot="scope">
              <el-row type="flex" align="middle">
                <div>
                  <el-input-number
                    controls-position="right"
                    size="mini"
                    v-if="scope.row.checked"
                    style="width: 90px;"
                    :min="scope.row.planned_ctn > 0 ? 1 : 0"
                    :max="parseInt(scope.row.planned_ctn)"
                    v-model="scope.row.x"
                  >
                  </el-input-number>
                  <span v-if="!scope.row.checked">{{ scope.row.x }}</span>
                </div>
                <div style="margin-left:10px" v-show="scope.row.checked"></div>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane
        label="2创建新货代单"
        name="second"
        v-if="type === '1'"
        :disabled="true"
      >
        <el-form
          :model="order"
          label-width="120px"
          ref="thisForm"
          label-position="left"
          :rules="rules"
        >
          <el-form-item prop="name" size="small" label="名称">
            <el-input v-model="order.name" style="width:220px "></el-input>
          </el-form-item>
          <el-form-item prop="warehouse_id" size="small" label="目的仓库">
            <el-select v-model="order.warehouse_id" style="width:220px ">
              <el-option
                v-for="item in warehouseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="transport_type" size="small" label="发货方式">
            <el-select v-model="order.transport_type" style="width:220px ">
              <el-option
                v-for="item in transportList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="date" size="small" label="预计发货日期">
            <el-date-picker
              v-model="order.date"
              type="date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="remark" size="small" label="备注">
            <el-input
              v-model="order.remark"
              type="textarea"
              rows="5"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane
        label="2选择货代单"
        name="third"
        v-if="type === '2'"
        :disabled="true"
      >
        <el-form label-position="left">
          <el-table
            :data="datList"
            ref="multipleTable"
            stripe
            border
            highlight-current-row
            style="width: 100%"
            @current-change="currentSelectionChange"
          >
            <el-table-column label="名称">
              <template v-slot="scope">
                <el-radio :label="scope.row.id + ''" v-model="radio">{{
                  scope.row.name
                }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column label="目的仓库" prop="warehouseName">
            </el-table-column>
            <el-table-column label="发货方式" prop="_transport_type">
            </el-table-column>
            <el-table-column label="预计发货日期" prop="date">
            </el-table-column>
          </el-table>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" v-if="tabactiveName === 'first'">
      <el-button
        size="small"
        @click.prevent="nexttabs('next')"
        type="primary"
        :disabled="detaillist.length === 0"
        >下一步</el-button
      >
    </span>
    <span slot="footer" v-if="tabactiveName != 'first'">
      <el-button size="small" @click.prevent="nexttabs('prive')" type="primary"
        >上一步</el-button
      >
      <el-button
        size="small"
        type="primary"
        :disabled="detaillist.length === 0"
        @click="moveforward()"
        >确定移动</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as forwardNewApi from '@/api/forwardNew'
import { mapGetters } from 'vuex'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
export default {
  components: {
    productInfoInTableCell
  },
  data () {
    return {
      type: '',
      visible: false,
      orderdetailItem: [],
      selectedList: [],
      selectItem: [],
      detaillist: [],
      datList: [], // 移动至货代单绑定数据
      order: {}, // 创建新货代单数据
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        transport_id: [{ required: true, trigger: 'change' }],
        warehouse_id: [{ required: true, trigger: 'change' }],
        date: [{ required: true, trigger: 'blur' }]
      },
      id: '',
      tabactiveName: 'first',
      warehouseList: [], // 创建新货代单数据
      transportList: [], // 创建新货代单数据
      pickerOptions: {
        // 创建新货代单数据
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      radio: '',
      selectId: null,
      filterId: this.id // 移动至货代单绑定数据
    }
  },
  created () {
    this.warehouseList = this.sysConfig.warehouselist
    this.transportList = this.sysConfig.Common.transport_type
    if (this.type === '2') {
      this.getforwardNewOrderListt()
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    type: function () {
      if (this.type === '2') {
        this.getforwardNewOrderListt()
      }
    }
  },
  methods: {
    checkboxflag (row, index) {
      if (row.id === 0) {
        return 1
      } else {
        return 0
      }
    },
    show (orderdata, id, type) {
      this.orderdetailItem = orderdata
      for (let i = 0; i < this.orderdetailItem.length; i++) {
        this.orderdetailItem[i].checked = false
      }
      this.id = id
      this.filterId = id
      this.type = type
      this.visible = true
      console.log(this.orderdetailItem)
    },
    //  选中checkbox项的回调
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
      } else {
        row.checked = true
      }
      this.selectedList = selection
      this.selectItem = this.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
    },
    //  全选回调函数
    handleSelectall (selection) {
      if (selection.length > 0) {
        for (let i = 0; i < this.orderdetailItem.length; i++) {
          this.orderdetailItem[i].checked = true
        }
      } else {
        for (let i = 0; i < this.orderdetailItem.length; i++) {
          this.orderdetailItem[i].checked = false
        }
      }
      this.selectedList = selection
      this.selectItem = this.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
    },
    handleSelectionChange (val) {
      this.detaillist = []
      val.forEach(item => {
        this.detaillist.push(item.label)
      })
    },
    nexttabs (clicktab) {
      if (clicktab === 'next') {
        if (this.type === '1') {
          this.tabactiveName = 'second'
        } else if (this.type === '2') {
          this.tabactiveName = 'third'
        }
      } else if (clicktab === 'prive') {
        this.tabactiveName = 'first'
      }
    },
    getforwardNewOrderListt () {
      forwardNewApi.getforwardNewOrderList({ step: 1 }).then(res => {
        this.datList = res.data.filter(item => item.id !== this.filterId)
      })
    },
    currentSelectionChange (row) {
      this.selectId = row.id
      this.radio = row.id + ''
    },
    closeDialog (done) {
      this.orderdetailItem = []
      this.id = ''
      this.filterId = ''
      this.type = ''
      done()
    },
    moveforward () {
      this.selectItem = this.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
      if (this.type === '1') {
        // 移至新货代单
        const JSondata = {
          from: this.id,
          order: this.order,
          item: this.selectItem
        }
        console.log(this.selectItem)
        this.$refs.thisForm.validate(valid => {
          if (valid) {
            forwardNewApi
              .moveforwardNewOrderDetailItem(JSondata)
              .then(data => {
                this.$emit('movesuccess')
                this.orderdetailItem = []
                this.id = ''
                this.filterId = ''
                this.type = ''
                this.visible = false
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            return false
          }
        })
      } else if (this.type === '2') {
        const data = {
          from: this.id,
          to: this.selectId,
          item: this.selectItem
        }
        forwardNewApi.moveforwardNewOrderDetailItem(data).then(res => {
          this.$emit('movesuccess')
          this.orderdetailItem = []
          this.id = ''
          this.filterId = ''
          this.type = ''
          this.visible = false
        })
      }
    }
  }
}
</script>
