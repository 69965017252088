<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">物流管理</el-breadcrumb-item>
        <el-breadcrumb-item>运营催单明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-row">
      <el-form :inline="true" size="mini">
          <el-form-item><el-input type="textarea" class="search-bar" placeholder="MFN，多个用逗号分隔" v-model="search.mfn"></el-input></el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="search.warehouse_id"
              placeholder="选择仓库"
            >
              <el-option
                v-for="item in warehouseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="search.apply_date"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="催单时间"
              end-placeholder="催单时间"
            ></el-date-picker>
          </el-form-item>
        <el-form-item>
          <el-input type="text" v-model="search.purchase_order" placeholder="采购单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.step" clearable>
            <el-option value="0" label="未出货代单"></el-option>
            <el-option value="1" label="已出单未发货"></el-option>
            <el-option value="2" label="已发货"></el-option>
            <el-option value="3" label="已完成"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.user_id" placeholder="催单人" clearable>
            <el-option
              v-for = "item in users"
              :key = "item.id"
              :value = "item.id"
              :label = "item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
          <el-button type="primary" @click="exportHandle">导出下载</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table :data="datList" stripe border width="100%" height="600" @select="handleSelect" @select-all="handleSelectAll">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column label="MFN" prop="mfn"></el-table-column>
        <el-table-column label="供应商编号" prop="vendor_sku"></el-table-column>
        <el-table-column label="外箱装箱率/内盒装箱率">
          <template slot-scope="scope">
            <div>
                {{scope.row.qty_ctn}} / {{scope.row.sellpack}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="平台" prop="_ec"></el-table-column>
        <el-table-column label="仓库" prop="_warehouse"></el-table-column>
        <el-table-column label="采购单" prop="purchase_order"></el-table-column>
        <el-table-column label="生产单号" prop="contract_id"></el-table-column>
        <el-table-column label="需求交期" prop="ship_date"></el-table-column>
        <el-table-column label="进仓时间" prop="inbound_date"></el-table-column>
        <el-table-column label="催单时间" prop="created_at"></el-table-column>
        <el-table-column label="催单人" prop="user_name"></el-table-column>
        <el-table-column label="催单数量" prop="apply_qty"></el-table-column>
        <el-table-column label="采购备注" prop="purchase_remark"></el-table-column>
        <el-table-column label="运营备注" prop="remark"></el-table-column>
        <el-table-column label="货代单名称" prop="logistics_order"></el-table-column>
        <el-table-column label="预计发货时间" prop="logistics_date"></el-table-column>
        <el-table-column label="状态" prop="logistics_step"></el-table-column>
      </el-table>

      <el-row class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="search.page"
          layout="total, sizes, prev, pager, next"
          :total="search.total"
          :page-size.sync="search.per_page"
        ></el-pagination>
      </el-row>
    </el-row>
    <el-button
      type="primary"
      size="small"
      @click="cancelApply"
    >
      撤销催单
    </el-button>
    <xlsx-downloader ref="xlsx-downloader-list" type="WMS_LOGISTICS_APPLY"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { logisticsList } from '@/api/forwardNew2021'
import { cancelApply } from '@/api/amazon'
import XlsxDownloader from '@/components/xlsx-downloader'
import confirm from '@/components/common/Confirm'
import { getUserApi } from '@/api/rootController'

export default {
  components: {
    XlsxDownloader
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  data () {
    return {
      warehouseList: [],
      datList: [],
      items: [],
      users: [],
      search: {
        mfn: '',
        warehouse_id: '',
        apply_date: '',
        purchase_order: ''
      },
      userFilter: {
        group_id: ''
      }
    }
  },
  created () {
    this.warehouseList = this.sysConfig.warehouselist
    this.searchHandle()
    this.getUser(2)
  },
  methods: {
    getUser (groupId) {
      this.userFilter.group_id = groupId
      getUserApi(this.userFilter).then(res => {
        this.users = res
      })
    },
    handleSelect (selection) {
      this.items = selection.map(item => {
        return {
          id: item.id
        }
      })
    },
    handleSelectAll (selection) {
      this.items = selection.map(item => {
        return {
          id: item.id
        }
      })
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.searchHandle()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.per_page = pagesize
      this.searchHandle()
    },
    searchHandle () {
      logisticsList(this.search).then(res => {
        this.datList = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.per_page = res.per_page
      })
    },
    exportHandle () {
      const params = {
        mfn: this.search.mfn,
        warehouse_id: this.search.warehouse_id,
        apply_date: this.search.apply_date,
        purchase_order: this.search.purchase_order
      }
      this.$refs['xlsx-downloader-list'].show(params)
    },
    cancelApply () {
      if (this.items === undefined || this.items.length === 0) {
        this.$alert('请至少选择一个催单记录')
        return false
      }
      confirm(
        '提示',
        '确定撤销已勾选的催单记录吗？'
      ).then(done => {
        done()
        cancelApply({ items: this.items }).then(() => {
          this.searchHandle()
        })
          .catch(err => {
            console.log(err)
          })
      })
    }
  }
}
</script>
