<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>SDW</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search">
      <el-col :span="18">
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-select v-model="search.step">
              <el-option
                v-for="item in sysConfig.ECInventoryTransfer.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="search.ec_id" placeholder="请选择ec">
              <el-option
                v-for="item in sysConfig.Common.ec"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchAllot()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-button
          size="mini"
          type="primary"
          @click="
            $refs['create-allot-dialog'].show(search.ec_id, false, '', '')
          "
          >创建调拨单</el-button
        >
      </el-col>
    </el-row>
    <el-row >
      <el-table stripe border :data="inventorlAllotls">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="步骤" prop="_step"></el-table-column>
        <el-table-column label="仓库">
          <template slot-scope="scope">
            {{ scope.row._warehouse_from }} &nbsp; - &nbsp;
            {{ scope.row._warehouse_to }}
          </template>
        </el-table-column>
        <el-table-column label="计划日期" prop="date"></el-table-column>
        <el-table-column label="创建日期" prop="created_at"></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="pushdetail(scope.row.id)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[10, 20, 50, 100, 150]"
        layout="total, prev,pager, next, sizes, jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <create-allot-dialog
      ref="create-allot-dialog"
      @create-success="getAllotls()"
    ></create-allot-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getSalesIntoryTransLs, getSalesEcList } from '@/api/amazon'
import createAllotDialog from './create-allot-dialog'
export default {
  components: {
    createAllotDialog
  },
  data () {
    return {
      search: {
        step: '',
        ec_id: 5
      },
      pageData: {
        current_page: 1,
        per_page: 10
      },
      ecList: [],
      inventorlAllotls: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    getSalesEcList({ type: 4 })
      .then(data => {
        this.ecList = data
      })
      .catch(err => {
        console.log(err)
      })
    this.getAllotls()
  },
  methods: {
    getAllotls () {
      const jsondata = {
        ec_id: this.search.ec_id,
        step: this.search.step,
        per_page: this.pageData.per_page,
        page: this.pageData.current_page
      }
      getSalesIntoryTransLs(jsondata)
        .then(data => {
          this.inventorlAllotls = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchAllot () {
      this.current_page = 1
      this.getAllotls()
    },
    pushdetail (id) {
      this.$router.push({
        name: 'sdwInventoryallotdetail',
        params: {
          id: id
        }
      })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
    }
  }
}
</script>
