<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>Shopify</el-breadcrumb-item>
        <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tab" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in sysConfig.ECProduct.status"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <product-list :status="tabvalue" :ecList="ecList"></product-list>
  </div>
</template>
<script>
import productList from './product-list'
import { mapGetters } from 'vuex'
import { getSalesEcList } from '@/api/amazon'
export default {
  components: {
    productList
  },
  data () {
    return {
      tabvalue: '1',
      ecList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    getSalesEcList({ type: 3 })
      .then(data => {
        this.ecList = data
      })
      .catch(err => {
        console.log(err)
      })
  }
}
</script>
