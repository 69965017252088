<template>
  <div>
    <el-dialog
      :title="title"
      width="500px"
      :visible.sync="visible"
      :show-close="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        size="small"
        ref="rateSetdialog"
        :model="search"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item prop="currency" label="主币">
          <el-select v-model="search.currency" :disabled="disable">
            <el-option
              v-for="item in currencylist"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="exchange" label="兑换">
          <el-select v-model="search.exchange" :disabled="disable">
            <el-option
              v-for="item in currencylist"
              :key="item.key"
              :label="item.val"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="rate" label="汇率">
          <el-input v-model="search.rate"></el-input>
        </el-form-item>
        <el-form-item prop="time" label="生效时间">
          <el-date-picker
            :picker-options="pickerOptions"
            v-model="search.time"
            value-format="yyyyMM"
            type="month"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveOrUpdateRate()">保存</el-button>
        <el-button type="primary" @click="handleClose()">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as rateSetApi from '../../../api/rateSet'
export default {
  data () {
    var validateNumberFour = (rule, value, callback) => {
      const numtwo = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,4})?$/
      if (!value) {
        callback(new Error('请输入汇率'))
      }
      if (numtwo.test(value)) {
        callback()
      } else {
        callback(new Error('最多只能保留四位小数'))
      }
    }
    return {
      title: '',
      visible: false,
      search: {},
      currencylist: [],
      disable: false,
      pickerOptions: {
        disabledDate: time => {
          // 设置可选择的日期为今天之后的一个月内
          const curDate = new Date().getTime()
          const day = 30 * 24 * 3600 * 1000
          const dateRegion = curDate + day
          return (
            time.getTime() < Date.now() - 8.64e7 || time.getTime() > dateRegion
          )
        }
      },
      rules: {
        currency: [{ required: true, message: '请选择主币', trigger: 'blur' }],
        exchange: [
          { required: true, message: '请选择兑换货币', trigger: 'blur' }
        ],
        rate: [{ validator: validateNumberFour, trigger: 'blur' }],
        time: [{ required: true, message: '请选择生效时间', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.currencylist = this.sysConfig.Common.currency
  },
  methods: {
    show (currency = null, exchange = null) {
      if (currency != null) {
        this.title = '更新汇率'
        this.search.currency = currency
        this.search.exchange = exchange
        this.disable = true
      } else {
        this.title = '添加汇率'
        this.disable = false
      }
      this.visible = true
    },
    handleClose () {
      this.search = {}
      this.visible = false
    },
    saveOrUpdateRate () {
      this.$refs.rateSetdialog.validate(valid => {
        if (valid) {
          self
            .$confirm('指定月份的汇率更新后不可更改，确定更新汇率？')
            .then(_ => {
              if (this.disable) {
                rateSetApi
                  .updatecurrency(this.search)
                  .then(data => {
                    this.$emit('refreshRate')
                  })
                  .catch(err => {
                    console.log(err)
                  })
              } else {
                rateSetApi
                  .savecurrency(this.search)
                  .then(data => {
                    this.$emit('refreshRate')
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }
              this.visible = false
              this.hide()
            })
            .catch(_ => {})
        }
      })
    }
  }
}
</script>
