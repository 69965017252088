import axios from 'axios'

axios.defaults.headers.post['X-Request-With'] = 'XMLHttpRequest'

const client = axios.create({
  timeout: 10000,
  baseURL: null
})

class HttpClient {
  constructor ({
    onRequest = undefined,
    onResponse = undefined,
    onError = undefined
  }) {
    this.onError = onError

    client.interceptors.request.use(config => {
      return onRequest ? onRequest(config) : config
    }, err => {
      onError && onError(err)
      return Promise.reject(err)
    })

    client.interceptors.response.use(resp => {
      return onResponse ? onResponse(resp) : resp
    }, err => {
      onError && onError(err)
      return Promise.reject(err)
    })

    // const get = this.get
    // this.get = (...args) => {
    //   return get(...args).catch(onError)
    // }
  }

  get (url, params = {}, config = {}) {
    if (url.indexOf('http') === -1) {
      url = process.env.VUE_APP_API_ORIGIN + url // 'https://erp-api.soffeedesign.net/admin' + url
    }

    return client.request(Object.assign(config, {
      method: 'GET',
      url,
      params
    }))
      .catch(this.onError)
  }

  post (url, data = {}, config = {}) {
    if (url.indexOf('http') === -1) {
      url = process.env.VUE_APP_API_ORIGIN + url // 'https://erp-api.soffeedesign.net/admin' + url
    }
    return client.request(Object.assign(config, {
      method: 'POST',
      url,
      data
    }))
      .catch(this.onError)
  }

  /**
   * 可中断的get请求
   * @returns
   */
  cGet () {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    return {
      cancel: msg => source.cancel(msg),
      run: (...args) => {
        const arg2 = args[2] || {}
        args[2] = Object.assign(arg2, { cancelToken: source.token })

        return this.get(...args)
      }
    }
  }

  cPost () {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    return {
      cancel: msg => source.cancel(msg),
      run: (...args) => {
        const arg2 = args[2] || {}
        args[2] = Object.assign(arg2, { cancelToken: source.token })
        return this.post(...args)
      }
    }
  }

  /**
   * 判断是否可中断
   * @param {*} thrown
   * @returns boolean
   */
  isCancel (thrown) {
    return axios.isCancel(thrown)
  }
}

function httpClientFactory () {
  const config = {}
  const methods = {
    create () {
      return new HttpClient(config)
    },
    // 请求拦截器
    onRequest (handler) {
      config.onRequest = handler
      return methods
    },
    // 响应拦截器
    onResponse (handler) {
      config.onResponse = handler
      return methods
    },
    onError (handler) {
      config.onError = handler
      return methods
    }
  }
  return methods
}

export default httpClientFactory
