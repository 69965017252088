import request from '../config/request'

export const getShopifyProductList = data => {
  return request('shopify/product/ls', 'get', data, true).then(data => {
    return data
  })
}

export const getShopifyProductDetail = data => {
  return request('shopify/product/detail', 'get', data, true).then(data => {
    return data
  })
}
