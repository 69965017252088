<template>
  <el-dialog title="新增其他款项" :visible.sync="visible" width="400px">
    <el-form
      :model="formDat"
      ref="thisForm"
      label-position="left"
      :rules="rules"
    >
      <el-form-item label-width="90px" prop="remark" size="small" label="款项">
        <el-input v-model="formDat.remark" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item label-width="90px" prop="amount" size="small" label="金额">
        <el-input v-model="formDat.amount" style="width:220px "></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click.prevent="onSubmit" type="primary"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as reimbursementApi from '@/api/reimbursement'

export default {
  props: ['orderId', 'purchaseId'],
  data () {
    return {
      visible: false,
      formDat: {},
      rules: {
        remark: [{ required: true, message: '请输入款项', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入金额', trigger: 'blur' }]
      }
    }
  },

  methods: {
    onSubmit () {
      if (!this.orderId) {
        return false
      }
      // this.formDat.purchase_order = this.purchaseId;
      // this.formDat.order_id = this.orderId;
      this.formDat.payment_order_id = this.purchaseId
      console.log(this.formDat)

      this.$refs.thisForm.validate(valid => {
        if (valid) {
          reimbursementApi
            .addToreimbursementOtherFee(this.formDat)
            .then(res => {
              this.hide()
              this.$emit('create-end')
            })
        } else {
          return false
        }
      })
    },
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
