<template>
  <el-dialog
    title="创建海外仓入库单"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-tabs v-model="tabvalue">
      <el-tab-pane label="1、设置方式" name="first">
        <el-row :gutter="16" style="margin-bottom: 50px; margin-left: 20px;">
          <el-radio
            v-model="overSeaType"
            label="create"
            style="line-height: 30px"
          >
            尚未创建海外仓入库单，需要系统同步创建；</el-radio
          >
        </el-row>
        <el-row :gutter="16" style="margin-bottom: 50px; margin-left: 20px;">
          <el-radio
            v-model="overSeaType"
            label="repair"
            style="line-height: 30px"
            >已创建海外仓入库单，补录至系统。</el-radio
          >
        </el-row>
        <el-row style="margin-top: 100px">
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="small" @click="changeTab()"
              >下一步</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="2、创建海入库单" name="second" :disabled="true">
        <el-tag style="margin-bottom: 15px;"
          >海外仓入库单创建后不可更改，确定创建？</el-tag
        >
        <el-form label-width="80px" label-position="left" v-if="fabflag">
          <el-form-item label="区域">
            <el-select v-model="formdata.area">
              <el-option
                v-for="item in sysConfig.Common.MWS.FBAInboundShipment"
                :key="item.key"
                :label="item.key"
                :value="item.val"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="国家/地区">
            <el-select v-model="formdata.unix">
              <el-option
                v-for="item in formdata.area"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-row>
          <div v-loading="loading" :element-loading-text="loadtext">
            {{ dataresult }}
          </div>
        </el-row>
        <el-row>
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="mini" @click="createConfrim()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="2、创建海入库单" name="third" :disabled="true">
        <el-tag style="margin-bottom: 15px;"
          >目前补录入库单仅支持FBAInboundShipment。</el-tag
        >
        <el-input
          v-model="fbashipcode"
          placeholder="多个FBAInboundShipment使用英文或中文逗号隔开"
        ></el-input>
        <el-row style="margin-top: 10px">
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="mini" @click="createConfrim()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { setShipment } from '@/api/forwardNew'
export default {
  data () {
    return {
      visible: false,
      tabvalue: 'first',
      overSeaType: '',
      fbashipcode: '',
      formdata: {
        area: '',
        unix: ''
      },
      fabflag: false,
      id: '',
      loading: false,
      loadtext: '加载中',
      dataresult: ''
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (isToFba, id) {
      this.fabflag = isToFba
      this.id = id
      this.visible = true
    },
    changeTab () {
      console.log(this.overSeaType)
      if (this.overSeaType === 'create') {
        this.tabvalue = 'second'
      } else if (this.overSeaType === 'repair') {
        this.tabvalue = 'third'
      }
    },
    createConfrim () {
      this.loading = true
      setShipment({
        id: this.id,
        mwsMarketplace: this.formdata.unix,
        FbaShipmentId: this.fbashipcode,
        setType: this.overSeaType
      })
        .then(data => {
          this.loadtext = '创建成功'
          this.loading = false
          this.$emit('create-oversea-success')
          this.hide()
        })
        .catch(err => {
          this.loadtext = '失败'
          this.loading = false
          this.dataresult = err.dat
          console.log(err)
        })
    },
    hide () {
      this.tabvalue = 'first'
      this.formdata = {}
      this.fabflag = false
      this.id = ''
      this.dataresult = ''
      this.overSeaType = ''
      this.fbashipcode = ''
      this.visible = false
    },
    closedone (done) {
      this.hide()
      done()
    }
  }
}
</script>
