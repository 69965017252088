<template>
  <el-dialog
    title="导入更新产品"
    :visible.sync="visible"
    :before-close="closeDone"
    width="500px"
  >
    <el-form label-width="80px">
      <el-form-item label-width="20px">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="excel"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="20px" label>
        <p>
          点击下载<el-link
            type="primary"
            :href="downloadTemplate"
            target="_blank"
            >产品excel模板文件.xls</el-link
          >
        </p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >upload</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
export default {
  data () {
    return {
      visible: false,
      dataimport: {
        id: '',
        deletemode: false
      },
      resInventoryList: []
    }
  },
  computed: {
    importInboundOrder () {
      return (
        baseUrl +
        'sales/wayfair/importProduct?token=' +
        localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return (
        baseUrl +
        'sales/wayfair/importProductTpl?token=' +
        localStorage.getItem('token')
      )
    }
  },
  methods: {
    show (id) {
      this.visible = true
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    },
    closeDone (done) {
      done()
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
