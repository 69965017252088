<template>
  <div>
    <el-row class="content-row">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysConfig.vendorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:160px;"
            v-model="search.tax"
            placeholder="出口退税"
            v-show="false"
          >
            <el-option
              v-for="item in sysConfig.Common.currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:80px;"
            v-model="search.currency"
            placeholder="币种"
          >
            <el-option
              v-for="item in sysConfig.Common.currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small">搜索</el-button>
          <!-- <el-button type="primary" size="small" @click="pushreimbursementDetail()">查看</el-button> -->
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="reimbursementlist" border>
        <el-table-column prop="id" label="报销单"></el-table-column>
        <el-table-column prop="vendor_name" label="供应商"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column prop="updated_at" label="最后更新"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="pushreimbursementDetail(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as reimbursementApi from '../../../../api/financeReimbursement'
export default {
  props: ['step'],
  data () {
    return {
      search: {},
      reimbursementlist: [],
      pageDat: {
        per_page: 10,
        current_page: 1
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    // 报销单列表接口参数
    listJsondata () {
      return {
        step: this.step,
        object_id: this.search.vendor_id,
        currency: this.search.currency,
        page: this.pageDat.current_page,
        num_per_page: this.pageDat.per_page
      }
    }
  },
  watch: {
    step: function () {
      this.getReimbursement()
    }
  },
  created () {
    this.getReimbursement()
  },
  methods: {
    getReimbursement () {
      reimbursementApi
        .getreimbursementlist(this.listJsondata)
        .then(data => {
          this.reimbursementlist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getReimbursement()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getReimbursement()
    },
    pushreimbursementDetail (reimbursementId) {
      this.$router.push({
        // path: '/finance/purchase/reimbursement/detail',
        name: 'financeReimbursementOrderDetail',
        params: {
          id: reimbursementId
        }
      })
    }
  }
}
</script>
