import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import ElementUI from 'element-ui'
import Print from 'vue-print-nb'

import 'element-ui/lib/theme-chalk/index.css'

export function install () {
  Vue.use(VueCompositionAPI)
  Vue.use(ElementUI)
  Vue.use(Print)
}
