<template>
  <el-dialog
    title="编辑库存明细"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closedone"
    width="800"
  >
    <el-form size="small" label-width="120px" label-position="left">
      <el-form-item label="SKU:"> &nbsp;&nbsp;{{ formdata.sku }} </el-form-item>
      <el-form-item label="仓库SKU:">
        &nbsp;&nbsp;{{ formdata._sku }}
      </el-form-item>
      <el-form-item label="QTY:">
        <el-input v-model="formdata.qty"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="mini" @click="saveItemTransfer()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { editItemTransfer } from '@/api/warehouse'
export default {
  data () {
    return {
      visible: false,
      id: '',
      formdata: {}
    }
  },
  methods: {
    show (id, formdata) {
      this.id = id
      this.formdata = formdata
      this.visible = true
    },
    saveItemTransfer () {
      const jsondata = {
        id: this.id,
        item_id: this.formdata.id,
        qty: this.formdata.qty
      }
      editItemTransfer(jsondata)
        .then(data => {
          this.$emit('edit-item-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      this.formdata = {}
      done()
    },
    hide () {
      this.id = ''
      this.formdata = {}
      this.visible = false
    }
  }
}
</script>
