<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据中心</el-breadcrumb-item>
        <el-breadcrumb-item>WMS仓库单据</el-breadcrumb-item>
        <el-breadcrumb-item>报损明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h4>报损明细</h4>
    </el-row>
    <el-row class="search">
      <el-form size="mini" :inline="true">
        <el-form-item>
          <el-date-picker
            size="mini"
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchList()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table border stripe width="100%" :data="dataList" height="600">
        <el-table-column prop="order_id" label="仓库单号"></el-table-column>
        <el-table-column prop="date" label="时间"></el-table-column>
        <el-table-column prop="_warehouse" label="仓库"></el-table-column>
        <el-table-column prop="platform" label="平台"></el-table-column>
        <el-table-column prop="_product.mfn" label="MFN"></el-table-column>
        <el-table-column prop="_product.name" label="品名"></el-table-column>
        <el-table-column prop="qty" label="数量"></el-table-column>
        <el-table-column prop="cost" label="成本单价"></el-table-column>
        <el-table-column prop="costAmount" label="金额"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 200, 300]"
        layout="total, prev, pager, next, sizes, jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { getdisposal } from '@/api/qcenterQuery'
export default {
  data () {
    return {
      dataList: [],
      search: {
        _date: [],
        start: '',
        end: ''
      },
      pageData: {
        current_page: 1,
        per_page: 100
      }
    }
  },
  beforeMount () {
    this.getdisposalList()
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getdisposalList () {
      const jsondata = {
        startDate: this.search.start,
        endDate: this.search.end,
        page: this.pageData.current_page,
        per_page: this.pageData.per_page
      }
      getdisposal(jsondata)
        .then(data => {
          this.dataList = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchList () {
      this.getdisposalList()
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getdisposalList()
    },
    changeSizeHandler (size) {
      this.pageData.per_page = size
      this.getdisposalList()
    }
  }
}
</script>
