<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>销售分析</el-breadcrumb-item>
        <el-breadcrumb-item>门店平台统计</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-tabs v-model="tabvalue">
        <el-tag
          type="danger"
          style="margin-bottom: 12px;"
          v-if="errorLogs.count > 0"
          >注意： 部分门店统计存在问题，待源数据修复后，可再操作执行统计。
          <el-button type="text" @click="$refs['err-logs-dialog'].show()"
            >[点击查看详情]</el-button
          ></el-tag
        >
        <el-tab-pane label="门店统计" name="first">
          <el-row class="search">
            <el-form size="mini" :inline="true">
              <el-form-item>
                <el-date-picker
                  size="mini"
                  v-model="search._date"
                  @change="setDate()"
                  type="daterange"
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchmallStatsList"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
          <el-row >
            <el-table border stripe :data="mallStatsList" width="100%">
              <el-table-column prop="ec" label="销售平台"></el-table-column>
              <el-table-column
                prop="amount_cny"
                label="总销售额CNY"
              ></el-table-column>
              <el-table-column
                prop="amount_usd"
                label="总销售额USD"
              ></el-table-column>
              <el-table-column prop="qty" label="总销售数"></el-table-column>
              <el-table-column
                prop="amount_ave"
                label="平均客单价USD"
              ></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="单品统计" name="second">
          <el-row class="search">
            <el-form size="mini" :inline="true">
              <el-form-item>
                <el-date-picker
                  size="mini"
                  v-model="search._date"
                  @change="setDate()"
                  type="daterange"
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-select v-model="search.ecId" clearable>
                  <el-option
                    v-for="item in sysConfig.Common.ec"
                    :key="item.key"
                    :label="item.val"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchskuStatsList()"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
          <el-row >
            <el-table
              border
              stripe
              :data="skuStatsList"
              width="100%"
              :default-sort="{ prop: 'amount', order: 'descending' }"
            >
              <el-table-column prop="mfn" label="MFN"></el-table-column>
              <el-table-column prop="sku" label="SKU"></el-table-column>
              <el-table-column
                prop="amount"
                label="总销售额"
                sortable
              ></el-table-column>
              <el-table-column
                prop="qty"
                label="总销售数"
                sortable
              ></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="回款率" name="third">
          <el-row class="search">
            <el-form size="mini" :inline="true">
              <el-form-item>
                <el-date-picker
                  v-model="search.date"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="选择年月"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchrateOfReturnList()"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </el-row>
          <el-row >
            <el-table border stripe :data="rateOfReturnList" width="100%">
              <el-table-column prop="no" label="销售平台"></el-table-column>
              <el-table-column prop="_type" label="类型"></el-table-column>
              <el-table-column
                prop="tolAmount"
                label="总销售额"
              ></el-table-column>
              <el-table-column prop="tolPrice" label="总回款"></el-table-column>
              <el-table-column prop="rate" label="回款率"></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <err-logs-dialog
      ref="err-logs-dialog"
      @set-log-success="getLogs()"
      :item="errorLogs.item"
    ></err-logs-dialog>
  </div>
</template>
<script>
import {
  getmallStats,
  getskuStats,
  getrateOfReturn,
  geterrorLogs
} from '@/api/qcenterQuery'
import errLogsDialog from './err-logs-dialog'
import { mapGetters } from 'vuex'
export default {
  components: {
    errLogsDialog
  },
  data () {
    return {
      search: {
        _date: [],
        startDate: '',
        endDate: '',
        ecId: ''
      },
      errorLogs: {},
      tabvalue: 'first',
      mallStatsList: [],
      skuStatsList: [],
      rateOfReturnList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getLogs()
  },
  methods: {
    setDate () {
      if (this.search._date == null) {
        this.search.startDate = ''
        this.search.endDate = ''
      } else {
        this.search.startDate = this.search._date[0]
        this.search.endDate = this.search._date[1]
      }
    },
    getLogs () {
      geterrorLogs()
        .then(data => {
          this.errorLogs = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getmallStatsList () {
      getmallStats(this.search)
        .then(data => {
          this.mallStatsList = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchmallStatsList () {
      this.mallStatsList = []
      this.getmallStatsList()
    },

    getskuStatsList () {
      getskuStats(this.search)
        .then(data => {
          this.skuStatsList = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchskuStatsList () {
      this.skuStatsList = []
      this.getskuStatsList()
    },

    getrateOfReturnList () {
      getrateOfReturn(this.search)
        .then(data => {
          this.rateOfReturnList = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchrateOfReturnList () {
      this.rateOfReturnList = []
      this.getrateOfReturnList()
    }
  }
}
</script>
