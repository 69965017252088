<!-- 添加查看采购单详细信息界面 -->
<template>
  <div class="stockedit">
    <el-row style="margin-bottom:30px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">采购管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/stock/stockOrder' }"
          >采购单管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>采购单编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-tabs :value="firstpage">
        <el-tab-pane label="详情" name="first">
          <stock-main
            :stockcode="stockcode"
            :editdisable="editdisable"
            @saveStockSuccess="changeStockcode"
            @confirmSuccess="changeEditdisable"
            @currencychange="setcurrencyType"
          ></stock-main>
        </el-tab-pane>
        <el-tab-pane
          label="明细"
          name="second"
          v-if="typeof stockcode === 'number'"
        >
          <stock-detail
            :stockcode="stockcode"
            :stockdetailList="stockdetailList"
            :editdisable="editdisable"
            @refreshdetail="refreshdetaildata()"
          ></stock-detail>
        </el-tab-pane>
        <el-tab-pane
          label="其它费用"
          name="third"
          v-if="typeof stockcode === 'number'"
          :disabled="!currencytype"
        >
          <stock-detail-fee
            :stockcode="stockcode"
            :feeList="feeList"
            @refreshStockFee="refreshdetailFee()"
          ></stock-detail-fee>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>
<script>
import stockMain from './stock-main'
import stockDetail from './stock-detail'
import stockDetailFee from './stock-detail-fee'
import * as stockOrder from '@/api/stockOrder'
export default {
  components: {
    stockMain,
    stockDetail,
    stockDetailFee
  },
  data () {
    return {
      firstpage: 'first',
      stockcode: this.$route.params.stockid,
      editdisable: this.$route.params.editdisable,
      stockdetailList: [],
      feeList: [],
      currencytype: 0
    }
  },
  created () {
    console.log(typeof this.stockcode)
    this.getStockDetailItem()
    this.getStockDetailFee()
  },
  methods: {
    getStockDetailFee () {
      const self = this
      stockOrder
        .getStockDetailFee({ order_id: self.stockcode })
        .then(data => {
          self.feeList = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getStockDetailItem () {
      const self = this
      stockOrder
        .getStockDetailItem({ order_id: self.stockcode })
        .then(data => {
          self.stockdetailList = data
          console.log(self.stockdetailList)
        })
        .catch(err => {
          console.log(err)
        })
    },
    refreshdetaildata () {
      this.getStockDetailItem()
    },
    refreshdetailFee () {
      this.getStockDetailFee()
    },
    //  采购单详情确认后修改编辑属性为不可编辑 反确认后可编辑
    changeEditdisable (editflag) {
      this.editdisable = editflag
    },
    //  采购单详情保存后获取采购单号
    changeStockcode (stockid) {
      this.stockcode = stockid
      // console.log(this.stockcode)
    },
    setcurrencyType (currencykey) {
      this.currencytype = currencykey
    }
  }
}
</script>
