import { httpClient } from '@/core'

/**
 * 获取采购单列表
 */
export function getPurchaseOrders (params) {
  return httpClient.get('/finance/wms/purchaseOrder', params)
}

export function getPurchaseDetail (params) {
  return httpClient.get('/finance/wms/purchaseItem', params)
}
