<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :before-close="closeDone"
    width="500px"
  >
    <el-form label-width="80px">
      <el-form-item label-width="20px">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="file"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
          :data="ruledata"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="20px" label>
        <p>
          导入模板格式文件<el-link
            type="primary"
            :href="downloadTemplate"
            target="_blank"
            >wms-order-disposal-adjustment.xls</el-link
          >
        </p>
      </el-form-item>
      <el-form-item label="清除模式">
        <el-switch
          v-model="dataimport.deletemode"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关"
          class="taxswitch"
        >
        </el-switch>
        <p>
          清除模式将先清空所有明细再导入数据，关闭该模式则在原有数据基础上追加导入数据（重复数据将被覆盖）
        </p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
export default {
  data () {
    return {
      visible: false,
      title: '',
      type: '',
      dataimport: {
        id: '',
        deletemode: false
      }
    }
  },
  computed: {
    importInboundOrder () {
      if (this.type === '-5') {
        return (
          baseUrl +
          'wms/disposal/importItem?token=' +
          localStorage.getItem('token')
        )
      } else if (this.type === '5') {
        return (
          baseUrl +
          'wms/adjustment/importItem?token=' +
          localStorage.getItem('token')
        )
      }
      return ''
    },
    // 导出excel模板
    downloadTemplate () {
      return (
        baseUrl +
        'wms/disposal/disposalAdjustmentTpl?token=' +
        localStorage.getItem('token')
      )
    },
    ruledata () {
      return {
        id: this.dataimport.id,
        empty: Number(this.dataimport.deletemode)
      }
    }
  },
  methods: {
    show (type, id) {
      if (type === '5') {
        this.title = '导入调整明细'
      } else if (type === '-5') {
        this.title = '导入报损明细'
      }
      this.type = type
      this.dataimport.id = id
      this.visible = true
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import-breakageadjuse')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    },
    closeDone (done) {
      done()
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
