<template>
  <div>
    <el-row class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>采购记录</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search" style="margin-top: 40px;">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-input v-model="search.mfn" placeholder="MFN"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchRecord()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table stripe border width="100%" :data="recordList">
        <el-table-column label="MFN" prop="_mfn"> </el-table-column>
        <el-table-column label="采购单 / 确认时间">
          <template slot-scope="scope">
            {{ scope.row.id }}/{{ scope.row.updated_at }}
          </template>
        </el-table-column>
        <el-table-column label="供应商" prop="_vendor"> </el-table-column>
        <el-table-column label="采购员" prop="_user"> </el-table-column>
        <el-table-column label="开票类型" prop="_invoice_type">
        </el-table-column>
        <el-table-column label="销售端" prop="_ec"> </el-table-column>
        <el-table-column label="采购价" prop="price">
          <template slot-scope="scope">
            {{ scope.row._currency }}&nbsp;{{ scope.row.price }}
          </template>
        </el-table-column>
        <el-table-column label="采购数" prop="qty"> </el-table-column>
        <el-table-column label="采购金额" prop="amount"> </el-table-column>
        <el-table-column label="交付数" prop="received_qty"> </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[100, 200, 300]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { getStockRecord } from '@/api/stockOrder'
export default {
  data () {
    return {
      search: {},
      pageDat: {
        per_page: 100,
        current_page: 1
      },
      recordList: []
    }
  },
  methods: {
    getRecord () {
      const jsondata = {
        mfn: this.search.mfn,
        page: this.pageDat.current_page,
        per_page: this.pageDat.per_page
      }
      getStockRecord(jsondata)
        .then(data => {
          this.recordList = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchRecord () {
      this.pageDat.current_page = 1
      this.pageDat.per_page = 100
      this.getRecord()
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getRecord()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getRecord()
    }
  }
}
</script>
