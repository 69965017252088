<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <h2>请务必根据Shipment的实际情况选择，否则下载操作将会失败。</h2>
    <el-form size="small">
      <el-form-item v-if="labelclass === 'pallet'" label="托盘数量">
        <el-input-number v-model="NumberOfPallets" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-select v-model="PageType">
          <el-option
            v-for="item in sysConfig.FBAInboundShipment.PAGE_TYPE_OF_LABEL"
            :key="item.key"
            :value="item.key"
            :label="item.key + item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="down()">下载</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import * as fbashipAPI from '@/api/fbaship'
export default {
  data () {
    return {
      title: '',
      visible: false,
      PageType: '',
      NumberOfPallets: '',
      labelclass: '',
      ShipmentId: ''
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (labelclass, ShipmentId) {
      this.visible = true
      this.labelclass = labelclass
      this.ShipmentId = ShipmentId
      if (labelclass === '') {
        this.title = '下载外箱标'
      } else if (labelclass === 'pallet') {
        this.title = '下载托盘标'
      }
    },
    closedone (done) {
      this.labelclass = ''
      this.ShipmentId = ''
      this.PageType = ''
      this.NumberOfPallets = ''
      done()
    },
    down () {
      fbashipAPI
        .downloadLabel({
          ShipmentId: this.ShipmentId,
          PageType: this.PageType,
          NumberOfPallets: this.NumberOfPallets,
          file: this.labelclass
        })
        .then(data => {
          const URL = this.dataURLtoBlob(data)
          const reader = new FileReader()
          reader.readAsDataURL(URL)
          reader.onload = function (e) {
            // 兼容IE
            if (window.navigator.msSaveOrOpenBlob) {
              const bstr = atob(e.target.result.split(',')[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
              }
              var blob = new Blob([u8arr])
              window.navigator.msSaveOrOpenBlob(blob, '标签.zip')
            } else {
              // 转换完成，创建一个a标签用于下载
              const a = document.createElement('a')
              a.download = '标签.zip'
              a.href = e.target.result
              a.setAttribute('id', 'export')
              a.click()
              document.getElementById('export').remove()
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    dataURLtoBlob (dataurl) {
      const bstr = atob(dataurl)
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: 'pdf' })
    }
  }
}
</script>
