<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>供应商管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <h3>{{ vendor && vendor.id ? '编辑' : '新增' }}供应商</h3>

    <el-alert v-if="error" :title="error" type="error" :closable="false">
    </el-alert>

    <el-form
      ref="form"
      :model="vendor"
      label-width="166px"
      label-position="left"
    >
      <el-form-item label="供应商名称">
        <el-input v-model="vendor.name"></el-input>
      </el-form-item>

      <el-form-item label="国家">
        <el-input v-model="vendor.country"></el-input>
      </el-form-item>

      <el-form-item label="省/州">
        <el-input v-model="vendor.province"></el-input>
      </el-form-item>

      <el-form-item label="城市">
        <el-input v-model="vendor.city"></el-input>
      </el-form-item>

      <el-form-item label="具体地址">
        <el-input v-model="vendor.addr"></el-input>
      </el-form-item>

      <el-form-item label="联系电话">
        <el-input v-model="vendor.tel"></el-input>
      </el-form-item>

      <el-form-item label="联系人">
        <el-input v-model="vendor.manager"></el-input>
      </el-form-item>

      <el-form-item label="联系人邮箱">
        <el-input v-model="vendor.manager_email"></el-input>
      </el-form-item>

      <el-form-item label="联系人电话">
        <el-input v-model="vendor.manager_phone"></el-input>
      </el-form-item>

      <el-form-item label="">
        <el-button
          type="primary"
          size="medium"
          @click="
            save()
            form.loading = true
          "
          :loading="form.loading"
        >
          {{ form.loading ? '正在保存...' : '保存' }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiGetVendor, apiSaveVendor } from '@/api/purchase'

export default {
  components: {},
  created () {
    this.form.loading = false
    const id = this.$route.query.id
    this.get(id)
  },
  data () {
    return {
      form: {
        loading: false
      },
      vendor: {
        name: '',
        tel: '',
        country: '',
        province: '',
        city: '',
        addr: '',
        manager: '',
        manager_email: '',
        manager_phone: ''
      },
      error: null
    }
  },
  methods: {
    get (id) {
      if (id) {
        apiGetVendor(id).then(res => {
          if (res) {
            this.vendor = res
          } else {
            this.error = '供应商不存在。'
            this.form.loading = true
          }
        })
      }
    },
    save () {
      apiSaveVendor(this.vendor).then(res => {
        this.form.loading = false
        this.vendor = res
      })
    }
  }
}
</script>

<style></style>
