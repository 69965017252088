<template>
  <div class="product-manage">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">产品管理</el-breadcrumb-item>
        <el-breadcrumb-item>产品信息</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search-bar">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="search.mfn"
              placeholder="MFN"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="search.is_new" placeholder="请选择" clearable>
              <el-option value="0" label="旧品"></el-option>
              <el-option value="1" label="新品"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getProductlist(search.mfn,search.is_new)"
              >检索</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button @click="reset()">重置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <el-dropdown>
          <el-button type="primary" size="small">
            批量操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$refs['product-import'].show()"
              >导入商品</el-dropdown-item
            >
            <el-dropdown-item @click.native="$refs['product-export'].show()"
              >导出产品</el-dropdown-item
            >
            <el-dropdown-item>批量传图</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2">&nbsp;</el-col>
    </el-row>
    <el-row>
      <!-- <el-button type="primary" @click="$refs['product-dialog'].show()">新增产品</el-button> -->
      <el-table stripe ref="productTable" :data="productlist" border>
        <el-table-column type="selection" width="36"></el-table-column>
        <el-table-column mini-width="20%" label="品名MFN">
          <template slot-scope="scope">
            <product-info-in-table-cell
              :cover="scope.row.cover"
              :title="scope.row.name"
              :mfn="scope.row.mfn"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column prop="cat" label="品类" width="100"></el-table-column>
        <el-table-column prop="clr" label="主色" width="100"></el-table-column>
        <el-table-column
          prop="mat"
          label="主材质"
          width="100"
        ></el-table-column>
        <el-table-column label="开发人员" mini-width="10%"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="$refs['product-dialog'].show(scope.row.mfn)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
    <product-edit ref="product-dialog" @success="getProductlist"></product-edit>

    <import-product ref="product-import"></import-product>

    <export-product ref="product-export"></export-product>
  </div>
</template>

<script type="text/javascript">
import * as productapi from '@/api/product'
import ProductEdit from './edit-product'
import importProduct from './import-product-dialog'
import ExportProduct from './export-product'
import productInfoInTableCell from '@/components/common/product-info-table-cell'

export default {
  components: {
    ProductEdit,
    importProduct,
    ExportProduct,
    productInfoInTableCell
  },
  data () {
    return {
      search: {
        mfn: '',
        is_new: ''
      },
      pageDat: { per_page: 10 },
      productlist: []
    }
  },
  methods: {
    getProductlist (mfndata = '', is_new_data = '') {
      const currentPage = this.pageDat.current_page || 1
      const prodJson = {
        mfn: mfndata,
        is_new: is_new_data,
        page: currentPage,
        num_per_page: this.pageDat.per_page
      }
      productapi.getProductlist(prodJson).then(res => {
        this.productlist = res.data
        this.pageDat.total = res.total
      })
    },
    reset () {
      this.search.mfn = ''
      this.search.is_new = ''
    },
    changePageHandler () {
      this.getProductlist()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getProductlist()
    }
  },
  mounted () {
    this.getProductlist()
  }
}
</script>
