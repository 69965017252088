<template>
  <div>
    <el-row class="content-row">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-input
            class="search-bar"
            style="width:120px;"
            v-model="search.id"
            placeholder="报销单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysConfig.vendorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:80px;"
            v-model="search.currency"
            placeholder="币种"
          >
            <el-option
              v-for="item in sysConfig.Common.currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="付款"
            end-placeholder="期限"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="reimbursementlist" border>
        <el-table-column prop="id" label="报销单号"></el-table-column>
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column label="收款账号">
          <template v-slot="scope">
            <p>{{ scope.row.bank_name }}</p>
            <p>{{ scope.row.bank_account }} ({{ scope.row.bank }})</p>
          </template>
        </el-table-column>
        <el-table-column label="总金额">
          <template v-slot="scope">
            <span>{{ scope.row.amount }} {{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deadline" label="付款期限"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="pushreimbursementDetail(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="search.page"
        :page-size="parseInt(search.num_per_page)"
        :page-sizes="[15, 30, 50, 100]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="search.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getReimbursementlist } from '@/api/financePurchaseNew'
export default {
  props: ['step'],
  data () {
    return {
      search: {
        id: '',
        start: '',
        end: '',
        _date: null,
        vendor_id: '',
        currency: '',
        page: 1,
        num_per_page: 15,
        total: 0
      },
      reimbursementlist: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    step: function () {
      this.getReimbursement()
    }
  },
  created () {
    this.getReimbursement()
  },
  methods: {
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getReimbursement () {
      const listJsondata = {
        step: this.step,
        id: this.search.id,
        vendor_id: this.search.vendor_id,
        currency: this.search.currency,
        start_date: this.search.start,
        end_date: this.search.end,
        page: this.search.page,
        num_per_page: this.search.num_per_page
      }
      getReimbursementlist(listJsondata)
        .then(data => {
          this.reimbursementlist = data.data
          this.search.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.search.page = currentPage
      this.getReimbursement()
    },
    changeSizeHandler (size) {
      this.search.num_per_page = size
      this.getReimbursement()
    },
    pushreimbursementDetail (reimbursementId) {
      this.$router.push({
        // path: '/finance/purchase/reimbursement/detail',
        name: 'financeReimbursementOrderNewDetail',
        params: {
          id: reimbursementId
        }
      })
    }
  }
}
</script>
