import request from '../config/request'
//  仓库单据列表
export const getwmsOrderList = data => {
  return request('wms/order/ls', 'get', data, true).then(data => {
    return data
  })
}
//  仓库单据详情
export const getwmsOrderDetail = data => {
  return request('wms/order/detail', 'get', data, true).then(data => {
    return data
  })
}
// 确认调拨出库单
export const confirmWmsTransferOut = data => {
  return request('wms/order/confirmTransferOut', 'post', data).then(data => {
    return data
  })
}
// 确认调拨入库单
export const confirmWmsTransferIn = data => {
  return request('wms/order/confirmTransferIn', 'post', data).then(data => {
    return data
  })
}
//  实时库存
export const getInventoryList = data => {
  return request('wms/inventory', 'get', data).then(data => {
    return data
  })
}
// 添加或编辑报损单
export const saveBreakage = data => {
  return request('wms/disposal/save', 'post', data, true).then(data => {
    return data
  })
}
// 提交审核确认报损单
export const confirmBreakage = data => {
  return request('wms/disposal/setStep', 'post', data, true).then(data => {
    return data
  })
}
//  删除报损单
export const deleteBreakage = data => {
  return request('wms/disposal/del', 'post', data, true).then(data => {
    return data
  })
}
// 添加或编辑调整单
export const saveAdjust = data => {
  return request('wms/adjustment/save', 'post', data, true).then(data => {
    return data
  })
}
// 提交审核确认调整单
export const confirmAdjust = data => {
  return request('wms/adjustment/setStep', 'post', data, true).then(data => {
    return data
  })
}
//  删除调整单
export const deleteAdjust = data => {
  return request('wms/adjustment/del', 'post', data, true).then(data => {
    return data
  })
}
//  删除报损单明细
export const deleteBreakageDetail = data => {
  return request('wms/disposal/item/del', 'post', data, true).then(data => {
    return data
  })
}
//  删除调整单明细
export const deleteAdjustDetail = data => {
  return request('wms/adjustment/item/del', 'post', data, true).then(data => {
    return data
  })
}
// 编辑调整单明细
export const editAdjustDetail = data => {
  return request('wms/adjustment/item/save', 'post', data, true).then(data => {
    return data
  })
}
// 编辑报损单明细
export const editBreakageDetail = data => {
  return request('wms/disposal/item/save', 'post', data, true).then(data => {
    return data
  })
}
// 确认采购入库单
export const confirmPurchaseIn = data => {
  return request('wms/order/confirmPurchaseIn', 'post', data, true).then(
    data => {
      return data
    }
  )
}
//  创建销售出库单
export const createSaleOrderOut = data => {
  return request('wms/order/createSalesOut', 'post', data, true).then(data => {
    return data
  })
}
// 确认出库
export const confirmSaleOrderOut = data => {
  return request('wms/order/confirmSalesOut', 'post', data, true).then(data => {
    return data
  })
}
// 删除销售出库单
export const deleteSaleItem = data => {
  return request('wms/order/delItems', 'post', data, true).then(data => {
    return data
  })
}
// 创建调拨出库单
export const createAllotOut = data => {
  return request('wms/transferOut/create', 'post', data).then(data => {
    return data
  })
}
//  海外仓调拨计划列表
export const getOverseaAllotList = data => {
  return request('wms/transferPlan', 'get', data).then(data => {
    return data
  })
}
//  添加至调拨计划
export const appendToPlan = data => {
  return request('wms/transferPlan/appendToPlan', 'post', data).then(data => {
    return data
  })
}
//  创建调拨计划
export const createTransferPlan = data => {
  return request('wms/transferPlan/create', 'post', data).then(data => {
    return data
  })
}
//  编辑调拨计划
export const editTransferPlan = data => {
  return request('wms/transferPlan/savePlan', 'post', data).then(data => {
    return data
  })
}
//  确认发货海外仓调拨计划
export const confirmPlan = data => {
  return request('wms/transferPlan/confirmPlan', 'post', data).then(data => {
    return data
  })
}
//  删除调拨计划
export const delPlan = data => {
  return request('wms/transferPlan/delPlan', 'post', data).then(data => {
    return data
  })
}
//  调拨单详情
export const getTransferPlanDetail = data => {
  return request('wms/transferPlan/detail', 'get', data).then(data => {
    return data
  })
}
//  编辑库存明细 调拨单详情
export const editTransferPlanItem = data => {
  return request('wms/transferPlan/editItem', 'post', data).then(data => {
    return data
  })
}
//  创建出库单 调拨单详情
export const outboundTransferPlan = data => {
  return request('wms/transferPlan/outbound', 'post', data).then(data => {
    return data
  })
}
//  创建入库单 调拨单详情
export const inboundTransferPlan = data => {
  return request('wms/transferPlan/inbound', 'post', data).then(data => {
    return data
  })
}
//  编辑明细 调拨单详情
export const editItemTransfer = data => {
  return request('wms/transferPlan/editItem', 'post', data).then(data => {
    return data
  })
}
