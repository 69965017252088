<template>
  <el-dialog title="编辑交货进度" :visible.sync="visible" width="600px">
    <el-form
      label-position="left"
      label-width="100px"
      ref="thisForm"
      :rules="rules"
      :model="deailInfo"
    >
      <el-form-item label="货品信息"
        >{{ propsInfo.product_name }}/ {{ propsInfo.sku }}</el-form-item
      >
      <el-form-item prop="order_id" label="采购信息"
        >{{ propsInfo.order_id }}/总{{ propsInfo.qty }}</el-form-item
      >
      <el-form-item label="预计到货日" prop="expected_date">
        <el-date-picker
          v-model="deailInfo.expected_date"
          type="date"
          placeholder="选择日期"
          :editable="false"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="expected_qty" label="预计到货数">
        <el-input
          type="number"
          v-model="deailInfo.expected_qty"
          style="width:220px "
        ></el-input>
      </el-form-item>
      <el-form-item prop="shipable_qty" label="可发数量">
        <el-input
          type="number"
          v-model="deailInfo.shipable_qty"
          style="width:220px "
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="说明"
          v-model="deailInfo.remark"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="更新方式">
        <el-select v-model="deailInfo.update" placeholder="更新方式">
          <el-option
            v-for="item in tabs"
            :key="item.key"
            :value="item.key"
            :label="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click.prevent="saveInfo">保存</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import * as trackingApi from '@/api/tracking'
export default {
  props: { propsInfo: Object },
  data () {
    return {
      visible: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      deailInfo: {},
      rules: {
        expected_date: [{ required: true, trigger: 'change' }]
        // expected_qty: [{ required: true, trigger: 'blur' }],
        // shipable_qty: [{ required: true, trigger: 'blur' }]
      },
      tabs: [
        {
          key: 0,
          val: '追加'
        },
        {
          key: 1,
          val: '覆盖'
        }
      ]
    }
  },

  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },

    saveInfo () {
      if (!this.propsInfo.id) {
        alert('数据错误')
        return false
      }

      this.deailInfo.id = this.propsInfo.id
      this.deailInfo.order_id = this.propsInfo.order_id
      this.$refs.thisForm.validate(valid => {
        if (!valid) {
          return false
        } else {
          if (!this.deailInfo.expected_qty && !this.deailInfo.shipable_qty) {
            this.$alert('预计到货数量和可发数量必须填写一个！')
            return false
          }
          if (
            this.deailInfo.expected_qty &&
            this.deailInfo.expected_qty % this.propsInfo.qty_ctn !== 0
          ) {
            this.$alert('预计到货数量和可发数量必须是装箱率的倍数！')
            return false
          }
          if (
            this.deailInfo.shipable_qty &&
            this.deailInfo.shipable_qty % this.propsInfo.qty_ctn !== 0
          ) {
            this.$alert('预计到货数量和可发数量必须是装箱率的倍数！')
            return false
          }
          trackingApi.saveTracking(this.deailInfo).then(res => {
            this.hide()
            this.$emit('change')
          })
        }
      })
    }
  }
}
</script>

<style></style>
