/**
 * card form validator
 */
function expiryDateValidator (rule, value, cb) {
  const date = new Date(Date.now())
  const expiryDate = new Date(value)

  if (date.getFullYear() > expiryDate.getFullYear()) {
    cb(new Error('Expiry date error'))
  }
  if (
    date.getFullYear() === expiryDate.getFullYear() &&
    date.getMonth() >= expiryDate.getMonth()
  ) {
    cb(new Error('Expiry date error'))
  }
  cb()
}

const rules = {
  no: [
    { required: true, message: 'Please enter card number', trigger: 'blur' },
    {
      pattern: /^\d{16}$/,
      message: 'Card number must be 16 digits.',
      trigger: 'blur'
    }
  ],
  cvc: [
    { required: true, message: 'Please enter cvc', trigger: 'blur' },
    { len: 3, message: 'Cvc must be 3 digits.', trigger: 'blur' }
  ],
  expiry_date: [
    {
      required: true,
      type: 'string',
      message: 'Please select expiry date',
      trigger: 'change'
    },
    { validator: expiryDateValidator, trigger: 'change' }
  ],
  billing_address: [],
  address_zip: [
    {
      pattern: /^\d{5}$/,
      message: 'Zip number must be 5 digits.',
      trigger: 'blur'
    }
  ]
}

export default rules
