<template>
  <el-dialog
    class="vendor-dialog"
    title="选择供应商"
    :show-close="true"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="800px"
    :before-close="closeDialog"
  >
    <el-row class="search-vendor">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="vendorname"
              placeholder="请输入供应商名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search()">检索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe ref="vendorTable" :data="vendorlist" border>
        <el-table-column prop="name" label="供应商" width="180">
        </el-table-column>
        <el-table-column label="地区" width="180"> </el-table-column>
        <el-table-column label="类型" width="180"> </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="sendVendorToParent(scope.row.id)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>
<script>
import * as stockOrderApi from '../../../api/stockOrder'
export default {
  data () {
    return {
      visible: false,
      vendorname: '',
      vendorlist: [],
      vendordetail: {},
      pageDat: {
        per_page: 10,
        current_page: 1
      }
    }
  },
  methods: {
    show () {
      const self = this
      self.visible = true
      this.getVendorlist()
    },
    getVendorlist () {
      const jsonData = {
        name: this.vendorname,
        page: this.pageDat.current_Page,
        num_per_page: this.pageDat.per_page
      }
      stockOrderApi
        .getVendorlist(jsonData)
        .then(data => {
          this.vendorlist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    search () {
      this.pageDat.current_Page = 1
      this.getVendorlist()
    },
    sendVendorToParent (vendorid) {
      stockOrderApi.getVendordetail({ id: vendorid }).then(data => {
        this.vendordetail = data
        this.$emit('VendorConfirm', this.vendordetail)
        this.visible = false
      })
    },
    changePageHandler (currentPage) {
      this.pageDat.current_Page = currentPage
      this.getVendorlist()
    },
    changeSizeHandler (pagesize) {
      this.pageDat.per_page = pagesize
      this.getVendorlist()
    },
    closeDialog (done) {
      done()
    }
  }
}
</script>
