<template>
  <el-dialog :visible.sync="visible" title="编辑" width="500px" show-close>
    <el-form label-width="100px">
      <el-form-item label="采购单id">
        {{ order_id }}
      </el-form-item>
      <el-form-item label="MFN">
        {{ mfn }}
      </el-form-item>
      <el-form-item label="商贸采购单号">
        <el-input type="text" v-model="purchase_order_no" style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item label="下单时间">
        <el-date-picker
          v-model="purchase_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="下单时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="进仓时间">
        <el-date-picker
          v-model="inbound_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="下单时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <label slot="label">备&emsp;&emsp;注</label>
        <el-input type="textarea" :rows="4" placeholder="备注" v-model="remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="saveUpdate">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { updateTracking } from '@/api/tracking'
export default {
  data () {
    return {
      visible: false,
      id: '',
      order_id: '',
      mfn: '',
      purchase_order_no: '',
      purchase_date: '',
      inbound_date: '',
      remark: ''
    }
  },
  methods: {
    show (row) {
      this.id = row.id
      this.order_id = row.order_id
      this.mfn = row.mfn
      this.purchase_order_no = row.purchase_order_no
      this.purchase_date = row.purchase_date
      this.inbound_date = row.inbound_date
      this.remark = row.remark
      this.visible = true
    },
    saveUpdate () {
      updateTracking({
        id: this.id,
        order_id: this.order_id,
        purchase_order_no: this.purchase_order_no,
        purchase_date: this.purchase_date,
        inbound_date: this.inbound_date,
        remark: this.remark
      })
        .then(data => {
          this.$emit('update-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
