<template>
  <div class="prepayment-query">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>预付款查询</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-tabs v-model="form.on" @tab-click="search()">
      <el-tab-pane
        v-for="item in onList"
        :name="item.key"
        :key="item.key"
        :label="item.val"
      ></el-tab-pane>
    </el-tabs>

    <el-form :inline="true" size="small">
      <el-form-item>
        <el-select
          v-model="form.vendor_id"
          placeholder="供应商"
          clearable
          filterable
        >
          <el-option
            v-for="unit in sysConfig.vendorList"
            :label="unit.name"
            :value="unit.id"
            :key="unit.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.id" placeholder="采购单id"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchinfo()">查 询</el-button>
        <el-button type="primary" @click="downloadLink()">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="order" border style="width: 100%">
      <el-table-column prop="id" label="采购单号"> </el-table-column>
      <el-table-column prop="ship_date" label="交期"> </el-table-column>

      <el-table-column prop="vendor_name" label="供应商"> </el-table-column>

      <el-table-column label="总挂账">
        <template v-slot="scope">
          {{ scope.row.prepayment_amount }} {{ scope.row._current }}
        </template>
      </el-table-column>
      <el-table-column label="已冲账">
        <template v-slot="scope">
          {{ scope.row.prepayment_used_amount }} {{ scope.row._current }}
        </template>
      </el-table-column>

      <el-table-column label="未冲账" prop="unpaid_amount"> </el-table-column>

      <el-table-column>
        <template v-slot="scope">
          <el-link @click="detail(scope.row.id)" type="primary">明细</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-row class="pagination">
      <el-pagination
        :current-page.sync="form.page"
        :page-size="parseInt(form.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="form.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as prepaymentQueryApi from '@/api/prepaymentQuery'
import { baseUrl } from '@/config/env'

export default {
  created () {
    this.form.page = 1
    this.search()
  },

  data () {
    return {
      form: {
        vendor_id: null,
        total: 0,
        per_page: 20,
        page: 1,
        on: 'true',
        id: null
      },

      onList: [
        {
          key: 'true',
          val: '挂账中'
        },
        {
          key: 'false',
          val: '已冲账'
        }
      ],
      order: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    search () {
      prepaymentQueryApi.search(this.form).then(res => {
        this.order = res.data
        this.form.total = res.total
      })
    },
    detail (id) {
      this.$router.push({ name: 'prepaymentQueryDetail', query: { id } })
    },
    changePageHandler (currentPage) {
      this.form.page = currentPage
      this.search()
    },
    changeSizeHandler (size) {
      this.form.per_page = size
      this.search()
    },
    searchinfo () {
      this.form.page = 1
      this.search()
    },
    downloadLink () {
      let url =
        baseUrl +
        'finance/purchase/order/exportSearch?token=' +
        localStorage.getItem('token') +
        '&on=' +
        this.form.on
      if (this.form.vendor_id) {
        url = url + '&vendor_id=' + this.form.vendor_id
        if (this.form.id) {
          url = url + '&id=' + this.form.id
        }
      } else if (this.form.id) {
        url = url + '&id=' + this.form.id
      }
      window.open(url)
    }
  }
}
</script>
