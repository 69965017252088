<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>预付款查询</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <h3>挂账详情</h3>

    <div>
      <el-row :gutter="16" style="margin-bottom:20px">
        <el-col :span="2" class="info">采购单</el-col>
        <el-col :span="4" class="info">{{ order.id }}</el-col>

        <el-col :span="2" class="info">供应商</el-col>
        <el-col :span="4" class="info">{{ order.vendor_name }}</el-col>

        <el-col :span="2" class="info">交期</el-col>
        <el-col :span="4" class="info">{{ order.ship_date }}</el-col>

        <el-col :span="2" class="info">状态</el-col>
        <el-col :span="4" class="info">{{ order._step }}</el-col>
      </el-row>

      <el-row :gutter="16" style="margin-bottom:40px">
        <el-col :span="2" class="info">总挂账</el-col>
        <el-col :span="4" class="info"
          >{{ order.prepayment_amount }} {{ order._currency }}</el-col
        >

        <el-col :span="2" class="info">已冲账</el-col>
        <el-col :span="4" class="info"
          >{{ order.prepayment_used_amount }} {{ order._currency }}</el-col
        >

        <el-col :span="2" class="info">未冲账</el-col>
        <el-col :span="4" class="info"
          >{{ order.unpaid_amount }} {{ order._currency }}</el-col
        >
      </el-row>
    </div>
    <h2>账目统计</h2>
    <el-table
      :data="accounttotal"
      border
      style="width: 60%"

      show-summary
    >
      <el-table-column prop="name"> </el-table-column>

      <el-table-column prop="cashOut" label="请款"> </el-table-column>

      <el-table-column prop="redAccount" label="冲账"></el-table-column>
    </el-table>

    <h2>请款记录</h2>
    <el-table :data="order.prepayment" border style="width: 100%" >
      <el-table-column prop="prepayment_id" label="请款单">
        <template v-slot="scope">{{ scope.row.prepayment_id || '/' }}</template>
      </el-table-column>

      <el-table-column label="请款金额">
        <template v-slot="scope"
          >{{ scope.row.amount }} {{ scope.row._currency }}</template
        >
      </el-table-column>

      <el-table-column prop="_type" label="类型"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { detail } from '@/api/prepaymentQuery'

export default {
  created () {
    detail({ id: this.$route.query.id }).then(res => {
      this.order = res
      this.accounttotal = [
        {
          name: '预付定金',
          cashOut: res.prepayment_amount,
          redAccount: res.prepayment_used_amount
        },
        {
          name: '到货款',
          cashOut: res.prepayment_amount_of_product,
          redAccount: res.prepayment_used_amount_of_product
        },
        {
          name: '预付定金退款',
          cashOut: res.prepayment_refund_amount,
          redAccount: res.prepayment_refund_used_amount
        }
      ]
    })
  },
  data () {
    return {
      form: {
        loading: false
      },
      order: {},
      accounttotal: [],
      error: null
    }
  },
  methods: {}
}
</script>

<style>
.table {
  margin: 1pc 0;
}

.info,
.info * {
  font-size: 13px;
  line-height: 160%;
}
</style>
