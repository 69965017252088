<template>
  <div>
    <div class="item-info" v-for="item in orderDetailItem" :key="item.id">
      <el-divider></el-divider>
      <el-row class="content-row">
        <el-col :span="6">
          采购单
          <span style="margin-left: 40px">{{ item.order_id }}</span>
        </el-col>
        <el-col :span="6">
          预付比例
          <span style="margin-left: 40px">{{ item._prepay_ratio }}</span>
        </el-col>
        <el-col :span="6">
          采购入库
          <span style="margin-left: 40px">{{ item.payment_id }}</span>
        </el-col>
        <el-col :span="6">
          备注
          <span style="margin-left: 40px">{{ item._type }}</span>
        </el-col>
      </el-row>

      <el-row class="content-row">
        <el-col :span="6">
          请款
          <span style="margin-left: 40px">{{ item.prepayment_amount }}</span>
        </el-col>
        <el-col :span="6">
          已冲账/未冲账
          <span style="margin-left: 40px">
            {{ item.prepayment_used_amount }}
            /{{
              item.prepayment_unused_amount
                ? item.prepayment_unused_amount
                : '无'
            }}
          </span>
        </el-col>
        <el-col :span="6">
          挂账: &nbsp;
        </el-col>
        <el-col :span="6">
          本次冲账: &nbsp;
        </el-col>
        <!-- <el-col :span="6">
            本次冲账 -->
        <!-- <span style="margin-left: 40px">{{item.prepay_using_amount}}</span> -->
        <!-- <span v-if="item._prepay_ratio" style="margin-left: 40px">￥{{ item.amount | usingamount(item.prepay_ratio)}} (挂账 ￥{{item.prepayment_amount - item.prepayment_used_amount}})</span>
            <span v-else style="margin-left: 40px">/</span>
          </el-col>
          <el-col :span="6">
            本次应付 -->
        <!-- <span style="margin-left: 40px">{{item.payment_amount ? item.payment_amount: '无'}}</span> -->
        <!-- <span style="margin-left: 40px">{{item.amount ? item.amount: '无'}}</span>
          </el-col> -->
      </el-row>
      <el-row style="margin-bottom: 10px">
        <el-col :span="6">
          报销总额: &nbsp;
        </el-col>
        <el-col :span="6">
          应付金额: &nbsp;
        </el-col>
      </el-row>

      <!-- <el-row class="content-row">
          <el-col :span="6" :offset="18">
            <el-button style="float: right" type="text" @click="addOtherFee(item.id)">+新增其他款项</el-button>
          </el-col>
        </el-row> -->

      <el-table
        :data="item.item"
        stripe
        border
        style="width: 100%"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column label="货品">
          <template v-slot="scope">
            <el-row v-if="scope.row.id">
              <img width="80" height="80" :src="scope.row._product_cover" />
              <div style="margin-left: 10px;">
                <p>{{ scope.row._product_name }}</p>
                <p>{{ scope.row._product_mfn }}</p>
              </div>
            </el-row>
            <el-row v-else> {{ scope.row.remark }}</el-row>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="purchase_order" label="采购单"></el-table-column> -->
        <el-table-column prop="qty" label="采购总数"></el-table-column>
        <el-table-column label="采购单价">
          <template v-slot="scope">
            <span v-if="scope.row.id"
              >{{ scope.row.purchese_price }} {{ scope.row._currency }}</span
            >
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="报销数量"></el-table-column>
        <el-table-column prop="purchese_priceqty" label="报销金额（实际货值）">
          <template slot-scope="scope">
            {{ (scope.row.purchese_price * scope.row.qty) | numFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="本次应付"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: ['orderDetailItem'],
  filters: {
    numFilter (value) {
      const realVal = parseFloat(value).toFixed(2)
      return realVal
    },
    usingamount (amount, prepayRatio) {
      const ratio = parseFloat(prepayRatio)
      const realVal = (amount / (1 - ratio) - amount).toFixed(2)
      return realVal
    }
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      const purchesePriceList = data.map(item => Number(item.purchese_price))
      const qtyList = data.map(item => Number(item.qty))
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        }
        if (column.property === 'purchese_priceqty') {
          sums[index] = 0
          for (let i = 0; i < purchesePriceList.length; i++) {
            sums[index] += purchesePriceList[i] * qtyList[i]
          }
          sums[index] = sums[index].toFixed(2)
        }
      })

      return sums
    }
  }
}
</script>
