<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>补货报告</el-breadcrumb-item>
        <el-breadcrumb-item>策略设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3" :offset="18">
        <el-button type="primary" size="small" @click="pushDetail()"
          >创建策略</el-button
        >
      </el-col>
    </el-row>
    <el-row  style="margin-top: 10px;">
      <el-table border stripe width="100%" :data="strategyList">
        <el-table-column prop="name" label="策略名称">
          <template slot-scope="scope">
            {{ scope.row.name }} ({{ scope.row._status }})
          </template>
        </el-table-column>
        <el-table-column prop="_size" label="oversize"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="pushDetail(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
import { getstrategyList } from '@/api/amazon'
export default {
  data () {
    return {
      strategyList: []
    }
  },
  created () {
    this.getstrategy()
  },
  methods: {
    getstrategy () {
      getstrategyList()
        .then(data => {
          this.strategyList = data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    pushDetail (id) {
      this.$router.push({
        name: 'strategySetDetail',
        params: {
          id: id
        }
      })
    }
  }
}
</script>
