<template>
  <el-dialog title="添加至请款单" :visible.sync="visible" width="1200px">
    <el-form label-position="left">
      <el-table
        :data="paymentList"
        ref="multipleTable"
        stripe
        border
        style="width: 100%"
        @current-change="currentSelectionChange"
      >
        <el-table-column label="请款单号">
          <template v-slot="scope">
            <el-radio v-model="radio" :label="scope.row.id + ''">{{
              scope.row.id
            }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="vendor_name" label="供应商"></el-table-column>
        <el-table-column label="收款信息">
          <template slot-scope="scope">
            <span>{{ scope.row.bank_name }}</span>
            <br />
            <span>{{ scope.row.bank_account }} （{{ scope.row.bank }}）</span>
          </template>
        </el-table-column>
        <el-table-column label="请款总金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount }}{{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deadline" label="付款期限"></el-table-column>
      </el-table>
    </el-form>

    <span slot="footer">
      <el-button
        size="small"
        type="primary"
        @click="addToOrder"
        :disabled="!prepayment_id"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as purchaeFinanceApi from '@/api/purchaseFinance'
import { Message } from 'element-ui'

export default {
  props: ['selectedOne', 'ids'],
  data () {
    return {
      radio: '',
      visible: false,
      paymentList: [],
      id: [],
      prepayment_id: 0
    }
  },

  methods: {
    addToOrder () {
      purchaeFinanceApi.addToOrder(this.id, this.prepayment_id).then(res => {
        this.$emit('paymentRes')
        this.hide()
      })
    },
    currentSelectionChange (row) {
      console.log(row)
      this.radio = row.id + ''
      this.prepayment_id = row.id
    },
    // handleSelectionChange(obj) {
    //   if (obj[0]) {
    //     this.prepayment_id = obj[0].id
    //     // this.selectPayment = obj[0]
    //   }
    // },
    show (id, vendor) {
      if (!id || !id.length) {
        return Message({
          type: 'error',
          message: '未选中预付款。'
        })
      }
      purchaeFinanceApi.prepaymentSimple(vendor).then(res => {
        this.paymentList = res
      })
      this.visible = true
      this.id = id
    },
    hide () {
      this.paymentList = []
      this.visible = false
    }
  }
}
</script>
