<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>亚马逊</el-breadcrumb-item>
        <el-breadcrumb-item>账期明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search">
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholde="开始日期"
            end-placeholde="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="search.ec_id"
            multiple
            placeholder="电商"
            clearable
          >
            <el-option
              v-for="item in sysConfig.Common.ec"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.feeType" placeholder="费用类型" clearable>
            <el-option
              v-for="item in feeType"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleExport()"
            >导出下载</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <xlsx-downloader ref="xlsx-downloader_shipment" type="AMZ_FINANCE_SHIPMENT"/>
    <xlsx-downloader ref="xlsx-downloader_refund" type="AMZ_FINANCE_REFUND"/>
    <xlsx-downloader ref="xlsx-downloader_adjustment" type="AMZ_FINANCE_ADJUSTMENT"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import XlsxDownloader from '@/components/xlsx-downloader'
import { Notification } from 'element-ui'

export default {
  components: {
    XlsxDownloader
  },
  data () {
    return {
      search: {
        _date: '',
        start: '',
        end: '',
        ec_id: '',
        feeType: ''
      },
      feeType: [
        {
          key: 1,
          val: '销售出货'
        },
        {
          key: 2,
          val: '销售退货'
        },
        {
          key: 3,
          val: '赔偿'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    setDate () {
      if (this.search._date === null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    handleExport () {
      if (!this.search.start || !this.search.end) {
        Notification.error({
          message: '始末日期不允许为空',
          duration: 5000
        })
        return false
      }
      if (!this.search.ec_id.length) {
        Notification.error({
          message: '电商不允许为空',
          duration: 5000
        })
        return false
      }
      if (!this.search.feeType) {
        Notification.error({
          message: '费用类型不允许为空',
          duration: 5000
        })
        return false
      }
      const params = {
        startDate: this.search.start,
        endDate: this.search.end,
        feeType: this.search.feeType,
        ec_id: this.search.ec_id ? this.search.ec_id.join(',') : null
      }
      if (this.search.feeType === 1) {
        this.$refs['xlsx-downloader_shipment'].show(params)
      }
      if (this.search.feeType === 2) {
        this.$refs['xlsx-downloader_refund'].show(params)
      }
      if (this.search.feeType === 3) {
        this.$refs['xlsx-downloader_adjustment'].show(params)
      }
    }
  }
}
</script>
