<template>
  <el-dialog :visible.sync="visible" title="备注" width="500px" show-close>
    <el-form :inline="true" label-width="120px">
      <el-form-item label="货品信息">
        &nbsp;&nbsp;{{ name }}<br />
        {{ mfn }}
      </el-form-item>
      <el-form-item label="采购单">
        &nbsp;&nbsp;{{ order_id }}<br />
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="4" placeholder="说明" v-model="remark">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="saveRemark"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { saveTrackRemark } from '@/api/tracking'
export default {
  data () {
    return {
      visible: false,
      id: '',
      name: '',
      mfn: '',
      order_id: '',
      remark: ''
    }
  },
  methods: {
    show (id, name, mfn, orderId, remark) {
      this.id = id
      this.name = name
      this.mfn = mfn
      this.order_id = orderId
      this.remark = remark
      this.visible = true
    },
    saveRemark () {
      saveTrackRemark({
        id: this.id,
        order_id: this.order_id,
        remark: this.remark
      })
        .then(data => {
          this.$emit('editremark-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
