<template>
  <el-dialog title="备注" :visible.sync="visible" width="500px">
    <el-form :inline="true" label-width="80px">
      <el-form-item label="备注">
        <el-input type="textarea" :rows="5" v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="saveReamrk"
        >保存更改</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as forwardNewApi from '@/api/forwardNew'
export default {
  props: ['labels'],
  data () {
    return {
      visible: false,
      form: { remark: '' }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    saveReamrk () {
      forwardNewApi
        .editplanRemark({ remark: this.form.remark, labels: this.labels })
        .then(data => {
          this.$emit('change')
          this.form = {}
          this.visible = false
        })
        .catch(err => {
          console.log(this.labels)
          console.log(err)
        })
    }
  }
}
</script>
