<!-- // 海外入库单 -->
<template>
  <el-dialog title="创建海外仓入库单" :visible.sync="visible" width="800px">
    <el-row>
      <el-form>
        <el-form-item label="选择仓库类型">
          <el-select v-model="ware_type" placeholder="FBA或TWUsa">
            <el-option
              v-for="item in ware_type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe ref="warehouseWarrantTable" border>
        <el-table-column type="selection" width="36"> </el-table-column>
        <el-table-column prop="id" label="名称" width="160"></el-table-column>
        <el-table-column
          prop="warehouseName"
          label="省市"
          mini-width="35%"
        ></el-table-column>
        <el-table-column
          prop="_transport_type"
          label="地址"
          mini-width="35%"
        ></el-table-column>
        <el-table-column
          prop=""
          label="邮编"
          mini-width="10%"
        ></el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-col :span="5" :offset="15">
        <el-button type="primary">确定</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      ware_types: [],
      ware_type: ''
    }
  },
  methods: {
    show () {
      this.visible = true
    }
  }
}
</script>
