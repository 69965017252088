<!-- 采购单列表 -->
<template>
  <div>
    <el-row style="margin-top:20px;">
      <el-col :span="15">
        <!-- <el-input placeholder="请输入采购单号" v-model="stockcode" class="searchstock">
          <el-button slot="append" @click="getstocklist"></el-button>
          <el-button slot="append" icon="el-icon-search" @click="getstocklist"></el-button>
        </el-input> -->
        <el-input
          style="width:200px; margin-right:20px"
          placeholder="请输入采购单号"
          v-model="stockcode"
          size="small"
        ></el-input>
        <!-- <el-select v-model="vendor_id" size="small">
          <el-option
           v-for=" item in sysConfig.vendorList"
           :key="item.id"
           :label="item.name"
           :value="item.id"
          >
          </el-option>
        </el-select> -->
        <el-input
          style="width:200px; margin-right:20px"
          placeholder="请输入供应商名称"
          v-model="vendorName"
          size="small"
        ></el-input>
        <el-button type="primary" @click="getstocklist" size="small"
          >搜索</el-button
        >
      </el-col>
      <el-col :span="4" :offset="3">
        <el-button
          type="default"
          v-show="stockstep === '0'"
          @click="pushstockedit"
          size="small"
          >添加采购单</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin-top:30px;">
      <el-table stripe style="width:100%" :data="stocklist" border>
        <!-- @row-dblclick="dbclickpushstockedit" -->
        <el-table-column prop="id" label="采购单号"></el-table-column>
        <el-table-column prop="vendorName" label="供应商"></el-table-column>
        <el-table-column prop="_transport_type" label="类型"></el-table-column>
        <el-table-column prop="_step" label="状态"></el-table-column>
        <el-table-column
          prop="warehouseName"
          label="目的仓库"
        ></el-table-column>
        <el-table-column prop="user_id,created_at" label="采购员/创建日期">
          <template slot-scope="scope"
            >{{ scope.row.user_id }}/{{ scope.row.created_at }}</template
          >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="pushstockedit(scope.row.id)"
              >查看</el-button
            >
            <el-button
              type="danger"
              size="small"
              v-if="stockstep === '0'"
              @click="deleteStockDetail(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import * as stockOrder from '@/api/stockOrder'
import { mapGetters } from 'vuex'
export default {
  name: 'stockList',
  props: ['stockstep'],
  data () {
    return {
      stockcode: '',
      stocklist: [],
      vendor_id: '',
      vendorName: '',
      pageDat: { per_page: 10 },
      productlist: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getstocklist()
    console.log(this.sysConfig)
  },
  watch: {
    stockstep: function () {
      this.getstocklist()
    }
  },
  methods: {
    getstocklist () {
      const currentPage = this.pageDat.current_page || 1
      stockOrder
        .getStocklist({
          step: this.stockstep,
          id: this.stockcode,
          vendor: this.vendorName,
          page: currentPage,
          num_per_page: this.pageDat.per_page
        })
        .then(data => {
          this.stocklist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    pushstockedit (stockcode = null) {
      this.$route.meta.keepAlive = true
      this.$router.push({
        // path: '/stock/stockEdit',
        name: 'stockEdit',
        params: {
          stockid: stockcode,
          editdisable: this.stockstep
        }
      })
    },
    dbclickpushstockedit (row) {
      this.$router.push({
        name: 'stockEdit',
        params: {
          stockid: row.id,
          editdisable: this.stockstep
        }
      })
    },
    deleteStockDetail (stockcode) {
      const self = this
      self
        .$confirm('确认是否删除该条采购单？')
        .then(_ => {
          stockOrder
            .deleteStockDetail({ id: stockcode })
            .then(data => {
              self.getstocklist()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    changePageHandler () {
      this.getstocklist()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getstocklist()
    }
  }
}
</script>

<style scope>
.searchstock {
  border: 1px solid #c5c5c5;
  border-radius: 20px;
  background: #f4f4f4;
}
.searchstock .el-input-group__append {
  border: none;
  background-color: transparent;
}
.searchstock .el-input__inner {
  height: 36px;
  line-height: 36px;
  border: none;
  background-color: transparent;
}
.searchstock .el-icon-search {
  font-size: 26px;
}
.searchstock .line {
  width: 1px;
  height: 26px;
  background-color: #c5c5c5;
  margin-left: 14px;
}
.searchstock:hover {
  border: 1px solid #d5e3e8;
  background: #fff;
}
.searchstock:hover .line {
  background-color: #d5e3e8;
}
.searchstock:hover .el-icon-search {
  color: #409eff;
  font-size: 26px;
}
</style>
