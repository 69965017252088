import request from '../config/request'

export const getProductlist = data => {
  return request('product', 'get', data)
}

export const saveProductBaseInfo = data => {
  return request('product/detail/save', 'post', data)
}

export const saveProductPhoto = data => {
  return request('product/detail/saveImg', 'post', data)
}

export const getProductDetail = data => {
  return request('product/detail', 'get', data)
}

export const saveProductAttribute = data => {
  return request('product/detail/saveAttribute', 'post', data)
}

export const getAttribute = () => {
  return request('product/attribute', 'get', null).then(data => {
    return data
  })
}

export const getAttbuDetail = data => {
  return request('product/attribute/detail', 'get', data, true).then(data => {
    return data
  })
}

export const saveAttribute = data => {
  return request('product/attribute/save', 'post', data)
}

export const getQiniuToken = data => {
  return request('resource/qiniu/token', 'get', data, true).then(data => {
    return data
  })
}
