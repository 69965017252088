<template>
  <div>
    <el-row class="search-nav">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select
            placeholder="电商"
            v-model="search.ec"
            @change="searchList()"
          >
            <el-option
              v-for="item in sysConfig.Common.ec"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table border stripe :data="fbashipList">
        <el-table-column label="ID" prop="ShipmentId"></el-table-column>
        <el-table-column label="Name" prop="ShipmentName"></el-table-column>
        <el-table-column label="电商" prop="ec_no"></el-table-column>
        <el-table-column label="From Address">
          <template slot-scope="scope">
            {{ scope.row.ShipFromAddress.Name }} <br />
            {{ scope.row.ShipFromAddress.CountryCode }}-
            {{ scope.row.ShipFromAddress.StateOrProvinceCode }}-
            {{ scope.row.ShipFromAddress.City }}-
            {{ scope.row.ShipFromAddress.AddressLine1 }},
            {{ scope.row.ShipFromAddress.PostalCode }}
          </template>
        </el-table-column>
        <el-table-column
          label="FBA Center ID"
          prop="DestinationFulfillmentCenterId"
        ></el-table-column>
        <el-table-column label="状态" prop="_status"></el-table-column>
        <el-table-column label="更新时间" prop="updated_at"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushDetail(scope.row.ShipmentId)"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="resetPlan(scope.row.ShipmentId)"
              v-if="step == 0"
              >重置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getinboundShipmentList, resetToPlan } from '@/api/fbaship'
export default {
  props: ['step'],
  data () {
    return {
      fbashipList: [],
      search: {},
      pageDat: {
        per_page: 10,
        current_page: 1
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    step: function () {
      this.getInboundList()
    }
  },
  created () {
    this.getInboundList()
  },
  methods: {
    getInboundList () {
      const listJsondata = {
        step: this.step,
        ec_id: this.search.ec,
        per_page: this.pageDat.per_page,
        page: this.pageDat.current_page
      }
      getinboundShipmentList(listJsondata)
        .then(data => {
          this.fbashipList = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchList () {
      this.getInboundList()
    },
    changePageHandler (currentPpage) {
      this.pageDat.current_page = currentPpage
      this.getInboundList()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getInboundList()
    },
    resetPlan (fbaid) {
      this.$confirm('提示', {
        title: '重置计划',
        message: '确定要将该Shipment重置回“计划中”吗？'
      })
        .then(_ => {
          resetToPlan({ ShipmentId: fbaid })
            .then(data => {
              this.$emit('reset-success')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    pushDetail (fbaid) {
      let routername = ''
      if (this.step === '1') {
        routername = 'fbaplanDetail'
      } else if (this.step === '2') {
        routername = 'fbauncommitDetail'
      } else if (this.step === '3') {
        routername = 'fbalogistFeeDetail'
      }
      this.$router.push({
        name: routername,
        params: {
          id: fbaid,
          step: this.step
        }
      })
    }
  }
}
</script>
