<template>
  <div>
    <el-row>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-select
            clearable
            v-model="search.warehouse"
            placeholder="选择仓库"
          >
            <el-option
              v-for="item in wareshouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交期：" v-show="type == 1">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.transport_type"
            placeholder="运输方式"
          >
            <el-option
              v-for="item in transportList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="type == 1">
          <el-input
            class="search-bar"
            v-model="search.order_id"
            placeholder="采购单"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-show="type == 1">
          <el-select clearable v-model="search.expected" placeholder="预计到货">
            <el-option
              v-for="item in expectedlist"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="type == 1">
          <el-select clearable v-model="search.shipable" placeholder="可发数量">
            <el-option
              v-for="item in shipablelist"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.status" placeholder="是否返工">
            <el-option
              v-for="item in sysConfig.LogisticsPlan.status"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="2" :offset="18">
        <el-button type="text">切换显示英制单位</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        :data="datList"
        stripe
        border
        style="width: 100%"
        @select="handleSelect"
        @select-all="handleSelectall"
        :show-summary="dflag"
        :summary-method="getSummaries"
      >
        <el-table-column
          type="selection"
          :selectable="checkboxflag"
          disable="true"
        ></el-table-column>
        <el-table-column label="货品" width="220">
          <template slot-scope="scope">
            <product-info-in-table-cell
              :cover="scope.row._product_cover"
              :title="scope.row._product_name"
              :mfn="scope.row._product_mfn"
              :sku="scope.row.sku"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column label="仓库/运输">
          <template slot-scope="scope">
            {{ scope.row.warehouse_name }} <br />
            {{ scope.row._transport_type }}
          </template>
        </el-table-column>
        <el-table-column label="外箱材积" width="150px">
          <template slot-scope="scope">
            {{ scope.row.ctn_length }} * {{ scope.row.ctn_width }} *
            {{ scope.row.ctn_height }}cm <br />
            {{ scope.row.ctn_g_weight }} kg <br />
            {{ scope.row.ctn_volume_m }} ㎡
          </template>
        </el-table-column>
        <el-table-column prop="label" label="箱标">
          <template slot-scope="scope">
            {{ scope.row.label }} <br />
            <el-tag
              v-if="
                scope.row._product_box_package_dimension != null &&
                  scope.row._product_box_package_dimension.sellpack > 1
              "
              >套{{ scope.row._product_box_package_dimension.sellpack }}</el-tag
            >
            <br />
            <el-tag v-if="scope.row.status > 1">{{ scope.row._status }}</el-tag>
            {{ scope.row._customs_type }}
          </template>
        </el-table-column>

        <el-table-column
          prop="order_id"
          label="采购单"
          v-if="type == 1"
        ></el-table-column>
        <el-table-column
          prop="ship_date"
          label="交期"
          v-if="type == 1"
        ></el-table-column>
        <el-table-column
          prop="expected_date"
          label="预计到货"
          v-if="type == 1"
        ></el-table-column>
        <el-table-column
          prop="inventory_qty"
          label="现有库存"
        ></el-table-column>
        <el-table-column
          prop="inventory_month"
          label="库存维持"
        ></el-table-column>

        <el-table-column
          prop="ctn"
          label="总箱数"
          v-if="type == 1"
        ></el-table-column>
        <el-table-column
          prop="expected_ctn"
          label="预计到货数"
          v-if="type == 1"
        ></el-table-column>

        <el-table-column prop="shipable_ctn" label="可发"></el-table-column>
        <el-table-column prop="unplanned_ctn,planned_ctn" label="未排/已排">
          <template slot-scope="scope">
            {{ scope.row.unplanned_ctn }}/{{ scope.row.planned_ctn }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" sortable></el-table-column>
        <el-table-column
          prop="x"
          label="发货数"
          width="200"
          justify="space-between"
        >
          <template v-slot="scope">
            <el-row type="flex" align="middle">
              <div>
                <el-input-number
                  v-if="scope.row.checked"
                  controls-position="right"
                  size="mini"
                  style="width: 99px;"
                  :min="scope.row.unplanned_ctn ? 1 : 0"
                  :max="parseInt(scope.row.unplanned_ctn)"
                  v-model="scope.row.x"
                ></el-input-number>
                <p v-else>/</p>
              </div>
              <div style="margin-left:10px" v-show="scope.row.checked">
                <p>
                  {{ (scope.row.ctn_g_weight * scope.row.x) | numFilter }} kg
                </p>
                <p>
                  {{ (scope.row.ctn_volume_m * scope.row.x) | numFilter }} ㎡
                </p>
              </div>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button type="text" @click="deleplan(scope.row.label)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <br />
    <el-row>
      <el-col :span="12">
        <div>
          <el-button type="primary" @click="createOrder">创建货代单</el-button>
          <el-button type="primary" @click="addTo">添加至货代单</el-button>
          <el-button type="primary" @click="editremark">修改备注</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[100, 200, 300, 500]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.num_per_page"
      ></el-pagination>
    </el-row>
    <create-forward-dialog
      ref="create-forward-dialog"
      :selectItem="selectItem"
      @change="change"
    />
    <add-to-forward-dialog
      ref="add-to-forward-dialog"
      :selectItem="selectItem"
      @change="change"
    />
    <edit-remark-dialog
      ref="edit-remark-dialog"
      :labels="labels"
      @change="change"
    ></edit-remark-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as forwardNewApi from '@/api/forwardNew2021'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
import createForwardDialog from './create-forward-dialog.vue'
import addToForwardDialog from './add-to-forward-dialog.vue'
import editRemarkDialog from './edit-remark-dialog.vue'
export default {
  props: ['type'],
  components: {
    createForwardDialog,
    addToForwardDialog,
    productInfoInTableCell,
    editRemarkDialog
  },
  filters: {
    numFilter (value) {
      return parseFloat(value).toFixed(2)
    }
  },
  data () {
    return {
      selectedList: [],
      selectItem: [],
      datList: [],
      labels: [],
      wareshouseList: [],
      transportList: [],
      search: {
        status: '',
        warehouse: '',
        transport_type: '',
        order_id: '',
        start: '',
        end: '',
        expected: '',
        shipable: '',
        page: 1,
        num_per_page: 100,
        total: 0,
        type: 1
      },
      date: [],
      expectedlist: [
        { key: '0', val: '否' },
        { key: '1', val: '预计到货' }
      ],
      shipablelist: [
        { key: '0', val: '不可发' },
        { key: '1', val: '是可发' }
      ]
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    dflag () {
      if (this.selectedList.length > 0) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    date: function (val) {
      if (val == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = val[0]
        this.search.end = val[1]
      }
    },
    type: function () {
      this.search = {
        warehouse: '',
        transport_type: '',
        order_id: '',
        start: '',
        end: '',
        expected: '',
        shipable: '',
        page: 1,
        num_per_page: 15,
        total: 0
      }
      this.search.type = this.type
      this.getDatList()
    }
  },
  created () {
    this.wareshouseList = this.sysConfig.warehouselist
    this.transportList = this.sysConfig.Common.transport_type
    this.search.type = this.type
    this.getDatList()
  },
  methods: {
    checkboxflag (row, index) {
      if (row.id === 0) {
        return 1
      } else {
        return 0
      }
    },
    getDatList () {
      forwardNewApi
        .getforwardNewList(this.search)
        .then(data => {
          this.datList = data.data
          this.search.total = data.total
          console.log(this.datList)
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleplan (lab) {
      this.$confirm('提示', {
        title: '删除货代单',
        message:
          '将删除相同Label的所有计划，同时删除已经被添加到货代单中的部分。确定删除？'
      })
        .then(_ => {
          forwardNewApi
            .delPlan({ label: lab })
            .then(data => {
              this.getDatList()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.selectedList = []
      this.getDatList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.num_per_page = pagesize
      this.selectedList = []
      this.getDatList()
    },
    searchHandle () {
      this.search.page = 1
      this.datList = []
      this.getDatList()
    },
    createOrder () {
      if (!this.checkSelected()) {
        return false
      }
      this.$refs['create-forward-dialog'].show()
    },
    addTo () {
      if (!this.checkSelected()) {
        return false
      }
      this.$refs['add-to-forward-dialog'].show()
    },
    editremark () {
      if (!this.checkSelected()) {
        return false
      }
      this.$refs['edit-remark-dialog'].show()
    },
    checkSelected () {
      if (this.selectedList.length === 0) {
        this.$alert('请至少勾选一个^_^')
        return false
      }
      if (
        !this.selectedList.every(item => {
          return item.x
        })
      ) {
        this.$alert('请填写数量！')
        return false
      }
      this.selectItem = this.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
      this.labels = this.selectedList.map(item => {
        return item.label
      })
      return true
    },
    //  选中checkbox项的回调
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
      } else {
        row.checked = true
        this.disable = true
      }
      this.selectedList = selection
    },
    //  全选回调函数
    handleSelectall (selection) {
      if (selection.length > 0) {
        for (let i = 0; i < this.datList.length; i++) {
          this.datList[i].checked = true
        }
      } else {
        for (let i = 0; i < this.datList.length; i++) {
          this.datList[i].checked = false
        }
      }
      this.selectedList = selection
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'x') {
          if (this.selectedList.length > 0) {
            const sumvolume = this.selectedList.map(item => {
              return {
                weightsum: item.x * item.ctn_g_weight,
                volumesum: item.x * item.ctn_volume_m
              }
            })
            let weightcount = 0
            let volumecount = 0
            for (let i = 0; i < sumvolume.length; i++) {
              weightcount += sumvolume[i].weightsum
              volumecount += sumvolume[i].volumesum
            }
            sums[index] =
              weightcount.toFixed(2) + 'kg,' + volumecount.toFixed(2) + '㎡'
          } else {
            sums[index] = '0'
          }
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    change () {
      this.getDatList()
    }
  }
}
</script>
