<template>
  <el-dialog
    title="编辑产品"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="600px"
  >
    <!-- <el-row>
           <el-tag>多个标签用中文或英文逗号隔开。</el-tag>
       </el-row> -->
    <el-row>
      <el-form label-position="left" label-width="80px" size="small">
        <el-form-item label="MFN">
          {{ formdata.mfn }}
        </el-form-item>
        <el-form-item label="品名">
          <el-input v-model="formdata.name"></el-input>
        </el-form-item>
        <el-form-item label="NO">
          <el-input v-model="formdata.no"></el-input>
        </el-form-item>
        <!-- <el-form-item label="标签">
                   <el-input v-model="formdata.tag"></el-input>
               </el-form-item> -->
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="3" :offset="18">
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import { editProductDetail } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        mfn: '',
        name: '',
        no: '',
        tag: '',
        ec: ''
      }
    }
  },
  methods: {
    show (formdata) {
      this.formdata.ec = formdata.ec_id
      this.formdata.mfn = formdata.mfn
      this.formdata.name = formdata._product.name
      this.formdata.no = formdata.no
      this.formdata.tag = formdata.tag
      this.visible = true
    },
    closedone (done) {
      done()
    },
    save () {
      editProductDetail(this.formdata)
        .then(data => {
          this.$emit('save-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.formdata.ec = ''
      this.formdata.mfn = ''
      this.formdata.name = ''
      this.formdata.no = ''
      this.formdata.tag = ''
      this.visible = false
    }
  }
}
</script>
