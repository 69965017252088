<template>
  <el-dialog title="创建请款单" :visible.sync="visible" width="600px">
    <el-form
      :model="formDat"
      ref="thisForm"
      label-position="left"
      :rules="rules"
    >
      <el-form-item
        label-width="90px"
        size="small"
        label="供应商"
        v-if="editflag === 0"
        >{{ vendor ? vendor.name : '' }}</el-form-item
      >

      <el-form-item
        label-width="90px"
        prop="bank_name"
        size="small"
        label="收款单位"
      >
        <el-input v-model="formDat.bank_name" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item
        label-width="90px"
        prop="bank"
        size="small"
        label="开户银行"
      >
        <el-input v-model="formDat.bank" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item
        label-width="90px"
        prop="bank_account"
        size="small"
        label="账户"
      >
        <el-input
          v-model="formDat.bank_account"
          style="width:220px "
        ></el-input>
      </el-form-item>
      <el-form-item
        label-width="90px"
        prop="deadline"
        size="small"
        label="付款期限"
      >
        <el-date-picker
          v-model="formDat.deadline"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label-width="90px" prop="remark" size="small" label="备注">
        <el-input v-model="formDat.remark" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click.prevent="createPayment" type="primary"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import {
  createEarnestFinance,
  getLastInvoiceRecord,
  editEarnestFinance
} from '@/api/purchaseFinanceNew'
export default {
  props: ['ids', 'vendor'],
  data () {
    return {
      visible: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      },
      formDat: {
        remark: '',
        currency: '',
        bank_name: ''
      },
      editflag: 0,
      rules: {
        bank_name: [{ required: true, message: '请输入', trigger: 'blur' }],
        bank: [{ required: true, trigger: 'blur' }],
        bank_account: [{ required: true, trigger: 'blur' }],
        deadline: [{ required: true, trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {
    vendor: {
      handler (newValue, oldValue) {
        if (this.vendor != null) {
          getLastInvoiceRecord({ vendor_id: this.vendor.vendor_id })
            .then(data => {
              if (data) {
                this.formDat = data
              }
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      deep: true
    }
  },
  // mounted() {
  //   purchaseFinanceApi.getlastPaymentRecord({ vendor_id: this.vendor.vendor_id })
  //   .then((data) => {
  //     this.formDat = data
  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // },
  methods: {
    createPayment () {
      if (this.editflag === 0) {
        this.$refs.thisForm.validate(valid => {
          if (valid) {
            this.formDat.ids = this.ids
            createEarnestFinance(this.formDat).then(res => {
              this.hide()
              this.$emit('paymentRes')
            })
          } else {
            return false
          }
        })
      } else if (this.editflag === 1) {
        editEarnestFinance(this.formDat)
          .then(data => {
            this.formDat = {}
            this.$emit('success-edit')
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    show (editflag, orderDetail) {
      this.visible = true
      this.editflag = editflag
      if (editflag === 0) {
        if (this.vendor.payment_record) {
          this.formDat.bank = this.vendor.payment_record.bank
          this.formDat.bank_name = this.vendor.payment_record.bank_name
          this.formDat.bank_account = this.vendor.payment_record.bank_account
        }
        this.formDat.currency = this.vendor.currency
        this.formDat.object_id = this.vendor.vendor_id
      } else if (editflag === 1) {
        this.formDat.id = orderDetail.id
        this.formDat.bank = orderDetail.bank
        this.formDat.bank_name = orderDetail.bank_name
        this.formDat.bank_account = orderDetail.bank_account
        this.formDat.deadline = orderDetail.deadline
        this.formDat.remark = orderDetail.remark
      }
    },
    hide () {
      this.formDat = {}
      this.visible = false
    }
  }
}
</script>
