<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
    :before-close="closedone"
  >
    <el-form
      :model="createdata"
      :inline="true"
      size="small"
      label-width="80px"
      :rules="rules"
      ref="create-dialog"
    >
      <el-form-item label="选择仓库" prop="warehouse_id" v-if="editflag === 0">
        <el-select v-model="createdata.warehouse_id" placeholder="请选择仓库">
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注说明">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入备注内容"
          v-model="createdata.textarea"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import * as warehouseApi from '@/api/warehouse'
export default {
  props: ['id'],
  data () {
    return {
      visible: false,
      title: '',
      createdata: {},
      editflag: 0, //  0创建1编辑
      rules: {
        warehouse_id: [
          { required: true, message: '请选择仓库', trigger: 'blur' }
        ]
      },
      type: ''
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (type, editflag) {
      if (type === '-5') {
        this.title = '创建报损单'
      } else if (type === '5') {
        this.title = '创建调整单'
      }
      this.editflag = editflag
      this.type = type
      this.visible = true
    },
    handleConfirm () {
      const self = this
      const jsonData = {
        warehouse_id: self.createdata.warehouse_id,
        remark: self.createdata.textarea,
        id: self.id
      }
      //  创建编辑报损单
      if (self.type === '-5') {
        warehouseApi
          .saveBreakage(jsonData)
          .then(data => {
            self.$emit('success-create')
            console.log(self.type)
          })
          .catch(err => {
            console.log(err)
          })
      } else if (self.type === '5') {
        warehouseApi
          .saveAdjust(jsonData)
          .then(data => {
            self.$emit('success-create')
          })
          .catch(err => {
            console.log(err)
          })
      }
      self.createdata = {}
      self.visible = false
    },
    closedone (done) {
      this.createdata = {}
      done()
    }
  }
}
</script>
