<script>
import { defineComponent, ref, reactive, computed } from '@vue/composition-api'
import { CONFIG_MAP } from './config'
import useFetch from './useFetch'
import { outputXLSX } from '@/service/io'

/**
 * 弹窗步骤
 * 1. prepare 2.downloading 3.export
 */
const DOWNLOAD_STEP = {
  // 下载失败
  FAIL: 0,
  PREPARE: 1,
  DOWNLOADING: 2,
  EXPORT: 3
}

export default defineComponent({
  name: 'xlsx-downloader',
  props: {
    type: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const visible = ref(false)
    const state = reactive({
      // 当前步骤
      step: DOWNLOAD_STEP.PREPARE,
      headers: [],
      rows: []
    })

    const config = CONFIG_MAP[props.type]

    const {
      getXlsxData,
      setParams,
      fetchState,
      reset,
      interrupt,
      model
    } = useFetch(props.type)

    const init = () => {
      reset()
      state.step = DOWNLOAD_STEP.PREPARE
      state.headers = []
      state.rows = []
    }

    const hide = () => (visible.value = false)

    const handleNext = async () => {
      switch (state.step) {
        case DOWNLOAD_STEP.FAIL:
          hide()
          break
        case DOWNLOAD_STEP.PREPARE:
          ;(async () => {
            // 开始下载
            try {
              state.step = DOWNLOAD_STEP.DOWNLOADING
              const { headers, rows } = await getXlsxData()
              state.rows = rows
              state.headers = headers
              state.step = DOWNLOAD_STEP.EXPORT
            } catch (e) {
              // TODO: 业务错误 前端中断业务，并且展示res.msg内容
              // 下载完毕或者下载失败的消息隔行显示。
              state.step = DOWNLOAD_STEP.FAIL
            }
          })()

          break
        case DOWNLOAD_STEP.DOWNLOADING:
          // 下载中不做处理
          break
        case DOWNLOAD_STEP.EXPORT:
          // 导出表格 并关闭弹窗
          outputXLSX(
            {
              headers: state.headers,
              rows: state.rows
            },
            config.filename
          )
          hide()
          break
      }
    }

    const handleClose = () => {
      visible.value = false
      interrupt.value && interrupt.value()
    }

    return {
      config,
      model,
      interrupt,
      visible,
      state,
      fetchState,
      show (params = {}) {
        init()
        setParams(params)
        visible.value = true
      },
      hide,
      handleNext,
      handleClose,
      buttonText: computed(() => {
        let text = '确定'
        switch (state.step) {
          case DOWNLOAD_STEP.FAIL:
            text = '关闭'
            break
          case DOWNLOAD_STEP.PREPARE:
            text = '开始下载'
            break
          case DOWNLOAD_STEP.DOWNLOADING:
            text = '数据下载中'
            break
          case DOWNLOAD_STEP.EXPORT:
          default:
            text = '导出Excel'
        }
        return text
      }),
      // 毫秒时间转换为可读的时：分：秒
      duration: computed(() => {
        const s = fetchState.elapsedTime / 1000
        const mins = Math.floor(s / 60)
        const seconds = Math.round(s % 60)
        return [mins, seconds]
      }),
      // 当前下载总数 和 perPage所对应的下载分页数
      downloadPages: computed(() => {
        return Math.ceil(fetchState.total / fetchState.perPage)
      })
    }
  }
})
</script>
<template>
  <el-dialog
    width="560px"
    title="导出"
    :visible="visible"
    @close="handleClose"
    :close-on-click-modal="false"
  >
    <div>
      <div
        class="p-0.5 border border-blue-400 bg-blue-200 rounded"
        v-if="config.headerExplain"
      >
        <i class="el-icon-warning mx-1 text-blue-500 text-base"></i>
        <span class="text-gray-500">{{ config.headerExplain }}</span>
      </div>
      <div v-show="state.step === 1">
        <el-form label-width="98px" label-position="left" v-if="config.fields">
          <el-form-item
            v-for="c in config.fields"
            :key="c.key"
            :label="c.label"
          >
            <el-input
              v-if="c.widget === 'input'"
              v-model="model[c.key]"
              size="small"
              v-bind="c.attrs"
            ></el-input>
            <el-date-picker
              v-if="c.widget === 'daterange'"
              size="small"
              v-model="model[c.key]"
              type="daterange"
              range-separator="至"
              v-bind="c.attrs"
            >
            </el-date-picker>
            <el-date-picker
              v-if="c.widget === 'month'"
              v-model="model[c.key]"
              size="small"
              type="month"
              v-bind="c.attrs"
            >
            </el-date-picker>
            <el-select
              size="small"
              v-if="c.widget === 'select'"
              v-model="model[c.key]"
              v-bind="c.attrs"
            >
              <el-option
                v-for="item in c.options.dataSource"
                :key="c.options.keyFactory(item)"
                v-bind="c.options.vlFactory(item)"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-show="state.step === 2">
        <p>
          共{{ fetchState.total }}条数据，每次下载{{
            fetchState.perPage
          }}条，分{{ downloadPages }}页下载，正在下载第{{
            fetchState.index
          }}页。
        </p>
      </div>
      <div v-show="state.step === 3">
        <p>
          全部{{ fetchState.total }}条数据已下载完毕，耗时 {{ duration[0] }}分{{
            duration[1]
          }}秒。
        </p>
      </div>
      <div v-show="state.step === 0">
        <!-- <p>共{{ fetchState.total }}条数据，每次下载{{ fetchState.perPage }}条，分{{ downloadPages }}页下载，正在下载第{{ fetchState.index }}页。</p> -->
        <p>下载失败，耗时 {{ duration[0] }}分{{ duration[1] }}秒。</p>
        <p>错误信息【{{ fetchState.message }}】</p>
      </div>
    </div>
    <template slot="footer">
      <el-button
        @click="handleNext"
        :disabled="fetchState.isFetching"
        size="small"
        type="primary"
        >{{ buttonText }}</el-button>
    </template>
  </el-dialog>
</template>
