<!-- // 待交付查询 -->
<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务管理</el-breadcrumb-item>
        <el-breadcrumb-item>待交互查询</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in tabslist"
          :key="item.key"
          :label="item.val"
          :name="item.key"
        >
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <turnover-query-list :on="tabvalue"></turnover-query-list>
  </div>
</template>
<script>
import turnoverQueryList from './turnover-querylist'
export default {
  components: {
    turnoverQueryList
  },
  data () {
    return {
      tabslist: [
        {
          key: 'false',
          val: '待交付'
        },
        {
          key: 'true',
          val: '已交付'
        }
      ],
      tabvalue: 'false'
    }
  }
}
</script>
