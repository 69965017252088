<template>
  <div id="login" class="h-full flex justify-center items-center">
    <el-card class="w-96 py-4" shadow="hover">
      <el-form :model="LoginForm" label-width="100px">
        <el-form-item label="账号" label-width="50px">
          <el-input
            v-model="LoginForm.account"
            placeholder="请输入邮箱"
            auto-complete="on"
            autocomplete="on"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="50px">
          <el-input
            auto-complete="on"
            autocomplete="on"
            type="password"
            v-model.number="LoginForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-button
          native-type="submit"
          class="w-full mt-6"
          type="primary"
          @click.prevent="submitForm('LoginForm')"
          >登录</el-button
        >
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { useAuth } from '@/domain/auth'
import * as rootController from '@/api/rootController'

const { doLogin } = useAuth()

export default {
  data () {
    const isDev = process.env.NODE_ENV === 'staging'
    return {
      LoginForm: {
        account: isDev ? '362680581@qq.com' : '',
        password: isDev ? '1234567' : ''
      }
    }
  },
  methods: {
    submitForm () {
      const self = this
      const myReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i
      if (!myReg.test(self.LoginForm.account)) {
        return self.$message.error('请输入正确的邮箱!')
      }
      if (!self.LoginForm.password) {
        return self.$message.error('请输入密码！')
      }
      rootController
        .login({
          account: self.LoginForm.account,
          pwd: self.LoginForm.password
        })
        .then(res => {
          // localStorage.setItem('account', self.LoginForm.account)
          // localStorage.setItem('password', self.LoginForm.password)
          // TODO: 即将弃用
          localStorage.setItem('token', res.token)
          doLogin(res.token)
          self.$router.push({ path: '/' })
        })
        .catch(e => {
          console.error(e)
        })
    }
  }
}
</script>

<style lang="css">
#login {
  background: url('~@/assets/logo.png');
  background-repeat: no-repeat;
  background-position: left bottom;
  background-color: #5F877E;
}
</style>
