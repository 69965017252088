<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>交付明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search">
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-input v-model="search.mfn" placeholder="MFN"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.order_id" placeholder="采购单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.clearance" placeholder="关单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysConfig.vendorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="入库开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.remark" placeholder="备注"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table
        border
        stripe
        :data="dataList"
        @select="handleSelect"
        @select-all="handleSelectall"
        @selection-change="handleSelectionChange"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column width="260" label="品名MFN">
          <template slot-scope="scope">
            <product-info-table-cell
              :mfn="scope.row.mfn"
              :cover="scope.row.product_cover"
              :title="scope.row.product_name"
            ></product-info-table-cell>
          </template>
        </el-table-column>
        <el-table-column prop="qty_total" label="总数"></el-table-column>
        <el-table-column prop="wms_id" label="采购入库单"></el-table-column>
        <el-table-column prop="_warehouse" label="入库仓库"></el-table-column>
        <el-table-column prop="date" label="入库时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="order_id" label="采购单"></el-table-column>
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column prop="price_include_tax" label="采购价（含税）"></el-table-column>
        <el-table-column prop="price" label="采购价"></el-table-column>
        <el-table-column prop="qty_remaind" label="未报销数">
          <template slot-scope="scope">
            <el-input
              type="number"
              v-if="scope.row.checked"
              size="mini"
              style="width: 99px;"
              :min="scope.row.unplanned_ctn ? 1 : 0"
              :max="parseInt(scope.row.qty_remaind)"
              v-model="scope.row.x_qty"
              @input="updateSelectQty(scope.row)"
            ></el-input>
            <p v-else>{{ scope.row.qty_remaind }}</p>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div style="margin:9px 0">
      <el-button type="primary" size="mini" @click="createOrder"
        >创建报销单</el-button
      >
      <el-button type="primary" size="mini" @click="addOrder">
        添加至报销单</el-button
      >
    </div>
    <el-row class="content-row">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[50, 100, 200, 500]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.num_per_page"
      ></el-pagination>
    </el-row>
    <create-reimbursement-order-dialog
      :deData="deData"
      :propsDat="propsDat"
      ref="create-reimbursement-order-dialog"
    ></create-reimbursement-order-dialog>
    <add-to-reimbursement-order-dialog
      :deData="deData"
      :propsDat="propsDat"
      ref="add-to-reimbursement-order-dialog"
    ></add-to-reimbursement-order-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPfinanceInvoice } from '@/api/purchaseFinanceNew'
import productInfoTableCell from '@/components/common/product-info-table-cell'
import createReimbursementOrderDialog from './create-reimbursement-order-dialog'
import addToReimbursementOrderDialog from './add-to-reimbursement-order-dialog'
export default {
  components: {
    productInfoTableCell,
    createReimbursementOrderDialog,
    addToReimbursementOrderDialog
  },
  data () {
    return {
      dataList: [],
      selectData: [],
      deData: [],
      search: {
        vendor_id: '',
        mfn: '',
        remark: '',
        page: 1,
        per_page: 50,
        total: 0,
        start: '',
        end: '',
        _date: null
      },
      propsDat: null,
      total_sel_price: 0,
      items: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getInvoiceList()
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getInvoiceList () {
      const jsonData = {
        mfn: this.search.mfn,
        vendor_id: this.search.vendor_id,
        start_date: this.search.start,
        end_date: this.search.end,
        remark: this.search.remark,
        order_id: this.search.order_id,
        clearance: this.search.clearance,
        page: this.search.page,
        per_page: this.search.per_page
      }
      getPfinanceInvoice(jsonData)
        .then(data => {
          this.dataList = data.data
          this.search.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSelectionChange (val) {
      if (val.length) {
        this.selectData = val
        return true
      } else {
        this.selectData = []
        this.deData = []
        this.total_sel_price = 0
      }
    },
    createOrder () {
      if (!this.selectData.length) {
        this.$alert('请选择').catch(() => {})
        return false
      }
      const selVendor = this.selectData.map(value => value.vendor_id)
      const selVendorSet = new Set(selVendor)
      if (selVendorSet.size > 1) {
        this.$alert('所选明细包含了多个供应商').catch(() => {})
        return false
      }
      this.deData = this.selectData.map(item => {
        return { _id: item._id, x_qty: item.x_qty }
      })
      this.propsDat = {
        vendor_name: this.selectData[0]._vendor,
        vendor_id: this.selectData[0].vendor_id,
        currency: this.selectData[0].currency
      }
      this.$refs['create-reimbursement-order-dialog'].show()
    },
    addOrder () {
      if (!this.selectData.length) {
        this.$alert('请选择').catch(() => {})
        return false
      }
      const selVendor = this.selectData.map(value => value.vendor_id)
      const selVendorSet = new Set(selVendor)
      if (selVendorSet.size > 1) {
        this.$alert('所选明细包含了多个供应商').catch(() => {})
        return false
      }
      this.deData = this.selectData.map(item => {
        return { _id: item._id, x_qty: item.x_qty }
      })
      this.propsDat = {
        vendor_name: this.selectData[0]._vendor,
        vendor_id: this.selectData[0].vendor_id,
        currency: this.selectData[0].currency
      }
      this.$refs['add-to-reimbursement-order-dialog'].show()
    },
    //  选中checkbox项的回调
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
      } else {
        row.checked = true
        // this.$set(row, 'x', row.qty_remaind)
        this.disable = true
      }
      this.total_sel_price = 0
      this.items = selection.map(item => {
        this.total_sel_price += item.price_include_tax * item.x_qty
        return {
          id: item.id,
          price_include_tax: item.price_include_tax,
          x_qty: item.x_qty
        }
      })
    },
    //  全选回调函数
    handleSelectall (selection) {
      const self = this
      if (selection.length > 0) {
        for (let i = 0; i < self.dataList.length; i++) {
          self.dataList[i].checked = true
          // this.$set(self.dataList[i], 'x', self.dataList[i].qty_remaind)
        }
      } else {
        for (let i = 0; i < self.dataList.length; i++) {
          self.dataList[i].checked = false
        }
      }
      this.total_sel_price = 0
      this.items = selection.map(item => {
        this.total_sel_price += item.price_include_tax * item.x_qty
        return {
          id: item.id,
          price_include_tax: item.price_include_tax,
          x_qty: item.x_qty
        }
      })
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.getInvoiceList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.per_page = pagesize
      this.getInvoiceList()
    },
    searchHandle () {
      this.search.page = 1
      this.search.per_page = 15
      this.getInvoiceList()
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 1) {
          sums[index] = this.total_sel_price.toFixed(2)
        }
      })
      return sums
    },
    updateSelectQty (row) {
      if (this.items.length) {
        this.total_sel_price = 0
        this.items.forEach(val => {
          if (val.id === row.id) {
            this.total_sel_price += row.price_include_tax * row.x_qty
            val.x_qty = row.x_qty
          } else {
            this.total_sel_price += val.price_include_tax * val.x_qty
          }
        })
      }
    }
  }
}
</script>
