<template>
  <div class="create-prepayment">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'prepayment' }"
          >预付款</el-breadcrumb-item
        >
        <el-breadcrumb-item>到货请款</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-tabs v-model="activeName">
      <el-tab-pane label="1 导入Excel" name="first">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="80px"
          style="width:600px"
        >
          <el-form-item prop="vendorId" label="供应商">
            <el-select
              v-model="ruleForm.vendorId"
              placeholder="选择供应商"
              filterable
              clearable
            >
              <el-option
                v-for="item in vendorList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="currency" label="币种">
            <el-select
              v-model="ruleForm.currency"
              placeholder="选择币种"
              filterable
              clearable
            >
              <el-option
                v-for="item in currencyList"
                :key="item.key"
                :value="item.key"
                :label="item.val"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文件" ref="excel" prop="file">
            <el-upload
              :action="uploadUrl"
              :limit="1"
              name="excel"
              :multiple="false"
              :on-success="onUploadSuccess"
              :before-upload="handleBeforeUpload"
              :on-error="onUploadError"
              :auto-upload="false"
              :show-file-list="true"
              ref="upload"
              :data="ruleForm"
              :on-change="chUploadFile"
              :before-remove="rmUploadFile"
            >
              <el-button>选取excel文件</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item>
            务必保证上传文件的模板格式符合要求，
            <el-link
              type="primary"
              :href="tplOfPurchaseItemToPrepayment"
              target="_blank"
              >点击下载模板。</el-link
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="nextHandle">下一步</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="2 款项报关" disabled name="second">
        <create-prepayment-second
          @nextHandle="nextHandle"
          :data="responseDat"
        />
      </el-tab-pane>
      <el-tab-pane label="3 生成预付款" disabled name="third">
        <create-prepayment-third
          :data="responseDat"
          :parentForm="ruleForm"
          :lastPayData="lastPayData"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env.js'
import createPrepaymentSecond from './create-prepayment-second-tab'
import createPrepaymentThird from './create-prepayment-third-tab'
import { mapGetters } from 'vuex'

export default {
  components: { createPrepaymentThird, createPrepaymentSecond },
  data () {
    return {
      activeName: 'first',
      vendorList: [],
      currencyList: [],
      ruleForm: {
        vendorId: '',
        currency: '',
        file: null
      },

      rules: {
        vendorId: [{ required: true, trigger: 'change' }],
        currency: [{ required: true, trigger: 'change' }],
        file: [{ required: true, trigger: 'change' }]
      },

      lastPayData: {},
      responseDat: {
        cache: '',
        report: {
          excel: [],
          vendor: {}
        }
      }
    }
  },
  mounted () {
    this.currencyList = this.sysConfig.Common.currency
    this.vendorList = this.sysConfig.vendorList
  },

  computed: {
    ...mapGetters(['sysConfig']),
    tplOfPurchaseItemToPrepayment () {
      return (
        baseUrl +
        'purchase/finance/prepayment/tplOfPurchaseItemToPrepayment?token=' +
        localStorage.getItem('token')
      )
    },
    //
    uploadUrl () {
      return (
        baseUrl +
        'purchase/finance/prepayment/uploadPrepaymentDetail?token=' +
        localStorage.getItem('token')
      )
    }
  },
  methods: {
    nextHandle (name = '') {
      console.log(this.ruleForm)
      // return false

      if (name === 'third') {
        this.activeName = name
        return true
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$refs.upload.submit()
          } else {
            return false
          }
        })
      }
    },

    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (response.res < 1) {
        this.$notify.error({ message: response.msg })
        return false
      }
      this.responseDat = response.dat
      this.lastPayData = response.dat.report.vendor.last_payment_record

      if (response.dat.report.excel.length === 0) {
        // this.$alert('文件数据有误');
        this.$alert('文件数据可能有误：' + response.msg)
        return false
      }
      this.activeName = 'second'
      return true
    },

    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    },

    chUploadFile () {
      this.ruleForm.file = true
      this.$refs.excel.clearValidate()
    },

    rmUploadFile () {
      this.ruleForm.file = null
    }
  }
}
</script>
<style>
.title-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.title-row span {
  margin-left: 40px;
}
.bottom-line {
  padding: 10px 10px;
  border-bottom: 1px solid gray;
}
</style>
