<template>
  <div>
    <el-row>
      <h3>导入美仓功能</h3>
    </el-row>
    <el-row style="margin-bottom: 20px">
      <el-tag
        >近期美仓的API服务器受政府限制，导致SD无法保证每次都准确拿到库存数据。该临时功能可导入Excel更新美仓库存（直接使用美仓导出的数据格式），待美仓API恢复后停用。</el-tag
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form label-width="80px">
        <el-form-item>
          <el-upload
            :action="importInboundOrder"
            :limit="1"
            name="excel"
            :multiple="false"
            :on-success="onUploadSuccess"
            :before-upload="handleBeforeUpload"
            :on-error="onUploadError"
            :auto-upload="false"
            :show-file-list="true"
            ref="upload"
          >
            <el-button type="primary" size="mini">选取excel文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label-width="20px" label>
          <p>
            <el-link type="primary" :href="downloadTemplate" target="_blank"
              >下载模板</el-link
            >
          </p>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 20px;"
        @click="handleConfirm()"
        >上传更新</el-button
      >
    </el-row>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env.js'
export default {
  data () {
    return {}
  },
  computed: {
    downloadTemplate () {
      return (
        baseUrl +
        'wms/test/importInventoryTpl?token=' +
        localStorage.getItem('token')
      )
    },
    importInboundOrder () {
      return (
        baseUrl +
        'wms/test/importInventory?token=' +
        localStorage.getItem('token')
      )
    }
  },
  methods: {
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
