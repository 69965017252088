<template>
  <div class="system-manage">
    <h2>Welcome to Soffee Design！</h2>
    <el-alert
      title="小提示"
      type="success"
      description="点击顶部导航栏的【主菜单】，再选择左侧👈需要操作的功能 ^_^😊"
    ></el-alert>
    <div style="margin-left: 180px; margin-top:20px; ">
      <iframe
        scrolling="no"
        src="https://tianqiapi.com/api.php?style=tw&skin=pitaya"
        frameborder="0"
        width="300"
        height="500"
        allowtransparency="true"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      test: 'test',
      post: {
        name: 'Soffee Design',
        age: 18
      },
      title: '这是一个dialog'
    }
  },
  methods: {
    chan () {
      console.log('change 更新了')
    }
  }
}
</script>

<style>
.system-manage {
  position: relative;
}
.map {
  width: 350px;
  position: absolute;
  left: 650px;
  top: 120px;
}
</style>
