<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>库存与补货</el-breadcrumb-item>
        <el-breadcrumb-item>补货报告</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search" style="margin-top: 10px">
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-input v-model="search.name" placeholder="品名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.sku" placeholder="SKU"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.fnsku" placeholder="FNSKU"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.asin" placeholder="ASIN"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.size" placeholder="尺寸">
            <el-option
              v-for="item in sysConfig.Common.productSize"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.no_supply" placeholder="自动补货">
            <el-option
              v-for="item in supplyOps"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="search.attribute"
            placeholder="自定义标签,多个用逗号分隔"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.ec_id" placeholder="EC必选">
            <el-option
              v-for="item in sysConfig.Common.ec"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="search.tagReverse">Tag反选排除</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="searchReplenis()"
            >搜索</el-button
          >
        </el-form-item>

        <div style="float:right">
          <el-form-item>
            <el-dropdown>
              <el-button type="primary" size="small">
                辅助功能<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="$refs['refresh-strategy-dialog'].show()"
                  >刷新策略</el-dropdown-item
                >
                <el-dropdown-item @click.native="downloadLink()"
                  >下载今日销量统计</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="
                    $refs['import-replenish-dialog'].show(search.ec_id)
                  "
                  >导入补货数据</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
          <!-- <el-form-item >
                        <el-button size="mini" type="primary" @click.native="downloadLink()">下载今日销量统计</el-button>
                    </el-form-item>
                    <el-form-item >
                        <el-button size="mini" type="primary" @click="$refs['refresh-strategy-dialog'].show()">刷新策略</el-button>
                    </el-form-item> -->
        </div>
      </el-form>
    </el-row>
    <el-row
      style="margin-top: 10px;"
      v-if="replenishList.length > 0"
    >
      <el-table
        border
        stripe
        width="100%"
        :data="replenishList"
        @select="handleSelect"
        @select-all="handleSelectall"
        show-summary
        :summary-method="getSummaries"
        :span-method="objSpan"
        ref="table"
        height="600"
        :default-sort="{ prop: 'forcetype', order: 'descending' }"
      >
        <el-table-column
          label="全选"
          type="selection"
          width="40"
        ></el-table-column>
        <el-table-column label="商品" width="250">
          <template slot-scope="scope">
            <product-info-table-cell
              :cover="scope.row._product.cover"
              :title="scope.row._product.name"
              :mfn="scope.row._product.mfn"
              v-if="scope.row._product"
            ></product-info-table-cell>
            <el-tag type="warning" v-if="scope.row.size === 1">Oversize</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="SKU/FNSKU/ASIN" width="130">
          <template slot-scope="scope">
            <div v-if="scope.row.forcetype === 0">
              {{ scope.row.sku }} <br />
              {{ scope.row.fnsku }} <br />
              {{ scope.row.asin }}
            </div>
            <div
              v-else-if="scope.row.forcetype === 1"
              style="background-color: yellow;"
            >
              {{ scope.row.sku }} <br />
              {{ scope.row.fnsku }} <br />
              {{ scope.row.asin }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="day7" label="7日销量" sortable></el-table-column>
        <el-table-column
          prop="day14"
          label="14日销量"
          sortable
        ></el-table-column>
        <el-table-column
          prop="day30"
          label="30日销量"
          sortable
        ></el-table-column>
        <el-table-column
          prop="day60"
          label="60日销量"
          sortable
        ></el-table-column>
        <el-table-column
          prop="day180"
          label="180日销量"
          sortable
        ></el-table-column>
        <el-table-column
          prop="gsp_qty"
          label="GPS库存"
          sortable
        ></el-table-column>
        <el-table-column
          prop="twusa_qty"
          label="TWUsa库存"
          sortable
        ></el-table-column>
        <el-table-column prop="plan_qty" label="计划中"></el-table-column>
        <el-table-column
          prop="fba_qty"
          label="FBA库存"
          sortable
        ></el-table-column>
        <el-table-column prop="supply_qty" label="补货" sortable>
          <template slot-scope="scope">
            {{ scope.row.supply_qty }}
            <el-tag>{{ scope.row.qty_in_case }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="提交数量">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.supply_qty"
              @input="updateSelectQty(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="forcetype" sortable></el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-col :span="3">
        <el-button type="primary" size="small" @click="showCreateDialog()"
          >创建FBA Shipping Plan</el-button
        >
      </el-col>
      <el-col :span="3" :offset="1">
        <el-button type="primary" size="small" @click="addFbaDialog()"
          >追加至FBA Shipping Plan</el-button
        >
      </el-col>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 300, 500]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <create-fbashipment-dialog
      ref="create-fbashipment-dialog"
      @success-create="createsucess"
    ></create-fbashipment-dialog>
    <add-fbashipment-dialog
      ref="add-fbashipment-dialog"
      @success-add="searchReplenis()"
    ></add-fbashipment-dialog>
    <refresh-strategy-dialog
      ref="refresh-strategy-dialog"
    ></refresh-strategy-dialog>
    <import-replenish-dialog
      ref="import-replenish-dialog"
      @sunccess-import="searchReplenis()"
    ></import-replenish-dialog>
  </div>
</template>
<script>
import { getReplenishmentfbaReport } from '@/api/amazon'
import { mapGetters } from 'vuex'
import productInfoTableCell from '@/components/common/product-info-table-cell'
import createFbashipmentDialog from './create-fbashipment-dialog'
import addFbashipmentDialog from './add-fbashipment-dialog'
import refreshStrategyDialog from './refresh-strategy-dialog'
import importReplenishDialog from './import-replenish-dialog'
import { baseUrl } from '@/config/env'

export default {
  components: {
    productInfoTableCell,
    createFbashipmentDialog,
    addFbashipmentDialog,
    refreshStrategyDialog,
    importReplenishDialog
  },
  data () {
    return {
      search: {
        tagReverse: false
      },
      replenishList: [],
      pageData: {
        current_page: 1,
        per_page: '100'
      },
      items: [],
      supplyOps: [
        { key: 0, val: '自动补货' },
        { key: 1, val: '非自动补货' }
      ],
      columnforce: {},
      total_supply_qty: 0,
      oversize_supply_qty: 0
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getReplenish()
  },
  methods: {
    getReplenish () {
      const jsondata = {
        sku: this.search.sku,
        name: this.search.name,
        fnsku: this.search.fnsku,
        asin: this.search.asin,
        size: this.search.size,
        tag: this.search.attribute,
        tagReverse: Number(this.search.tagReverse),
        page: this.pageData.current_page,
        per_page: this.pageData.per_page,
        no_supply: this.search.no_supply,
        ec_id: this.search.ec_id
      }
      getReplenishmentfbaReport(jsondata)
        .then(data => {
          const reList = []
          data.data.map((item, index) => {
            reList.push(Object.assign({}, item, { forcetype: 0 }))
          })
          this.replenishList = reList
          // this.replenishList = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    downloadLink (type) {
      const url =
        baseUrl +
        'sales/replenishment/download?token=' +
        localStorage.getItem('token')
      window.open(url)
    },

    searchReplenis () {
      this.pageData.current_page = 1
      this.getReplenish()
    },
    compare (property) {
      return function (a, b) {
        var value1 = a[property]
        var value2 = b[property]
        return value1 - value2
      }
    },
    createsucess (data) {
      const forcepdtList = data
      for (let i = 0; i < forcepdtList.length; i++) {
        this.replenishList.find((item, index) => {
          if (item.sku === forcepdtList[i].sku) {
            this.replenishList[index].forcetype = 1
          }
        })
      }
      this.replenishList.sort(this.compare('forcetype'))
      console.log(this.replenishList)
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getReplenish()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getReplenish()
    },

    updateSelectQty (row) {
      if (this.items.length) {
        this.items.forEach(val => {
          if (val.sku === row.sku) {
            this.total_supply_qty += row.supply_qty - val.supply_qty
            val.supply_qty = row.supply_qty
          }
        })
      }
    },
    handleSelect (selection) {
      this.total_supply_qty = 0
      this.oversize_supply_qty = 0
      this.items = selection.map(item => {
        this.total_supply_qty += (item.supply_qty - 0)
        if (item.size === 1) {
          this.oversize_supply_qty += (item.supply_qty - 0)
        }
        return {
          sku: item.sku,
          supply_qty: item.supply_qty,
          product_id: item.product_id
        }
      })
    },

    handleSelectall (selection) {
      this.total_supply_qty = 0
      this.oversize_supply_qty = 0
      this.items = selection.map(item => {
        this.total_supply_qty += (item.supply_qty - 0)
        if (item.size === 1) {
          this.oversize_supply_qty += (item.supply_qty - 0)
        }
        return {
          sku: item.sku,
          supply_qty: item.supply_qty,
          product_id: item.product_id
        }
      })
    },
    checkSelected () {
      if (this.items.length === 0) {
        this.$alert('请至少选择一款产品')
        return false
      }
      return true
    },
    showCreateDialog () {
      // console.log(this.items)
      if (this.checkSelected()) {
        this.$refs['create-fbashipment-dialog'].show(
          this.items,
          this.search.ec_id
        )
      }
    },
    addFbaDialog () {
      if (this.checkSelected()) {
        this.$refs['add-fbashipment-dialog'].show(this.items, this.search.ec_id)
      }
    },
    objSpan () {
      this.$nextTick(x => {
        if (this.$refs.table.$el) {
          var current = this.$refs.table.$el
            .querySelector('.el-table__footer-wrapper')
            .querySelector('.el-table__footer')
          var cell = current.rows[0].cells
          // cell[2].style.display = 'none'
          // cell[3].style.display = 'none'
          // cell[4].style.display = 'none'
          // cell[1].classList.remove('is-leaf')
          cell[1].colSpan = 14
        }
      })
    },
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index === 1) {
          sums[index] = '总数：' + this.total_supply_qty + '； standardsize：' + (this.total_supply_qty - this.oversize_supply_qty) + '； oversize：' + this.oversize_supply_qty
        }
      })
      return sums
    }
  }
}
</script>
<style scoped>
  .el-table .red {
    background: #f5f5dc;
  }
  .el-table__footer .cell{
    white-space: pre-wrap;
  }
</style>
