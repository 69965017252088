<template>
  <el-dialog
    title="批量增加标签"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="500"
  >
    <el-tag type="info">多个标签用英文逗号隔开。</el-tag>
    <el-form>
      <el-form-item label="MFN"> &nbsp;&nbsp;{{ formdata.mfn }} </el-form-item>
      <el-form-item label="标签">
        <el-input v-model="formdata.label" type="textarea" :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="saveTag()">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { editProductLabel } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        ec_id: '',
        label: '',
        mfn: ''
      }
    }
  },
  methods: {
    show (mfn, ecId, tag) {
      this.formdata.mfn = mfn
      this.formdata.ec_id = ecId
      this.$set(this.formdata, 'label', tag)
      this.visible = true
    },
    closedone (done) {
      done()
    },
    saveTag () {
      const jsondata = {
        ec: this.formdata.ec_id,
        mfn: this.formdata.mfn,
        tag: this.formdata.label
      }
      editProductLabel(jsondata)
        .then(data => {
          this.$emit('add-tag-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.formdata = {}
      this.visible = false
    }
  }
}
</script>
