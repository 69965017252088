import { storage } from '@/core'

export function useAuth () {
  const doLogin = (token) => {
    storage.token = token
  }

  const doLogout = () => {
    storage.clearToken()
  }

  return {
    doLogin,
    doLogout
  }
}
