<template>
  <el-dialog title="反审核" :visible.sync="visible" width="500px">
    <el-form>
      <el-form-item>
        <p>
          反审核不会撤回已经支付的请款，未审核支付的情况则将被删除。同时相关交货进度、货代计划、货代单中的明细也将被删除，可通过以下指定邮箱通知相关人员
        </p>
        <p>确定执行反审核</p>
      </el-form-item>
      <el-form-item label="邮箱通知">
        <el-input
          v-model="emaillist"
          type="textarea"
          :rows="5"
          placeholder="一行一个邮箱，如不通知请留空"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="hide">取 消</el-button>
      <el-button size="small" type="primary" @click="unconfirmStock"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as stockOrderApi from '../../../api/stockOrder'
export default {
  data () {
    return {
      emaillist: '',
      visible: false,
      stockcode: ''
    }
  },
  methods: {
    show (stockcode) {
      this.visible = true
      this.stockcode = stockcode
    },
    hide () {
      this.visible = false
    },
    unconfirmStock () {
      const self = this
      const email = self.emaillist.split('\n')
      const jsonData = {
        id: self.stockcode,
        email: email
      }
      stockOrderApi
        .unconfirmStock(jsonData)
        .then(data => {
          self.$emit('unconfirmSuccess')
          self.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
