<template>
  <el-dialog
    class="attributeDetail"
    title="属性详情"
    :show-close="true"
    :visible.sync="visible"
    width="1000px"
  >
    <el-row>
      <el-col :span="10" style="font-size:13px; color:#7F7F7F">
        <label>属性名:</label>
        <label class="label">{{ pareattribute.name }}</label>
      </el-col>
      <el-col :span="10" style="font-size:13px; color:#7F7F7F">
        <label>字段:</label>
        <label class="label">{{ pareattribute.id }}</label>
      </el-col>
    </el-row>

    <br />
    <br />

    <el-row style="margin-top:20px">
      <el-form
        size="small"
        :model="attribute"
        label-width="100px"
        label-position="left"
        ref="attribute"
        id="attribute"
        :rules="rules"
        style="width:300px"
      >
        <el-form-item label="子属性名" prop="name">
          <el-input v-model="attribute.name"></el-input>
        </el-form-item>

        <el-form-item label="字段" prop="id">
          <el-input v-model="attribute.id"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="addAttributedetail"
            >添加子属性</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>

    <el-row  style="margin-top:20px">
      <el-table
        :stripe="true"
        :border="true"
        :data="attbudetlist"
        ref="AttributeTable"
      >
        <el-table-column label="子属性" prop="name"></el-table-column>
        <el-table-column label="字段名" prop="id"></el-table-column>
      </el-table>
    </el-row>
  </el-dialog>
</template>
<script>
import * as productapi from '../../../api/product'
export default {
  data () {
    var validateFirletter = (rule, value, callback) => {
      const Firletter = /^[a-zA-Z][a-zA-Z0-9_]*$/
      if (!value) {
        callback(new Error('请输入属性字段名'))
      } else if (Firletter.test(value)) {
        callback()
      } else {
        callback(new Error('请输入以字母开头，字母数字下划线组成的字段名'))
      }
    }
    return {
      visible: false,
      pareattribute: {},
      attribute: {},
      attbudetlist: [],
      rules: {
        name: [{ required: true, message: '请输入属性名称', trigger: 'blur' }],
        id: [{ required: true, validator: validateFirletter, trigger: 'blur' }]
      }
    }
  },
  methods: {
    getAttributeDetail (jsondata) {
      productapi
        .getAttbuDetail(jsondata)
        .then(data => {
          this.attbudetlist = data.sub
        })
        .catch(err => {
          console.log(err)
        })
    },
    show (parentdata) {
      const self = this
      if (parentdata) {
        self.visible = true
        self.pareattribute = parentdata
        self.getAttributeDetail({ _id: self.pareattribute._id })
      }
    },
    addAttributedetail () {
      const self = this
      self.$refs.attribute.validate(valid => {
        if (valid) {
          const attriFormdata = new FormData()

          attriFormdata.append('name', self.attribute.name)

          attriFormdata.append('id', self.attribute.id)

          attriFormdata.append('pid', self.pareattribute._id)
          productapi
            .saveAttribute(attriFormdata)
            .then(res => {
              self.attribute.pid = res.pid
              self.attribute.name = null
              self.attribute.id = null
              self.getAttributeDetail({ _id: self.pareattribute._id })
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },
    emptyAttribute () {
      this.attribute = {}
    }
  }
}
</script>
<style>
.label {
  margin-left: 40px;
}
</style>
