<template>
  <el-dialog
    title="编辑明细"
    :visible.sync="visible"
    :show-close="true"
    width="350px"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-form :inline="true" :model="formdata" label-width="120px" size="small">
      <el-form-item label="SKU"> &nbsp;&nbsp;{{ formdata.sku }} </el-form-item>
      <el-form-item label="库存">
        &nbsp;&nbsp;{{ formdata.inventoryqty }}
      </el-form-item>
      <el-form-item label="QTY">
        <el-input-number :min="1" v-model="formdata.qty"></el-input-number>
      </el-form-item>
      <el-form-item label="采购装箱率">
        &nbsp;&nbsp;{{ formdata.stockpkgRate }}
      </el-form-item>
      <el-form-item label="装箱率">
        &nbsp;&nbsp;{{ formdata.pkgRate }}
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="saveShipment()"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as fbaAPI from '@/api/fbaship'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        qty: '',
        inventoryqty: 0
      }
    }
  },
  methods: {
    show (formdata) {
      this.visible = true
      this.formdata.ShipmentId = formdata.ShipmentId
      this.formdata.product_id = formdata.product_id
      this.formdata.qty = formdata.QuantityShipped
      this.formdata.sku = formdata.SellerSKU
      this.formdata.inventoryqty = formdata.inventory
      this.formdata.stockpkgRate = formdata.purchase_qty_ctn
      this.formdata.pkgRate = formdata.QuantityInCase
    },
    closedone (done) {
      this.formdata = {
        qty: '',
        inventoryqty: 0
      }
      done()
    },
    saveShipment () {
      fbaAPI
        .editShipmentItem({
          ShipmentId: this.formdata.ShipmentId,
          product_id: this.formdata.product_id,
          qty: this.formdata.qty,
          sku: this.formdata.sku
        })
        .then(data => {
          this.$emit('edit-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
