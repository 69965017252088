<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">库存</el-breadcrumb-item>
        <el-breadcrumb-item>库存变动明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="conten-row">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select v-model="search.saas" placeholder="saas" clearable>
              <el-option
                v-for="item in sysConfig.Common.saas"
                :key="item.key"
                :value="item.val.id"
                :label="item.val.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="search.mfn" placeholder="mfn"></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="search.term"
              type="month"
              value-format="yyyyMM"
              placeholder="请选择年月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getInventoryChangeDetail()"
              >搜索</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="downloadLink()"
              >导出下载</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe :data="changeDetailList" border style="width:100%">
        <el-table-column prop="_product.mfn" label="MFN"></el-table-column>
        <el-table-column prop="_product.name" label="品名"></el-table-column>
        <el-table-column prop="order_type" label="业务类型"></el-table-column>
        <el-table-column prop="date" label="时间"></el-table-column>
        <el-table-column prop="order_id" label="仓库单号"></el-table-column>
        <el-table-column prop="qty" label="数量"></el-table-column>
        <el-table-column prop="price" label="业务单价"></el-table-column>
        <el-table-column prop="amount" label="业务金额"></el-table-column>
        <el-table-column prop="cost" label="成本单价"></el-table-column>
        <el-table-column prop="cost_amount" label="成本金额"></el-table-column>
        <el-table-column prop="inventory" label="结余数量"></el-table-column>
        <el-table-column
          prop="inventory_amount"
          label="结余成本"
        ></el-table-column>
        <el-table-column prop="no" label="业务单号"></el-table-column>
        <el-table-column prop="saas" label="业务主体">
          <template slot-scope="scope">
            {{ getSaasName(scope.row.saas) }}
          </template>
        </el-table-column>
        <el-table-column prop="term" label="账期"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 200, 300]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import * as inventoryBalanceMonthApi from '@/api/inventoryMangement'
export default {
  data () {
    return {
      search: {
        saas: '',
        mfn: '',
        term: ''
      },
      pageData: {
        current_page: 1,
        per_page: 100
      },
      changeDetailList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    const date = new Date()
    this.search.term =
      date.getFullYear().toString() + ('0' + (date.getMonth() + 1)).slice(-2)
    this.getInventoryChangeDetail()
  },
  methods: {
    getSaasName (value) {
      const self = this
      const saas = self.sysConfig.Common.saas
      for (let i = 0; i < saas.length; i++) {
        if (saas[i].val.id === value) {
          return saas[i].val.name
        }
      }
    },
    getInventoryChangeDetail () {
      const josndata = {
        saas: this.search.saas,
        mfn: this.search.mfn,
        term: this.search.term,
        per_page: this.pageData.per_page,
        page: this.pageData.current_page
      }
      inventoryBalanceMonthApi
        .getInventorychangeDetailList(josndata)
        .then(data => {
          this.changeDetailList = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getInventoryChangeDetail()
    },
    changeSizeHandler (size) {
      this.pageData.per_page = size
      this.getInventoryChangeDetail()
    },
    downloadLink () {
      const url =
        baseUrl +
        'finance/inventory/changeDetail/export?token=' +
        localStorage.getItem('token') +
        '&saas=' +
        this.search.saas +
        '&mfn=' +
        this.search.mfn +
        '&term=' +
        this.search.term
      window.open(url)
    }
  }
}
</script>
