import request from '../config/request'

export const getamazonProductList = data => {
  return request('amazon/product', 'get', data, true).then(data => {
    return data
  })
}

export const editProductDetail = data => {
  return request('sales/product/edit', 'post', data, true)
}

export const stripepay = data => {
  return request('stripe/pay', 'post', data).then(data => {
    return data
  })
}

export const getWayfairRefundlist = data => {
  return request('wayfair/refund/ls', 'get', data, true).then(data => {
    return data
  })
}

export const getWayfairRefundDetail = data => {
  return request('wayfair/refund/detail', 'get', data, true).then(data => {
    return data
  })
}

export const apiTwusaRefund = data => {
  return request('wayfair/refund/apiTwusaRefund', 'post', data).then(data => {
    return data
  })
}

export const editWayfairRefund = data => {
  return request('wayfair/refund/edit', 'post', data).then(data => {
    return data
  })
}

export const getInventory = data => {
  return request('ec/inventory', 'get', data, true).then(data => {
    return data
  })
}

export const getSaleList = data => {
  return request('sales/wayfair', 'get', data, true).then(data => {
    return data
  })
}
// 批量审核
export const confirmList = data => {
  return request('sales/wayfair/confirm', 'post', data, true).then(data => {
    return data
  })
}

// 审核订单
export const confirmDetail = data => {
  return request('sales/wayfair/detail/confirm', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const manuShip = data => {
  return request('wayfair/sale/ship', 'post', data, true).then(data => {
    return data
  })
}

export const getWayfairProductList = data => {
  return request('wayfair/product/ls', 'get', data, true).then(data => {
    return data
  })
}

export const getWayfairProductDetail = data => {
  return request('sales/product/detail', 'get', data, true).then(data => {
    return data
  })
}
//  借出库存
export const borrowWayfair = data => {
  return request('sales/product/borrowInventory ', 'post', data).then(data => {
    return data
  })
}
//  归还库存
export const returnInventory = data => {
  return request('sales/product/returnInventory', 'post', data).then(data => {
    return data
  })
}

export const getSalesProductList = data => {
  return request('sales/product', 'get', data, true).then(data => {
    return data
  })
}
//  商品ec筛选列表
export const getSalesEcList = data => {
  return request('sales/product/getEcList', 'get', data, true).then(data => {
    return data
  })
}
//  FBA补货报告
export const getReplenishmentfbaReport = data => {
  return request('sales/replenishment/fbaReport', 'get', data, true).then(
    data => {
      return data
    }
  )
}
//  刷新策略 FBA补货报告
export const runStrategy = data => {
  return request('sales/strategy/runStrategy', 'post', data).then(data => {
    return data
  })
}
//  创建FbaShipment
export const createShipment = data => {
  return request('sales/replenishment/createShipment', 'post', data).then(
    data => {
      return data
    }
  )
}
//  可以追加的FbaInboundShipment列表
export const getfbaInboundShipmentList = data => {
  return request(
    'sales/replenishment/fbaInboundShipmentList',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}
//  追加至FbaShipment
export const addappendToShipment = data => {
  return request('sales/replenishment/appendToShipment', 'post', data).then(
    data => {
      return data
    }
  )
}
//  wayfair销售单详情
export const getWayfairSaleDetail = data => {
  return request('sales/wayfair/detail', 'get', data, true).then(data => {
    return data
  })
}
//  wayfair销售单编辑
export const editWayfairSale = data => {
  return request('wayfair/sale/editOrder', 'post', data, true).then(data => {
    return data
  })
}
//  编辑销售单备注
// export const editWayfairSaleRemark = (data) => {
//   return request('wayfair/sale/orderRemark', 'post', data, true).then((data) => {
//     return data
//   })
// }
//  批量设置状态&备注
export const editWayfairSaleRemark = data => {
  return request('sales/wayfair/setStatus', 'post', data, true).then(data => {
    return data
  })
}
// 设置状态&备注详情
export const editWayfairSaleDetailRemark = data => {
  return request('sales/wayfair/detail/setStatus', 'post', data, true).then(
    data => {
      return data
    }
  )
}
// 取消销售单item第三方出库单
// export const cancelOutOrder = (data) => {
//   return request('wayfair/sale/cancelOutOrder', 'post', data, true).then((data) => {
//     return data
//   })
// }
export const cancelOutOrder = data => {
  return request('sales/wayfair/detail/cancel', 'post', data, true).then(
    data => {
      return data
    }
  )
}
// 重新发货
export const reship = data => {
  return request('sales/wayfair/detail/reship', 'post', data, true).then(
    data => {
      return data
    }
  )
}
// 下载订单
export const downloadOrder = data => {
  return request('wayfair/sale/downloadOrder', 'post', data).then(data => {
    return data
  })
}
// 下载PDF
export const downloadPDF = data => {
  return request('wayfair/sale/downloadPdfNew', 'post', data).then(data => {
    return data
  })
}
// 发货
export const shipTo = data => {
  return request('wayfair/sale/shipTo', 'post', data).then(data => {
    return data
  })
}

// 更新订单地址
export const editAddr = data => {
  return request('wayfair/sale/editAddr', 'post', data).then(data => {
    return data
  })
}

//  上架or下架商品
export const upOrDownProduct = data => {
  return request('sales/product/upOrDownProduct', 'post', data).then(data => {
    return data
  })
}
// 编辑标签属性
export const editProductLabel = data => {
  return request('sales/product/setTag', 'post', data).then(data => {
    return data
  })
}
//  批量删除标签
export const deleteProductTag = data => {
  return request('sales/product/deleteTag', 'post', data).then(data => {
    return data
  })
}

//  获取策略列表
export const getstrategyList = data => {
  return request('sales/strategy/ls', 'get', data)
}
//  策略明细
export const getstrategyDetail = data => {
  return request('sales/strategy/detail', 'get', data)
}
//  添加策略
export const addstrategy = data => {
  return request('sales/strategy/add', 'post', data)
}
// 修改策略
export const alterstartegy = data => {
  return request('sales/strategy/edit', 'post', data)
}
// 库存明细列表
export const getSalesInventoryLs = data => {
  return request('sales/inventory/ls', 'get', data)
}
// 库存调拨列表
export const getSalesIntoryTransLs = data => {
  return request('sales/inventory/transferLs', 'get', data)
}
// 创建调拨单
export const createSalesTransfer = data => {
  return request('sales/inventory/createTransfer', 'post', data)
}
// 调拨单明细
export const getSalesTransferDetail = data => {
  return request('sales/inventory/transferDetail', 'get', data)
}
//  确认调拨单
export const confirmSalesTransfer = data => {
  return request('sales/inventory/confirmTransfer', 'post', data)
}
// 编辑调拨单
export const editSalesTransfer = data => {
  return request('sales/inventory/editTransfer', 'post', data)
}
// 删除调拨单
export const deleteSalesTransfer = data => {
  return request('sales/inventory/delTransfer', 'post', data)
}
// 需催单发货清单
export const shipmentList = data => {
  return request('logistics/plan/toPlanList', 'get', data)
}
// 催单发货
export const saveLogisticsApply = data => {
  return request('logistics/plan/saveApply', 'post', data)
}
// 撤销催单记录
export const cancelApply = data => {
  return request('/logistics/plan/cancelApply', 'post', data)
}
export const setWarehouse = data => {
  return request('sales/wayfair/setWarehouse', 'post', data)
}
