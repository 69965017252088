<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>WMS</el-breadcrumb-item>
        <el-breadcrumb-item>海外仓</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs">
      <el-tabs v-model="tabvalue" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in sysConfig.ECInventoryTransferPlan.step"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        >
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row >
      <el-table stripe border :data="allotlist" width="100%">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column
          label="平台"
          prop="_ec"
          v-if="tabvalue === '2'"
        ></el-table-column>
        <el-table-column label="仓库" prop="_warehouse_from">
          <template slot-scope="scope">
            {{ scope.row._warehouse_from }} - {{ scope.row._warehouse_to }}
          </template>
        </el-table-column>
        <el-table-column label="计划日期" prop="date"></el-table-column>
        <el-table-column label="创建日期" prop="created_at"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="tabvalue === '2'"
              @click="$refs['add-allot-dialog'].show(scope.row.id)"
              >添加</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="tabvalue === '2'"
              @click="$refs['create-allot-dialog'].show(scope.row.id, false, scope.row.warehouse_from, scope.row.warehouse_to, scope.row.date, scope.row.remark)"
              >创建</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="tabvalue != '2'"
              @click="pushDetail(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
    <create-allot-dialog
      ref="create-allot-dialog"
      @create-success="getOverseaAllotList()"
    ></create-allot-dialog>
    <add-allot-dialog
      ref="add-allot-dialog"
      @add-succcess="getOverseaAllotList()"
    ></add-allot-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getOverseaAllotList } from '@/api/warehouse'
import createAllotDialog from './create-allot-dialog'
import addAllotDialog from './add-allot-dialog'
export default {
  components: {
    createAllotDialog,
    addAllotDialog
  },
  data () {
    return {
      tabvalue: '2',
      allotlist: [],
      pageDat: {
        currentPage: 1,
        perPage: 10
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getOverseaAllotList()
  },
  methods: {
    getOverseaAllotList () {
      const jsondata = {
        step: this.tabvalue,
        page: this.pageDat.currentPage,
        per_page: this.pageDat.perPage
      }
      getOverseaAllotList(jsondata)
        .then(data => {
          this.allotlist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageDat.currentPage = currentPage
    },
    changeSizeHandler (size) {
      this.pageDat.perPage = size
    },
    handleClick () {
      this.pageDat.currentPage = 1
      this.getOverseaAllotList()
    },
    pushDetail (id) {
      this.$router.push({
        name: 'overseaAllotPlanDetail',
        params: {
          id: id
        }
      })
    }
  }
}
</script>
