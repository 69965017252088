<template>
  <div class="forward-plan">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>物流管理</el-breadcrumb-item>
        <el-breadcrumb-item>货代计划</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select
            clearable
            v-model="search.warehouse"
            placeholder="选择仓库"
          >
            <el-option
              v-for="item in wareshouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交期：">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.transport_type"
            placeholder="运输方式"
          >
            <el-option
              v-for="item in transportList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            class="search-bar"
            v-model="search.order_id"
            placeholder="采购单"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.expected" placeholder="预计到货">
            <el-option
              v-for="item in expectedlist"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.shipable" placeholder="可发数量">
            <el-option
              v-for="item in shipablelist"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-table
      :data="datList"
      stripe
      border
      style="width: 100%"
      @select="handleSelect"
      @select-all="handleSelectall"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="货品" mini-width="25%">
        <template slot-scope="scope">
          <product-info-in-table-cell
            :cover="scope.row._product_cover"
            :title="scope.row._product_name"
            :mfn="scope.row._product_mfn"
          ></product-info-in-table-cell>
        </template>
      </el-table-column>
      <el-table-column label="仓库运输" width="150">
        <template slot-scope="scope">
          <p>
            {{ scope.row.warehouse_name }}<br />{{ scope.row._transport_type }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="总数 / 箱数 / 装箱率" width="160">
        <template slot-scope="scope">
          {{ scope.row.qty }} / {{ scope.row.ctn }} /
          {{ scope.row.qty_ctn }}
        </template>
      </el-table-column>
      <el-table-column
        prop="order_id"
        label="采购单"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="ship_date"
        label="交期"
        width="99"
      ></el-table-column>
      <el-table-column
        prop="expected_date"
        label="预计到货日"
        width="100"
      ></el-table-column>
      <el-table-column prop="expected_ctn" label="预计到货数" width="99">
        <template v-slot="scope">
          <span>{{ scope.row.expected_ctn }}</span>
          <el-tag v-show="scope.row.sellpack > 1"
            >套{{ scope.row.sellpack }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="shipable_ctn"
        width="99"
        label="可发"
      ></el-table-column>
      <el-table-column prop="_status" width="99" label="状态"></el-table-column>
      <el-table-column label="未排 / 已排" width="99">
        <template v-slot="scope">
          {{ scope.row.unplanned_ctn }}箱 / {{ scope.row.planned_ctn }}箱
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        width="99"
        label="备注"
        sortable
      ></el-table-column>
      <el-table-column label="发货数" width="200" justify="space-between">
        <template v-slot="scope">
          <el-row type="flex" align="middle">
            <div>
              <el-input-number
                v-if="scope.row.checked"
                controls-position="right"
                size="mini"
                style="width: 99px;"
                :min="scope.row.shipable_ctn ? 1 : 0"
                :max="scope.row.shipable_ctn"
                v-model="scope.row.x"
              ></el-input-number>
              <p v-else>/</p>
            </div>
            <div style="margin-left:10px" v-show="scope.row.checked">
              <p>10 kg</p>
              <p>2000 ㎡</p>
            </div>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <br />
    <el-row>
      <el-col :span="6">
        <div>
          <el-button type="primary" @click="createOrder">创建货代单</el-button>
          <el-button type="primary" @click="addTo">添加至货代单</el-button>
        </div>
      </el-col>
    </el-row>

    <el-row class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[15, 20, 30, 50, 100, 200]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.num_per_page"
      ></el-pagination>
    </el-row>

    <create-forward-dialog
      ref="create-forward-dialog"
      :selectItem="selectItem"
      @change="change"
    />
    <add-to-forward-dialog
      ref="add-to-forward-dialog"
      :selectItem="selectItem"
      @change="change"
    />
  </div>
</template>
<script>
import * as forwardApi from '@/api/forward'
import createForwardDialog from './create-forward-dialog.vue'
import addToForwardDialog from './add-to-forward-dialog.vue'
import { mapGetters } from 'vuex'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
export default {
  components: {
    createForwardDialog,
    addToForwardDialog,
    productInfoInTableCell
  },
  data () {
    return {
      selectedList: [],
      selectItem: [],
      datList: [],
      wareshouseList: [],
      transportList: [],
      search: {
        warehouse: '',
        transport_type: '',
        order_id: '',
        start: '',
        end: '',
        expected: '',
        shipable: '',
        page: 1,
        num_per_page: 15,
        total: 0
      },
      date: [],
      expectedlist: [
        { key: '0', val: '否' },
        { key: '1', val: '预计到货' }
      ],
      shipablelist: [
        { key: '0', val: '不可发' },
        { key: '1', val: '是可发' }
      ]
    }
  },
  watch: {
    date: function (val) {
      if (val == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = val[0]
        this.search.end = val[1]
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getDatList()
    this.wareshouseList = this.sysConfig.warehouselist
    this.transportList = this.sysConfig.Common.transport_type
  },
  methods: {
    getDatList () {
      forwardApi.forwardPlan(this.search).then(res => {
        this.datList = res.data
        // res.data.map(item => { this.datList.push(Object.assign({}, item, { checked: false })) })
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.num_per_page = parseInt(res.per_page)
      })
    },
    searchHandle () {
      this.getDatList()
    },
    createOrder () {
      if (!this.checkSelected()) {
        return false
      }
      this.$refs['create-forward-dialog'].show()
    },
    addTo () {
      if (!this.checkSelected()) {
        return false
      }
      this.$refs['add-to-forward-dialog'].show()
    },
    checkSelected () {
      if (this.selectedList.length === 0) {
        this.$alert('请至少勾选一个^_^')
        return false
      }
      if (
        !this.selectedList.every(item => {
          return item.x
        })
      ) {
        this.$alert('请填写数量！')
        return false
      }
      this.selectItem = this.selectedList.map(item => {
        return {
          id: item.id,
          x: item.x
        }
      })
      return true
    },
    // 选中checkbox项的回调
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
      } else {
        row.checked = true
        row.x = row.shipable_ctn
      }
      this.selectedList = selection
    },
    //  全选回调函数
    handleSelectall (selection) {
      const self = this
      if (selection.length > 0) {
        for (let i = 0; i < self.datList.length; i++) {
          self.datList[i].checked = true
          self.datList[i].x = self.datList[i].shipable_ctn
        }
      } else {
        for (let i = 0; i < self.datList.length; i++) {
          self.datList[i].checked = false
        }
      }
      self.selectedList = selection
    },
    change () {
      this.getDatList()
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.getDatList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.num_per_page = pagesize
      this.getDatList()
    }
  }
}
</script>

<style></style>
