import { reactive, toRaw, ref } from '@vue/composition-api'
import { httpClient } from '@/core'
import { CONFIG_MAP } from './config'
import { generateParams } from './util'

function parseNumber (source) {
  if (source === null || source === '') {
    return source
  }

  return isNaN(source)
    ? source
    : Number(source)
}

function convertTableData (header = {}, list = []) {
  const keys = Object.keys(header)
  if (!keys.length) {
    return []
  }
  const h = Object.values(header)
  const rows = list.map(li => {
    return keys.map(key => {
      const v = parseNumber(li[key])
      return v !== null ? v : ''
    })
  })

  return {
    headers: h,
    rows
  }
}

export default function (type) {
  const { url, fields } = CONFIG_MAP[type]

  const fetchState = reactive({
    // 总数
    total: 0,
    // 当前页
    index: 0,
    // 正在下载的数据范围
    perPage: 500,
    // 下载用时 毫秒
    elapsedTime: 0,
    isFetching: false,
    message: null
  })

  const interrupt = ref(null)

  const model = reactive(generateParams(fields))

  const fetchHeader = () => {
    return httpClient.get(url, { per_page: -1 })
      .then(res => {
        // 使用服务端给的信息作为文件名
        CONFIG_MAP[type].filename = res.msg || CONFIG_MAP[type].filename
        return res.dat
      })
  }

  const fetchList = () => {
    const pagingData = {
      per_page: fetchState.perPage,
      page: fetchState.index
    }

    const p = Object.assign(toRaw(model), pagingData)
    const list = []

    return new Promise((resolve, reject) => {
      const fetchOnce = () => {
        fetchState.index += 1
        p.page = fetchState.index
        const { cancel, run } = httpClient.cGet()
        interrupt.value = cancel
        run(url, p, { timeout: 0 })
          .then(res => {
            const { data = [], total = 0 } = res.dat
            fetchState.total = total
            if (total !== 0) {
              list.push(...data)
            }

            if (total > list.length) {
              fetchOnce()
            } else {
              // 拉取完成后 释放 中断方法
              interrupt.value = null
              resolve(list)
            }
          })
          .catch(reject)
      }

      fetchOnce()
    })
  }

  const setParams = (params = {}) => {
    Object.keys(params).forEach(key => {
      model[key] = params[key]
    })
  }

  const reset = () => {
    // 总数
    fetchState.total = 0
    // 当前页
    fetchState.index = 0
    // 下载用时 毫秒
    fetchState.elapsedTime = 0
    fetchState.isFetching = false
    fetchState.message = null

    const defaultParams = generateParams(fields)
    for (const k in defaultParams) {
      model[k] = defaultParams[k]
    }
  }

  // 根据type 返回接口地址并组装表格最终数据
  const getXlsxData = async () => {
    const [headers, rows] = [[], []]
    try {
      fetchState.isFetching = true
      fetchState.elapsedTime = performance.now()
      const headerData = await fetchHeader()
      const list = await fetchList()
      const { headers: h, rows: r } = convertTableData(headerData, list)
      headers.push(...h)
      rows.push(...r)
    } catch (e) {
      console.error(e)
      fetchState.message = e.message
      // if (e.code === httpClient.ERROR_CODE.BUSINESS) {
      //   // 业务错误
      //   fetchState.message = e.message
      // }
      throw e
    } finally {
      fetchState.elapsedTime = performance.now() - fetchState.elapsedTime
      fetchState.isFetching = false
    }

    return {
      headers,
      rows
    }
  }

  return {
    model,
    fetchState,
    getXlsxData,
    setParams,
    reset,
    interrupt
  }
}
