import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/view/auth/Login'
import Index from '../components/index/index'
import productInfo from '../components/product/productlist/index'
import productAttribute from '../components/product/productAttribute/index'
import stockOrder from '../components/stock/stockorder/stock'
import stockEdit from '../components/stock/stockorder/stock-edit'
import stockRecord from '@/components/stock/stockRecord/index'
import purchaseVendor from '../components/stock/vendor/index'
import purchaseVendorDetail from '../components/stock/vendor/detail'

import trackingList from '@/components/stock/tracking/tracking-list'
import prepayment from '@/components/stock/finance/prepayment/index'
import prepaymentCreate from '@/components/stock/finance/prepayment/create-prepayment-first-tab'
import prepaymentOrder from '@/components/stock/finance/prepayment/prepayment-order'
import prepaymentOrderDetail from '@/components/stock/finance/prepayment/prepayment-order-detail'
import reimbursement from '@/components/stock/finance/reimbursement/index'
import reimbursementDetail from '@/components/stock/finance/reimbursement/reimbursement-detail'
import reimbursementOrder from '@/components/stock/finance/reimbursement/reimbursement-order'
import reimbursementOrderDetail from '@/components/stock/finance/reimbursement/reimbursement-order-detail'

import wmsWarehouse from '@/components/warehouse/wms/warehouse'
// import wmsWarehouseEditor from '@/components/warehouse/wms/warehouse-editor'
import forwardplan from '../components/logistics/forward/forward-plan'

import wmsStockCost from '@/components/warehouse/Fee/stockCost'

import forwardOrderlist from '../components/logistics/forward/forward-orderlist'
import forwardOrder from '../components/logistics/forward/forward-order'
import systemManage from '../components/index'

// import forwardplanNew from '@/components/logistics/forwardNew/forward-plan'
import forwardOrderlistNew from '@/components/logistics/forwardNew/forward-orderlist'
import forwardOrderNew from '@/components/logistics/forwardNew/forward-order'

import forwardplanNew from '@/components/logistics/forwardNew2021/forward-plan'
import forwardOrderlistNew2021 from '@/components/logistics/forwardNew2021/forward-orderlist'
import forwardOrderNew2021 from '@/components/logistics/forwardNew2021/forward-order'
import logisticsApplyList from '@/components/logistics/apply/order-list'

import prepaymentQuery from '../components/finance/purchase/prepayment-query/index'
import prepaymentQueryDetail from '../components/finance/purchase/prepayment-query/detail'

import financeReimbursementOrder from '../components/finance/purchase/Reimbursement/index'
import financeReimbursementOrderDetail from '../components/finance/purchase/Reimbursement/reimbursement-order-detail'

import turnoverquery from '../components/finance/purchase/turnover-query/index'

import payableBill from '../components/finance/purchase/payableBill/index'
import payableBillDetail from '../components/finance/purchase/payableBill/payable-bill-detail'

import productCost from '../components/finance/inventoryManagement/productCost/index'

import allotCountList from '@/components/finance/warehouseBills/allotCount/index'

import breakageClaims from '@/components/finance/analysisReport/breakageClaims/index'

import rateSet from '@/components/finance/rateSet/index'

import profitStat from '@/components/finance/amazon/profitStat/index'
import paymentDetail from '@/components/finance/amazon/paymentDetail/index'
import paymentList from '@/components/finance/amazon/paymentList'

import stockOutIn from '@/components/warehouse/stockOutIn/index'
import stockInOutDetail from '@/components/warehouse/stockOutIn/stock-in-out-detail'
import allotOutInDetail from '@/components/warehouse/stockOutIn/allot-out-in-detail'

import breakageAdjustDetail from '@/components/warehouse/stockOutIn/breakage-adjust-detail'

import saleorderOutInDetail from '@/components/warehouse/stockOutIn/saleorder-out-in-detail'

import wmsInventory from '../components/warehouse/wmsInventory/index'
import overseaAllotPlan from '@/components/warehouse/overseaAllotPlan/index'
import overseaAllotPlanDetail from '@/components/warehouse/overseaAllotPlan/allot-detail'
import usaWarehouseInventoryUpdate from '@/components/warehouse/usaWarhouseIntoryUpdate/index'

import amazonProduct from '@/components/amazon/product/index'
import amzInventoryAllot from '@/components/amazon/inventoryAllot/index'
import amzInventoryAllotDetail from '@/components/amazon/inventoryAllot/allot-detail'
import amzInventoryAllotCreate from '@/components/amazon/inventoryAllot/create-allot-dialog'
import amzInventoryAllotImport from '@/components/amazon/inventoryAllot/import-detail-dialog'
import amzstripe from '@/components/amazon/stripe/index'
import amawayOrder from '@/components/amazon/wayOrder/index'
// import wayfairRefundList from '@/components/amazon/returnWayOrder'
import wayfairRefundDetail from '@/components/amazon/wayfairRefund/wayfair-refund-detail'
// import wayfairRefundDetail from '@/components/amazon/returnWayOrder/sales-return-detail'
import fbaInboundShipment from '@/components/warehouse/FBA'
import fbaplanDetail from '@/components/warehouse/FBA/fba-plan-detail'
import fbauncommitDetail from '@/components/warehouse/FBA/fba-uncommit-detail'
import fbalogistFeeDetail from '@/components/warehouse/FBA/fba-logistfee-detail'
import inventoryChangeDetail from '@/components/finance/inventoryManagement/inventoryChangeDetail/index'
import overseaShipment from '@/components/stock/tracking/shipment-list'
import overseaInventory from '@/components/amazon/oversea/inventory'

import wayfairProduct from '@/components/amazon/wayProduct/index'
import wayfairInventoryDetail from '@/components/amazon/wayInventoryDetail/index'
import wayfairInventoryallot from '@/components/amazon/wayInventoryAllot/index'
import wayfairInventoryallotdetail from '@/components/amazon/wayInventoryAllot/allot-detail'
import shopifyProduct from '@/components/amazon/shopify/product/index'
import fbaReplenish from '@/components/amazon/replenish/fbaReplenish'
import strategySet from '@/components/amazon/replenish/strategySet'
import strategySetDetail from '@/components/amazon/replenish/strategySet/strategy-set-detail'

import sdwProduct from '@/components/amazon/sdw/sdwProduct/index'
import sdwInventoryallot from '@/components/amazon/sdw/sdwInventoryAllot/index'
import sdwInventoryallotdetail from '@/components/amazon/sdw/sdwInventoryAllot/allot-detail'

import qcentersaleOut from '@/components/qcenterQuery/WMS/SalesOut'
import qcenterdisposal from '@/components/qcenterQuery/WMS/disposal'
import qcenterSaleShopInfo from '@/components/qcenterQuery/sales/saleShopInfo'

import purchaseFinanceDelievryDetail from '@/components/stock/financeNew/deliveryDetail'
import reimbursementOrderNew from '@/components/stock/financeNew/reimbursement/index'
import reimbursementOrderDetailNew from '@/components/stock/financeNew/reimbursement/reimbusement-order-detail'
import prepaymentNew from '@/components/stock/financeNew/prepayment/index'
import prepaymentCreateNew from '@/components/stock/financeNew/prepayment/create-prepayment-first-tab'
import prepaymentOrderNew from '@/components/stock/financeNew/prepayment/prepayment-order'
import prepaymentOrderDetailNew from '@/components/stock/financeNew/prepayment/prepayment-order-detail'

import financeReimbursementOrderNew from '@/components/finance/purchaseNew/reimbursement/index'
import financeReimbursementOrderNewDetail from '@/components/finance/purchaseNew/reimbursement/reimbursement-order-detail'
import prepaymentOrderlistNew from '@/components/finance/purchaseNew/cashOut/prepayment-order'
import prepaymentOrderlistNewDetail from '@/components/finance/purchaseNew/cashOut/prepayment-order-detail'
import payableBillNew from '@/components/finance/purchaseNew/payableBill/index'
import payableBillNewDetail from '@/components/finance/purchaseNew/payableBill/payable-bill-detail'

import financeRoutes from './finance'
import salesRoutes from './sales'
import dataCenterRoutes from './dataCenter'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    { path: '/login', name: 'Login', component: Login },
    {
      path: '/',
      component: Index,
      children: [
        { path: '', component: systemManage },
        { path: '/system-manage', component: systemManage },
        { path: '/product/productInfo', component: productInfo },
        { path: '/product/productAttribute', component: productAttribute },

        {
          path: '/stock/stockOrder',
          component: stockOrder,
          meta: { keepAlive: false }
        },
        { path: '/stock/stockEdit', component: stockEdit, name: 'stockEdit' },
        { path: '/stock/record', component: stockRecord },
        {
          path: '/purchase/vendor',
          component: purchaseVendor,
          name: 'purchase-vendor'
        },
        {
          path: '/purchase/vendor/detail',
          component: purchaseVendorDetail,
          name: 'purchase-vendor-detail'
        },

        { path: '/stock/tracking/tracking-set', component: trackingList },

        {
          path: '/stock/finance/prepayment',
          component: prepayment,
          name: 'prepayment'
        },
        {
          path: '/stock/finance/prepayment/create',
          component: prepaymentCreate,
          name: 'prepaymentCreate'
        },

        {
          path: '/stock/finance/prepayment/order',
          component: prepaymentOrder,
          name: 'prepaymentOrder'
        },
        {
          path: '/stock/finance/prepayment/order/detail',
          component: prepaymentOrderDetail,
          name: 'prepaymentOrderDetail'
        },

        {
          path: '/stock/finance/reimbursement',
          component: reimbursement,
          name: 'reimbursement'
        },
        {
          path: '/stock/finance/reimbursement/detail',
          component: reimbursementDetail,
          name: 'reimbursementDetail'
        },

        {
          path: '/stock/finance/reimbursement/order',
          component: reimbursementOrder,
          name: 'reimbursementOrder'
        },
        {
          path: '/stock/finance/reimbursement/order/detail',
          component: reimbursementOrderDetail,
          name: 'reimbursementOrderDetail'
        },

        {
          path: '/stock/finance/delivery/detail',
          component: purchaseFinanceDelievryDetail
        },
        {
          path: '/stock/finance/reimbursementNew/order',
          component: reimbursementOrderNew,
          name: 'reimbursementOrderNew'
        },
        {
          path: '/stock/finance/reimbursementNew/detail',
          component: reimbursementOrderDetailNew,
          name: 'reimbursementOrderDetailNew'
        },
        {
          path: '/stock/financeNew/prepayment',
          component: prepaymentNew,
          name: 'prepaymentNew'
        },
        {
          path: '/stock/financeNew/prepayment/create',
          component: prepaymentCreateNew,
          name: 'prepaymentCreateNew'
        },
        {
          path: '/stock/financeNew/prepayment/order',
          component: prepaymentOrderNew,
          name: 'prepaymentOrderNew'
        },
        {
          path: '/stock/financeNew/prepayment/order/detail',
          component: prepaymentOrderDetailNew,
          name: 'prepaymentOrderDetailNew'
        },

        { path: '/wms/warehouse', component: wmsWarehouse },

        {
          path: '/logistmanage/forward/orderlist',
          component: forwardOrderlist
        },
        {
          path: '/logistmanage/forward/order',
          component: forwardOrder,
          name: 'forwardOrder'
        },
        { path: '/logistmanage/forward/plan', component: forwardplan },
        { path: '/logistmanage/forward/plan/new', component: forwardplanNew },
        {
          path: '/logistmanage/forward/orderlist/new',
          component: forwardOrderlistNew
        },
        {
          path: '/logistmanage/forward/orderlist/new2021',
          component: forwardOrderlistNew2021
        },
        {
          path: '/logistmanage/forward/order/new',
          component: forwardOrderNew,
          name: 'forwardOrderNew'
        },
        {
          path: '/logistmanage/forward/order/new2021',
          component: forwardOrderNew2021,
          name: 'forwardOrderNew2021'
        },
        {
          path: '/logistmanage/apply-list',
          component: logisticsApplyList
        },
        {
          path: '/finance/purchase/prepayment-query',
          component: prepaymentQuery
        },
        {
          path: '/finance/purchase/prepayment-query/detail',
          component: prepaymentQueryDetail,
          name: 'prepaymentQueryDetail'
        },

        // {
        //   path: '/finance/purchase/prepayment-order',
        //   component: prepaymentOrderlist
        // },
        // {
        //   path: '/finance/purchase/prepayment-orderdetail',
        //   component: prepaymentOrderlistDetail,
        //   name: 'prepaymentOrderlistDetail'
        // },

        {
          path: '/finance/purchase/reimbursement',
          component: financeReimbursementOrder
        },
        {
          path: '/finance/purchase/reimbursement/detail',
          component: financeReimbursementOrderDetail,
          name: 'financeReimbursementOrderDetail'
        },

        {
          path: '/finance/purchaseNew/reimbursement',
          component: financeReimbursementOrderNew
        },
        {
          path: '/finance/purchaseNew/reimbursement/detail',
          component: financeReimbursementOrderNewDetail,
          name: 'financeReimbursementOrderNewDetail'
        },

        {
          path: '/finance/purchaseNew/prepayment-order',
          component: prepaymentOrderlistNew
        },
        {
          path: '/finance/purchaseNew/prepayment-orderdetail',
          component: prepaymentOrderlistNewDetail,
          name: 'prepaymentOrderlistNewDetail'
        },

        { path: '/finance/purchase/turnover-query', component: turnoverquery },

        { path: '/finance/purchase/payable-bill', component: payableBill },
        {
          path: '/finance/purchase/payablebill/detail',
          component: payableBillDetail,
          name: 'payableBillDetail'
        },

        {
          path: '/finance/purchaseNew/payable-bill',
          component: payableBillNew
        },
        {
          path: '/finance/purchaseNew/payablebill/detail',
          component: payableBillNewDetail,
          name: 'payableBillNewDetail'
        },

        {
          path: '/finance/inventoryManagement/product-cost',
          component: productCost
        },
        {
          path: '/finance/inventoryManagement/inventory-change-detail',
          component: inventoryChangeDetail
        },

        ...financeRoutes,
        ...salesRoutes,
        ...dataCenterRoutes,

        {
          path: '/finance/warehousebills/allot-count',
          component: allotCountList
        },

        { path: '/finance/rate-set', component: rateSet },

        { path: '/finance/amazon/profit', component: profitStat },
        { path: '/finance/amazon/paymentDetail', component: paymentDetail },
        { path: '/finance/amazon/paymentList', component: paymentList },

        {
          path: '/finance/anreport/breakage/claims',
          component: breakageClaims
        },

        { path: '/wms/warehouse/stock-out-in', component: stockOutIn },

        {
          path: '/wms/warehouse/stock-out-in-detail',
          component: stockInOutDetail,
          name: 'stockInOutDetail'
        },

        {
          path: '/wms/warehouse/allot-out-in-detail',
          component: allotOutInDetail,
          name: 'allotOutInDetail'
        },

        {
          path: '/wms/warehouse/breakage-adjust-detail',
          component: breakageAdjustDetail,
          name: 'breakageAdjustDetail'
        },

        {
          path: '/wms/warehouse/saleorder-out-in-detail',
          component: saleorderOutInDetail,
          name: 'saleorderOutInDetail'
        },

        { path: '/wms/fee/stockcost', component: wmsStockCost },

        { path: '/oversea/allot-plan', component: overseaAllotPlan },
        {
          path: '/oversea/allot-plan-detail',
          component: overseaAllotPlanDetail,
          name: 'overseaAllotPlanDetail'
        },

        { path: '/wms/warehouse/inventory', component: wmsInventory },
        { path: '/FBA/inbound-shipment', component: fbaInboundShipment },
        {
          path: '/FBA/fba-plan-detail',
          component: fbaplanDetail,
          name: 'fbaplanDetail'
        },
        {
          path: '/FBA/fba-commit-detail',
          component: fbauncommitDetail,
          name: 'fbauncommitDetail'
        },
        {
          path: '/FBA/fba-logistfee-detail',
          component: fbalogistFeeDetail,
          name: 'fbalogistFeeDetail'
        },

        {
          path: '/usa-warehouse/inventory/update',
          component: usaWarehouseInventoryUpdate
        },

        { path: '/amazon/product', component: amazonProduct },
        { path: '/amazon/inventory/allot', component: amzInventoryAllot },
        { path: '/amazon/inventory/allot-detail', component: amzInventoryAllotDetail, name: 'amzInventoryAllotDetail' },
        { path: '/amazon/inventory/allot-create', component: amzInventoryAllotCreate },
        { path: '/amazon/inventory/allot-import', component: amzInventoryAllotImport },
        { path: '/amazon/stripe', component: amzstripe },
        { path: '/amazon/wayOrder', component: amawayOrder },
        {
          path: '/amazon/wayfair-refund-detail',
          component: wayfairRefundDetail,
          name: 'wayfairRefundDetail'
        },
        { path: '/amazon/oversea/apply_shipment', component: overseaShipment },
        { path: '/amazon/oversea/inventory', component: overseaInventory },

        // {
        //   path: '/wayfair/sales/detail',
        //   component: wayfairSalesDetail,
        //   name: 'wayfairSalesDetail'
        // },
        {
          path: '/wayfair/inventory/detail/:ec_id',
          component: wayfairInventoryDetail
        },
        {
          path: '/wayfair/inventory/allot/:ec_id',
          component: wayfairInventoryallot
        },
        {
          path: '/wayfair/inventory/allot/detail',
          component: wayfairInventoryallotdetail,
          name: 'wayfairInventoryallotdetail'
        },
        { path: '/amazon/wayfair/product', component: wayfairProduct },
        { path: '/amazon/Shopify/product', component: shopifyProduct },
        { path: '/fba/replenish', component: fbaReplenish },
        { path: '/replenish/strategy', component: strategySet },
        {
          path: '/replenish/strategy/detail',
          component: strategySetDetail,
          name: 'strategySetDetail'
        },

        { path: '/sales/sdw/inventory/allot', component: sdwInventoryallot },
        {
          path: '/sdw/inventory/allot/detail',
          component: sdwInventoryallotdetail,
          name: 'sdwInventoryallotdetail'
        },
        { path: '/sales/SDW/product', component: sdwProduct },

        // { path: '/qcenter/query/stockOrder', component: qcenterStockOrder },
        // { path: '/qcenter/query/stockDetail', component: qcenterStockDetail },
        { path: '/qcenter/query/saleOut', component: qcentersaleOut },
        { path: '/qcenter/query/disposal', component: qcenterdisposal },
        { path: '/qcenter/query/saleShopInfo', component: qcenterSaleShopInfo }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const path = to.path
  if (path === '/login') {
    next()
  } else {
    if (localStorage.getItem('token')) {
      // store.dispatch('changeCurrPath', to.path)
      // rootController.getInitialState().then((state) => {
      //   if (state == false) {
      //     router.push({ path: '/initialization' })
      //   } else {
      //     store.dispatch('changeCurrPath', to.path)
      //   }
      // })
      localStorage.setItem('token', localStorage.getItem('token'))
      next()
    } else {
      router.push({ path: '/login' })
      next()
    }
  }
})

export default router
