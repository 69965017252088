<template>
  <div class="stock-detail-fee" style="margin-top:20px;">
    <el-row style="margin-bottom:10px;">
      <el-col :span="4" :offset="18">
        <el-button type="primary" size="mini" @click="show">新增款项</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe ref="feeTable" :data="feeList" border>
        <el-table-column
          prop="remark"
          label="款项"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="金额"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="160"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="deletefee(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog
      ref="addfee-dialog"
      class="addfee-dialog"
      title="新增款项"
      :show-close="true"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="500px"
      :before-close="closeDialog"
    >
      <el-form
        size="small"
        :model="addFee"
        label-width="74px"
        label-position="left"
        ref="addFee"
        id="addFee"
      >
        <el-row>
          <el-col :span="18">
            <el-form-item label="款项" prop="id">
              <el-input v-model="addFee.id"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="金额" prop="price">
              <el-input v-model="addFee.price"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addFee.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          native-type="submit"
          type="primary"
          @click="addstockFee"
          >保存</el-button
        >
        <el-button size="small" @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as stockOrder from '@/api/stockOrder'
export default {
  props: ['stockcode', 'feeList'],
  data () {
    return {
      visible: false,
      addFee: {}
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    addstockFee () {
      const stockFeefomdata = new FormData()
      stockFeefomdata.append('order_id', this.stockcode)
      stockFeefomdata.append('remark', this.addFee.id)
      stockFeefomdata.append('price', this.addFee.price)
      stockOrder
        .addFee(stockFeefomdata)
        .then(data => {
          this.$emit('refreshStockFee')
          this.addFee = {}
        })
        .catch(err => {
          console.log(err)
        })
    },
    deletefee (feeid) {
      const deleFomdata = new FormData()
      deleFomdata.append('id', feeid)
      this.$confirm('确认是否删除该条费用明细？')
        .then(_ => {
          stockOrder
            .deleteFee(deleFomdata)
            .then(data => {
              this.$emit('refreshStockFee')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    closeDialog (done) {
      this.visible = false
    }
  }
}
</script>
