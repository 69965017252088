<template>
  <div class="reimbursement-order">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>报销</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-tabs v-model="search.step" @tab-click="handleTabs">
      <el-tab-pane
        v-for="item in stepList"
        :key="item.key"
        :label="item.val"
        :name="item.key + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <el-row class="content-row">
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-input
            class="search-bar"
            style="width:120px;"
            v-model="search.id"
            placeholder="报销单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in vendor"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:80px;"
            v-model="search.currency"
            placeholder="币种"
          >
            <el-option
              v-for="item in currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="付款"
            end-placeholder="期限"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row class="content-row">
      <el-table
        :data="datList"
        stripe
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="报销单号"></el-table-column>
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column label="收款账号">
          <template v-slot="scope">
            <p>{{ scope.row.bank_name }}</p>
            <p>{{ scope.row.bank_account }} ({{ scope.row.bank }})</p>
          </template>
        </el-table-column>
        <el-table-column label="总金额">
          <template v-slot="scope">
            <span>{{ scope.row.amount }} {{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deadline" label="付款期限"></el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <el-button type="text" @click="onEdit(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="content-row">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[15, 20, 30, 50, 100, 200]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.num_per_page"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { getInvoiceOrder } from '@/api/purchaseFinanceNew'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      ids: [],
      selectedList: [],
      datList: [],
      vendor: [],
      currency: [],
      typeList: [],
      stepList: [],

      search: {
        start: '',
        end: '',
        _date: null,
        vendor_id: '',
        step: '1',
        currency: '',
        page: 1,
        num_per_page: 15,
        total: 0
      },
      date: [],
      vendorName: ''
    }
  },

  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.setSysConfig()
    this.getList()
  },

  methods: {
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    setSysConfig () {
      this.currency = this.sysConfig.Common.currency
      this.vendor = this.sysConfig.vendorList
      this.stepList = this.sysConfig.FinancePaymentInvoiceOrder.step
    },
    getList () {
      getInvoiceOrder(this.search).then(res => {
        this.datList = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.num_per_page = parseInt(res.per_page)
      })
    },
    searchHandle () {
      this.getList()
    },
    handleTabs (tab, event) {
      this.search.step = tab.name
      this.getList()
    },
    onEdit (id) {
      this.$router.push({
        name: 'reimbursementOrderDetailNew',
        query: { id: id }
      })
    },
    handleSelectionChange (val) {
      this.selectedList = val
      this.vendorName = val[0] && val[0].vendor_name
      this.ids = val.map(item => item.id)
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.trackingList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.num_per_page = pagesize
      this.trackingList()
    }
  }
}
</script>

<style>
.content-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
