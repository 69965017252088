<template>
  <div class="navMenu">
    <template v-for="navMenu in navMenus">
      <el-menu-item
        v-if="!navMenu.childs && navMenu.entity"
        :key="navMenu.entity.id"
        :data="navMenu"
        :index="navMenu.entity.route"
        :route="navMenu.entity.route"
      >
        <i :class="navMenu.entity.icon"></i>
        <span slot="title">{{ navMenu.entity.title }}</span>
      </el-menu-item>
      <el-submenu
        v-if="navMenu.childs && navMenu.entity"
        :key="navMenu.entity.id"
        :data="navMenu"
        :index="navMenu.entity.id.toString()"
      >
        <template slot="title">
          <i :class="navMenu.entity.icon"></i>
          <span> {{ navMenu.entity.title }}</span>
        </template>
        <NavMenu :navMenus="navMenu.childs"></NavMenu>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  props: ['navMenus']
}
</script>
