<template>
  <div class="stockmain">
    <h3>采购单</h3>
    <el-form
      size="mini"
      :model="stockmain"
      label-width="74px"
      label-position="left"
      ref="stockmainForm"
      id="stockmainForm"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单号" prop="id">
            {{ stockmain.id }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="合同号" prop="contract_id">
            <el-input
              v-model="stockmain.contract_id"
              :disabled="editdisable === '1'"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="流程">
            {{ stockmain._step }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="销售端" prop="ec_id">
            <el-select
              v-model="stockmain.ec_id"
              placeholder="请选择"
              :disabled="editdisable === '1'"
            >
              <el-option
                v-for="item in sysConfig.Common.ec"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="交货日期" prop="ship_date">
            <el-date-picker
              v-model="stockmain.ship_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择日期"
              :disabled="editdisable === '1'"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="目的仓库" prop="warehouse_id">
            <el-select
              v-model="stockmain.warehouse_id"
              placeholder="请选择"
              :disabled="editdisable === '1'"
            >
              <el-option
                v-for="item in sysConfig.warehouselist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="运输方式" prop="transport_type">
            <el-select
              v-model="stockmain.transport_type"
              placeholder="请选择"
              :disabled="editdisable === '1'"
            >
              <el-option
                v-for="item in sysConfig.Common.transport_type"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="22">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="stockmain.remark"
              type="textarea"
              :disabled="editdisable === '1'"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <h3>支付&款项</h3>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="开票类型" prop="invoice_type">
            <el-select
              v-model="stockmain.invoice_type"
              placeholder="请选择"
              :disabled="editdisable === '1'"
            >
              <el-option
                v-for="item in sysConfig.PurchaseOrder.invoice_type"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="币种" prop="currency">
            <el-select
              v-model="stockmain.currency"
              placeholder="请选择"
              :disabled="editdisable === '1'"
            >
              <el-option
                v-for="item in sysConfig.Common.currency"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="预付定金" prop="prepay_ratio">
            <el-select
              v-model="stockmain.prepay_ratio"
              placeholder="请选择"
              :disabled="editdisable === '1'"
            >
              <el-option
                v-for="item in sysConfig.PurchaseOrder.prepay_ratio"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="出口退税" prop="tax_refund_for_export">
            <el-switch
              v-model="stockmain.tax_refund_for_export"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="开"
              inactive-text="关"
              class="taxswitch"
              :disabled="editdisable === '1'"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <h3>供应商</h3>
        </el-col>
        <el-col :span="3">
          <el-button
            type="text"
            size="small"
            style="margin-top:15px;"
            @click="$refs['select-vendor'].show()"
            :disabled="editdisable === '1'"
          >
            编辑
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="名称" prop="vendor.name">
            {{ stockmain.vendor.name }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="类型" prop="vendor.id">
            {{ stockmain.vendor.id }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="地区" prop="vendor.city">
            {{ stockmain.vendor.city }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="具体地址" prop="vendor.addr">
            {{ stockmain.vendor.addr }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="电话" prop="vendor.tel">
            {{ stockmain.vendor.tel }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="联系人" prop="vendor.manage">
            {{ stockmain.vendor.manage }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="邮箱" prop="vendor.manage_email">
            {{ stockmain.vendor.manage_email }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="2">
          <el-form-item label="手机" prop="vendor.manager_phone">
            {{ stockmain.vendor.manager_phone }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <h3>其它</h3>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="采购员">
            {{ stockmain.user.name }}/{{ stockmain.user.account }}
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="3">
          <el-form-item label="创建日期">
            {{ stockmain.created_at }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3" :offset="2">
          <el-button
            native-type="submit"
            type="primary"
            size="mini"
            @click.prevent="saveStockmain()"
            :disabled="editdisable === '1'"
            >保存</el-button
          >
        </el-col>
        <el-col :span="3" :offset="2">
          <el-button
            native-type="submit"
            type="primary"
            size="mini"
            @click.prevent="$refs['confirm-stock-dialog'].show(stockcode)"
            :disabled="editdisable === '1'"
            >审核</el-button
          >
        </el-col>
        <el-col :span="3" :offset="2">
          <el-button
            native-type="submit"
            type="primary"
            size="mini"
            @click.prevent="$refs['unconfirm-stock-dialog'].show(stockcode)"
            :disabled="editdisable === '0'"
            >反审核</el-button
          >
        </el-col>
        <el-col :span="3" :offset="2">
          <el-button
            native-type="submit"
            type="danger"
            size="mini"
            @click.prevent="deleteStockDetail()"
            :disabled="editdisable === '1'"
            >删除</el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <select-vendor
      ref="select-vendor"
      @VendorConfirm="changeVendor"
    ></select-vendor>
    <unconfirm-stock-dialog
      ref="unconfirm-stock-dialog"
      @unconfirmSuccess="unconfrirmSuccess"
    ></unconfirm-stock-dialog>
    <confirm-stock-dialog
      ref="confirm-stock-dialog"
      @confirmSuccess="confrirmSuccess"
    ></confirm-stock-dialog>
  </div>
</template>
<script>
import selectVendor from './select-vendor'
import * as stockOrder from '../../../api/stockOrder'
import { mapGetters } from 'vuex'
import unconfirmStockDialog from './unconfirm-stock-dialog'
import confirmStockDialog from './confirm-stock-dialog'
export default {
  components: {
    selectVendor,
    unconfirmStockDialog,
    confirmStockDialog
  },
  props: ['stockcode', 'editdisable'],
  data () {
    return {
      stockmain: { tax_refund_for_export: true, vendor: {}, user: {} },
      warehouses: []
    }
  },
  computed: mapGetters(['sysConfig']),
  watch: {
    'stockmain.currency': function () {
      this.$emit('currencychange', this.stockmain.currency)
    }
  },
  created () {
    if (typeof this.stockcode === 'number') {
      this.getStockDetail()
    }
  },
  methods: {
    // 获取供应商信息
    changeVendor (Vendordetail) {
      this.stockmain.vendor = Vendordetail
    },
    // 保存采购单详情
    saveStockmain () {
      if (!this.stockmain.vendor.id) {
        return this.$message.error('请选择供应商！')
      }
      const stockmainData = {
        contract_id: this.stockmain.contract_id,
        ship_date: this.stockmain.ship_date,
        warehouse_id: this.stockmain.warehouse_id,
        transport_type: this.stockmain.transport_type,
        remark: this.stockmain.remark,
        vendor_id: this.stockmain.vendor.id,
        invoice_type: this.stockmain.invoice_type,
        currency: this.stockmain.currency,
        prepay_ratio: this.stockmain.prepay_ratio,
        tax_refund_for_export: this.stockmain.tax_refund_for_export,
        id: this.stockmain.id,
        ec_id: this.stockmain.ec_id
      }
      stockOrder
        .saveStockMain(stockmainData)
        .then(data => {
          this.$set(this.stockmain, 'id', data.id)
          this.$set(this.stockmain, 'step', data.step)
          this.$set(this.stockmain, 'user_id', data.user_id)
          this.$set(this.stockmain, 'created_at', data.created_at)
          this.$emit('saveStockSuccess', data.id)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getStockDetail () {
      stockOrder
        .getStockDetail({ id: this.stockcode })
        .then(data => {
          this.stockmain = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    // confirmStockDetail() {
    //   let self = this
    //   this.$confirm('确定审核通过该条采购单？')
    //   .then(_ => {
    //   stockOrder.confirmStockDetail({ id: this.stockcode })
    //   .then((data) => {
    //     this.$emit('confirmSuccess', '1')
    //   }).catch((err) => {
    //     console.log(err)
    //   })
    //   }).catch(_ => { });
    // },
    deleteStockDetail () {
      this
        .$confirm('确认是否删除该条采购单？')
        .then(_ => {
          stockOrder
            .deleteStockDetail({ id: this.stockcode })
            .then(data => {
              this.stockmain = { vendor: {} }
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    unconfrirmSuccess () {
      this.$emit('confirmSuccess', '0')
    },
    confrirmSuccess () {
      this.$emit('confirmSuccess', '1')
    }
  }
}
</script>

<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
