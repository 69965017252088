<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>wayfair</el-breadcrumb-item>
        <el-breadcrumb-item>销售退货</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h3>Wayfair Rrefund Order</h3>
    </el-row>
    <el-row>
      <el-form>
        <el-row>
          <el-col :span="6">
            <el-form-item label="PO">
              &nbsp;&nbsp;{{ detaildata.po }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="流程">
              &nbsp;&nbsp;{{ detaildata._step }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              &nbsp;&nbsp;{{ detaildata._status }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建时间">
              &nbsp;&nbsp;{{ detaildata.created_at }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="备注">
              &nbsp;&nbsp;{{ detaildata.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row >
      <el-table stripe border :data="detaildata.item" width="100%">
        <el-table-column prop="" label="MFN"></el-table-column>
        <el-table-column prop="sku" label="SKU"></el-table-column>
        <el-table-column prop="qty" label="QTY"></el-table-column>
        <el-table-column prop="price" label="Credit"></el-table-column>
        <el-table-column prop="remark" label="Reason"></el-table-column>
        <el-table-column prop="_warehouse" label="退回仓库"></el-table-column>
        <el-table-column prop="outbound_no" label="退货单号"></el-table-column>
        <el-table-column prop="refund_date" label="退货时间"></el-table-column>
        <el-table-column prop="express_no" label="运单号"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="$refs['edit-detail-dialog'].show(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleteItem()"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <edit-detail-dialog
      ref="edit-detail-dialog"
      @edit-success="getdetaildata()"
    ></edit-detail-dialog>
  </div>
</template>
<script>
import { getWayfairRefundDetail } from '@/api/amazon'
import editDetailDialog from './edit-detail-dialog'
export default {
  data () {
    return {
      detaildata: {},
      po: this.$route.params.po
    }
  },
  created () {
    this.getdetaildata()
  },
  components: {
    editDetailDialog
  },
  methods: {
    getdetaildata () {
      getWayfairRefundDetail({ po: this.po })
        .then(data => {
          this.detaildata = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteItem () {
      this.$confirm('如果明细已入库成功则无法删除。确定删除该明细吗？')
        .then(_ => {})
        .catch(_ => {})
    }
  }
}
</script>
