<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>报销单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h4>报销单</h4>
    </el-row>
    <el-row class="content-row">
      <el-col :span="6"> 单号: &nbsp;&nbsp;{{ orderDetail.id }} </el-col>
      <el-col :span="6"> 供应商:&nbsp;&nbsp;{{ orderDetail._vendor }} </el-col>
      <el-col :span="6">
        金额:&nbsp;&nbsp;{{ orderDetail.amount }}&nbsp;{{
          orderDetail._currency
        }}
      </el-col>
      <el-col :span="6"> 状态:&nbsp;&nbsp;{{ orderDetail._step }} </el-col>
    </el-row>
    <el-row style="margin-top: 10px;">
      <el-button
        type="primary"
        size="mini"
        @click="unConfirm()"
        v-if="orderDetail.step === 3"
        >反审核</el-button
      >
      <el-button
        type="primary"
        size="mini"
        @click="confirm()"
        v-if="orderDetail.step === 2"
        >审核通过</el-button
      >
    </el-row>
    <el-row class="content-row">
      <h4>支付信息</h4>
    </el-row>
    <el-row class="content-row">
      <el-col :span="6">
        收款单位:&nbsp;&nbsp;{{ orderDetail.bank_name }}
      </el-col>
      <el-col :span="6"> 开户行:&nbsp;&nbsp;{{ orderDetail.bank }} </el-col>
      <el-col :span="6">
        账号:&nbsp;&nbsp;{{ orderDetail.bank_account }}
      </el-col>
    </el-row>
    <el-row class="content-row">
      <el-col :span="6">
        应付金额:&nbsp;&nbsp;{{ orderDetail.amount }}{{ orderDetail._currency }}
      </el-col>
      <el-col :span="6">
        付款期限:&nbsp;&nbsp;{{ orderDetail.deadline }}
      </el-col>
    </el-row>
    <el-row class="content-row">
      <h4>开票资料</h4>
    </el-row>
    <el-row class="content-row">
      <el-col :span="8">
        公司名称:&nbsp;&nbsp;{{ orderDetail.bank_name }}
      </el-col>
      <el-col :span="8"> 开户行:&nbsp;&nbsp;{{ orderDetail.bank }} </el-col>
      <el-col :span="8">
        地址:&nbsp;&nbsp;{{ orderDetail.bank_account }}
      </el-col>
    </el-row>
    <el-row class="content-row">
      <el-col :span="8">
        统一社会信用代码:&nbsp;&nbsp;{{ orderDetail.amount }}
      </el-col>
      <el-col :span="8">
        对公账户:&nbsp;&nbsp;{{ orderDetail.deadline }}
      </el-col>
      <el-col :span="8"> 电话:&nbsp;&nbsp;{{ orderDetail.deadline }} </el-col>
    </el-row>
    <!--明细 -->
    <el-tabs v-model="tabvlue">
      <el-tab-pane label="报销明细" name="first">
        <reimbursement-detail-tab
          :orderDetailItem="orderDetail.detail"
        ></reimbursement-detail-tab>
      </el-tab-pane>
      <el-tab-pane label="开票明细" name="second">
        <invoice-detail-tab
          :invoiceDetailList="invoiceDetailList"
          :id="orderDetail.id"
          type="2"
        ></invoice-detail-tab>
      </el-tab-pane>
      <el-tab-pane label="开票资料" name="third">
        <invoice-info-tab :invoiceInfoList="invoiceInfoList"></invoice-info-tab>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  getReimbursementDetail,
  getOrderTicketInfo,
  getOrderTicketDetail,
  confirmReimbursement,
  unConfirmReimbursement
} from '@/api/financePurchaseNew'
import reimbursementDetailTab from './reimbursement-detail-tab'
import invoiceInfoTab from './invoice-info-tab'
import invoiceDetailTab from './invoice-detail-tab'

export default {
  components: {
    reimbursementDetailTab,
    invoiceInfoTab,
    invoiceDetailTab
  },
  data () {
    return {
      id: this.$route.params.id,
      orderDetail: {},
      invoiceDetailList: [],
      invoiceInfoList: [],
      tabvlue: 'first'
    }
  },
  created () {
    this.getDetailData()
    this.getTicketDetail()
    this.getTicketInfo()
  },
  methods: {
    getDetailData () {
      getReimbursementDetail({ id: this.id })
        .then(data => {
          this.orderDetail = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTicketDetail () {
      getOrderTicketDetail({ id: this.id })
        .then(data => {
          this.invoiceDetailList = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTicketInfo () {
      getOrderTicketInfo({ id: this.id })
        .then(data => {
          this.invoiceInfoList = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirm () {
      this.$confirm('提示', {
        title: '报销审核',
        message:
          '审核通过后系统将完成自动生成支付应付款、自动冲账等操作。确定审核通过该报销单？'
      })
        .then(_ => {
          confirmReimbursement({ id: this.id })
            .then(data => {
              this.getDetailData()
              this.getTicketDetail()
              this.getTicketInfo()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    unConfirm () {
      this.$confirm('提示', {
        title: '报销反审核',
        message:
          '反审核后系统将自动清除应付款记录、反自动冲账等操作。确定反审核该报销单？？'
      })
        .then(_ => {
          unConfirmReimbursement({ id: this.id })
            .then(data => {
              this.getDetailData()
              this.getTicketDetail()
              this.getTicketInfo()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
