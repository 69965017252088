import request from '../config/request'

export const prepayment = data => {
  const param = {
    vendor_id: data.vendor_id,
    order_id: data.order_id,
    step: data.step,
    prepayment_id: data.prepayment_id,
    currency: data.currency,
    page: data.page,
    num_per_page: data.num_per_page,
    start: data.start,
    end: data.end
  }
  return request('purchase/finance/prepayment', 'get', param, true)
}

export const createOrder = data => {
  return request('purchase/finance/prepayment/createOrder', 'post', data)
}

export const editOrder = data => {
  return request('purchase/finance/prepayment/editOrder', 'post', data)
}

/** 添加至请款单 */
export const addToOrder = (id, orderId) => {
  const param = {
    ids: id,
    prepayment_id: orderId
  }
  return request('purchase/finance/prepayment/addToOrder', 'post', param, true)
}
/** 申请预付款-确认预付款报告 */
export const confirmPrepaymentReport = data => {
  return request(
    'purchase/finance/prepayment/confirmPrepaymentReport',
    'post',
    data,
    true
  )
}

/** 请款单列表 */
export const prepaymentOrder = data => {
  return request('purchase/finance/prepayment/order', 'get', data, true)
}

export const prepaymentSimple = data => {
  const param = {
    simple: 1,
    vendor_id: data.vendor_id,
    currency: data.currency,
    step: 0
  }
  return request('purchase/finance/prepayment/order', 'get', param, true)
}

/** 请款单明细 */
export const prepaymentOrderDetail = data => {
  return request('purchase/finance/prepayment/order/detail', 'get', data, true)
}
/** 请款单款提交财务审核 */
export const prepaymentOrderDetailConfirm = data => {
  return request(
    'purchase/finance/prepayment/order/detail/confirm',
    'post',
    data
  )
}
export const getlastPaymentRecord = data => {
  return request(
    'purchase/finance/prepayment/lastPaymentRecord',
    'get',
    data,
    true
  )
}
export const deletePrepaymentOrder = data => {
  return request('purchase/finance/prepayment/order/delete', 'post', data, true)
}
