import { render, staticRenderFns } from "./saleorder-out-in-detail.vue?vue&type=template&id=1ca9f622&"
import script from "./saleorder-out-in-detail.vue?vue&type=script&lang=js&"
export * from "./saleorder-out-in-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports