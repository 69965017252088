import { Message, Notification } from 'element-ui'
import httpFactory from './http'
import storage from './storage'
import router from '@/router'

const HTTP_ERROR_CODE = {
  // 业务错误
  BUSINESS: 1,
  // 系统错误
  SYSTEM: 2
}

const httpClient = httpFactory()
  .onRequest(req => {
    const token = storage.token
    token && (req.headers.Authorization = 'Bearer' + token)
    return req
  })
  .onResponse(resp => {
    const { data = {} } = resp
    if ('res' in data) {
      switch (data.res) {
        case 1:
          return data
        case 0:
        case -2:
          return (() => {
            const error = new Error(data.msg + (data.dat ? JSON.stringify(data.dat) : '') || '业务参数错误！')
            error.code = HTTP_ERROR_CODE.BUSINESS
            return Promise.reject(error)
          })()
        case -1:
          // 退出登录
          router.push({ path: '/login' })
          break
        default:
          return (() => {
            const error = new Error('系统错误！')
            error.code = HTTP_ERROR_CODE.SYSTEM
            return Promise.reject(error)
          })()
      }
    } else {
      const error = new Error('服务器异常，未返回状态码！')
      error.code = HTTP_ERROR_CODE.SYSTEM
      return Promise.reject(error)
    }
  })
  .onError(err => {
    if (httpClient.isCancel(err)) {
      console.log('Request canceled', err.message)
    } else if (err.code === HTTP_ERROR_CODE.BUSINESS) {
      Notification.error({
        message: err.message,
        duration: 5000
      })
    } else if (err.code === HTTP_ERROR_CODE.SYSTEM) {

    } else if (err.response && err.response.status !== 200) {
      Message.error({
        message: '服务器错误!!!立即联系IT人员',
        center: true,
        offset: 200,
        showClose: true,
        duration: 0
      })
    }
    throw err
  })
  .create()

httpClient.ERROR_CODE = HTTP_ERROR_CODE

export { storage, httpClient }

/**
 * 初始化核心功能
 */
export function initCore () {

}
