<template>
  <el-dialog
    title="创建海外仓入库单"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-tabs v-model="tabvalue">
      <el-tab-pane label="1、设置方式" name="first">
        <el-row :gutter="16" style="margin-bottom: 50px; margin-left: 20px;">
          <el-radio
            v-model="formdata.setType"
            label="create"
            style="line-height: 30px"
          >
            尚未创建海外仓入库单，需要系统同步创建；</el-radio
          >
        </el-row>
        <el-row :gutter="16" style="margin-bottom: 50px; margin-left: 20px;">
          <el-radio
            v-model="formdata.setType"
            label="repair"
            style="line-height: 30px"
            >已创建海外仓入库单，补录至系统。</el-radio
          >
        </el-row>
        <el-row style="margin-top: 100px">
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="small" @click="changeTab()"
              >下一步</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="2、创建入库单" name="second" :disabled="true">
        <el-tag style="margin-bottom: 15px;"
          >海外仓入库单创建后不可更改，确定创建？</el-tag
        >
        <el-form label-width="120px" label-position="left" size="small">
          <!-- fba -->
          <template v-if="fabflag">
            <el-form-item label="区域">
              <el-select v-model="formdata.area">
                <el-option
                  v-for="item in sysConfig.Common.MWS.FBAInboundShipment"
                  :key="item.key"
                  :label="item.key"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="国家/地区">
              <el-select v-model="formdata.mwsMarketplace">
                <el-option
                  v-for="item in formdata.area"
                  :key="item.key"
                  :label="item.val"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- twusa -->
          <template v-else>
            <el-form-item label="发货时间">
              <el-date-picker
                v-model="formdata.shipping_time"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="预计到仓时间">
              <el-date-picker
                v-model="formdata.schedule_time"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="预计离港时间">
              <el-date-picker
                v-model="formdata.etd_time"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="预计到港时间">
              <el-date-picker
                v-model="formdata.eta_time"
                type="date"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="来源类型">
              <el-select v-model="formdata.source_type">
                <el-option
                  v-for="item in sysConfig.Common.twusa_inbound.SOURCE_TYPE"
                  :key="item.key"
                  :label="item.val"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="入库派送方式">
              <el-select
                v-model="formdata.delivery_type"
                @change="deletein_type()"
              >
                <el-option
                  v-for="item in sysConfig.Common.twusa_inbound.DELIVERY_TYPE"
                  :key="item.key"
                  :label="item.val"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="入库类型">
              <el-form v-if="formdata.delivery_type === 1" inline size="small">
                <el-form-item>
                  <el-select v-model="formdata._type">
                    <el-option
                      v-for="item in sysConfig.Common.twusa_inbound
                        .DELIVERY_AND_BOX_TYPE._1"
                      :key="item.key"
                      :label="item.val"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="formdata._type">
                  <el-input
                    type="number"
                    v-model="formdata.in_type[`${formdata._type}`]"
                    placeholder="数量"
                    @blur="BlurText($event)"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-form
                v-if="formdata.delivery_type != 1"
                size="small"
                label-position="right"
              >
                <el-form-item
                  v-for="item in sysConfig.Common.twusa_inbound
                    .DELIVERY_AND_BOX_TYPE[`_${formdata.delivery_type}`]"
                  :label="item.val"
                  :key="item.key"
                >
                  <el-input
                    type="number"
                    v-model="formdata.in_type[`${item.key}`]"
                    placeholder="数量"
                    style="width: 220px;"
                    @blur="BlurText($event)"
                  ></el-input>
                </el-form-item>
              </el-form>
            </el-form-item>
          </template>
        </el-form>
        <el-row>
          <div v-loading="loading" :element-loading-text="loadtext">
            {{ dataresult }}
          </div>
        </el-row>
        <el-row>
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="mini" @click="createConfrim()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="2、创建入库单" name="third" :disabled="true">
        <el-tag style="margin-bottom: 15px;"
          >目前补录入库单仅支持FBAInboundShipment。</el-tag
        >
        <el-input
          v-model="formdata.FbaShipmentId"
          placeholder="多个FBAInboundShipment使用英文或中文逗号隔开"
        ></el-input>
        <el-row style="margin-top: 10px">
          <el-col :span="2" :offset="18">
            <el-button type="primary" size="mini" @click="createConfrim()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { setShipment } from '@/api/forwardNew'
export default {
  data () {
    return {
      visible: false,
      tabvalue: 'first',
      // overSeaType: '',
      // fbashipcode: '',
      formdata: {
        id: '',
        FbaShipmentId: '',
        setType: '',
        area: '',
        mwsMarketplace: '',
        in_type: {}
      },
      fabflag: false,
      loading: false,
      loadtext: '加载中',
      dataresult: ''
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    BlurText (e) {
      const boolean = new RegExp('^[1-9][0-9]*$').test(e.target.value)
      if (!boolean) {
        this.$message({
          message: '请输入正整数',
          center: true,
          offset: 350,
          type: 'warning'
        })
        e.target.value = ' '
      }
    },
    show (isToFba, id) {
      this.fabflag = isToFba
      this.formdata.id = id
      this.visible = true
    },
    deletein_type () {
      this.formdata.in_type = {}
    },
    changeTab () {
      console.log(this.formdata.setType)
      if (this.formdata.setType === 'create') {
        this.tabvalue = 'second'
      } else if (this.formdata.setType === 'repair') {
        this.tabvalue = 'third'
      }
    },
    createConfrim () {
      this.loading = true
      // { id: this.id,
      //               mwsMarketplace: this.formdata.unix,
      //               FbaShipmentId: this.fbashipcode,
      //               setType: this.overSeaType,
      //               shipping_time: this.formdata.shipping_time,
      //               schedule_time: this.formdata.schedule_time,
      //               etd_time: this.formdata.etd_time,
      //               eta_time: this.formdata.eta_time }
      setShipment(this.formdata)
        .then(data => {
          this.loadtext = '创建成功'
          this.loading = false
          this.$emit('create-oversea-success')
          this.hide()
        })
        .catch(err => {
          this.loadtext = '失败'
          this.loading = false
          this.dataresult = err.dat
          console.log(err)
        })
    },
    hide () {
      this.tabvalue = 'first'
      this.formdata = {
        id: '',
        FbaShipmentId: '',
        setType: '',
        area: '',
        mwsMarketplace: '',
        in_type: {}
      }
      this.fabflag = false
      this.dataresult = ''
      this.visible = false
    },
    closedone (done) {
      this.hide()
      done()
    }
  }
}
</script>
