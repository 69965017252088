<template>
  <el-dialog
    title="创建销售出库单"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-form
      :model="createdata"
      size="small"
      label-width="80px"
      :rules="rules"
      ref="create-dialog"
    >
      <el-form-item label="选择仓库" prop="warehouse_id">
        <el-select v-model="createdata.warehouse_id" placeholder="请选择仓库">
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="外部单号">
        <el-input v-model="createdata.outNumber"></el-input>
      </el-form-item>
      <el-form-item label="外部平台">
        <el-input v-model="createdata.outTerrace"></el-input>
      </el-form-item>
      <el-form-item label="备注说明">
        <el-input
          v-model="createdata.remark"
          type="textarea"
          :rows="5"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="createOrder()">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { createSaleOrderOut } from '@/api/warehouse'
export default {
  data () {
    return {
      visible: false,
      createdata: {},
      rules: {
        warehouse_id: [
          { required: true, message: '请选择仓库', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show () {
      this.visible = true
    },
    closedone (done) {
      this.createdata = {}
      done()
    },
    createOrder () {
      const jsondata = {
        warehouse_id: this.createdata.warehouse_id,
        no: this.createdata.outNumber,
        platform: this.createdata.outTerrace,
        remark: this.createdata.remark
      }
      createSaleOrderOut(jsondata)
        .then(data => {
          this.$emit('success-create')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.createdata = {}
      this.visible = false
    }
  }
}
</script>
