import request from '../config/request'

export const getcurrencyList = data => {
  return request('finance/currency', 'get', data)
}
export const savecurrency = data => {
  return request('finance/currency/save', 'post', data).then(data => {
    return data
  })
}
export const updatecurrency = data => {
  return request('finance/currency/update', 'post', data).then(data => {
    return data
  })
}
