<template>
  <el-dialog
    title="编辑Shipment"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-form size="small" label-width="90px" :model="formdata" :inline="true">
      <el-form-item label="Name">
        <el-input v-model="formdata.ShipmentName"></el-input>
      </el-form-item>
      <el-form-item label="Label Type">
        <el-select v-model="formdata.LabelPrepType">
          <el-option
            v-for="item in sysConfig.FBAInboundShipment.LABEL_PREP_TYPE"
            :key="item.key"
            :label="item.val"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="原厂包装">
        <el-switch
          v-model="formdata.AreCasesRequired"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关"
          class="taxswitch"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请填写备注"
          v-model="formdata.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="saveshipMent()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import * as fbaAPI from '@/api/fbaship'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        ShipmentId: '',
        ShipmentName: '',
        LabelPrepType: '',
        AreCasesRequired: '',
        remark: ''
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (formdata) {
      this.visible = true
      this.formdata.ShipmentId = formdata.ShipmentId
      this.formdata.ShipmentName = formdata.ShipmentName
      this.formdata.LabelPrepType = formdata.LabelPrepType
      this.formdata.AreCasesRequired = formdata.AreCasesRequired
      this.formdata.remark = formdata.remark
    },
    closedone (done) {
      this.formdata = {
        ShipmentId: '',
        ShipmentName: '',
        LabelPrepType: '',
        AreCasesRequired: '',
        remark: ''
      }
      done()
    },
    saveshipMent () {
      fbaAPI
        .editShipment(this.formdata)
        .then(data => {
          this.$emit('edit-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 150px !important;
}
</style>
