<template>
  <div class="stockorder">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>采购单管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue" @tab-click="selectTabs()">
        <el-tab-pane label="未完成" name="first" class="unfinishstock">
          <stock-list :stockstep="stockstep"></stock-list>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="second" class="finishstock">
          <stock-list :stockstep="stockstep"></stock-list>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>
<script>
import stockList from './stock-list'
export default {
  name: 'stock',
  components: {
    stockList
  },
  data () {
    return {
      stockcode: '',
      tabvalue: 'first',
      stockstep: '0'
    }
  },
  methods: {
    selectTabs () {
      const self = this
      if (self.tabvalue === 'first') {
        self.stockstep = '0'
      } else {
        self.stockstep = '1'
      }
    }
  }
}
</script>
