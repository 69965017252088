import request from '../config/request'

//  报销单列表
export const getReimbursementlist = data => {
  return request('finance/purchase/invoice/ls', 'get', data).then(data => {
    return data
  })
}
//  报销单明细
export const getReimbursementDetail = data => {
  return request('finance/purchase/invoice/detail', 'get', data).then(data => {
    return data
  })
}
export const getOrderTicketInfo = data => {
  return request('finance/purchase/invoice/ticketInfo', 'get', data).then(
    data => {
      return data
    }
  )
}
export const getOrderTicketDetail = data => {
  return request('finance/purchase/invoice/ticketDetail', 'get', data).then(
    data => {
      return data
    }
  )
}
//  报销单审核
export const confirmReimbursement = data => {
  return request('finance/purchase/invoice/approve', 'post', data).then(
    data => {
      return data
    }
  )
}
// 报销单反审核
export const unConfirmReimbursement = data => {
  return request('finance/purchase/invoice/disapprove', 'post', data).then(
    data => {
      return data
    }
  )
}
// 请款审核列表
export const getEarnestOrderLs = data => {
  return request('finance/purchase/earnest/orderLs', 'get', data).then(data => {
    return data
  })
}
// 请款单明细
export const getEarnestOrderLsDetail = data => {
  return request('finance/purchase/earnest/orderDetail', 'get', data).then(
    data => {
      return data
    }
  )
}
// 请款审核通过
export const confirmEarnestOrder = data => {
  return request('finance/purchase/earnest/checkOrder', 'post', data).then(
    data => {
      return data
    }
  )
}
// 反审核
export const unConfirmEarnestOrder = data => {
  return request('finance/purchase/earnest/backOrder', 'post', data).then(
    data => {
      return data
    }
  )
}
// 应付款列表
export const getpaymentBillLs = data => {
  return request('finance/purchase/paymentBill/ls', 'get', data).then(data => {
    return data
  })
}
// 应付款明细
export const getpaymentDetail = data => {
  return request('finance/purchase/paymentBill/detail', 'get', data).then(
    data => {
      return data
    }
  )
}
