<template>
  <el-dialog
    title="创建海外仓出库单"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="closedone"
    width="500px"
  >
    <el-tag style="margin-bottom: 30px"
      >注意：第三方出库单只允许创建一次，一旦创建不可更改。</el-tag
    >
    <el-form label-width="120px" label-position="left">
      <el-form-item label="ReferenceID">
        <el-input
          v-model="formdata.rid"
          placeholder="若不填写系统将自动生成"
        ></el-input>
      </el-form-item>
      <el-form-item label="运输方式">
        <el-select v-model="formdata.ShipmentType">
          <el-option
            v-for="item in typeList"
            :key="item.key"
            :value="item.val"
            :label="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="自提">
        <el-switch
          v-model="formdata.IsPartnered"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="是"
          inactive-text="否"
          class="taxswitch"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="createOverseas()">创建</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as fbaAPI from '@/api/fbaship'
export default {
  data () {
    return {
      visible: false,
      ShipmentId: '',
      formdata: {
        rid: '',
        ShipmentType: '',
        IsPartnered: true
      },
      typeList: [
        {
          key: 0,
          val: 'LTL'
        },
        {
          key: 1,
          val: 'SPD'
        }
      ]
    }
  },
  methods: {
    show (ShipmentId) {
      this.visible = true
      this.ShipmentId = ShipmentId
    },
    createOverseas () {
      const jsondata = {
        ShipmentId: this.ShipmentId,
        rid: this.formdata.rid,
        ShipmentType: this.formdata.ShipmentType,
        IsPartnered: this.formdata.IsPartnered
      }
      fbaAPI
        .createoutbound(jsondata)
        .then(data => {
          this.$emit('create-overseas-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      this.ShipmentId = ''
      this.rid = ''
      done()
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 10px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
