<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>FBA</el-breadcrumb-item>
        <el-breadcrumb-item>Inbound Shipment</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs">
      <el-tabs v-model="tabvalue">
        <el-tab-pane label="Basic Info" name="first">
          <el-row>
            <el-col :span="6">
              <h4>Inbound Shipment</h4>
            </el-col>
            <el-col :span="1" :offset="13">
              <el-button
                type="text"
                @click="$refs['edit-shipment-dialog'].show(detaildata)"
                >编辑</el-button
              >
            </el-col>
          </el-row>
          <el-row>
            <el-form>
              <el-row>
                <el-col :span="5">
                  <el-form-item label="ID">
                    &nbsp;&nbsp;{{ detaildata.ShipmentId }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Name">
                    &nbsp;&nbsp;{{ detaildata.ShipmentName }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Status">
                    &nbsp;&nbsp;{{ detaildata._status }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="Destination">
                    &nbsp;&nbsp;{{ detaildata.DestinationFulfillmentCenterId }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item label="Label Type">
                    &nbsp;&nbsp;{{ detaildata.LabelPrepType }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="原厂包装">
                    &nbsp;&nbsp;{{ detaildata.AreCasesRequired }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="5">
                  <el-form-item label="仓库">
                    &nbsp;&nbsp;{{ detaildata._from }}~{{ detaildata._to }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="电商">
                    &nbsp;&nbsp;{{ detaildata.ec_no }}
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="流程">
                    &nbsp;&nbsp;{{ detaildata._step }} &nbsp;&nbsp;<el-button
                      type="text"
                      @click.native="resetStatus()"
                      >{{ detaildata._status }}</el-button
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="最后更新">
                    &nbsp;&nbsp;{{ detaildata.updated_at }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="备注">
                    &nbsp;&nbsp;{{ detaildata.remark }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="
                $refs['confirm-shipment-dialog'].show(
                  detaildata.ShipmentId,
                  step
                )
              "
              >确认提交</el-button
            >
          </el-row>
          <el-row>
            <el-col :span="6">
              <h4>Address From</h4>
            </el-col>
          </el-row>
          <el-row>
            <el-form v-if="detaildata.ShipFromAddress != null">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Name">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.Name }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="CountryCode">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.CountryCode }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="State(Province)">
                    &nbsp;&nbsp;{{
                      detaildata.ShipFromAddress.StateOrProvinceCode
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="City">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.City }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Address">
                    &nbsp;&nbsp;{{ detaildata.ShipFromAddress.AddressLine1 }}
                    <el-tag
                      >{{ detaildata.ShipFromAddress.PostalCode }}
                    </el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>
          <el-row>
            <h4>Address To</h4>
          </el-row>
          <el-row>
            <el-form v-if="detaildata.ShipToAddress != null">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Name">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.Name }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="CountryCode">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.CountryCode }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="State(Province)">
                    &nbsp;&nbsp;{{
                      detaildata.ShipToAddress.StateOrProvinceCode
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="6" :offset="2">
                  <el-form-item label="City">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.City }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Address">
                    &nbsp;&nbsp;{{ detaildata.ShipToAddress.AddressLine1 }}
                    <el-tag>{{ detaildata.ShipToAddress.PostalCode }} </el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-row>
          <div class="flex justify-between">
            <h4>Item</h4>
            <el-button type="text"
              v-if="step == 3"
              @click="handleOnekeySync"
            >一键同步</el-button>
          </div>
          <el-row>
            <el-table border stripe :data="detaildata.item">
              <el-table-column label="SKU" prop="SellerSKU"></el-table-column>
              <el-table-column label="库存" prop="inventory"></el-table-column>
              <el-table-column
                label="QTY"
                prop="QuantityShipped"
              ></el-table-column>
              <el-table-column
                label="采购装箱率"
                prop="purchase_qty_ctn"
              ></el-table-column>
              <el-table-column
                label="装箱率"
                prop="QuantityInCase"
              ></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Shipping Service" name="second">
          <el-row>
            <el-col :span="5">
              <h4>Shipping Service</h4>
            </el-col>
            <el-col :span="3" :offset="12">
              <el-button
                type="text"
                @click.prevent="
                  $refs['fba-set-shipservice-dialog'].show(
                    detaildata._to_country,
                    detaildata.ShipmentId,
                    detailshipService
                  )
                "
                >编辑</el-button
              >
            </el-col>
          </el-row>
          <div v-if="!detailshipService.ShipmentType">
            <el-row>
              <p>暂无物流信息</p>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>海外仓出库单</h4>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                单号：&nbsp;&nbsp;{{ detaildata.outbound }}
              </el-col>
              <el-col :span="2">
                <el-button
                  type="text"
                  @click="
                    $refs['fba-create-overseas-pdtship-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  v-if="!detaildata.outbound"
                  >创建</el-button
                >
                <el-button
                  v-else-if="detaildata.outbound"
                  type="text"
                  @click="updateWMSItems()"
                  >同步明细</el-button
                >
              </el-col>
            </el-row>
          </div>
          <!-- SPD自提 -->
          <div
            v-else-if="
              detailshipService.ShipmentType === 'SP' &&
                detailshipService.IsPartnered === true
            "
          >
            <el-row style="margin-bottom: 30px">
              <el-col :span="5">
                自提： &nbsp;&nbsp;{{ detailshipService.IsPartnered }}
              </el-col>
              <el-col :span="5" :offset="1">
                运输方式： &nbsp;&nbsp;{{ detailshipService.ShipmentType }}
              </el-col>
              <el-col :span="5" :offset="1">
                物流状态： &nbsp;&nbsp;{{ detailshipService.TransportStatus }}
              </el-col>
            </el-row>
            <el-row>
              <el-table
                border
                stripe
                :data="
                  detailshipService.TransportDetails.PartneredSmallParcelData
                    .PackageList
                "
              >
                <el-table-column
                  label="长"
                  prop="Dimensions.Length"
                ></el-table-column>
                <el-table-column
                  label="宽"
                  prop="Dimensions.Width"
                ></el-table-column>
                <el-table-column
                  label="高"
                  prop="Dimensions.Height"
                ></el-table-column>
                <el-table-column
                  label="长度单位"
                  prop="Dimensions.Unit"
                ></el-table-column>
                <el-table-column
                  label="重量"
                  prop="Weight.Value"
                ></el-table-column>
                <el-table-column
                  label="重量单位"
                  prop="Weight.Unit"
                ></el-table-column>
                <el-table-column
                  label="状态"
                  prop="PackageStat"
                ></el-table-column>
                <el-table-column
                  label="物流编号"
                  prop="TrackingId"
                ></el-table-column>
                <el-table-column
                  label="物流商"
                  prop="CarrierName"
                ></el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>Shipping Charges</h4>
              </el-col>
              <el-col :span="2" :offset="15">
                <el-button type="text" @click="estimateTransport()"
                  >评估费用&nbsp;|</el-button
                >
              </el-col>
              <el-col :span="1">
                <el-button type="text" @click="confirmTransport()"
                  >费用确认</el-button
                >
              </el-col>
            </el-row>
            <el-row
              v-if="
                detailshipService.TransportDetails.PartneredSmallParcelData
                  .PartneredEstimate
              "
            >
              <el-col :span="6">
                费用：&nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredSmallParcelData
                    .PartneredEstimate.Amount
                }}
              </el-col>
              <el-col :span="6" :offset="2">
                确认截止日期：&nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredSmallParcelData
                    .PartneredEstimate.ConfirmDeadline
                }}
              </el-col>
              <el-col :span="6" :offset="2">
                取消截止日期：&nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredSmallParcelData
                    .PartneredEstimate.VoidDeadline
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>箱规上传&标签下载</h4>
              </el-col>
              <el-col :span="1" :offset="15">
                <el-button
                  type="text"
                  v-if="detailshipService.CartonContent"
                  @click="findDocument(detailshipService.CartonContent)"
                  >查看&nbsp;|</el-button
                >
              </el-col>
              <el-col :span="1">
                <el-button
                  type="text"
                  @click="
                    $refs['fba-import-carton-size-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >上传</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                外箱标：&nbsp;&nbsp;<el-button
                  size="small"
                  type="primary"
                  @click="
                    $refs['fba-dowmload-label-dialog'].show(
                      '',
                      detaildata.ShipmentId
                    )
                  "
                  >下载.zip</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>海外仓出库单</h4>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                单号：&nbsp;&nbsp;{{ detaildata.outbound }}
              </el-col>
              <el-col :span="2">
                <el-button
                  v-if="!detaildata.outbound"
                  type="text"
                  @click="
                    $refs['fba-create-overseas-pdtship-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >创建</el-button
                >
                <el-button
                  v-else-if="detaildata.outbound"
                  type="text"
                  @click="updateWMSItems()"
                  >同步明细</el-button
                >
              </el-col>
            </el-row>
          </div>
          <!-- SPD自发 -->
          <div
            v-else-if="
              detailshipService.ShipmentType === 'SP' &&
                detailshipService.IsPartnered === false
            "
          >
            <el-row style="margin-bottom: 30px">
              <el-col :span="5">
                自提： &nbsp;&nbsp;{{ detailshipService.IsPartnered }}
              </el-col>
              <el-col :span="5" :offset="1">
                运输方式： &nbsp;&nbsp;{{ detailshipService.ShipmentType }}
              </el-col>
              <el-col :span="5" :offset="1">
                物流状态： &nbsp;&nbsp;{{ detailshipService.TransportStatus }}
              </el-col>
            </el-row>
            <el-row>
              <el-table
                border
                stripe
                :data="
                  detailshipService.TransportDetails.NonPartneredSmallParcelData
                    .PackageList.member
                "
              >
                <el-table-column
                  label="状态"
                  prop="PackageStatus"
                ></el-table-column>
                <el-table-column
                  label="物流单号"
                  prop="TrackingId"
                ></el-table-column>
                <el-table-column
                  label="物流商"
                  prop="CarrierName"
                ></el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>箱规上传&标签下载</h4>
              </el-col>
              <el-col :span="1" :offset="15">
                <el-button
                  type="text"
                  v-if="detailshipService.CartonContent"
                  @click="findDocument(detailshipService.CartonContent)"
                  >查看&nbsp;|</el-button
                >
              </el-col>
              <el-col :span="1">
                <el-button
                  type="text"
                  @click="
                    $refs['fba-import-carton-size-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >上传</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                外箱标：&nbsp;&nbsp;<el-button
                  size="small"
                  type="primary"
                  @click="
                    $refs['fba-dowmload-label-dialog'].show(
                      '',
                      detaildata.ShipmentId
                    )
                  "
                  >下载.zip</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>海外仓出库单</h4>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                单号：&nbsp;&nbsp;{{ detaildata.outbound }}
              </el-col>
              <el-col :span="2">
                <el-button
                  v-if="!detaildata.outbound"
                  type="text"
                  @click="
                    $refs['fba-create-overseas-pdtship-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >创建</el-button
                >
                <el-button
                  v-else-if="detaildata.outbound"
                  type="text"
                  @click="updateWMSItems()"
                  >同步明细</el-button
                >
              </el-col>
            </el-row>
          </div>
          <!-- LTL自提 -->
          <div
            v-else-if="
              detailshipService.ShipmentType === 'LTL' &&
                detailshipService.IsPartnered === true
            "
          >
            <el-row style="margin-bottom: 30px">
              <el-col :span="5">
                自提： &nbsp;&nbsp;{{ detailshipService.IsPartnered }}
              </el-col>
              <el-col :span="5" :offset="1">
                运输方式： &nbsp;&nbsp;{{ detailshipService.ShipmentType }}
              </el-col>
              <el-col :span="5" :offset="1">
                物流状态： &nbsp;&nbsp;{{ detailshipService.TransportStatus }}
              </el-col>
            </el-row>
            <el-row>
              <el-table
                border
                stripe
                v-if="
                  detailshipService.TransportDetails.PartneredLtlData.PalletList
                "
                :data="
                  detailshipService.TransportDetails.PartneredLtlData.PalletList
                "
              >
                <el-table-column
                  label="长"
                  prop="Dimensions.Length"
                ></el-table-column>
                <el-table-column
                  label="宽"
                  prop="Dimensions.Width"
                ></el-table-column>
                <el-table-column
                  label="高"
                  prop="Dimensions.Height"
                ></el-table-column>
                <el-table-column
                  label="长度单位"
                  prop="Dimensions.Unit"
                ></el-table-column>
                <el-table-column
                  label="重量"
                  prop="Weight.Value"
                ></el-table-column>
                <el-table-column
                  label="重量单位"
                  prop="Weight.Unit"
                ></el-table-column>
                <el-table-column
                  label="叠加"
                  prop="IsStacked"
                ></el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>Shipping Charges</h4>
              </el-col>
              <el-col :span="2" :offset="15">
                <el-button type="text" @click="estimateTransport()"
                  >评估费用&nbsp;|</el-button
                >
              </el-col>
              <el-col :span="1">
                <el-button type="text" @click="confirmTransport()"
                  >费用确认</el-button
                >
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 15px">
              <el-col :span="6">
                ReferenceId： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .AmazonReferenceId
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                物流商： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .CarrierName
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                isBillOfLading： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .IsBillOfLadingAvailable
                }}
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 15px">
              <el-col :span="6">
                估值(商家): &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .SellerDeclaredValue.Value
                }}{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .SellerDeclaredValue.CurrencyCode
                }}
              </el-col>
            </el-row>
            <el-row
              style="margin-bottom: 15px"
              v-if="
                detailshipService.TransportDetails.PartneredLtlData
                  .PartneredEstimate
              "
            >
              <el-col :span="6">
                费用： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .PartneredEstimate.Amount.Value
                }}
                &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .PartneredEstimate.Amount.CurrencyCode
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                确认截止日期： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .PartneredEstimate.ConfirmDeadline
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                取消截止日期： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .PartneredEstimate.VoidDeadline
                }}
              </el-col>
            </el-row>
            <hr />
            <el-row style="margin-bottom: 15px">
              <el-col :span="6">
                姓名： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData.Contact
                    .Name
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                预定时间： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .FreightReadyDate
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                总箱数： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData.BoxCount
                }}
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 15px">
              <el-col :span="6">
                电话： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData.Contact
                    .Phone
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                提货日期： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .FreightReadyDate
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                总重量：
                <span
                  v-if="
                    detailshipService.TransportDetails.PartneredLtlData
                      .TotalWeight
                  "
                >
                  &nbsp;&nbsp;{{
                    detailshipService.TransportDetails.PartneredLtlData
                      .TotalWeight.Value
                  }}
                  &nbsp;&nbsp;{{
                    detailshipService.TransportDetails.PartneredLtlData
                      .TotalWeight.Unit
                  }}
                </span>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 15px">
              <el-col :span="6">
                邮箱： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData.Contact
                    .Email
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                发货日期： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .FreightReadyDate
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                重量级(自称)： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .SellerFreightClass
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                传真： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData.Contact
                    .Fax
                }}
              </el-col>
              <el-col :span="6" :offset="1"> </el-col>
              <el-col :span="6" :offset="1">
                重量级： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.PartneredLtlData
                    .PreviewFreightClass
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>箱规上传&标签下载</h4>
              </el-col>
              <el-col :span="1" :offset="15">
                <el-button
                  type="text"
                  v-if="detailshipService.CartonContent"
                  @click="findDocument(detailshipService.CartonContent)"
                  >查看&nbsp;|</el-button
                >
              </el-col>
              <el-col :span="1">
                <el-button
                  type="text"
                  @click="
                    $refs['fba-import-carton-size-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >上传</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                外箱标：&nbsp;&nbsp;<el-button
                  size="small"
                  type="primary"
                  @click="
                    $refs['fba-dowmload-label-dialog'].show(
                      '',
                      detaildata.ShipmentId
                    )
                  "
                  >下载.zip</el-button
                >
              </el-col>
              <el-col :span="6" :offset="1">
                托盘标：&nbsp;&nbsp;<el-button
                  size="small"
                  type="primary"
                  @click="
                    $refs['fba-dowmload-label-dialog'].show(
                      'pallet',
                      detaildata.ShipmentId
                    )
                  "
                  >下载.zip</el-button
                >
              </el-col>
              <el-col :span="6" :offset="1">
                提货单：&nbsp;&nbsp;<el-button
                  size="small"
                  type="primary"
                  @click="down()"
                  >下载.zip</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>海外仓出库单</h4>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                单号：&nbsp;&nbsp;{{ detaildata.outbound }}
              </el-col>
              <el-col :span="2">
                <el-button
                  v-if="!detaildata.outbound"
                  type="text"
                  @click="
                    $refs['fba-create-overseas-pdtship-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >创建</el-button
                >
                <el-button
                  v-else-if="detaildata.outbound"
                  type="text"
                  @click="updateWMSItems()"
                  >同步明细</el-button
                >
              </el-col>
            </el-row>
          </div>
          <!-- LTL自发 -->
          <div
            v-else-if="
              detailshipService.ShipmentType === 'LTL' &&
                detailshipService.IsPartnered === false
            "
          >
            <el-row>
              <el-col :span="5">
                自提： &nbsp;&nbsp;{{ detailshipService.IsPartnered }}
              </el-col>
              <el-col :span="5" :offset="1">
                运输方式： &nbsp;&nbsp;{{ detailshipService.ShipmentType }}
              </el-col>
              <el-col :span="5" :offset="1">
                物流状态： &nbsp;&nbsp;{{ detailshipService.TransportStatus }}
              </el-col>
            </el-row>
            <el-row>
              <h4>Transport Info</h4>
            </el-row>
            <el-row>
              <el-col :span="6">
                物流单号： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.NonPartneredLtlData
                    .ProNumber
                }}
              </el-col>
              <el-col :span="6" :offset="1">
                物流商： &nbsp;&nbsp;{{
                  detailshipService.TransportDetails.NonPartneredLtlData
                    .CarrierName
                }}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>箱规上传&标签下载</h4>
              </el-col>
              <el-col :span="1" :offset="15">
                <el-button
                  type="text"
                  v-if="detailshipService.CartonContent"
                  @click="findDocument(detailshipService.CartonContent)"
                  >查看&nbsp;|</el-button
                >
              </el-col>
              <el-col :span="1">
                <el-button
                  type="text"
                  @click="
                    $refs['fba-import-carton-size-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >上传</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                外箱标：&nbsp;&nbsp;<el-button
                  size="small"
                  type="primary"
                  @click="
                    $refs['fba-dowmload-label-dialog'].show(
                      '',
                      detaildata.ShipmentId
                    )
                  "
                  >下载.zip</el-button
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5">
                <h4>海外仓出库单</h4>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                单号：&nbsp;&nbsp;{{ detaildata.outbound }}
              </el-col>
              <el-col :span="2">
                <el-button
                  v-if="!detaildata.outbound"
                  type="text"
                  @click="
                    $refs['fba-create-overseas-pdtship-dialog'].show(
                      detaildata.ShipmentId
                    )
                  "
                  >创建</el-button
                >
                <el-button
                  v-else-if="detaildata.outbound"
                  type="text"
                  @click="updateWMSItems()"
                  >同步明细</el-button
                >
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <edit-shipment-dialog
      ref="edit-shipment-dialog"
      @edit-success="getfbaDetail()"
    ></edit-shipment-dialog>
    <fba-set-shipservice-dialog
      ref="fba-set-shipservice-dialog"
      @save-transport-success="getShippingService()"
    ></fba-set-shipservice-dialog>
    <fba-import-carton-size-dialog
      ref="fba-import-carton-size-dialog"
      @success-import-fbacarton="getShippingService()"
    ></fba-import-carton-size-dialog>
    <fba-create-overseas-pdtship-dialog
      ref="fba-create-overseas-pdtship-dialog"
      @create-overseas-success="refreshData()"
    ></fba-create-overseas-pdtship-dialog>
    <fba-download-label-dialog
      ref="fba-dowmload-label-dialog"
    ></fba-download-label-dialog>
    <confirm-shipment-dialog
      ref="confirm-shipment-dialog"
      @success-confirm="getfbaDetail()"
    ></confirm-shipment-dialog>
  </div>
</template>
<script>
import * as fbashipAPI from '@/api/fbaship'
import editShipmentDialog from './edit-shipment-dialog'
import fbaSetShipserviceDialog from './fba-set-shipservice-dialog'
import fbaImportCartonSizeDialog from './fba-import-carton-size-dialog'
import fbaCreateOverseasPdtshipDialog from './fba-create-overseas-pdtship-dialog'
import fbaDownloadLabelDialog from './fba-download-label-dialog'
import confirmShipmentDialog from './confirm-shipment-dialog'
import confirm from '@/components/confirm'
import { API } from '@/service/api'

export default {
  components: {
    editShipmentDialog,
    fbaSetShipserviceDialog,
    fbaImportCartonSizeDialog,
    fbaCreateOverseasPdtshipDialog,
    fbaDownloadLabelDialog,
    confirmShipmentDialog
  },
  data () {
    return {
      tabvalue: 'first',
      fbaid: this.$route.params.id,
      step: this.$route.params.step,
      detaildata: {},
      detailshipService: {}
    }
  },
  created () {
    this.getfbaDetail()
    this.getShippingService()
  },
  watch: {
    tabvalue: function () {
      if (this.tabvalue === 'second') {
        this.getShippingService()
      } else {
        this.getfbaDetail()
      }
    }
  },
  methods: {
    // TODO: #I3R1NF
    handleOnekeySync () {
      confirm(
        '提示',
        'FBA入库单创建之后如果在系统外更改明细数量，可用该操作同步Amazon数据。同时系统将更新WMS调拨出入库单的数量。'
      )
        .then(done => {
          // TODO: 接口调用
          const params = {
            ec_id: this.detaildata.ec_id, // 平台ID
            shipment_id: this.fbaid // FBA订单id
          }

          API.Warehouse.syncFBAInboundItem(params)
            .then(() => {
              //
            })
            .finally(() => {
              done()
            })
        })
    },
    getfbaDetail () {
      fbashipAPI
        .getShipmentDetail({ ShipmentId: this.fbaid })
        .then(data => {
          this.detaildata = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getShippingService () {
      fbashipAPI
        .getShipmentServeice({ ShipmentId: this.fbaid })
        .then(data => {
          this.detailshipService = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    refreshData () {
      this.getfbaDetail()
      this.getShippingService()
    },
    resetStatus () {
      this.$confirm('提示', {
        title: '重置status状态',
        message: '确定重置入库单状态？'
      })
        .then(_ => {
          fbashipAPI
            .resetfbaStatus({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.$router.push({
                path: '/FBA/inbound-shipment',
                query: {
                  step: this.step
                }
              })
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    // 同步明细
    updateWMSItems () {
      this.$confirm('提示', {
        title: '提交Shipment',
        message: `该操作将从Amazon重新同步InboundShipment的明细，并刷新对应WMS调拨出入库单的明细。
            确认操作？?`
      })
        .then(_ => {
          fbashipAPI
            .updateItems({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.getfbaDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    // 确认提交Shipment
    confirmShipment () {
      this.$confirm('提示', {
        title: '提交Shipment',
        message: `务必注意，如果是SP自发，系统将设置为手动模式，后续操作请在Amazon后台完成。
           确定提交当前InboundShipment?`
      })
        .then(_ => {
          fbashipAPI
            .confirmShipment({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.getfbaDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    findDocument (src) {
      window.open(src)
    },
    //  费用评估
    estimateTransport () {
      this.$confirm('提示', { title: '费用评估', message: '确定评估当前费用?' })
        .then(_ => {
          fbashipAPI
            .estimateTransport({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.getfbaDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //  费用确认
    confirmTransport () {
      this.$confirm('提示', { title: '费用确认', message: '确认当前费用?' })
        .then(_ => {
          fbashipAPI
            .confirmTransport({ ShipmentId: this.detaildata.ShipmentId })
            .then(data => {
              this.getfbaDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    down () {
      fbashipAPI
        .downloadLabel({
          ShipmentId: this.detaildata.ShipmentId,
          file: ' bill'
        })
        .then(data => {
          const URL = this.dataURLtoBlob(data)
          const reader = new FileReader()
          reader.readAsDataURL(URL)
          reader.onload = function (e) {
            // 兼容IE
            if (window.navigator.msSaveOrOpenBlob) {
              const bstr = atob(e.target.result.split(',')[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
              }
              var blob = new Blob([u8arr])
              window.navigator.msSaveOrOpenBlob(blob, '标签.zip')
            } else {
              // 转换完成，创建一个a标签用于下载
              const a = document.createElement('a')
              a.download = '标签.zip'
              a.href = e.target.result
              a.setAttribute('id', 'export')
              a.click()
              document.getElementById('export').remove()
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    dataURLtoBlob (dataurl) {
      const bstr = atob(dataurl)
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: 'pdf' })
    }
  }
}
</script>
