<template>
  <div>
    <el-row class="content-row">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysConfig.vendorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getpayableBilllist()"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="payBilllist" border>
        <el-table-column type="selection" width="36"></el-table-column>
        <el-table-column prop="vendor_name" label="供应商"></el-table-column>
        <el-table-column prop="bank_account" label="收款账号"></el-table-column>
        <el-table-column prop="_step" label="类型"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column prop="_currency" label="币种"></el-table-column>
        <el-table-column prop="deadline" label="应付日期"></el-table-column>
        <el-table-column prop="bank" label="支付流水/银行"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushpayBillDetail(scope.row.id)"
              >明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as payableBillApi from '../../../../api/payableBill'
export default {
  props: ['step'],
  data () {
    return {
      search: {},
      pageDat: {
        per_page: 10,
        current_page: 1
      },
      payBilllist: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    // 应付款列表接口参数
    listJsondata () {
      return {
        step: this.step,
        vendor_id: this.search.vendor_id,
        data_start: this.search.start,
        data_end: this.search.end,
        page: this.pageDat.current_page,
        per_page: this.pageDat.per_page
      }
    }
  },
  watch: {
    step: function () {
      this.getpayableBilllist()
    }
  },
  created () {
    this.getpayableBilllist()
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.search._date === null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getpayableBilllist () {
      payableBillApi
        .getpayableBillList(this.listJsondata)
        .then(data => {
          this.payBilllist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getpayableBilllist()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getpayableBilllist()
    },
    pushpayBillDetail (paybillid = null) {
      this.$router.push({
        name: 'payableBillDetail',
        params: {
          id: paybillid
        }
      })
    }
  }
}
</script>
