<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">物流管理</el-breadcrumb-item>
      <el-breadcrumb-item>货代计划（新）</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row style="margin-top:20px">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in sysConfig.LogisticsPlan.type"
          :label="item.val"
          :name="item.key.toString()"
          :key="item.key"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <forward-plan-stocklist :type="tabvalue"></forward-plan-stocklist>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import forwardPlanStocklist from './forward-plan-stocklist'
export default {
  components: {
    forwardPlanStocklist
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  data () {
    return {
      tabvalue: '1',
      tabs: [
        {
          key: '1',
          val: '采购直发'
        },
        {
          key: '2',
          val: '跨境调拨'
        }
      ]
    }
  }
}
</script>
