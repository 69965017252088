const TYPE = {
  FINANCE_PROCUREMENT_IN_OUT: 'FINANCE_PROCUREMENT_IN_OUT',
  FINANCE_ALLOT_IN_OUT: 'FINANCE_ALLOT_IN_OUT',
  FINANCE_BREAKAGE_ADJUSTMENT: 'FINANCE_BREAKAGE_ADJUSTMENT',
  FINANCE_SALES_IN_OUT: 'FINANCE_SALES_IN_OUT',
  FINANCE_MONTHLY_BALANCE: 'FINANCE_MONTHLY_BALANCE',
  SALES_WAYFAIR_ORDER_DETAIL: 'SALES_WAYFAIR_ORDER_DETAIL',
  SALES_WAYFAIR_STOCK_OUT_RECORD: 'SALES_WAYFAIR_STOCK_OUT_RECORD',
  SALES_WAYFAIR_REFUND: 'SALES_WAYFAIR_REFUND',
  SALES_WAYFAIR_ORDER: 'SALES_WAYFAIR_ORDER',
  DCENTER_PURCHASE_DETAIL: 'DCENTER_PURCHASE_DETAIL',
  DCENTER_PURCHASE_ORDER: 'DCENTER_PURCHASE_ORDER',
  AMZ_FINANCE_SHIPMENT: 'AMZ_FINANCE_SHIPMENT',
  AMZ_FINANCE_REFUND: 'AMZ_FINANCE_REFUND',
  AMZ_FINANCE_ADJUSTMENT: 'AMZ_FINANCE_ADJUSTMENT',
  WMS_LOGISTICS_APPLY: 'WMS_LOGISTICS_APPLY',
  PURCHASE_TRACKING_LIST: 'PURCHASE_TRACKING_LIST',
  SALES_LOGISTICS_PLAN: 'SALES_LOGISTICS_PLAN'
}

export default TYPE
