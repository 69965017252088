import request from '../config/request'

/** 货代计划 */
export const forwardPlan = data => {
  return request('purchase/transport', 'get', data, true)
}
/** 创建货代单 */
export const createForward = data => {
  return request('purchase/transport/createOrder', 'post', data)
}
/** 货代单列表 */
export const forwardOrderList = data => {
  return request('purchase/transport/order', 'get', data, true)
}
/** 添加到货代单 */
export const addToOrder = data => {
  return request('purchase/transport/addToOrder', 'post', data)
}
