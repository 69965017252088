/**
 * 根据给定的fields生成可用的httpparams对象
 * @param {*} fields
 * @returns
 */
export function generateParams (fields = []) {
  return fields.reduce((o, c) => {
    o[c.key] = c.defaultValue || null
    return o
  }, {})
}
