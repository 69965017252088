<template>
  <div class="create-prepayment-second" v-if="data.cache">
    <el-row class="title-row">
      <div class="bottom-line">
        供应商
        <span>{{ vendor.name }}</span>
      </div>
    </el-row>

    <div v-for="item in excel" :key="item.id">
      <el-row class="title-row">
        <el-col :span="6">
          <div>
            采购单
            <span>{{ item.id }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            定金
            <span>{{ item._prepay_ratio }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            已请款
            <span>{{ item.prepayment_amount }} {{ item._currency }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            可请款
            <span>{{ item.prepayable_amount }} {{ item._currency }}</span>
          </div>
        </el-col>
      </el-row>
      <el-table
        show-summary
        :data="item.item"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column label="品名编号">
          <template slot-scope="scope">
            <el-row type="flex" v-if="scope.row.product_id">
              <img width="80" height="80" :src="scope.row._product_cover" />
              <div style="margin-left: 10px;">
                <p>{{ scope.row._product_name }}</p>
                <p>{{ scope.row._product_mfn }}</p>
              </div>
            </el-row>
            <el-row v-else>其他款项</el-row>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="交付数"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column
          prop="prepayment_amount"
          label="应付金额"
        ></el-table-column>
      </el-table>
    </div>
    <br />
    <el-row>
      <el-button type="primary" @click="nextHandle">下一步</el-button>
    </el-row>
  </div>
</template>
<script>
import * as purchaeFinanceApi from '@/api/purchaseFinance'

export default {
  props: ['data'],
  data () {
    return {
      search: {},
      prepaymentForm: {},
      excel: this.data.report.excel,
      vendor: this.data.report.vendor
    }
  },

  watch: {
    data: function (val) {
      this.excel = val.report.excel
      this.vendor = val.report.vendor
    }
  },

  computed: {},
  methods: {
    saveHandle () {
      const data = {
        cache: this.data.cache,
        order: null
      }
      purchaeFinanceApi.confirmPrepaymentReport(data).then(res => {
        this.$router.push({ name: 'prepayment' })
      })
    },
    nextHandle () {
      this.$emit('nextHandle', 'third')
    },
    currentSelectionChange (row) {
      this.$refs.multipleTable.clearSelection()
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    handleSelectionChange (val) {
      this.selectPayment = val

      // console.log(val)
    }
  }
}
</script>
<style></style>
