<template>
  <div class="prepayment-order">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>请款管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-tabs v-model="search.step" @tab-click="handleTabs">
      <el-tab-pane
        v-for="item in stepList"
        :key="item.key"
        :label="item.val"
        :name="item.key + ''"
      >
        <el-row>
          <el-form :inline="true">
            <el-form-item>
              <el-input
                class="search-bar"
                v-model="search.id"
                placeholder="请款单号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                clearable
                v-model="search.vendor_id"
                placeholder="供应商"
                filterable
              >
                <el-option
                  v-for="item in vendor"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="付款期限">
              <el-date-picker
                v-model="date"
                @change="setDate()"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="searchHandle">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <el-table
          :data="orderList"
          stripe
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            prop="id"
            width="55"
            label="请款单号"
          ></el-table-column>
          <el-table-column prop="vendor_name" label="供应商"></el-table-column>
          <el-table-column label="金额">
            <template slot-scope="scope">
              <span>{{ scope.row.amount }} {{ scope.row._currency }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deadline" label="付款期限"></el-table-column>
          <el-table-column prop="created_at" label="创建时间"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button type="text" @click="detail(scope.row.id)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <br />

        <el-row class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="search.page"
            :page-sizes="[15, 20, 30, 50, 100, 200]"
            layout="total, sizes, prev, pager, next"
            :total="search.total"
            :page-size.sync="search.num_per_page"
          ></el-pagination>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import * as financeApi from '@/api/purchaseFinance'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      orderList: [],
      vendor: [],
      currency: [],
      stepList: [],

      search: {
        vendor_id: '',
        step: '1',
        prepayment_id: '',
        start_date: '',
        end_date: '',
        page: 1,
        num_per_page: 15,
        total: 0
      },
      date: ''
    }
  },

  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    // this.search.step = '0'
    this.stepList = this.sysConfig.FinancePurchasePrepayment.step
    this.prepaymentOrderList()
    this.vendor = this.sysConfig.vendorList
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start_date = this.date[0]
        this.search.end_date = this.date[1]
      }
    },
    detail (id) {
      this.$router.push({
        name: 'prepaymentOrderDetail',
        query: {
          id: id
        }
      })
    },
    prepaymentOrderList () {
      financeApi.prepaymentOrder(this.search).then(res => {
        this.orderList = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.num_per_page = parseInt(res.per_page)
      })
    },

    searchHandle () {
      this.prepaymentOrderList()
    },

    handleTabs (tab, event) {
      this.search.step = tab.name
      this.prepaymentOrderList()
    },

    // 跳轉到申請預付款
    createPrepayment () {
      this.$router.push({ name: 'prepaymentCreate' })
    },
    handleSelectionChange (val) {
      this.selectedList = val
      this.vendorName = val[0] && val[0].vendor_name
      this.ids = val.map(item => item.id)
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.prepaymentOrderList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.num_per_page = pagesize
      this.prepaymentOrderList()
    }
  }
}
</script>

<style></style>
