import types from './types'
import * as rootController from '../api/rootController'

const actions = {
  adminLogin ({ commit }) {
    commit(types.ADMIN_LOGIN)
  },
  adminLogout ({ commit }) {
    commit(types.ADMIN_LOGOUT)
  },
  createImage (store, payload) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader()
      reader.readAsDataURL(payload.file)
      reader.onload = e => {
        resolve(e.target.result)
      }
      if (!payload.type.test(payload.file.name)) {
        reader.abort()
        reject(new Error('type'))
      }
      if (payload.file.size > payload.size * 1024 * 1024) {
        reader.abort()
        reject(new Error('size'))
      }
    })
  },
  setSysConfig ({ commit }) {
    var sysConfig = {}
    // 获取config 、warehouse、vendor 等接口数据合并成sysConfig
    rootController
      .getSysConfig()
      .then(data => {
        // sysConfig.Common = data.Common
        // sysConfig.PurchaseOrder = data.PurchaseOrder
        // sysConfig.FinancePurchasePrepayment = data.FinancePurchasePrepayment
        sysConfig = data
        rootController
          .getWarehouselist()
          .then(warehouse => {
            sysConfig.warehouselist = warehouse.data
            // 获取vendor
            rootController
              .getVendorlist({ simple: 1 })
              .then(res => {
                sysConfig.vendorList = res
                commit(types.GETSYS_CONFIG, sysConfig)
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }
}
export default actions
