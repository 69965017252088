const ENUM_STORAGE = {
  TOKEN: 'et_token',
  USER_INFO: 'et_user_info',
  // TODO: 系统预设项， 后期考虑使用service worker 重构
  SYSTEM_CONFIG: 'et_system_config'
}

const lsg = window.localStorage

class Storage {
  #token = null
  #userInfo = null
  #systemConfig = null
  constructor () {
    this.#token = lsg.getItem(ENUM_STORAGE.TOKEN)
    this.#userInfo = lsg.getItem(ENUM_STORAGE.USER_INFO)
    this.#systemConfig = lsg.getItem(ENUM_STORAGE.SYSTEM_CONFIG)
  }

  get token () {
    return this.#token
  }

  set token (tk) {
    this.#token = tk
    lsg.setItem(ENUM_STORAGE.TOKEN, tk)
  }

  clearToken () {
    lsg.removeItem(ENUM_STORAGE.TOKEN)
  }
}

export default new Storage()
