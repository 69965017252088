import { baseUrl } from './env'
import axios from 'axios'
import router from '@/router'
import { Notification, Message } from 'element-ui'

axios.defaults.baseURL = baseUrl

axios.defaults.headers.post['X-Request-With'] = 'XMLHttpRequest'

axios.interceptors.response.use(
  function (res) {
    return res
  },
  function (error) {
    console.log(error.response)
    if (error.response.status !== 200) {
      // Notification.error({
      //   message: '服务器错误',
      //   duration: 0,
      //   offset: 200
      // })
      Message.error({
        message: '服务器错误!!!立即联系IT人员',
        center: true,
        offset: 200,
        showClose: true,
        duration: 0
      })
    }
  }
)

export default async (url, method, data = {}, forbid, responseType = null) => {
  let response
  if (localStorage.getItem('token')) {
    // axios.defaults.headers.common['Authorization'] = 'Bearer' + sessionStorage.getItem('token')
    axios.defaults.headers.common.Authorization =
      'Bearer' + localStorage.getItem('token')
    // axios.defaults.headers.common['Authorization'] = 'Token kane-52653784'
  }
  if (method === 'get') {
    const dat = {
      params: data
    }
    if (responseType) {
      dat.responseType = responseType
    }
    response = await axios.get(url, dat)
  } else if (method === 'post') {
    response = await axios.post(url, data)
  }
  if (response.data.res === 1) {
    if (!forbid) {
      Notification.success({
        message: response.data.msg,
        duration: 1500
      })
    }
    // 在登入成功处保存token， 这里废弃
    // if (response.data) {
    //   if (response.data.dat) {
    //     if (response.data.dat.token) {
    //       sessionStorage.setItem('token', response.data.dat.token)
    //     }
    //   }
    // }
    // console.log(response.data)
    return Promise.resolve(response.data.dat)
  } else if (response.data.res === -2) {
    Notification.error({
      message: response.data.msg,
      duration: 5000
    })
    return Promise.reject(response.data)
  } else if (response.data.res === 0) {
    if (response.data.dat) {
      Notification.error({
        message: response.data.msg + response.data.dat,
        duration: 0
      })
    } else {
      Notification.error({
        message: response.data.msg,
        duration: 5000
      })
    }
    // return Promise.reject(response.data.msg)
    return Promise.reject(response.data)
  } else if (response.data.res === -1) {
    router.push({ path: '/login' })
  } else {
    Notification.error({
      message: response.data.msg,
      duration: 1500
    })
    return Promise.reject(response.data.msg)
  }
}

export { axios }
