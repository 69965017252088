<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>供应商管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-tabs v-model="tabsvule">
      <el-tab-pane label="基础资料" name="first">
        <base-vendor @send-vendorid="setvendoid"></base-vendor>
      </el-tab-pane>
      <el-tab-pane label="开票信息" name="second" :disabled="true">
        <invoice-info :vendorid="vendorid"></invoice-info>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import baseVendor from './search'
import invoiceInfo from './invoice-info'
export default {
  components: {
    baseVendor,
    invoiceInfo
  },
  data () {
    return {
      tabsvule: 'first',
      vendorid: ''
    }
  },
  methods: {
    setvendoid (id) {
      this.vendorid = id
      this.tabsvule = 'second'
    }
  }
}
</script>
