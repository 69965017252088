<template>
  <div>
    <el-row class="content-searcha">
      <el-form :inline="true" size="small">
        <!-- <el-form-item>
          <WarehouseMultSelector
            v-model="search.warehouseId"
            placeholder="请选择仓库"
            clearable
          />
        </el-form-item> -->
        <el-form-item>
          <el-date-picker
            v-model="search.yearmonth"
            type="month"
            value-format="yyyyMM"
            placeholder="请选择年月"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAllotCountlist()"
            >搜索</el-button
          >
          <!-- <el-button type="primary" @click="downloadLink">导出下载</el-button> -->
        </el-form-item>
      </el-form>
    </el-row>
    <el-row >
      <el-table stripe :data="allotCountlist" border width="100%">
        <el-table-column
          prop="saas"
          label="公司主体"
        ></el-table-column>
        <el-table-column
          prop="warehouse_from"
          label="调出仓库"
        ></el-table-column>
        <el-table-column prop="warehouse_to" label="调入仓库"></el-table-column>
        <el-table-column prop="cost_amount" label="调出成本"></el-table-column>
        <el-table-column prop="amount" label="报关金额">
          <!-- <template slot-scope="scope">
            <span v-if="scope.row.remark === null">
              /
            </span>
            <span v-else>
              {{ scope.row.remark }}
            </span>
          </template> -->
        </el-table-column>
      </el-table>
    </el-row>
    <!-- <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 300, 500]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import * as warehouseBillApi from '../../../../api/warehouseBill'
// import WarehouseMultSelector from '../WarehouseMultSelector'
export default {
  // components: {
  //   WarehouseMultSelector
  // },
  props: ['type'],
  data () {
    return {
      search: {
        yearmonth: ''
      },
      allotCountlist: [],
      pageData: {
        current_page: 1,
        per_page: 100
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    type: function () {
      this.getAllotCountlist()
    }
  },
  // created () {
  //   let date = new Date()
  //   this.search.yearmonth =
  //     date.getFullYear().toString() + ('0' + (date.getMonth() + 1)).slice(-2)
  //   this.getAllotCountlist()
  // },
  methods: {
    getAllotCountlist () {
      const params = {
        term: this.search.yearmonth
        // page: this.pageData.current_page,
        // per_page: this.pageData.per_page
      }
      warehouseBillApi
        .getAllotCountlist(params)
        .then(data => {
          this.allotCountlist = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getAllotCountlist()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getAllotCountlist()
    },
    downloadLink () {
      const url =
        baseUrl +
        'finance/wms/exportTransferSum?token=' +
        localStorage.getItem('token') +
        '&type=' +
        this.type +
        '&time=' +
        this.search.yearmonth +
        '&warehouse_id=' +
        (this.search.warehouseId && Array.isArray(this.search.warehouseId)
          ? this.search.warehouseId.join(',')
          : '')

      window.open(url)
    }
  }
}
</script>
