<template>
  <el-dialog title="添加至货代单" :visible.sync="visible" width="1200px">
    <el-form label-position="left">
      <el-table
        :data="datList"
        ref="multipleTable"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        @current-change="currentSelectionChange"
      >
        <el-table-column label="名称">
          <template v-slot="scope">
            <el-radio :label="scope.row.id + ''" v-model="radio">{{
              scope.row.name
            }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column label="目的仓库" prop="warehouseName">
        </el-table-column>
        <el-table-column label="发货方式" prop="_transport_type">
        </el-table-column>
        <el-table-column label="预计发货日期" prop="date"> </el-table-column>
      </el-table>
    </el-form>

    <span slot="footer">
      <el-button size="small" type="primary" @click="addToOrder"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as forwardApi from '@/api/forward'
import * as forwardOrderApi from '@/api/forwardOrder'
import { Message } from 'element-ui'

export default {
  props: ['selectItem', 'orderid', 'type'],
  data () {
    return {
      visible: false,
      datList: [],
      selectId: null,
      radio: '',
      filterId: this.orderid
    }
  },
  computed: {},
  created () {
    forwardApi.forwardOrderList({ step: 0 }).then(res => {
      this.datList = res.data.filter(item => item.id !== this.filterId)
    })
  },

  methods: {
    addToOrder () {
      if (this.selectId === null) {
        Message({
          type: 'error',
          message: '请选择一项'
        })
        return false
      }

      console.log(this.type)

      if (this.type === 'move') {
        // 移动货代单明细
        const data = {
          from: this.orderid,
          to: this.selectId,
          item: this.selectItem
        }
        forwardOrderApi.moveTransportOrderDetailItem(data).then(res => {
          this.$emit('change')
          this.hide()
        })
      } else {
        // 追加至货代单
        const data = {
          id: this.selectId,
          item: this.selectItem
        }
        forwardApi.addToOrder(data).then(res => {
          this.$emit('change')
          this.hide()
        })
      }
    },
    currentSelectionChange (row) {
      this.selectId = row.id
      this.radio = row.id + ''
    },

    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
