import Vue from 'vue'
import Vuex from 'vuex'
// import plugins from './plugins/plugins'
import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

// const PersistedState = createPersistedState({
//   storage: window.sessionStorage,
//   reducer (val) {
//     return {
//       sysConfig: val.sysConfig
//     }
//   }
// })
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer (val) {
        return {
          sysConfig: val.sysConfig
        }
      }
    })
  ]
})
