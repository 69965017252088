import request from '@/config/request'

export const getinboundShipmentList = data => {
  return request('fba/inboundShipment', 'get', data, true).then(data => {
    return data
  })
}

export const getShipmentDetail = data => {
  return request('fba/inboundShipment/detail', 'get', data, true).then(data => {
    return data
  })
}
//  重置 Shipment 状态
export const resetfbaStatus = data => {
  return request('fba/inboundShipment/resetStatus', 'post', data, true).then(
    data => {
      return data
    }
  )
}
//  重置 Shipment step回计划中
export const resetToPlan = data => {
  return request('fba/inboundShipment/resetToPlan', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const editShipment = data => {
  return request('fba/inboundShipment/update', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const deleteShipment = data => {
  return request('fba/inboundShipment/delete', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const editShipmentAddress = data => {
  return request('fba/inboundShipment/updateAddr', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const editShipmentItem = data => {
  return request('fba/inboundShipment/updateItem', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const deleteShipmentItem = data => {
  return request('fba/inboundShipment/deleteItem', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const getShipmentServeice = data => {
  return request(
    'fba/inboundShipment/getTransportContent',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}

export const getshippingServicesParam = data => {
  return request(
    'fba/inboundShipment/shippingServicesParam',
    'get',
    data,
    true
  ).then(data => {
    return data
  })
}

export const setTransportContent = data => {
  return request(
    'fba/inboundShipment/setTransportContent',
    'post',
    data,
    true
  ).then(data => {
    return data
  })
}

export const createoutbound = data => {
  return request('fba/inboundShipment/outbound', 'post', data, true).then(
    data => {
      return data
    }
  )
}

export const confirmShipment = data => {
  return request('fba/inboundShipment/confirm', 'post', data, true).then(
    data => {
      return data
    }
  )
}
//  费用评估（仅限SPD,LTL 自提）
export const estimateTransport = data => {
  return request(
    'fba/inboundShipment/estimateTransport',
    'post',
    data,
    true
  ).then(data => {
    return data
  })
}
//  确认费用（仅限SPD,LTL 自提）
export const confirmTransport = data => {
  return request(
    'fba/inboundShipment/confirmTransport',
    'post',
    data,
    true
  ).then(data => {
    return data
  })
}

export const downloadLabel = data => {
  return request('fba/inboundShipment/downloadLabel', 'get', data, true).then(
    data => {
      return data
    }
  )
}
//  更新shipment 明细
export const updateItems = data => {
  return request('fba/inboundShipment/updateItems', 'get', data, true).then(
    data => {
      return data
    }
  )
}
