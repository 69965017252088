<template>
  <div>
    <el-dialog title="导入采购入库单" :visible.sync="visible" width="500px">
      <el-form
        :model="dataimport"
        :inline="true"
        size="small"
        :rules="rules"
        ref="stockimport"
      >
        <el-form-item label="入库仓库" prop="warehouseId">
          <el-select v-model="dataimport.warehouseId">
            <el-option
              v-for="item in sysConfig.warehouselist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="20px">
          <el-upload
            :action="importInboundOrder"
            :limit="1"
            name="file"
            :multiple="false"
            :on-success="onUploadSuccess"
            :before-upload="handleBeforeUpload"
            :on-error="onUploadError"
            :auto-upload="false"
            :show-file-list="true"
            ref="upload"
            :data="ruledata"
          >
            <el-button size="small" type="primary">选取excel文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="更新可发数量:">
          <el-switch
            v-model="syncToShipable"
            active-value="1"
            inactive-value="0"
            active-color="#13ce66"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label-width="20px" label>
          <p>
            务必保证导入文件使用模板格式<el-link
              type="primary"
              :href="downloadTemplate"
              target="_blank"
              >purchase-inbound-tpl.xls</el-link
            >
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="hide">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirm"
          >上 传</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env.js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      dataimport: {},
      visible: false,
      rules: {
        warehouseId: [
          { required: true, message: '请选择仓库', trigger: 'blur' }
        ]
      },
      syncToShipable: 0
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    importInboundOrder () {
      return (
        baseUrl +
        'wms/order/importOrder?token=' +
        localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return baseUrl + 'wms/order/tpl?token=' + localStorage.getItem('token')
    },
    ruledata () {
      return {
        warehouse_id: this.dataimport.warehouseId,
        syncToShipable: this.syncToShipable
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.dataimport = {}
      this.visible = false
    },
    handleConfirm () {
      const self = this
      self.$refs.stockimport.validate(valid => {
        if (valid) {
          self.$refs.upload.submit()
        }
      })
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import-stockinwarehouse')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
