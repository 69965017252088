<template>
  <el-dialog
    title="编辑明细"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-form
      :inline="true"
      label-width="120px"
      size="small"
      :model="detaildata"
    >
      <el-form-item label="MFN" v-if="detaildata.product">
        {{ detaildata.product.mfn }}
      </el-form-item>
      <el-form-item label="SKU">
        <el-input placeholder="非必填" v-model="detaildata.sku"></el-input>
      </el-form-item>
      <el-form-item label="供应商SKU">
        <el-input
          placeholder="非必填"
          v-model="detaildata.vendor_sku"
        ></el-input>
      </el-form-item>
      <el-form-item label="采购总数">
        <el-input v-model="detaildata.qty"></el-input>
      </el-form-item>
      <el-form-item label="装箱率">
        <el-input v-model="detaildata.qty_ctn"></el-input>
      </el-form-item>
      <el-form-item label="采购价格">
        <el-input v-model="detaildata.price"></el-input>
      </el-form-item>
      <el-form-item label="套系">
        <el-input v-model="detaildata.sellpack"></el-input>
      </el-form-item>
      <el-form-item label="外箱长（CM）">
        <el-input v-model="detaildata.ctn_length"></el-input>
      </el-form-item>
      <el-form-item label="外箱宽（CM）">
        <el-input v-model="detaildata.ctn_width"></el-input>
      </el-form-item>
      <el-form-item label="外箱高（CM）">
        <el-input v-model="detaildata.ctn_height"></el-input>
      </el-form-item>
      <el-form-item label="外箱净重（KG）">
        <el-input v-model="detaildata.ctn_weight"></el-input>
      </el-form-item>
      <el-form-item label="外箱毛重（KG）">
        <el-input v-model="detaildata.ctn_g_weight"></el-input>
      </el-form-item>
      <el-form-item label="内盒长（CM）">
        <el-input v-model="detaildata.box_length"></el-input>
      </el-form-item>
      <el-form-item label="内盒宽（CM）">
        <el-input v-model="detaildata.box_width"></el-input>
      </el-form-item>
      <el-form-item label="内盒高（CM）">
        <el-input v-model="detaildata.box_height"></el-input>
      </el-form-item>
      <el-form-item label="内盒净重（KG）">
        <el-input v-model="detaildata.box_weight"></el-input>
      </el-form-item>
      <el-form-item label="内盒毛重（KG）">
        <el-input v-model="detaildata.box_g_weight"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :rows="5"
          v-model="detaildata.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click.prevent="saveItemDetail()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { editOrderItemDetail } from '@/api/stockOrder'
export default {
  data () {
    return {
      visible: false,
      detaildata: {}
    }
  },
  methods: {
    show (detail) {
      this.visible = true
      this.detaildata = detail
    },
    closedone (done) {
      done()
    },
    saveItemDetail () {
      console.log(this.detaildata)
      editOrderItemDetail(this.detaildata)
        .then(data => {
          this.$emit('edit-item-success')
          this.visible = false
          this.detaildata = {}
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped></style>
