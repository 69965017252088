<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>亚马逊</el-breadcrumb-item>
        <el-breadcrumb-item>账期列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="nav">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select v-model="search.ecId" placeholder="ec" clearable style="width: 120px;">
            <el-option
              v-for="item in sysConfig.Common.ec"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="search.marketplace" placeholder="站点" clearable style="width: 120px;">
            <el-option
              v-for="item in sysConfig.Common.marketplace"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="search.status"
            placeholder="账期状态"
            clearable
            v-if="sysConfig.AmazonFinanceGroup"
            style="width: 120px;"
          >
            <el-option
              v-for="item in sysConfig.AmazonFinanceGroup.ProcessingStatus"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search.groupStart"
            type="month"
            value-format="yyyy-MM"
            placeholder="选择年月"
            style="width: 120px;"
          >
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input type="text" v-model="search.finance_id" placeholder="账期id" style="width: 120px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchList()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-table
        border
        stripe
        width="100%"
        :data="financeList"
        @select="handleSelect"
        @select-all="handleSelectall"
      >
        <el-table-column type="selection" width="40px"></el-table-column>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="ec" prop="_ec"></el-table-column>
        <el-table-column label="起始时间" prop="FinancialEventGroupStart"></el-table-column>
        <el-table-column label="结束时间" prop="FinancialEventGroupEnd"></el-table-column>
        <el-table-column label="到账金额" prop="ConvertedTotal">
          <template slot-scope="scope">
            {{ scope.row.ConvertedTotal }}&nbsp;&nbsp;{{ scope.row.ConvertedCurrency }}
          </template>
        </el-table-column>
        <el-table-column label="原始金额" prop="OriginalTotal">
          <template slot-scope="scope">
            {{ scope.row.OriginalTotal }}&nbsp;&nbsp;{{ scope.row.BeginningBalanceCurrency }}
          </template>
        </el-table-column>
        <el-table-column label="账期状态" prop="FundTransferStatus"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="$refs['detail-dialog'].show(scope.row)">查看</el-button>
            <el-button type="text" @click="$refs['liquidation_dialog'].show(scope.row)">清算</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:10px;">
      <el-col :span="3">
        <el-button type="primary" size="small" @click="downLoadLink()"
        >导出excel
        </el-button
        >
      </el-col>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="search.page"
        :page-size="parseInt(search.per_page)"
        :page-sizes="[50,100,200]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="search.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <detail-dialog ref="detail-dialog"></detail-dialog>
    <liquidation-dialog ref="liquidation_dialog" @setSucc="getListData"></liquidation-dialog>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env'
import { getFinanceList } from '@/api/financeAmazon'
import detailDialog from './detail-dialog'
import liquidationDialog from './liquidation-dialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    detailDialog,
    liquidationDialog
  },
  data () {
    return {
      // pageData: {
      //     current_page: 1,
      //     per_page: 10
      // },
      financeList: [],
      financeId: [],
      search: {
        ecId: '',
        groupStart: '',
        status: '',
        marketplace: '',
        finance_id: '',
        per_page: 10,
        page: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  mounted () {
    this.getListData()
  },
  methods: {
    getListData () {
      // let jsonData = {
      //     per_page: this.pageData.per_page,
      //     page: this.pageData.current_page,
      // }
      getFinanceList(this.search)
        .then(data => {
          this.financeList = data.data
          this.search.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSelect (selection, row) {
      this.financeId = selection.map(item => {
        return item.id
      })
    },
    handleSelectall (selection) {
      this.financeId = selection.map(item => {
        return item.id
      })
    },
    checkSelected () {
      if (this.financeId.length === 0) {
        this.$alert('请至少选择一个账期')
        return false
      }
      return true
    },
    downLoadLink () {
      if (this.checkSelected()) {
        const url =
          baseUrl +
          'finance/amz/exportFinanceAllData?token=' +
          localStorage.getItem('token') +
          '&id=' +
          this.financeId.join(',')
        window.open(url)
      }
    },
    searchList () {
      this.search.per_page = 10
      this.search.page = 1
      this.getListData()
    },
    changePageHandler (currentPage) {
      this.search.page = currentPage
      this.getListData()
    },
    changeSizeHandler (size) {
      this.search.per_page = size
      this.getListData()
    }
  }
}
</script>
