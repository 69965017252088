import request from '../config/request'

export const getpayableBillList = data => {
  return request('finance/purchase/payableBill/search', 'get', data)
}

//  应付款详情
export const getpayableBillDetail = data => {
  return request('finance/purchase/payableBill/detail', 'get', data).then(
    data => {
      return data
    }
  )
}
