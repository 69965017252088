<template>
  <el-dialog
    :visible.sync="visible"
    title="修改报销数量"
    width="600px"
    :close-on-click-modal="false"
  >
    <el-form label-position="left" label-width="120px">
      <el-form-item label="采购单:">
        {{ formdata.purchase_id }}
      </el-form-item>
      <el-form-item label="采购入库单:">
        {{ formdata.wms_id }}
      </el-form-item>
      <el-form-item label="MFN/SKU:">
        {{ formdata.mfn }}
      </el-form-item>
      <el-form-item label="采购总数">
        {{ formdata.qty_total }}
      </el-form-item>
      <el-form-item label="报销数量">
        <el-input v-model="formdata.qty" :max="formdata.qty"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="reBack">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { rebackDeliver } from '@/api/purchaseFinanceNew'
export default {
  data () {
    return {
      visible: false,
      formdata: {},
      id: '',
      deid: ''
    }
  },
  methods: {
    show (id, purchaseId, wmsId, purchasemdata) {
      this.id = id
      this.formdata.purchase_id = purchaseId
      this.formdata.wms_id = wmsId
      this.deid = purchasemdata.invoice_id
      this.formdata.mfn = purchasemdata.mfn
      this.formdata.qty_total = purchasemdata.qty_total
      this.$set(this.formdata, 'qty', purchasemdata.qty)
      // this.formdata.qty = purchasemdata.qty
      this.visible = true
    },
    reBack () {
      rebackDeliver({
        id: this.id,
        invoice_id: this.deid,
        qty: this.formdata.qty
      })
        .then(data => {
          this.$emit('reback-success')
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.formdata = {}
      this.visible = false
    }
  }
}
</script>
