<template>
  <el-dialog title="导入商品明细" :visible.sync="visible" width="500px">
    <el-form label-position="left">
      <el-form-item label-width="10px">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="excel"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
          :data="ruledata"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="10px" label>
        <p>
          导入的商品格式务必遵循模板格式
          <el-link type="primary" :href="downloadTemplate" target="_blank"
            >product-template.xls</el-link
          >
          <br />
          若忽略未填写字段仅更新有数据的字段，相应商品的id列请填写“1”，<br />
          反之，留空表示所有数据（包括空数据）都导入系统。该方式也适用于创建新品，id标记为“1”，仅导入有数据的字段，否则全部导入。
        </p>
      </el-form-item>
      <el-form-item label="邮箱通知">
        <el-input
          v-model="emaillist"
          type="textarea"
          :rows="5"
          placeholder="成功导入产品后，系统将把新增产品信息推送给相关人员:一行一个邮箱，如不通知请留空"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="hide">取 消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >上 传</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
export default {
  data () {
    return {
      visible: false,
      // ruledata: {
      //   email: ''
      // },
      emaillist: ''
    }
  },
  computed: {
    //
    importInboundOrder () {
      return (
        baseUrl + 'product/io/import?token=' + localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return baseUrl + 'product/io/tpl?token=' + localStorage.getItem('token')
    },
    ruledata () {
      return {
        email: this.emaillist.split('\n')
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import-product')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
