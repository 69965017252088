<template>
  <el-dialog title="创建报销单" :visible.sync="visible" width="600px">
    <el-form
      :model="formDat"
      ref="thisForm"
      label-position="left"
      size="small"
      :rules="rules"
    >
      <el-form-item label-width="90px" label="供应商">{{
        propsDat ? propsDat.vendor_name : ''
      }}</el-form-item>

      <el-form-item label-width="90px" prop="bank_name" label="收款单位">
        <el-input v-model="formDat.bank_name" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item label-width="90px" prop="bank" label="开户银行">
        <el-input v-model="formDat.bank" style="width:220px "></el-input>
      </el-form-item>
      <el-form-item label-width="90px" prop="bank_account" label="账户">
        <el-input
          v-model="formDat.bank_account"
          style="width:220px "
        ></el-input>
      </el-form-item>
      <el-form-item label-width="90px" prop="deadline" label="付款期限">
        <el-date-picker
          v-model="formDat.deadline"
          type="date"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          placeholder="选择日期"
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click.prevent="onSubmit" type="primary">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as reimbursementApi from '@/api/reimbursement'
import * as purchaseFinanceApi from '@/api/purchaseFinance'
export default {
  props: ['ids', 'propsDat', 'lastRecord'],
  data () {
    return {
      visible: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 1
        }
      },
      formDat: {},
      rules: {
        bank_name: [{ required: true, message: '请输入', trigger: 'blur' }],
        bank: [{ required: true, trigger: 'blur' }],
        bank_account: [{ required: true, trigger: 'blur' }],
        deadline: [{ required: true, trigger: 'change' }]
      },
      idds: this.ids
    }
  },
  computed: {},
  watch: {
    lastRecord: function (val) {
      console.log(val)
      if (val == null) return false
      this.formDat = val
    },
    propsDat: {
      handler (newValue, oldValue) {
        if (this.propsDat != null) {
          purchaseFinanceApi
            .getlastPaymentRecord({ vendor_id: this.propsDat.vendor_id })
            .then(data => {
              this.formDat = data
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      deep: true
    }
  },
  methods: {
    onSubmit () {
      Object.assign(this.formDat, this.propsDat, { item: this.ids })
      // console.log(this.formDat)
      // return false
      this.$refs.thisForm.validate(valid => {
        if (valid) {
          reimbursementApi.createReimbursementOrder(this.formDat).then(res => {
            this.hide()
            this.$emit('create-end')
          })
        } else {
          return false
        }
      })
    },
    show () {
      this.visible = true
      console.log(this.propsDat)
    },
    hide () {
      this.visible = false
    }
  }
}
</script>
