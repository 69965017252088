import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/index.js'
// import Rollbar from 'rollbar'

import { install } from '@/plugin'
import { initCore } from '@/core'

import '@/style/index.css'

install()
initCore()

/*
Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true, // true by default
        // -- Add this into your configuration ---
        code_version: process.env.CODE_VERSION,
        guess_uncaught_frames: true
      }
    }
  }
})
*/

Vue.config.errorHandler = (err, vm, info) => {
  // vm.$rollbar.error(err) 临时注释掉rollbar的功能
  throw err // rethrow
}

const isDev = process.env.NODE_ENV === 'staging'
Vue.config.devtools = isDev
Vue.config.productionTip = isDev
// Vue.prototype.$stripe = Stripe('pk_test_Ri3mOlLLPVQPHRCRXnjHfexA')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
