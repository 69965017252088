var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":3,"offset":18}},[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.downloadExcel()}}},[_vm._v("导出下载")])],1)],1),_c('el-row',[_c('el-table',{ref:"warrantDetailTable",attrs:{"stripe":"","data":_vm.orderdetail.item,"border":"","show-summary":"","summary-method":_vm.getSummaries}},[_c('el-table-column',{attrs:{"prop":"name,mfn","label":"货品","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('product-info-in-table-cell',{attrs:{"cover":scope.row.cover,"title":scope.row.name,"mfn":scope.row.mfn}})]}}])}),_c('el-table-column',{attrs:{"prop":"qty_ctn","label":"装箱率","width":"80"}}),_c('el-table-column',{attrs:{"prop":"ctn_width,ctn_length,ctn_height,ctn_weight","label":"外箱材积","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.ctn_length != null)?_c('span',[_vm._v(" "+_vm._s(scope.row.ctn_length)+" * "+_vm._s(scope.row.ctn_width)+" * "+_vm._s(scope.row.ctn_height)+" "),_c('br'),(scope.row.ctn_g_weight != null)?_c('span',[_vm._v(" "+_vm._s(scope.row.ctn_g_weight)+"kg"),_c('br')]):_vm._e()]):_vm._e(),_vm._v(" "+_vm._s(scope.row.ctn_volume_m)+"m"),_c('sup',[_vm._v("3")])]}}])}),_c('el-table-column',{attrs:{"prop":"label","label":"箱标","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.label)+" "),_c('br'),(
              scope.row._product_box_package_dimension &&
                scope.row._product_box_package_dimension.sellpack > 1
            )?_c('el-tag',[_vm._v("套"+_vm._s(scope.row._product_box_package_dimension.sellpack))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"purchase_order_id","label":"采购单","width":"120"}}),_c('el-table-column',{attrs:{"prop":"ship_date","label":"交期","width":"160"}}),_c('el-table-column',{attrs:{"prop":"expected_date,expected_ctn","label":"预计到货","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.expected_date)+"/"+_vm._s(scope.row.expected_ctn)+"箱 ")]}}])}),_c('el-table-column',{attrs:{"prop":"unplanned_ctn,shipable_ctn","label":"未排/可发","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.unplanned_ctn)+"/"+_vm._s(scope.row.shipable_ctn)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"x","label":"发货数","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.x)+" "),(scope.row.ctn_g_weight != null)?_c('p',[_vm._v(" "+_vm._s(_vm._f("numFilter")((scope.row.ctn_g_weight * scope.row.x)))+" kg ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("numFilter")((scope.row.ctn_volume_m * scope.row.x)))+" ㎡")])]}}])})],1)],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderdetail.step === 1),expression:"orderdetail.step === 1"}],staticStyle:{"margin-top":"20px","margin-bottom":"30px"}},[_c('el-col',{attrs:{"span":2}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$refs['edit-forward-order-detail-dialog'].show(
            _vm.orderdetail.item,
            _vm.orderdetail.id
          )}}},[_vm._v("编辑")])],1),_c('el-col',{attrs:{"span":2,"offset":1}},[_c('el-dropdown',[_c('el-button',{attrs:{"type":"primary","size":"small"}},[_vm._v(" 移动"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.$refs['move-forward-order-detail-dialog'].show(
                _vm.orderdetail.item,
                _vm.orderdetail.id,
                '2'
              )}}},[_vm._v("移至其他货代单")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.$refs['move-forward-order-detail-dialog'].show(
                _vm.orderdetail.item,
                _vm.orderdetail.id,
                '1'
              )}}},[_vm._v("移至新货代单")])],1)],1)],1),_c('el-col',{attrs:{"span":2,"offset":1}},[_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.$refs['delete-forward-order-detail-dialog'].show(
            _vm.orderdetail.item,
            _vm.orderdetail.id
          )}}},[_vm._v("删除")])],1)],1),_c('move-forward-order-detail-dialog',{ref:"move-forward-order-detail-dialog",on:{"movesuccess":_vm.change}}),_c('edit-forward-order-detail-dialog',{ref:"edit-forward-order-detail-dialog",on:{"editsuccess":_vm.change}}),_c('delete-forward-order-detail-dialog',{ref:"delete-forward-order-detail-dialog",on:{"deletesuccess":_vm.change}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }