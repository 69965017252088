export default [
  // 采购 订单
  {
    path: '/data-center/procurement/order',
    name: 'data-center_procurement_order',
    component: () => import('@/view/data-center/procurement/order/View')
    // component: () => import('@/components/qcenterQuery/purchase/stockOrder/index')
  },
  // 采购 详情
  {
    path: '/data-center/procurement/detail',
    name: 'data-center_procurement_detail',
    component: () => import('@/view/data-center/procurement/detail/View')
    // component: () => import('@/components/qcenterQuery/purchase/stockDetail/index')
  }
]
