import { MessageBox } from 'element-ui'

const DEFAULT_MESSAGEBOX_OPTIONS = {
  showClose: false,
  closeOnClickModal: false,
  closeOnPressEscape: false
}

export default function (title = '', msg = '', options = {}) {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(msg, title, {
      ...DEFAULT_MESSAGEBOX_OPTIONS,
      ...options,
      beforeClose: (action, _, done) => {
        if (action === 'confirm') {
          resolve(done)
        } else {
          done()
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('关闭confirm')
        }
      }
    }).catch(() => {})
  })
}
