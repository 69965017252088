<template>
  <el-dialog
    title="编辑发货地址"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-tag>注意： 具体地址信息可修改， 但涉及出入仓库不可更改</el-tag>
    <el-form
      :model="formdata"
      :inline="true"
      size="small"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="Name" prop="Name">
        <el-input v-model="formdata.Name"></el-input>
      </el-form-item>
      <el-form-item label="CountryCode" prop="CountryCode">
        <el-input v-model="formdata.CountryCode"></el-input>
      </el-form-item>
      <el-form-item label="State/Province" prop="StateOrProvinceCode">
        <el-input v-model="formdata.StateOrProvinceCode"></el-input>
      </el-form-item>
      <el-form-item label="City" prop="City">
        <el-input v-model="formdata.City"></el-input>
      </el-form-item>
      <el-form-item label="Address" prop="AddressLine1">
        <el-input
          type="textarea"
          v-model="formdata.AddressLine1"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="PostCode" prop="PostalCode">
        <el-input v-model="formdata.PostalCode"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="saveAddress()">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import * as fbaAPI from '@/api/fbaship'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        ShipmentId: '',
        Name: '',
        CountryCode: '',
        StateOrProvinceCode: '',
        City: '',
        AddressLine1: '',
        PostalCode: ''
      },
      rules: {
        Name: [{ required: true, message: '请填写Name', trigger: 'blur' }],
        CountryCode: [
          { required: true, message: '请填写countryCode', trigger: 'blur' }
        ],
        StateOrProvinceCode: [
          { required: true, message: '请填写state', trigger: 'blur' }
        ],
        City: [{ required: true, message: '请填写city', trigger: 'blur' }],
        AddressLine1: [
          { required: true, message: '请填写address', trigger: 'blur' }
        ],
        PostalCode: [
          { required: true, message: '请填写postcode', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    show (formdata) {
      this.visible = true
      this.formdata.ShipmentId = formdata.ShipmentId
      this.formdata.Name = formdata.ShipFromAddress.Name
      this.formdata.CountryCode = formdata.ShipFromAddress.CountryCode
      this.formdata.StateOrProvinceCode =
        formdata.ShipFromAddress.StateOrProvinceCode
      this.formdata.City = formdata.ShipFromAddress.City
      this.formdata.AddressLine1 = formdata.ShipFromAddress.AddressLine1
      this.formdata.PostalCode = formdata.ShipFromAddress.PostalCode
    },
    closedone (done) {
      this.formdata = {
        ShipmentId: '',
        Name: '',
        CountryCode: '',
        StateOrProvinceCode: '',
        City: '',
        AddressLine1: '',
        PostalCode: ''
      }
      done()
    },
    saveAddress () {
      fbaAPI
        .editShipmentAddress(this.formdata)
        .then(data => {
          this.$emit('edit-success')
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
