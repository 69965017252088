<template>
  <div>
    <div class="item-info" v-for="item in orderDetailItem" :key="item.id">
      <el-divider></el-divider>
      <el-row class="content-row">
        <el-col :span="6">
          采购单
          <span style="margin-left: 40px">{{ item.id }}</span>
        </el-col>
        <el-col :span="6">
          预付比例
          <span style="margin-left: 40px">{{ item._prepay_ratio }}</span>
        </el-col>
        <el-col :span="6">
          采购入库
          <span style="margin-left: 40px">{{ item.wms_id }}</span>
        </el-col>
        <el-col :span="6">
          备注
          <span style="margin-left: 40px">{{ item.remark }}</span>
        </el-col>
      </el-row>

      <el-row class="content-row">
        <el-col :span="6">
          预付定金请款
          <span style="margin-left: 40px">{{ item.prepayment_amount }}</span>
        </el-col>
        <el-col :span="6">
          预付定金已冲账
          <span style="margin-left: 40px">
            {{ item.prepayment_used_amount }}
          </span>
        </el-col>
        <el-col :span="6">
          挂账:
          <span style="margin-left: 40px">
            {{ item.uncertain_amount }}
          </span>
        </el-col>
        <el-col :span="6">
          本次冲账:
          <span style="margin-left: 40px">
            {{ item.currency_amount }}
          </span>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 10px">
        <el-col :span="6">
          到货请款:
          <span style="margin-left: 40px">
            {{ item.prepayment_for_product }}
          </span>
        </el-col>
        <el-col :span="6">
          到货请款已冲账:
          <span style="margin-left: 40px">
            {{ item.prepayment_for_product_used }}
          </span>
        </el-col>
      </el-row>
      <el-row style="margin-bottom: 10px">
        <el-col :span="6">
          报销总额:
          <span style="margin-left: 40px">
            {{ item.totle_amount }}
          </span>
        </el-col>
        <el-col :span="6">
          应付金额:
          <span style="margin-left: 40px">
            {{ item.payable_amount }}
          </span>
        </el-col>
      </el-row>

      <el-table
        :data="item.items"
        stripe
        border
        style="width: 100%"
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column label="货品">
          <template v-slot="scope">
            <el-row type="flex" v-if="scope.row.id">
              <img width="80" height="80" :src="scope.row.product_cover" />
              <div style="margin-left: 10px;">
                <p>{{ scope.row.product_name }}</p>
                <p>{{ scope.row.product_mfn }}</p>
              </div>
            </el-row>
            <el-row v-else> {{ scope.row.remark }}</el-row>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="purchase_order" label="采购单"></el-table-column> -->
        <el-table-column prop="qty_total" label="采购总数"></el-table-column>
        <el-table-column label="采购单价">
          <template v-slot="scope">
            <span v-if="scope.row.id"
              >{{ scope.row.price }} {{ scope.row._currency }}</span
            >
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="qty" label="报销数量"></el-table-column>
        <el-table-column prop="amount" label="报销金额（实际货值）">
        </el-table-column>
        <el-table-column prop="payable" label="本次应付"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: ['orderDetailItem'],
  filters: {
    numFilter (value) {
      return parseFloat(value).toFixed(2)
    },
    usingamount (amount, prepayRatio) {
      const ratio = parseFloat(prepayRatio)
      return (amount / (1 - ratio) - amount).toFixed(2)
    }
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      const purchesePriceList = data.map(item => Number(item.purchese_price))
      const qtyList = data.map(item => Number(item.qty))
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        }
        if (column.property === 'purchese_priceqty') {
          sums[index] = 0
          for (let i = 0; i < purchesePriceList.length; i++) {
            sums[index] += purchesePriceList[i] * qtyList[i]
          }
          sums[index] = sums[index].toFixed(2)
        }
      })

      return sums
    }
  }
}
</script>
