<!-- 确认货代单 -->
<template>
  <el-dialog title="货代单确认" :visible.sync="visible" width="800px">
    <el-tabs v-model="tabsactivename">
      <el-tab-pane label="1检测交付数量" name="0" :disabled="true">
      </el-tab-pane>
      <el-tab-pane label="2外币采购" name="1" :disabled="true"> </el-tab-pane>
      <el-tab-pane label="3调拨海外" name="2" :disabled="true"> </el-tab-pane>
      <el-tab-pane label="4出口退税" name="3" :disabled="true"> </el-tab-pane>
      <el-tab-pane label="5完成" name="4" :disabled="true"> </el-tab-pane>
    </el-tabs>

    <el-tag style="color: red"
      >注意每一步的操作都不可撤销，务必保证货代单清单已确认不再更变</el-tag
    >
    <br /><br />
    <p v-if="tabsactivename === '0'">
      由福州仓转出的明细(RMB采购)，如果采购交付数量不足，将无法发货。
    </p>
    <br /><br />
    <el-checkbox v-model="conformdata.fix" v-if="tabsactivename === '0'"
      >自动补录采购入库单</el-checkbox
    >

    <p v-if="tabsactivename === '1'">
      非RMB采购的部分，将自动生成海外仓的采购入库单，并完成交付（生成待报销的交付单）。
    </p>

    <p v-if="tabsactivename === '2'">
      RMB采购的部分，自动生成调拨出库单和海外仓的调拨入库单。
    </p>

    <p v-if="tabsactivename === '3'">
      跨境调拨的部分将自动完成采购退库、采购入库，确保采购入库单、货代单、报关单、报销单完全一致。
    </p>

    <p v-if="tabsactivename === '4'">
      系统将自动重置&统计货代单明细和货代计划的数量。
    </p>

    <span slot="footer">
      <el-button
        size="small"
        @click.prevent="confirmforward"
        type="primary"
        v-if="tabsactivename != '4'"
        >下一步</el-button
      >
      <el-button
        size="small"
        @click.prevent="confirmforward"
        type="primary"
        v-else
        >完成</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
// import * as forwardOrderAPI from '../../../api/forwardOrder'
import * as forwardNewApi from '@/api/forwardNew'

import { mapGetters } from 'vuex'

export default {
  props: ['fowardid', 'status'],
  data () {
    return {
      visible: false,
      conformdata: {
        id: this.fowardid,
        fix: false
      }
      // tabsactivename: this.status.toString(),
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    tabsactivename: function () {
      console.log(this.status)
      return this.status + ''
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    confirmforward () {
      forwardNewApi
        .confirmOrder(this.conformdata)
        .then(data => {
          this.$emit('confirmsuccess')
          console.log(this.tabsactivename)
          // this.tabsactivename = this.status.toString()
          // console.log(data)
          if (this.status === 5) {
            this.visible = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
