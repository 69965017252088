<template>
  <div class="tracking-list">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>海外仓库存</el-breadcrumb-item>
        <el-breadcrumb-item>催单发货</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-select clearable v-model="search.ec_id" placeholder="选择平台">
            <el-option
              v-for = "item in ecList"
              :key = "item.key"
              :value = "item.key"
              :label = "item.val">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.warehouse_id"
            placeholder="选择仓库">
            <el-option
              v-for="item in warehouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search.ship_date"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="需求交期"
            end-placeholder="需求交期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input class="search-bar" v-model="search.purchase_order" placeholder="采购单" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input class="search-bar" v-model="search.contract_id" placeholder="合同号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="textarea" class="search-bar" v-model="search.mfn" placeholder="MFN， 多个用逗号分隔" clearable></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="searchHandle">搜索</el-button>
            <el-dropdown style="margin-left:20px">
              <el-button type="primary">上传 & 下载<i class="el-icon-arrow-down el-icon--right"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="$refs['import-logistics-apply-dialog'].show()"
                >上传催单明细</el-dropdown-item>
                <el-dropdown-item @click.native="exportHandle"
                >下载待催单明细</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table :data="datList" stripe border width="100%" height="600" @select="handleSelect" @select-all="handleSelectAll">
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column prop="mfn" label="MFN"></el-table-column>
      <el-table-column prop="sku" label="条码号"></el-table-column>
      <el-table-column prop="_ec" label="平台"></el-table-column>
      <el-table-column label="总数/箱数/外箱装箱率/内盒装箱率" width="150">
        <template slot-scope="scope">
          <div style="margin-left: 10px;">
            <p>
              {{ scope.row.qty }}/
              {{ scope.row.ctn }}/
              {{ scope.row.qty_ctn }}/
              {{ scope.row.sellpack }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="purchase_order" label="采购单"></el-table-column>
      <el-table-column prop="purchase_contract" label="生产单号"></el-table-column>
      <el-table-column prop="ship_date" label="需求交期"></el-table-column>
      <el-table-column prop="inbound_date" label="进仓日期"></el-table-column>
      <el-table-column prop="unplanned_qty" label="未排数量"></el-table-column>
      <el-table-column prop="applied_qty" label="已催数量"></el-table-column>
      <el-table-column label="催单数量">
        <template slot-scope="scope">
          <el-input size="mini" v-model="scope.row.apply_qty" :ref="'apply_qty'+scope.row.id" v-show="scope.row.unplanned_qty>0" @change="updateQty(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-input size="mini" v-model="scope.row.remark" v-show="scope.row.unplanned_qty>0" @change="updateRemark(scope.row)"></el-input>
        </template>
      </el-table-column>
    </el-table>

    <el-row class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[15, 20, 30, 50, 100, 200]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.per_page">
      </el-pagination>
    </el-row>
    <el-button
      type="primary"
      size="small"
      @click="handleSaveApply"
    >
      提交催单
    </el-button>
    <import-logistics-apply-dialog
      ref="import-logistics-apply-dialog"
      @success-import="shipmentList()">
    </import-logistics-apply-dialog>
    <xlsx-downloader ref="xlsx-downloader-list" type="SALES_LOGISTICS_PLAN"/>
  </div>
</template>
<script>
import * as shipmentApi from '@/api/amazon'
import { mapGetters } from 'vuex'
import XlsxDownloader from '@/components/xlsx-downloader'
import ImportLogisticsApplyDialog from '@/components/stock/tracking/import-logistics-apply-dialog'

export default {
  components: {
    XlsxDownloader,
    ImportLogisticsApplyDialog
  },
  data () {
    return {
      // formVisible: false,
      datList: [],
      ecList: [],
      warehouseList: [],
      items: [],
      search: {
        ec_id: '',
        warehouse_id: '',
        purchase_order: '',
        mfn: '',
        contract_id: '',
        ship_date: [],
        page: 1,
        per_page: 15,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.ecList = this.sysConfig.Common.ec
    this.warehouseList = this.sysConfig.warehouselist
    this.shipmentList()
  },
  methods: {
    shipmentList () {
      shipmentApi.shipmentList(this.search).then(res => {
        this.datList = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.per_page = res.per_page
      })
    },
    searchHandle () {
      this.shipmentList()
    },
    exportHandle () {
      const params = {
        ec_id: this.search.ec_id,
        warehouse_id: this.search.warehouse_id,
        ship_date: this.search.ship_date,
        purchase_order: this.search.purchase_order,
        contract_id: this.search.contract_id,
        mfn: this.search.mfn
      }
      this.$refs['xlsx-downloader-list'].show(params)
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.shipmentList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.per_page = pagesize
      this.shipmentList()
    },
    handleSelect (selection) {
      this.items = selection.map(item => {
        return {
          plan_id: item.id,
          mfn: item.mfn,
          qty_ctn: item.qty_ctn,
          purchase_order: item.purchase_order,
          unplanned_qty: item.unplanned_qty,
          apply_qty: item.apply_qty - 0,
          applied_qty: item.applied_qty - 0,
          remark: item.remark
        }
      })
    },
    handleSelectAll (selection) {
      this.items = selection.map(item => {
        return {
          plan_id: item.id,
          mfn: item.mfn,
          qty_ctn: item.qty_ctn,
          purchase_order: item.purchase_order,
          unplanned_qty: item.unplanned_qty,
          apply_qty: item.apply_qty - 0,
          applied_qty: item.applied_qty - 0,
          remark: item.remark
        }
      })
    },
    updateQty (row) {
      if (row.apply_qty - 0 + row.applied_qty > row.unplanned_qty) {
        this.$alert('催单数量不可大于未排数量-已催数量')
        var rid = 'apply_qty' + row.id
        this.$refs[rid].focus()
        return false
      }
      if (row.apply_qty % row.qty_ctn !== 0) {
        this.$alert('催单数量不合整箱')
        rid = 'apply_qty' + row.id
        this.$refs[rid].focus()
        return false
      }
      this.items.forEach(val => {
        if (val.plan_id === row.id) {
          val.apply_qty = row.apply_qty
        }
      })
    },
    updateRemark (row) {
      this.items.forEach(val => {
        if (val.plan_id === row.id) {
          val.remark = row.remark
        }
      })
    },
    handleSaveApply () {
      if (this.items.length === 0) {
        this.$alert('请至少选择一款产品')
        return false
      }
      if (!this.items.every((val) => { return val.apply_qty })) {
        this.$alert('催单数量不能为空')
        return false
      }
      if (this.items.every((val) => { return val.apply_qty - 0 + val.applied_qty > val.unplanned_qty })) {
        this.$alert('催单数量不可大于未排数量-已催数量')
        return false
      }
      if (this.items.every((val) => { return val.apply_qty % val.qty_ctn !== 0 })) {
        this.$alert('催单数量不合整箱')
        return false
      }
      shipmentApi.saveLogisticsApply({
        items: this.items
      })
        .then(() => {
          this.items = []
          this.shipmentList()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
