import request from '../config/request'

//  报销单列表
export const getreimbursementlist = data => {
  return request('finance/purchase/reimbursement/search', 'get', data).then(
    data => {
      return data
    }
  )
}
//  报销单明细
export const getreimbursementDetail = data => {
  return request('finance/purchase/reimbursement/detail', 'get', data).then(
    data => {
      return data
    }
  )
}
//  报销单审核
export const confirmReimbursement = data => {
  return request(
    'finance/purchase/reimbursement/detail/confirm',
    'post',
    data
  ).then(data => {
    return data
  })
}
// 报销单反审核
export const revokeReimbursement = data => {
  return request(
    'finance/purchase/reimbursement/detail/revoke',
    'post',
    data
  ).then(data => {
    return data
  })
}
