const state = {
  logined: false,
  menu: [
    {
      // 一级菜单
      entity: {
        id: 16,
        title: '基础设置'
      },
      childs: [
        //  二级菜单
        {
          entity: {
            id: 164,
            route: '/system-manage',
            title: '系统管理'
          }
        }
      ]
    },
    {
      entity: {
        // 一级菜单
        id: 17,
        title: '产品管理'
      },
      childs: [
        // 二级菜单
        {
          entity: {
            id: 171,
            route: '/product/productInfo',
            title: '产品信息'
          }
        },
        {
          entity: {
            id: 172,
            route: '/product/productAttribute',
            title: '扩展属性'
          }
        }
      ]
    },
    {
      entity: {
        //  一级菜单
        id: 18,
        title: '采购管理'
      },
      childs: [
        //  二级菜单
        {
          entity: {
            id: 180,
            route: '/purchase/vendor',
            title: '供应商管理'
          }
        },
        {
          entity: {
            id: 181,
            route: '/stock/stockOrder',
            title: '采购单管理'
          }
        },
        {
          entity: {
            id: 182,
            route: '/stock/record',
            title: '采购记录'
          }
        },
        {
          entity: {
            id: 183,
            route: '/stock/tracking/tracking-set',
            title: '交货进度'
          }
        },
        {
          entity: {
            id: 184,
            title: '财务'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 1841,
                title: '预付款',
                route: '/stock/finance/prepayment'
              }
            },
            {
              entity: {
                id: 1842,
                title: '请款管理',
                route: '/stock/finance/prepayment/order'
              }
            },
            {
              entity: {
                id: 1843,
                title: '交付待报销',
                route: '/stock/finance/reimbursement'
              }
            },
            {
              entity: {
                id: 1844,
                title: '报销',
                route: '/stock/finance/reimbursement/order'
              }
            }
          ]
        },
        {
          entity: {
            id: 185,
            title: '财务(新)'
          },
          childs: [
            {
              entity: {
                id: 1851,
                title: '预付款',
                route: '/stock/financeNew/prepayment'
              }
            },
            {
              entity: {
                id: 1852,
                title: '请款',
                route: '/stock/financeNew/prepayment/order'
              }
            },
            {
              entity: {
                id: 1855,
                title: '交付明细',
                route: '/stock/finance/delivery/detail'
              }
            },
            {
              entity: {
                id: 1856,
                title: '报销单',
                route: '/stock/finance/reimbursementNew/order'
              }
            }
          ]
        }
      ]
    },
    {
      entity: {
        //  一级菜单
        id: 20,
        title: 'WMS'
      },
      childs: [
        //  二级菜单
        {
          entity: {
            id: 201,
            route: '/wms/warehouse',
            title: '仓库设置'
          }
        },
        {
          entity: {
            id: 202,
            route: '/wms/warehouse/stock-out-in',
            title: '业务单据'
          }
        },
        {
          entity: {
            id: 203,
            route: '/wms/warehouse/inventory',
            title: '实时库存'
          }
        },
        {
          entity: {
            id: 206,
            title: '费用&成本'
          },
          childs: [
            {
              entity: {
                id: 2061,
                route: '/wms/fee/stockcost',
                title: '导出采购成本'
              }
            }
          ]
        },
        {
          entity: {
            id: 204,
            title: '物流管理'
          },
          childs: [
            {
              entity: {
                id: 2040,
                route: '/logistmanage/apply-list',
                title: '催单明细'
              }
            },
            {
              entity: {
                id: 2041,
                route: '/logistmanage/forward/plan/new',
                title: '货代计划'
              }
            },
            // {
            //   entity: {
            //     id: 2042,
            //     route: '/logistmanage/forward/orderlist/new',
            //     title: '货代单'
            //   }
            // },
            {
              entity: {
                id: 2043,
                route: '/logistmanage/forward/orderlist/new2021',
                title: '货代单'
              }
            }
          ]
        },
        {
          entity: {
            id: 205,
            title: '海外仓'
          },
          childs: [
            {
              entity: {
                id: 2051,
                route: '/FBA/inbound-shipment',
                title: 'FBA Inbound Shipment'
              }
            },
            {
              entity: {
                id: 2052,
                route: '/oversea/allot-plan',
                title: '调拨计划'
              }
            },
            {
              entity: {
                id: 2053,
                route: '/usa-warehouse/inventory/update',
                title: '美仓库存更新（临时）'
              }
            }
          ]
        }
      ]
    },
    {
      entity: {
        //  一级菜单
        id: 21,
        title: '财务'
      },
      childs: [
        //  二级菜单
        {
          entity: {
            id: 210,
            title: '采购管理'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2101,
                route: '/finance/purchase/expenses-requisition',
                title: '请款审核'
              }
            },
            {
              entity: {
                id: 2102,
                route: '/finance/purchase/reimbursement',
                title: '报销审核'
              }
            },
            {
              entity: {
                id: 2103,
                route: '/finance/purchase/prepayment-query',
                title: '预付款查询'
              }
            },
            {
              entity: {
                id: 2105,
                route: '/finance/purchase/payable-bill',
                title: '应付款'
              }
            }
          ]
        },
        {
          entity: {
            id: 211,
            title: '采购管理(新)'
          },
          childs: [
            {
              entity: {
                id: 2111,
                route: '/finance/purchaseNew/prepayment-order',
                title: '请款审核'
              }
            },
            {
              entity: {
                id: 2114,
                route: '/finance/purchase/turnover-query',
                title: '待交付查询'
              }
            },
            {
              entity: {
                id: 2112,
                route: '/finance/purchaseNew/reimbursement',
                title: '报销审核'
              }
            },
            {
              entity: {
                id: 2115,
                route: '/finance/purchaseNew/payable-bill',
                title: '应付款'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 212,
            title: '库存'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2121,
                route: '/finance/inventoryManagement/product-cost',
                title: 'X01产品成本'
              }
            },
            {
              entity: {
                id: 2122,
                route: '/finance/inventory/monthly-balance',
                title: 'X20月度库存结余'
              }
            },
            {
              entity: {
                id: 2123,
                route: '/finance/inventoryManagement/inventory-change-detail',
                title: 'X03库存变动明细'
              }
            }
          ]
        },
        // {
        //   entity: {
        //     //  二级菜单
        //     id: 213,
        //     title: '仓库单据'
        //   },
        //   childs: [
        //     //  三级菜单
        //     {
        //       entity: {
        //         id: 2131,
        //         route: '/finance/warehouse-receipts/procurement-in-out',
        //         title: 'F01 F02采购出入库明细'
        //       }
        //     },
        //     {
        //       entity: {
        //         id: 2132,
        //         route: '/finance/warehouse-receipts/allot-in-out',
        //         title: 'E06A 调拨出入库明细'
        //       }
        //     },
        //     {
        //       entity: {
        //         id: 2133,
        //         route: '/finance/warehousebills/allot-count',
        //         title: 'E06B 调拨统计'
        //       }
        //     },
        //     {
        //       entity: {
        //         id: 2134,
        //         route: '/finance/warehouse-receipts/breakage-adjustment',
        //         title: 'E05A E06C 调整报损明细'
        //       }
        //     },
        //     {
        //       entity: {
        //         id: 2135,
        //         route: '/finance/warehouse-receipts/sales-in-out',
        //         title: 'F03 F04 销售进出仓明细'
        //       }
        //     }
        //   ]
        // },
        {
          entity: {
            //  二级菜单
            id: 214,
            title: '亚马逊'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2140,
                route: '/finance/amazon/paymentList',
                title: '账期'
              }
            },
            {
              entity: {
                id: 2141,
                route: '/finance/amazon/paymentDetail',
                title: '账期明细'
              }
            },
            {
              entity: {
                id: 2145,
                route: '/finance/amazon/profit',
                title: '毛利统计'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 215,
            title: '分析&报告'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2150,
                route: '/finance/anreport/breakage/claims',
                title: '报损理赔单'
              }
            }
          ]
        },
        {
          entity: {
            id: 218,
            route: '/finance/rate-set',
            title: '汇率设置'
          }
        }
      ]
    },
    {
      entity: {
        //  一级菜单
        id: 22,
        title: '销售'
      },
      childs: [
        {
          entity: {
            //  二级菜单
            id: 220,
            title: 'Amazon'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2201,
                route: '/amazon/product',
                title: '产品管理'
              }
            },
            {
              entity: {
                id: 2202,
                route: '/amazon/inventory/allot',
                title: '库存调拨'
              }
            }
          ]
        },
        {
          // entity: { //  二级菜单
          //   id: 221,
          //   route: '/amazon/stripe',
          //   title: 'Stripe收款管理',
          // }
          entity: {
            //  二级菜单
            id: 221,
            title: '库存&补货'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2210,
                route: '/replenish/strategy',
                title: '策略设置'
              }
            },
            {
              entity: {
                id: 2211,
                route: '/fba/replenish',
                title: 'FBA补货报告'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 222,
            title: 'Wayfair'
          },
          childs: [
            {
              entity: {
                //  三级菜单
                id: 2220,
                route: '/amazon/wayfair/product',
                title: '产品管理'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2221,
                route: '/wayfair/inventory/detail/3',
                title: '库存明细'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2222,
                route: '/wayfair/inventory/allot/3',
                title: '库存调拨'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2223,
                route: '/amazon/wayfair/sales',
                title: 'Sales'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2224,
                route: '/sales/wayfair/refund',
                title: '销售退货'
              }
            }
            // {
            //     entity: { //  三级菜单
            //       id: 2226,
            //       route: '/amazon/wayOrder',
            //       title: 'Wayfair销售订单',
            //     }
            // },
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 223,
            title: '海外仓库存'
          },
          childs: [
            {
              entity: {
                id: 2230,
                route: '/amazon/oversea/apply_shipment',
                title: '催单发货'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2231,
                route: '/amazon/oversea/inventory',
                title: '实时库存查询'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 224,
            title: 'Shopify'
          },
          childs: [
            {
              entity: {
                //  三级菜单
                id: 2241,
                route: '/amazon/Shopify/product',
                title: '产品管理'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2242,
                route: '/wayfair/inventory/allot/4',
                title: '库存调拨'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2243,
                route: '/wayfair/inventory/detail/4',
                title: '库存明细'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 225,
            title: 'SDW'
          },
          childs: [
            {
              entity: {
                //  三级菜单
                id: 2250,
                route: '/sales/SDW/product',
                title: '产品管理'
              }
            },
            {
              entity: {
                //  三级菜单
                id: 2252,
                route: '/sales/sdw/inventory/allot',
                title: '库存调拨'
              }
            }
          ]
        }
      ]
    },
    {
      entity: {
        //  一级菜单
        id: 23,
        title: '数据中心'
      },
      childs: [
        {
          entity: {
            //  二级菜单
            id: 230,
            title: '采购'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2301,
                route: '/data-center/procurement/order',
                title: 'C01A采购订单'
              }
            },
            {
              entity: {
                id: 2302,
                route: '/data-center/procurement/detail',
                title: 'C01B采购明细'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 231,
            title: 'WMS仓库单据'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2311,
                route: '/finance/warehouse-receipts/procurement-in-out',
                title: 'F01 F02采购出入库明细'
              }
            },
            {
              entity: {
                id: 2312,
                route: '/finance/warehouse-receipts/allot-in-out',
                title: 'E06A 调拨出入库明细'
              }
            },
            {
              entity: {
                id: 2313,
                route: '/finance/warehousebills/allot-count',
                title: 'E06B 调拨统计'
              }
            },
            {
              entity: {
                id: 2314,
                route: '/finance/warehouse-receipts/breakage-adjustment',
                title: 'E05A E06C 调整报损明细'
              }
            },
            {
              entity: {
                id: 2315,
                route: '/finance/warehouse-receipts/sales-in-out',
                title: 'F03 F04 销售进出仓明细'
              }
            }
          ]
        },
        {
          entity: {
            //  二级菜单
            id: 232,
            title: '销售分析'
          },
          childs: [
            //  三级菜单
            {
              entity: {
                id: 2325,
                route: '/qcenter/query/saleShopInfo',
                title: '门店平台统计'
              }
            }
          ]
        }
      ]
    }
  ],
  sysConfig: {}
}

export default state
