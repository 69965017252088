import request from '../config/request'

export const getStocklist = data => {
  return request('purchase/order', 'get', data, true).then(data => {
    return data
  })
}

export const getVendorlist = data => {
  return request('purchase/vendor', 'get', data, true).then(data => {
    return data
  })
}

export const getVendordetail = data => {
  return request('purchase/vendor/detail', 'get', data, true).then(data => {
    return data
  })
}

export const saveStockMain = data => {
  return request('purchase/order/detail/save', 'post', data)
}
// 采购单详情
export const getStockDetail = data => {
  return request('purchase/order/detail', 'get', data, true).then(data => {
    return data
  })
}
// 采购单明细列表
export const getStockDetailItem = data => {
  return request('purchase/order/detail/item', 'get', data, true).then(data => {
    return data
  })
}
// 采购单明细费用列表
export const getStockDetailFee = data => {
  return request('purchase/order/detail/fee', 'get', data, true).then(data => {
    return data
  })
}
// 采购单审核
export const confirmStockDetail = data => {
  return request('purchase/order/detail/confirm', 'post', data).then(data => {
    return data
  })
}
// 采购单反审核
export const unconfirmStock = data => {
  return request('purchase/order/detail/revoke', 'post', data).then(data => {
    return data
  })
}
// 采购单删除
export const deleteStockDetail = data => {
  return request('purchase/order/detail/delete', 'post', data).then(data => {
    return data
  })
}
// 同步明细材积至产品新信息
export const syncDimenssionToProdict = data => {
  return request(
    'purchase/order/detail/item/syncDimenssionToProduct',
    'post',
    data
  ).then(data => {
    return data
  })
}
// 添加采购单其它费用
export const addFee = data => {
  return request('purchase/order/detail/fee/add', 'post', data).then(data => {
    return data
  })
}
// 删除采购单其它费用
export const deleteFee = data => {
  return request('purchase/order/detail/fee/delete', 'post', data).then(
    data => {
      return data
    }
  )
}
// 删除一条采购明细
export const deleteStockDetailItem = data => {
  return request('purchase/order/detail/item/delete', 'post', data).then(
    data => {
      return data
    }
  )
}
// 重置明细的SKU
export const resetSku = data => {
  return request('purchase/order/detail/item/resetSku', 'post', data).then(
    data => {
      return data
    }
  )
}
// 重置明细的供应商SKU
export const resetVendorSku = data => {
  return request(
    'purchase/order/detail/item/resetVendorSku',
    'post',
    data
  ).then(data => {
    return data
  })
}

export const editOrderItemDetail = data => {
  return request('purchase/order/detail/item/edit', 'post', data).then(data => {
    return data
  })
}

export const getStockRecord = data => {
  return request('purchase/order/record', 'get', data).then(data => {
    return data
  })
}
