<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item>实时库存</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="conten-row">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select v-model="search.warehouseid" placeholder="请选择仓库">
              <el-option
                v-for="item in sysConfig.warehouselist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="search.mfn" placeholder="MFN"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getInventoryList()"
              >搜索</el-button
            >
            <el-button type="primary" @click="downLoadLink()"
              >导出下载</el-button
            >
            <el-button type="primary" @click="$refs['import-dialog'].show()"
              >更新返工数据</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row >
      <el-table stripe :data="inventoryList" border style="width:100%">
        <el-table-column prop="_product_name" label="品名"></el-table-column>
        <el-table-column prop="_product_mfn" label="MFN"></el-table-column>
        <el-table-column
          prop="_product_width,_product_length,_product_height"
          label="尺寸"
        >
          <template slot-scope="scope">
            {{ scope.row._product_width }}*{{ scope.row._product_length }}*{{
              scope.row._product_height
            }}
          </template>
        </el-table-column>
        <el-table-column prop="pcs" label="当前总数"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
    <import-update-rework-dialog
      ref="import-dialog"
      @sunccess-import-rework="getInventoryList()"
    ></import-update-rework-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as warehouseApi from '../../../api/warehouse'
import importUpdateReworkDialog from './import-update-rework-dialog'
import { baseUrl } from '@/config/env'
export default {
  components: {
    importUpdateReworkDialog
  },
  data () {
    return {
      search: {},
      inventoryList: [],
      pageDat: {
        per_page: 10,
        current_page: 1
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getInventoryList()
  },
  methods: {
    getInventoryList () {
      const self = this
      const listJsondata = {
        warehouse_id: self.search.warehouseid,
        mfn: self.search.mfn,
        page: this.pageDat.current_page,
        per_page: this.pageDat.per_page
      }
      warehouseApi
        .getInventoryList(listJsondata)
        .then(data => {
          self.inventoryList = data.data
          self.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getInventoryList()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getInventoryList()
    },
    downLoadLink () {
      const url =
        baseUrl +
        'wms/inventory/export?token=' +
        localStorage.getItem('token') +
        '&warehouse_id=' +
        this.search.warehouseid
      window.open(url)
    }
  }
}
</script>
