<template>
  <el-dialog
    title="统计日志"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="900px"
  >
    <el-table
      :data="errLogsItem"
      border
      stripe
      width="100%"
      v-if="errLogsItem.length > 0"
    >
      <el-table-column prop="ec" label="门店">
        <template slot-scope="scope">
          {{ scope.row.ec }}
          &nbsp;&nbsp;&nbsp;
          <el-button type="text" @click="setLog(scope.row, scope.$index)">
            {{ scope.row.status }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="date" label="日期"></el-table-column>
      <el-table-column prop="error" label="错误说明"></el-table-column>
    </el-table>
    <span slot="footer">
      <el-button type="primary" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { sethandleLog } from '@/api/qcenterQuery'
export default {
  props: ['item'],
  data () {
    return {
      visible: false,
      errLogsItem: []
    }
  },
  watch: {
    item: {
      handler (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.errLogsItem = []
          // eslint-disable-next-line
          for (const i in this.item) {
            this.$set(this.item[i], 'status', '修复统计')
            this.errLogsItem.push(this.item[i])
          }
        }
      },
      deep: true
    }
  },
  methods: {
    show () {
      if (this.errLogsItem.length === 0) {
        // eslint-disable-next-line
        for (const i in this.item) {
          this.$set(this.item[i], 'status', '修复统计')
          this.errLogsItem.push(this.item[i])
        }
      }
      this.visible = true
    },
    setLog (daterow, index) {
      this.errLogsItem[index].status = '正在统计'
      sethandleLog({
        ec: daterow.ec,
        date: daterow.date
      })
        .then(data => {
          this.$emit('set-log-success')
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      this.hide()
      done()
    },
    hide () {
      this.errLogsItem = []
      this.visible = false
    }
  }
}
</script>
