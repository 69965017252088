<template>
  <div>
    <el-row class="content-searcha">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="search.sku"
              placeholder="SKU,多个用逗号分隔"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.mfn"
              placeholder="mfn,多个用逗号分隔"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.tag"
              placeholder="自定义标签,多个用逗号分隔"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="search.name" placeholder="品名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.no"
              placeholder="NO平台编号(多个用逗号隔开）"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="search.ec_id" placeholder="请选择ec">
              <el-option
                v-for="item in ecList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="search.tagReverse">Tag反选排除</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-dropdown>
              <el-button type="primary" size="small"
                >搜索
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="searchProduct()"
                  >搜索</el-dropdown-item
                >
                <el-dropdown-item @click.native="downloadSeProduct()"
                  >下载</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button type="primary" @click="searchProduct()">搜索</el-button> -->
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-dropdown>
          <el-button type="primary" size="small"
            >下载库存
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="downloadLink()"
              >汇总库存</el-dropdown-item
            >
            <el-dropdown-item @click.native="downloadLink('detail')"
              >库存明细</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="$refs['import-product-dialog'].show()"
              >导入更新产品</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        border
        width="100%"
        :data="productList"
        @select="handleSelect"
        @select-all="handleSelectall"
        height="600"
      >
        <el-table-column type="selection" width="39"></el-table-column>
        <el-table-column label="产品信息">
          <template slot-scope="scope">
            <product-info-table-cell
              :cover="scope.row._product.cover"
              :title="scope.row.name"
              :mfn="scope.row._product.mfn"
              v-if="scope.row.name"
            ></product-info-table-cell>
            <product-info-table-cell
              :cover="scope.row._product.cover"
              :title="scope.row._product.name"
              :mfn="scope.row._product.mfn"
              v-else
            ></product-info-table-cell>
            <!-- <el-tag type="info">{{scope.row.tag}}</el-tag>
                        <el-button type="text" @click="$refs['all-add-attribute-dialog'].show(scope.row.mfn,search.ec_id)">编辑</el-button> -->
          </template>
        </el-table-column>
        <el-table-column label="sku/NO" prop="sku">
          <template slot-scope="scope">
            <div>{{ scope.row.sku }}/{{ scope.row.no }}</div>
            <el-tag type="info">{{ scope.row.tag }}</el-tag>
            <el-button
              type="text"
              @click="
                $refs['all-add-attribute-dialog'].show(
                  scope.row.mfn,
                  search.ec_id,
                  scope.row.tag
                )
              "
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="可用库存"
          prop="qty"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="总库存"
          prop="qty_total"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="采购未发"
          prop="qty_purchase"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="物流在途"
          prop="qty_transfer"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="调拨在途"
          prop="qty_on_way"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="其余库存"
          prop="qty_other"
          min-width="30%"
          align="center"
        >
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="非专属仓库(如FBA)的可用库存"
            >
              <span slot="reference">{{ scope.row.qty_other }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="共享库存"
          prop="qty_share"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="实际可用库存"
          prop="qty_available"
          min-width="30%"
        ></el-table-column>
        <el-table-column label="归还数量" prop="return_qty" min-width="30%">
          <template slot-scope="scope">
            <el-input-number
              v-if="scope.row.checked"
              controls-position="right"
              size="mini"
              style="width: 99px;"
              :min="1"
              v-model="scope.row.return_qty"
            ></el-input-number>
            <p v-else>/</p>
          </template>
        </el-table-column>
        <el-table-column min-width="30%">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="$refs['edit-product-dialog'].show(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="$refs['product-detail-dialog'].show(scope.row.mfn)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-button type="primary" size="small" @click="showExportDialog()"
        >导出下载</el-button
      >
      <el-dropdown style="margin-left:20px">
        <el-button type="primary" size="small"
          >上下架
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="productUpOrDown(1)"
            >上架</el-dropdown-item
          >
          <el-dropdown-item @click.native="productUpOrDown(-1)"
            >下架</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown style="margin-left:20px">
        <el-button type="primary" size="small">
          库存借用
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="showBorrowDialog()"
            >借入库存</el-dropdown-item
          >
          <el-dropdown-item @click.native="showReturnDialog()"
            >归还库存</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown style="margin-left:20px">
        <el-button type="primary" size="small">
          设置标签
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="addAllLabel()"
            >增加标签</el-dropdown-item
          >
          <el-dropdown-item @click.native="deleteAllLabel()"
            >删除标签</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-button type="primary" size="small" style="margin-left:20px" @click="addAllLabel()">增加批量标签</el-button> -->
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 250, 500, 1000]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <product-detail-dialog ref="product-detail-dialog"></product-detail-dialog>
    <export-product-dialog ref="export-product-dialog"></export-product-dialog>
    <import-product-dialog ref="import-product-dialog"></import-product-dialog>
    <borrowing-inventory-dialog
      ref="borrowing-inventory-dialog"
    ></borrowing-inventory-dialog>
    <return-the-inventory-dialog
      ref="return-the-inventory-dialog"
    ></return-the-inventory-dialog>
    <all-add-attribute-dialog
      ref="all-add-attribute-dialog"
      @add-tag-success="searchProduct()"
    ></all-add-attribute-dialog>
    <all-delete-tag-dialog
      ref="all-delete-tag-dialog"
      @delete-tag-success="searchProduct()"
    ></all-delete-tag-dialog>
    <edit-product-dialog
      ref="edit-product-dialog"
      @save-success="searchProduct()"
    ></edit-product-dialog>
    <!-- <edit-attribute-dialog ref="edit-attribute-dialog"></edit-attribute-dialog> -->
  </div>
</template>
<script>
import { getSalesProductList, upOrDownProduct } from '@/api/amazon'
import { mapGetters } from 'vuex'
import productInfoTableCell from '../../common/product-info-table-cell'
import productDetailDialog from './product-detail-dialog'
import exportProductDialog from '../export-product-dialog'
import borrowingInventoryDialog from '@/components/amazon/borrowing-inventory-dialog'
import returnTheInventoryDialog from '@/components/amazon/return-the-inventory-dialog'
import allAddAttributeDialog from '@/components/amazon/all-add-attribute-dialog'
import allDeleteTagDialog from '@/components/amazon/all-delete-tag-dialog'
import importProductDialog from './import-product-dialog'
import editProductDialog from '@/components/amazon/edit-product-dialog'
// import editAttributeDialog from '@/components/amazon/edit-attribute-dialog'
import { baseUrl } from '@/config/env'
export default {
  props: ['status', 'ecList'],
  components: {
    productInfoTableCell,
    productDetailDialog,
    exportProductDialog,
    borrowingInventoryDialog,
    returnTheInventoryDialog,
    allAddAttributeDialog,
    allDeleteTagDialog,
    importProductDialog,
    editProductDialog
    //   editAttributeDialog
  },
  data () {
    return {
      search: {
        ec_id: 1,
        tagReverse: false,
        sku: '',
        tag: '',
        no: '',
        name: '',
        mfn: '',
        status: ''
      },
      productList: [],
      pageData: {
        current_page: 1,
        per_page: 100
      },
      productMfn: [],
      selectedList: [],
      selectReturn: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    status: function () {
      this.pageData.current_page = 1
      this.getProductList()
    },
    ecList: function () {
      if (this.ecList.length > 0) {
        this.search.ec_id = this.ecList[0].key
        this.pageData.current_page = 1
        this.getProductList()
      }
    }
  },
  methods: {
    getProductList () {
      const self = this
      const listJsondata = {
        ec_type: '2',
        ec_id: this.search.ec_id,
        sku: this.search.sku,
        mfn: this.search.mfn,
        tag: this.search.tag,
        no: this.search.no,
        name: this.search.name,
        tagReverse: Number(this.search.tagReverse),
        status: this.status,
        page: this.pageData.current_page,
        per_page: this.pageData.per_page
      }
      getSalesProductList(listJsondata)
        .then(data => {
          self.productList = data.data
          self.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchProduct () {
      this.pageData.current_page = 1
      this.getProductList()
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getProductList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getProductList()
    },
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
        row.return_qty = 0
      } else {
        row.checked = true
        row.return_qty = 1
      }
      this.productMfn = selection.map(item => {
        return item.mfn
      })
      this.selectedList = selection
    },
    handleSelectall (selection) {
      if (selection.length > 0) {
        for (let i = 0; i < this.productList.length; i++) {
          this.productList[i].checked = true
          this.productList[i].return_qty = 1
        }
      } else {
        for (let i = 0; i < this.productList.length; i++) {
          this.productList[i].checked = false
          this.productList[i].return_qty = 0
        }
      }
      this.selectedList = selection
      this.productMfn = selection.map(item => {
        return item.mfn
      })
    },
    checkSelected () {
      if (this.productMfn.length === 0) {
        this.$alert('请至少选择一款产品')
        return false
      }
      return true
    },
    showExportDialog () {
      if (this.checkSelected()) {
        this.$refs['export-product-dialog'].show(
          this.productMfn,
          this.search.ec_id
        )
      }
    },
    addAllLabel () {
      if (this.checkSelected()) {
        this.$refs['all-add-attribute-dialog'].show(
          this.productMfn,
          this.search.ec_id
        )
      }
    },
    deleteAllLabel () {
      if (this.checkSelected()) {
        this.$refs['all-delete-tag-dialog'].show(
          this.productMfn,
          this.search.ec_id
        )
      }
    },
    showBorrowDialog () {
      this.$refs['borrowing-inventory-dialog'].show(this.search.ec_id)
    },
    showReturnDialog () {
      this.selectReturn = this.selectedList.map(item => {
        return {
          mfn: item._product.mfn,
          qty: item.return_qty
        }
      })
      console.log(this.selectReturn)
      this.$refs['return-the-inventory-dialog'].show(
        this.search.ec_id,
        this.selectReturn
      )
    },
    downloadLink (type) {
      if (type) {
        const url =
          baseUrl +
          'sales/product/exportInventoryDetail?token=' +
          localStorage.getItem('token') +
          '&ec_id=' +
          this.search.ec_id
        window.open(url)
      } else {
        const url =
          baseUrl +
          'sales/product/exportInventory?token=' +
          localStorage.getItem('token') +
          '&ec_id=' +
          this.search.ec_id
        window.open(url)
      }
    },
    downloadSeProduct () {
      const url =
        baseUrl +
        'sales/product/exportProSearch?token=' +
        localStorage.getItem('token') +
        '&ec_type=' +
        '2' +
        '&ec_id=' +
        this.search.ec_id +
        '&sku=' +
        this.search.sku +
        '&tag=' +
        this.search.tag +
        '&tagReverse=' +
        this.search.tagReverse +
        '&no=' +
        this.search.no +
        '&name=' +
        this.search.name +
        '&mfn=' +
        this.search.mfn +
        '&status=' +
        this.search.status +
        '&page=' +
        this.pageData.current_page +
        '&per_page=' +
        this.pageData.per_page
      window.open(url)
    },
    returnInventory () {
      if (this.checkSelected()) {
        this.$confirm('提示', {
          title: '归还库存',
          message: '借出的库存将被收回，借入的库存将被归还。确定执行吗？'
        })
          .then(_ => {})
          .catch(_ => {})
      }
    },
    // 上下架
    productUpOrDown (status) {
      if (this.checkSelected()) {
        const jsondata = {
          mfn: this.productMfn.join(','),
          status: status,
          ec_id: this.search.ec_id
        }
        upOrDownProduct(jsondata)
          .then(data => {
            this.pageData.current_page = 1
            this.getProductList()
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }
}
</script>
