<template>
  <el-dialog
    title="导入调拨出库单明细"
    :visible.sync="visible"
    :before-close="closeDone"
    width="500px"
  >
    <el-form label-width="80px">
      <el-form-item label-width="20px">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="excel"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
          :data="ruledata"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="20px" label>
        <p>
          导入模板格式文件<el-link
            type="primary"
            :href="downloadTemplate"
            target="_blank"
            >ec-wms-order-detail.xls</el-link
          >
        </p>
      </el-form-item>
      <el-form-item label="清除模式">
        <el-switch
          v-model="dataimport.deletemode"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="开"
          inactive-text="关"
          class="taxswitch"
        >
        </el-switch>
        <p>
          清除模式将先清空所有明细再导入数据，关闭该模式则在原有数据基础上追加导入数据（重复数据将被覆盖）
        </p>
      </el-form-item>
    </el-form>
    <el-tag type="warning" v-if="resInventoryList.length"
      >以下产品库存不足</el-tag
    >
    <el-table
      border
      stripe
      :data="resInventoryList"
      width="100%"
      v-if="resInventoryList.length"
    >
      <el-table-column prop="sku" label="SKU"></el-table-column>
      <el-table-column prop="qty" label="调拨数量"></el-table-column>
      <el-table-column prop="has_qty" label="实际库存"></el-table-column>
    </el-table>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
export default {
  data () {
    return {
      visible: false,
      dataimport: {
        id: '',
        deletemode: false
      },
      resInventoryList: []
    }
  },
  computed: {
    importInboundOrder () {
      return (
        baseUrl +
        'sales/inventory/exportItem?token=' +
        localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return (
        baseUrl +
        'sales/inventory/exportItemTpl?token=' +
        localStorage.getItem('token')
      )
    },
    ruledata () {
      return {
        id: this.dataimport.id,
        empty: Number(this.dataimport.deletemode)
      }
    }
  },
  methods: {
    show (id) {
      this.dataimport.id = id
      this.visible = true
    },
    handleConfirm () {
      this.resInventoryList = []
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (!response.res) {
        this.resInventoryList = response.dat
        this.$notify({ message: response.msg })
      } else {
        this.$emit('success-import-allot')
        this.$notify({ message: response.msg })
        this.resInventoryList = []
        this.visible = false
      }
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        console.log(err)
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    },
    closeDone (done) {
      done()
    }
  }
}
</script>
<style>
.taxswitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

.taxswitch .el-switch__label--right {
  z-index: 1;
  right: -3px;
}

.taxswitch .el-switch__label--left {
  z-index: 1;
  left: 19px;
}

.taxswitch .el-switch__label.is-active {
  display: block;
}
.taxswitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 50px !important;
}
</style>
