<template>
  <div>
    <el-row>
      <el-col :span="3" :offset="10">
        <el-button type="primary" size="small" @click="downloadLink()"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table border stripe style="width:100%" :data="invoiceDetailList">
        <el-table-column prop="purchase_id" label="采购单号"></el-table-column>
        <el-table-column prop="contract_id" label="合同号"></el-table-column>
        <el-table-column
          prop="_invoice_type"
          label="开票类型"
        ></el-table-column>
        <el-table-column
          prop="purchase_in"
          label="采购入库单"
        ></el-table-column>
        <el-table-column prop="pur_in_date" label="入库日期"></el-table-column>
        <el-table-column prop="_warehouse" label="入库仓库"></el-table-column>
        <el-table-column prop="_user" label="人员"></el-table-column>
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column prop="_pur_user" label="采购员"></el-table-column>
        <el-table-column prop="mfn" label="产品编码"></el-table-column>
        <el-table-column prop="product_name" label="品名"></el-table-column>
        <el-table-column prop="name" label="开票品名"></el-table-column>
        <el-table-column prop="unit" label="单位"></el-table-column>
        <el-table-column prop="qty" label="交付（报销）数"></el-table-column>
        <el-table-column prop="price" label="采购价"></el-table-column>
        <el-table-column prop="amount" label="交付金额"></el-table-column>
        <el-table-column
          prop="no_tax_amount"
          label="不含税金额"
        ></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env'
export default {
  props: ['invoiceDetailList', 'id', 'type'],

  methods: {
    downloadLink () {
      let url
      if (this.type === 1) {
        url =
          baseUrl +
          'purchase/finance/reimbursement/exportInvoiceDetailJfd?token=' +
          localStorage.getItem('token') +
          '&id=' +
          this.id
      } else if (this.type === 2) {
        url =
          baseUrl +
          'purchase/finance/reimbursement/exportInvoiceDetail?token=' +
          localStorage.getItem('token') +
          '&id=' +
          this.id
      }
      window.open(url)
    }
  }
}
</script>
