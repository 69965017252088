<template>
  <div>
    <el-row class="content-row">
      <el-col :span="19">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-select
              clearable
              v-model="search.vendor_id"
              placeholder="供应商"
              v-if="type === '1' || type === '-1'"
              filterable
            >
              <el-option
                v-for="item in sysConfig.vendorList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.stockCode"
              placeholder="采购单号"
              v-if="type === '1' || type === '-1'"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="search.warehouse_id"
              placeholder="仓库"
            >
              <el-option
                v-for="item in sysConfig.warehouselist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.wareCode"
              placeholder="仓库单号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="search.no" placeholder="外部单号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="search.date"
              @change="setDate()"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="search.step"
              placeholder="状态"
              v-if="type === '1'"
            >
              <el-option
                v-for="item in sysConfig.WMSOrderBasePurchaseIn.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
            <el-select
              clearable
              v-model="search.step"
              placeholder="状态"
              v-if="type === '-1'"
            >
              <el-option
                v-for="item in sysConfig.WMSOrderBasePurchaseOut.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
            <el-select
              clearable
              v-model="search.step"
              placeholder="状态"
              v-if="type === '-3'"
            >
              <el-option
                v-for="item in sysConfig.WMSOrderBaseTransferOut.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
            <el-select
              clearable
              v-model="search.step"
              placeholder="状态"
              v-if="type === '3'"
            >
              <el-option
                v-for="item in sysConfig.WMSOrderBaseTransferIn.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
            <el-select
              clearable
              v-model="search.step"
              placeholder="流程"
              v-if="type === '-4'"
            >
              <el-option
                v-for="item in sysConfig.WMSOrderBaseTransferIn.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="searchOrderlist()"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4" v-if="type === '1'">
        <el-button
          type="primary"
          size="small"
          @click="$refs['stockInImport'].show()"
          >导入采购入库单</el-button
        >
      </el-col>
      <el-col :span="4" v-if="type === '-4'">
        <el-button
          type="primary"
          size="small"
          @click="$refs['create-saleorder-out-dialog'].show()"
          >创建销售出库单</el-button
        >
      </el-col>
      <el-col :span="4" v-if="type === '-3'">
        <el-button
          type="primary"
          size="small"
          @click="$refs['create-allot-out-dialog'].show()"
          >创建调拨出库单</el-button
        >
      </el-col>
      <el-col :span="4" v-if="type === '-5'">
        <el-button
          type="primary"
          size="small"
          @click="$refs['create-breakage-dialog'].show('-5', 0)"
          >创建报损单</el-button
        >
      </el-col>
      <el-col :span="4" v-if="type === '5'">
        <el-button
          type="primary"
          size="small"
          @click="$refs['create-breakage-dialog'].show('5', 0)"
          >创建调整单</el-button
        >
      </el-col>
    </el-row>
    <!-- 采购入库 -->
    <el-row v-if="type === '1' || type === '-1'">
      <el-table stripe style="width:100%" :data="stockOutInlist" border>
        <el-table-column prop="id" label="仓库单号">
          <template slot-scope="scope">
            {{ scope.row.id }} <el-tag>{{ scope.row._id }} </el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="no" label="外部单号"></el-table-column> -->
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column prop="_warehouse_name" label="仓库"></el-table-column>
        <el-table-column prop="_user_name" label="人员"></el-table-column>
        <el-table-column prop="_step" label="流程"></el-table-column>
        <el-table-column prop="updated_at" label="最后更新"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >查看</el-button
            >
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 调拨出库 -->
    <el-row v-if="type === '3' || type === '-3'">
      <el-table stripe style="width:100%" :data="stockOutInlist" border>
        <el-table-column prop="id" label="仓库单号">
          <template slot-scope="scope">
            {{ scope.row.id }} <el-tag>{{ scope.row._id }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="no" label="外部单号"></el-table-column>
        <el-table-column
          prop="_warehouse_from"
          label="调出仓库"
        ></el-table-column>
        <el-table-column
          prop="_warehouse_to"
          label="目的仓库"
        ></el-table-column>
        <el-table-column prop="_user_name" label="人员"></el-table-column>
        <el-table-column prop="_step" label="流程"></el-table-column>
        <el-table-column prop="no" label="第三方单号"></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          :key="300"
        ></el-table-column>
        <el-table-column
          prop="updated_at"
          label="最后更新"
          :key="301"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >查看</el-button
            >
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 报损单调整单 -->
    <el-row v-if="type === '5' || type === '-5'">
      <el-table stripe style="width:100%" :data="stockOutInlist" border>
        <el-table-column prop="id" label="仓库单号">
          <template slot-scope="scope">
            {{ scope.row.id }} <el-tag>{{ scope.row._id }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="no" label="外部单号"></el-table-column>
        <el-table-column prop="_warehouse_name" label="仓库"></el-table-column>
        <el-table-column prop="_user_name" label="仓管"></el-table-column>
        <el-table-column prop="_step" label="流程"></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          :key="500"
        ></el-table-column>
        <el-table-column prop="updated_at" label="最后更新"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >查看</el-button
            >
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 销售出入库单 -->
    <el-row v-if="type === '4' || type === '-4'">
      <el-table stripe style="width:100%" :data="stockOutInlist" border>
        <el-table-column prop="id" label="仓库单号">
          <template slot-scope="scope">
            {{ scope.row.id }} <el-tag>{{ scope.row._id }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="no" label="外部单号"></el-table-column>
        <el-table-column
          prop="_warehouse_name"
          label="出库仓库"
        ></el-table-column>
        <el-table-column prop="_user_name" label="人员"></el-table-column>
        <el-table-column prop="_step" label="流程"></el-table-column>
        <el-table-column prop="updated_at" label="最后更新"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >查看</el-button
            >
            <el-button type="text" @click="pushOutInDetail(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
    <stock-in-import-dialog ref="stockInImport"></stock-in-import-dialog>
    <create-breakage-adjust-dialog
      ref="create-breakage-dialog"
      @success-create="getWarehouseOrderlist()"
    ></create-breakage-adjust-dialog>
    <create-saleorder-out-dialog
      ref="create-saleorder-out-dialog"
      @success-create="getWarehouseOrderlist()"
    ></create-saleorder-out-dialog>
    <create-allot-out-dialog
      ref="create-allot-out-dialog"
      @success-create="getWarehouseOrderlist()"
    ></create-allot-out-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as warehouseApi from '../../../api/warehouse'
import stockInImportDialog from './stock-in-import-dialog'
import createBreakageAdjustDialog from './create-breakage-adjust-dialog'
import createSaleorderOutDialog from './create-saleorder-out-dialog'
import createAllotOutDialog from './create-allot-out-dialog'
export default {
  components: {
    stockInImportDialog,
    createBreakageAdjustDialog,
    createSaleorderOutDialog,
    createAllotOutDialog
  },
  props: ['type'],
  data () {
    return {
      search: {},
      pageDat: {
        per_page: 10,
        current_page: 1
      },
      stockOutInlist: []
    }
  },
  watch: {
    type: function () {
      this.pageDat.current_page = 1
      this.getWarehouseOrderlist()
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    // 仓库单据列表接口参数
    listJsondata () {
      return {
        type: this.type,
        vendor_id: this.search.vendor_id,
        id: this.search.wareCode,
        purchase_id: this.search.stockCode,
        step: this.search.step,
        no: this.search.no,
        warehouse_id: this.search.warehouse_id,
        startDate: this.search.start,
        endDate: this.search.end,
        page: this.pageDat.current_page,
        per_page: this.pageDat.per_page
      }
    }
  },
  created () {
    this.getWarehouseOrderlist()
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.search.date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search.date[0]
        this.search.end = this.search.date[1]
      }
    },
    getWarehouseOrderlist () {
      const self = this
      console.log(self.type)
      warehouseApi
        .getwmsOrderList(self.listJsondata)
        .then(data => {
          self.stockOutInlist = data.data
          self.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchOrderlist () {
      this.pageDat.current_page = 1
      this.getWarehouseOrderlist()
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getWarehouseOrderlist()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getWarehouseOrderlist()
    },
    pushOutInDetail (warehouseId) {
      const self = this
      let routename = ''
      if (self.type === '1' || self.type === '-1') {
        routename = 'stockInOutDetail'
      } else if (self.type === '3' || self.type === '-3') {
        routename = 'allotOutInDetail'
      } else if (self.type === '4' || self.type === '-4') {
        routename = 'saleorderOutInDetail'
      } else if (self.type === '5' || self.type === '-5') {
        routename = 'breakageAdjustDetail'
      }
      this.$router.push({
        name: routename,
        params: {
          id: warehouseId,
          type: self.type
        }
      })
    }
  }
}
</script>
