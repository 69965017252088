<!-- 货代单详情 -->
<template>
  <div class="forward-order">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>货品库存</el-breadcrumb-item>
        <el-breadcrumb-item>库存调拨</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>货单运单</h3>
      </el-col>
      <el-col :span="5" :offset="15">
        <el-button
          type="text"
          @click="$refs['edit-forward-dialog'].show(1)"
          :disabled="orderdetail.step === 1"
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-form :model="orderdetail" label-width="80px" size="small">
        <el-row>
          <el-col :span="5">
            <el-form-item label="ID" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.id }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="name" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.name }}
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="目的仓" size="small">
              &nbsp;&nbsp; &nbsp;&nbsp; {{ orderdetail.warehouse_name }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="运输方式" size="small">
              &nbsp;&nbsp;{{ orderdetail._transport_type }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1" size="small">
            <el-form-item label="预计发货">
              &nbsp;&nbsp;{{ orderdetail.date
              }}<el-tag size="mini">{{ orderdetail._step }}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="备注" size="small">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ orderdetail.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-button
        type="primary"
        size="small"
        @click="$refs['confirm-forward-dialog'].show()"
        v-if="orderdetail.step === 0"
        >确认</el-button
      >
      <el-button
        type="danger"
        size="small"
        @click="deleteTransportDetail"
        v-if="orderdetail.step === 0"
        >删除</el-button
      >
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>海外仓入库单</h3>
      </el-col>
      <el-col :span="5" :offset="3">
        <el-button
          type="text"
          @click="setShipment()"
          v-show="forwarddatalength === 0"
          >创建</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <el-form :model="orderdetail" label-width="100px" size="small">
          <el-form-item label="入库单单号" size="small">
            <!-- <el-input v-model='forwarddata.id' :disabled="true"></el-input> -->
            <span v-for="(item, index) in orderdetail._shipment_sn" :key="index"
              >{{ item }},</span
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>明细</h3>
      </el-col>
      <el-col :span="3" :offset="18">
        <el-button type="text" size="small" @click="downloadExcel('item')"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        ref="warrantDetailTable"
        :data="orderdetail.item"
        border
        @selection-change="handleSelectionChange"
        @select="handleSelect"
        @select-all="handleSelectall"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column
          prop="_product_name,_product_mfn"
          label="货品"
          mini-width="25%"
        >
          <template slot-scope="scope">
            <!-- {{scope.row._product_name}} <br>
                        {{scope.row._product_mfn}} -->
            <product-info-in-table-cell
              :cover="scope.row._product_cover"
              :title="scope.row._product_name"
              :mfn="scope.row._product_mfn"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column
          prop="qty_ctn"
          label="装箱率"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="_product_box_package_dimension.unit"
          label="单位"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="ctn_width,ctn_length,ctn_height,ctn_weight"
          label="外箱材积"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.ctn_width }} * {{ scope.row.ctn_length }} *
            {{ scope.row.ctn_height }} <br />
            {{ scope.row.ctn_weight }}kg {{ scope.row.ctn_volume_m }}m<sup
              >3</sup
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="purchase_order_id"
          label="采购单"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="ship_date"
          label="交期"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="expected_date,expected_ctn"
          label="预计到货"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.expected_date }}/{{ scope.row.expected_ctn }}箱
            <el-tag>套{{ scope.row.sellpack }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="unplanned_ctn,shipable_ctn"
          label="未排/可发"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.unplanned_ctn }}/{{ scope.row.shipable_ctn }}
          </template>
        </el-table-column>
        <el-table-column label="发货数" width="200">
          <template v-slot="scope">
            <el-row type="flex" align="middle">
              <div>
                <el-input-number
                  controls-position="right"
                  size="mini"
                  v-if="scope.row.checked"
                  style="width: 90px;"
                  :min="1"
                  :max="scope.row.planned_ctn"
                  v-model="scope.row.x"
                >
                </el-input-number>
                <span v-if="!scope.row.checked">{{
                  scope.row.planned_ctn
                }}</span>
              </div>
              <div style="margin-left:10px" v-show="scope.row.checked"></div>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row
      style="margin-top:20px; margin-bottom:30px;"
      v-show="orderdetail.step === 0"
    >
      <el-col :span="2">
        <el-button
          type="primary"
          size="small"
          :disabled="detaillist.length === 0"
          @click="saveDetailItem"
          >编辑</el-button
        >
      </el-col>
      <el-col :span="2" :offset="1">
        <el-dropdown>
          <el-button
            type="primary"
            size="small"
            :disabled="detaillist.length === 0"
          >
            移动<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="addTo"
              >移至其他货代单</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="$refs['edit-forward-dialog'].show(2)"
              >移至新货代单</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2" :offset="1">
        <el-button
          type="danger"
          size="small"
          :disabled="detaillist.length === 0"
          @click="deleteDetailItem()"
          >删除</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="3">
        <h3>报关单</h3>
      </el-col>
      <el-col :span="5" :offset="13">
        <el-button type="text" size="small" @click="downloadExcel('warrant')"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        ref="warrantDetailTable"
        :data="orderdetail.customs"
        border
      >
        <el-table-column
          prop="hs_code"
          label="商品编号"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="product_name"
          label="品名"
          mini-width="35%"
        ></el-table-column>
        <el-table-column prop="unit" label="单位" width="100"></el-table-column>
        <el-table-column
          prop="info"
          label="规格型号"
          mini-width="35%"
        ></el-table-column>
        <el-table-column prop="weight" label="净重" mini-width="10%">
          <template slot-scope="scope"> {{ scope.row.weight }}kg </template>
        </el-table-column>
        <el-table-column
          prop="qty"
          label="数量"
          mini-width="10%"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="单价"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="currency"
          label="货币"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="总价"
          width="100"
        ></el-table-column>
      </el-table>
    </el-row>
    <edit-ware-warrant ref="edit-ware-warrant-dialog"></edit-ware-warrant>
    <edit-forward-dialog
      ref="edit-forward-dialog"
      :orderDetail="orderdetail"
      :selectItem="selectItem"
      @change="change"
    ></edit-forward-dialog>
    <confirm-forward-dialog
      ref="confirm-forward-dialog"
      :fowardid="orderid"
      @confirmsuccess="change"
    ></confirm-forward-dialog>
    <add-to-forward-dialog
      ref="add-to-forward-dialog"
      :selectItem="selectItem"
      :orderid="orderid"
      type="move"
      @change="change"
    />
  </div>
</template>
<script>
import editWareWarrant from './edit-warehouse-warrant'
import editForwardDialog from './create-forward-dialog'
import addToForwardDialog from './add-to-forward-dialog.vue'
import * as forwardOrderAPI from '../../../api/forwardOrder'
import confirmForwardDialog from './confirm-forward-dialog.vue'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
import { baseUrl } from '@/config/env'

export default {
  components: {
    editWareWarrant,
    editForwardDialog,
    addToForwardDialog,
    productInfoInTableCell,
    confirmForwardDialog
  },
  data () {
    return {
      orderid: this.$route.query.order_id,
      orderdetail: {},
      detaillist: [],
      forwarddatalength: 0, // 海外入库单数组长度
      selectedList: [],
      selectItem: []
    }
  },
  created () {
    this.getOrdertail()
  },
  methods: {
    downloadExcel (type) {
      let url =
        baseUrl +
        'purchase/transport/order/detail/download?token=' +
        localStorage.getItem('token')
      if (type === 'item') {
        url += '&id=' + this.orderid
      } else if (type === 'warrant') {
        // 导出报关
        url += '&id=' + this.orderid + '&mode=' + 'customer'
        console.log(url)
      }
      window.open(url)
    },
    getOrdertail () {
      const self = this
      forwardOrderAPI
        .getTransportOrderDetail({ id: self.orderid })
        .then(data => {
          self.orderdetail = data
          self.forwarddatalength = data._shipment_sn.length
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleSelectionChange (val) {
      this.detaillist = []
      val.forEach(item => {
        this.detaillist.push(item.id)
      })
    },
    //  删除货代单
    deleteTransportDetail () {
      const self = this
      self
        .$confirm('提示', {
          title: '删除货代单',
          message: '删除货代单的同时，所有明细将退回至货代计划,请确认是否删除'
        })
        .then(_ => {
          forwardOrderAPI
            .deleteTransportOrderDetail({ id: self.orderdetail.id })
            .then(data => {
              self.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //  删除勾选的明细
    deleteDetailItem () {
      const self = this
      const detailformdata = new FormData()
      detailformdata.append('id', self.orderdetail.id)
      if (self.detaillist.length > 0) {
        for (let i = 0; i < self.detaillist.length; i++) {
          detailformdata.append(`item_ids[${i}]`, self.detaillist[i])
        }
      } else {
        this.$alert('请至少勾选一个^_^')
        return false
      }
      self
        .$confirm('确认是否删除选中的货代单明细？')
        .then(_ => {
          forwardOrderAPI
            .deleteTransportOrderDetailItem(detailformdata)
            .then(data => {
              this.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //  编辑货代单明细
    saveDetailItem () {
      const self = this
      if (self.selectedList.length === 0) {
        this.$alert('请至少勾选一个^_^')
        return false
      }
      const itemdata = self.selectedList.map(item => {
        return {
          id: item.id,
          x: item.x,
          order_id: item.order_id,
          mfn: item._product_mfn
        }
      })
      const jsondata = {
        id: self.orderdetail.id,
        item: itemdata
      }
      forwardOrderAPI
        .saveTransportOrderDetailItem(jsondata)
        .then(data => {
          this.getOrdertail()
        })
        .catch(err => {
          console.log(err)
        })
    },
    //  选中checkbox项的回调
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
      } else {
        row.checked = true
      }
      this.selectedList = selection
      this.selectItem = this.selectedList.map(item => {
        return {
          id: item.id,
          x: item.x
        }
      })
    },
    //  全选回调函数
    handleSelectall (selection) {
      const self = this
      if (selection.length > 0) {
        for (let i = 0; i < self.orderdetail.item.length; i++) {
          self.orderdetail.item[i].checked = true
        }
      } else {
        for (let i = 0; i < self.orderdetail.item.length; i++) {
          self.orderdetail.item[i].checked = false
        }
      }
      self.selectedList = selection
      this.selectItem = this.selectedList.map(item => {
        return {
          id: item.id,
          x: item.x
        }
      })
    },
    checkSelected () {
      if (this.selectedList.length === 0) {
        this.$alert('请至少勾选一个^_^')
        return false
      }
      if (
        !this.selectedList.every(item => {
          return item.x
        })
      ) {
        this.$alert('请填写数量！')
        return false
      }
      this.selectItem = this.selectedList.map(item => {
        return {
          id: item.id,
          x: item.x
        }
      })
      return true
    },
    //  追加至货代单
    addTo () {
      if (!this.checkSelected()) {
        return false
      }
      this.$refs['add-to-forward-dialog'].show()
    },
    change () {
      this.getOrdertail()
    },
    // 创建海外入库单
    setShipment () {
      const self = this
      const jsonData = {
        id: self.orderid
      }
      self
        .$confirm('提示', {
          title: '创建海外入库单',
          message:
            '目前FBA仓库无法追踪入库数量差异，暂时无法自动创建报损单和调整单。 海外仓入库单创建后不可更改，确定创建？'
        })
        .then(_ => {
          forwardOrderAPI
            .setShipment(jsonData)
            .then(data => {
              console.log(data)
              this.getOrdertail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
