<!-- 采购单明细导出 -->
<template>
  <el-dialog title="自动关联SKU" :visible.sync="visible" width="500px">
    <el-form ref="form" :model="resetSkuform" label-width="80px">
      <el-row>
        <el-form-item label="销售平台">
          <el-select v-model="resetSkuform.ec_type" placeholder="请选择">
            <el-option
              v-for="item in sysConfig.PurchaseOrder.ec"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="编码类型">
          <el-select v-model="resetSkuform.sku_type" placeholder="请选择">
            <el-option
              v-for="item in sysConfig.PurchaseOrder.sku_type"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="resetSku()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import * as stockOrder from '../../../api/stockOrder'
export default {
  props: ['stockcode'],
  data () {
    return {
      visible: false,
      resetSkuform: {}
    }
  },
  computed: mapGetters(['sysConfig']),
  methods: {
    show () {
      this.visible = true
    },
    resetSku () {
      const self = this
      const skuformdata = new FormData()
      skuformdata.append('id', self.stockcode)
      skuformdata.append('ec', self.resetSkuform.ec_type)
      skuformdata.append('sku', self.resetSkuform.sku_type)
      stockOrder
        .resetSku(skuformdata)
        .then(data => {
          this.$emit('sunccess-resetSku')
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
