<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>海外仓库存</el-breadcrumb-item>
        <el-breadcrumb-item>实时库存</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-tag
        >部分产品如果编号关联有问题，将无法检索到相应库存，需要优先修复。</el-tag
      >
    </el-row>
    <el-row class="search" style="margin-top: 10px">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input v-model="search.mfn" placeholder="mfn"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="search.warehouseId"
              placeholder="仓库"
              clearable
            >
              <el-option
                v-for="item in sysConfig.warehouselist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchinventory()"
              >搜索</el-button
            >
            <el-button type="primary">导出下载</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-button type="text">合并展示</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table border stripe width="100%" :data="inventoryList">
        <el-table-column type="selection" width="36"></el-table-column>
        <el-table-column label="商品" width="220">
          <template slot-scope="scope" v-if="scope.row._product">
            <product-info-in-table-cell
              :cover="scope.row._product.cover"
              :title="scope.row._product.name"
              :mfn="scope.row._product.mfn"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column prop="_warehouse" label="仓库"></el-table-column>
        <el-table-column
          prop="qty_in_purchase"
          label="采购在途"
        ></el-table-column>
        <el-table-column
          prop="qty_in_transfer"
          label="调拨在途"
        ></el-table-column>
        <el-table-column prop="qty" label="可用库存"></el-table-column>
        <el-table-column prop="qty_total" label="总库存"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
import * as inventoryAPI from '@/api/amazon'
import { mapGetters } from 'vuex'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
export default {
  components: {
    productInfoInTableCell
  },
  data () {
    return {
      search: {
        mfn: '',
        warehouseId: ''
      },
      pageData: {
        current_page: 1,
        per_page: 20
      },
      inventoryList: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.getInventoryList()
  },
  methods: {
    getInventoryList () {
      const jsonData = {
        mfn: this.search.mfn,
        warehouse_id: this.search.warehouseId,
        page: this.pageData.current_page,
        per_page: this.pageData.per_page
      }
      inventoryAPI
        .getInventory(jsonData)
        .then(data => {
          this.inventoryList = data.data
          console.log(this.inventoryList)
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchinventory () {
      this.pageData.current_page = 1
      this.getInventoryList()
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getInventoryList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getInventoryList()
    }
  }
}
</script>
