<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/finance/purchase/reimbursement' }"
          >报销审核</el-breadcrumb-item
        >
        <el-breadcrumb-item>查看报销单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <h2>采购单报销</h2>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="报销单">
              &nbsp;&nbsp;{{ order.id }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="供应商">
              &nbsp;&nbsp;{{ order.vendor_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="总金额">
              &nbsp;&nbsp;{{ order.amount }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              &nbsp;&nbsp;{{ order._step }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16" style="margin:10px 0px 30px 0px">
          <el-button
            type="primary"
            size="small"
            v-if="order.step === 4"
            @click="revokeReimbursement()"
            >反审核</el-button
          >
          <el-button
            type="primary"
            size="small"
            v-if="order.step === 2"
            @click="confirmReimbursement()"
            >审核通过</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <h2>支付信息</h2>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="收款单位">
              &nbsp;&nbsp;{{ order.bank_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户行">
              &nbsp;&nbsp;{{ order.bank }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账号">
              &nbsp;&nbsp;{{ order.bank_account }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="付款期限">
              &nbsp;&nbsp;{{ order.deadline }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="本次实付">
              &nbsp;&nbsp;{{ order.payment_amount }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备注">
              &nbsp;&nbsp;{{ order.remark }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <h2>报销明细</h2>
    <el-tabs v-model="tablvue">
      <el-tab-pane label="报销明细" name="first">
        <reimbur-detail-tab
          :reimbusementdetailList="reimbusementdetailList"
        ></reimbur-detail-tab>
      </el-tab-pane>
      <el-tab-pane label="开票明细" name="second">
        <invoice-detail-tab
          :invoiceDetailList="invoiceDetailList"
          :id="order.id"
        ></invoice-detail-tab>
      </el-tab-pane>
      <el-tab-pane label="开票资料" name="third">
        <invoice-info-tab :invoiceInfoList="invoiceInfoList"></invoice-info-tab>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import * as reimbursementApi from '../../../../api/financeReimbursement'
import reimburDetailTab from './reimbur-detail-tab'
import invoiceDetailTab from './invoice-detail-tab'
import invoiceInfoTab from './invoice-info-tab'
export default {
  components: {
    reimburDetailTab,
    invoiceDetailTab,
    invoiceInfoTab
  },
  data () {
    return {
      order: {},
      id: this.$route.params.id,
      reimbusementdetailList: [],
      invoiceDetailList: [],
      invoiceInfoList: [],
      tablvue: 'first'
    }
  },
  created () {
    this.getReimbursementDetail()
  },
  methods: {
    getReimbursementDetail () {
      reimbursementApi
        .getreimbursementDetail({ id: this.id })
        .then(data => {
          this.order = data.reimbursement
          this.reimbusementdetailList = data.item
          this.invoiceDetailList = data.kaipiao_detail
          this.invoiceInfoList = data.kaipiao_res
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmReimbursement () {
      const confirmformdata = new FormData()
      confirmformdata.append('id', this.id)
      this
        .$confirm('提示', {
          title: '报销审核',
          message:
            '审核通过后系统将完成自动生成支付应付款、自动冲账等操作。 确定审核通过该报销单？'
        })
        .then(_ => {
          reimbursementApi
            .confirmReimbursement(confirmformdata)
            .then(data => {
              this.getReimbursementDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    revokeReimbursement () {
      const revokeformdata = new FormData()
      revokeformdata.append('id', this.id)
      this
        .$confirm('提示', {
          title: '报销反审核',
          message:
            '反审核后系统将自动清除应付款记录，反自动冲账等操作。 确定反审核该报销单？'
        })
        .then(_ => {
          reimbursementApi
            .revokeReimbursement(revokeformdata)
            .then(data => {
              this.getReimbursementDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    }
  }
}
</script>
