<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>货品库存</el-breadcrumb-item>
        <el-breadcrumb-item>库存明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-tag
        >部分产品如果编号关联有问题，将无法检索到相应库存，需要优先修复。</el-tag
      >
    </el-row>
    <el-row class="search" style="margin-top: 15px">
      <el-col :span="18">
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-input placeholder="MFN" v-model="search.mfn"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="SKU" v-model="search.sku"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="请选择仓库"
              v-model="search.warehouse_id"
              clearable
            >
              <el-option
                v-for="item in sysConfig.warehouselist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="请选择是否锁定"
              v-model="search.is_locked"
              clearable
            >
              <el-option
                v-for="item in ops"
                :key = "item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="search.ec_id" placeholder="请选择ec">
              <el-option
                v-for="item in sysConfig.Common.ec"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchInventory()"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-button type="primary" size="mini" @click="downLoadLink()"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe border witdh="100%" :data="inventoryLs"
        height="52vh"
      >
        <el-table-column type="selection" width="39"></el-table-column>
        <el-table-column label="商品" width="300">
          <template slot-scope="scope">
            <product-info-table-cell
              :cover="scope.row._product.cover"
              :title="scope.row._product.name"
              :mfn="scope.row.mfn"
              v-if="scope.row._product"
            ></product-info-table-cell>
          </template>
        </el-table-column>
        <el-table-column label="仓库SKU" prop="_sku"></el-table-column>
        <el-table-column label="仓库" prop="_warehouse"></el-table-column>
        <el-table-column label="自有库存" prop="qty"></el-table-column>
        <el-table-column label="共享库存" prop="qty_share"></el-table-column>
        <el-table-column label="冻结库存" prop="qty_locked"></el-table-column>
        <el-table-column label="可用库存">
          <template slot-scope="scope">
            {{scope.row.qty + scope.row.qty_share + scope.row.qty_locked}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleLockInventory({'ec_id':search.ec_id,'warehouse_id':scope.row.warehouse_id,'_sku':scope.row._sku,'is_locked':0})"
              v-if="scope.row.is_locked"
            >解锁</el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleLockInventory({'ec_id':search.ec_id,'warehouse_id':scope.row.warehouse_id,'_sku':scope.row._sku,'is_locked':1})"
              v-else
            >锁定</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[10, 20, 50, 100, 150]"
        layout="total, prev,pager, next, sizes, jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import { getSalesInventoryLs } from '@/api/amazon'
import productInfoTableCell from '@/components/common/product-info-table-cell'
import { API } from '@/service/api'
import confirm from '@/components/common/Confirm'
import { Notification } from 'element-ui'

export default {
  components: {
    productInfoTableCell
  },
  data () {
    return {
      search: {
        mfn: '',
        sku: '',
        warehouse_id: '',
        is_locked: '',
        ec_id: Number(this.$route.params.ec_id)
      },
      pageData: {
        current_page: 1,
        per_page: 10
      },
      // ecList: [],
      inventoryLs: [],
      ops: [
        {
          value: '0',
          label: '否'
        },
        {
          value: '1',
          label: '是'
        }
      ]
    }
  },
  created () {
    // getSalesEcList()
    // .then((data) => {
    //     this.ecList = data
    // }).catch((err) => {
    //     console.log(err)
    // })
    this.getInventoryDetail()
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    $route: {
      handler: function () {
        this.search.ec_id = Number(this.$route.params.ec_id)
        this.searchAllot()
      },
      deep: true
    }
  },
  methods: {
    downLoadLink () {
      const url =
        baseUrl +
        'sales/inventory/exportLs?token=' +
        localStorage.getItem('token') +
        '&ec_id=' +
        this.search.ec_id +
        '&warehouse_id=' +
        this.search.warehouse_id +
        '&sku=' +
        this.search.sku +
        '&mfn=' +
        this.search.mfn +
        '&is_locked=' +
        this.search.is_locked
      window.open(url)
    },
    getInventoryDetail () {
      const jsondata = {
        ec_id: this.search.ec_id,
        warehouse_id: this.search.warehouse_id,
        sku: this.search.sku,
        mfn: this.search.mfn,
        is_locked: this.search.is_locked,
        page: this.pageData.current_page,
        per_page: this.pageData.per_page
      }
      getSalesInventoryLs(jsondata)
        .then(data => {
          this.inventoryLs = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getInventoryDetail()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getInventoryDetail()
    },
    searchInventory () {
      this.pageData.current_page = 1
      this.getInventoryDetail()
    },
    handleLockInventory: function (data) {
      confirm(
        '提示',
        '确定' + (data.is_locked ? '锁定' : '解锁') + '该条库存记录吗？'
      )
        .then(done => {
          API.Sales.lockInventory(data)
            .then((res) => {
              done()
              Notification.success({
                message: '设置成功',
                duration: 1500
              })
              this.getInventoryDetail()
            })
        })
    }
  }
}
</script>
