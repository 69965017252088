<template>
  <div>
    <el-row>
      <el-col :span="1" :offset="13">
        <el-button type="text" size="small" @click="downloadExcel('warrant')"
          >按照报关品名汇总导出</el-button
        >
      </el-col>
      <el-col :span="1" :offset="3">
        <el-button
          type="text"
          size="small"
          @click="$refs['upload-order-clearance-dialog'].show(orderdetail.id)"
          >上传报关单</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe ref="warrantDetailTable" :data="clearanceItem" border>
        <el-table-column prop="mfn" label="MFN" width="160">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.description"
              @click="
                $refs['edit-clearance-dialog'].show(scope.row, orderdetail)
              "
              >{{ scope.row.mfn }}</el-button
            >
            <el-button
              type="text"
              v-else
              @click="
                $refs['edit-clearance-dialog'].show(scope.row, orderdetail)
              "
              >MFN</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="hs_code"
          label="商品编号"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="description"
          label="品名"
          mini-width="35%"
        ></el-table-column>
        <el-table-column prop="unit" label="单位" width="100"></el-table-column>
        <el-table-column
          prop="info"
          label="规格型号"
          mini-width="35%"
        ></el-table-column>
        <el-table-column prop="weight" label="净重" mini-width="10%">
          <template slot-scope="scope"> {{ scope.row.weight }}kg </template>
        </el-table-column>
        <el-table-column
          prop="qty"
          label="数量"
          mini-width="10%"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="单价"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="currency"
          label="货币"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="总价"
          width="100"
        ></el-table-column>
      </el-table>
    </el-row>
    <edit-clearance-dialog
      ref="edit-clearance-dialog"
      @clearance-success="change"
    ></edit-clearance-dialog>
    <upload-order-clearance-dialog
      ref="upload-order-clearance-dialog"
    ></upload-order-clearance-dialog>
  </div>
</template>
<script>
import { baseUrl } from '@/config/env'
import editClearanceDialog from './edit-clearance-dialog'
import uploadOrderClearanceDialog from './upload-order-clearance-dialog'
export default {
  props: ['clearanceItem', 'orderdetail'],
  components: {
    editClearanceDialog,
    uploadOrderClearanceDialog
  },
  methods: {
    downloadExcel () {
      const url =
        baseUrl +
        'logistics/order/clearance/downloads?token=' +
        localStorage.getItem('token') +
        '&id=' +
        this.orderdetail.id
      window.open(url)
    },
    change () {
      this.$emit('clearance-success')
    }
  }
}
</script>
