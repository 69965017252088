<template>
  <div>
    <el-table border stripe style="width:100%" :data="invoiceInfoList">
      <el-table-column prop="name" label="开票品名"></el-table-column>
      <el-table-column prop="qty" label="数量"></el-table-column>
      <el-table-column prop="unit" label="单位"></el-table-column>
      <el-table-column prop="amount" label="交付金额"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: ['invoiceInfoList']
}
</script>
