<template>
  <el-dialog
    title="创建调拨出库单"
    :visible.sync="visible"
    width="500px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-form :inline="true" label-width="100px" size="small">
      <el-form-item label="调出仓库">
        <el-select clearable v-model="formdata.warehouse_id" placeholder="仓库">
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调入仓库">
        <el-select
          clearable
          v-model="formdata.to_warehouse_id"
          placeholder="仓库"
        >
          <el-option
            v-for="item in sysConfig.warehouselist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注说明">
        <el-input v-model="formdata.remark" type="area" :rows="5"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="createOrder()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { createAllotOut } from '@/api/warehouse'
export default {
  data () {
    return {
      visible: false,
      formdata: {}
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show () {
      this.visible = true
    },
    closedone (done) {
      done()
    },
    createOrder () {
      const jsondata = {
        warehouse_id: this.formdata.warehouse_id,
        to_warehouse_id: this.formdata.to_warehouse_id,
        remark: this.formdata.remark
      }
      createAllotOut(jsondata)
        .then(data => {
          this.$emit('success-create')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.formdata = {}
      this.visible = false
    }
  }
}
</script>
