<template>
  <el-dialog
    title="更新返工数据"
    :visible.sync="visible"
    :before-close="closeDone"
    width="500px"
  >
    <el-form
      label-width="80px"
      :model="search"
      :rules="rules"
      ref="rework"
      size="mini"
    >
      <el-form-item label-width="80px" label="上传文件">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="file"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
          :data="ruledata"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label-width="20px" label>
        <p>
          该数据将被同步至货代计划“跨境调拨”中，<el-link
            type="primary"
            :href="downloadTemplate"
            target="_blank"
            >下载导入文件模板</el-link
          >
        </p>
      </el-form-item>
      <el-form-item prop="transport_type" label="运输方式">
        <el-select
          clearable
          v-model="search.transport_type"
          placeholder="运输方式"
        >
          <el-option
            v-for="item in transportList"
            :key="item.key"
            :label="item.val"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="warehouse" label="目的仓库">
        <el-select clearable v-model="search.warehouse" placeholder="目的仓库">
          <el-option
            v-for="item in wareshouseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分拆换标:">
        <!-- <el-checkbox v-model="search.exp">只分拆无需返工的记录</el-checkbox> -->
        <el-select v-model="search.exp">
          <el-option
            v-for="item in exparray"
            :key="item.key"
            :value="item.key"
            :label="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      visible: false,
      wareshouseList: [],
      transportList: [],
      exparray: [
        {
          key: false,
          val: '待返工'
        },
        {
          key: true,
          val: '不返工'
        }
      ],
      search: {
        transport_type: '',
        warehouse: '',
        exp: false
      },
      rules: {
        transport_type: [
          { required: true, message: '请选择运输方式', trigger: 'blur' }
        ],
        warehouse: [
          { required: true, message: '请选择目的仓库', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    importInboundOrder () {
      return (
        baseUrl +
        'wms/inventory/importLogicPlan?token=' +
        localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return (
        baseUrl +
        'wms/inventory/importLogisticsPlanTpl?token=' +
        localStorage.getItem('token')
      )
    },
    ...mapGetters(['sysConfig']),
    ruledata () {
      return {
        transport_type: this.search.transport_type,
        warehouse_id: this.search.warehouse,
        exp: this.search.exp
      }
    }
  },
  created () {
    this.wareshouseList = this.sysConfig.warehouselist
    this.transportList = this.sysConfig.Common.transport_type
  },
  methods: {
    show () {
      this.visible = true
    },
    closeDone (done) {
      done()
    },
    handleConfirm () {
      this.$refs.rework.validate(valid => {
        if (valid) {
          this.$refs.upload.submit()
        }
      })
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      this.$emit('sunccess-import-rework')
      this.$notify({ message: response.msg })
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
