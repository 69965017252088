<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">WMS</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/wms/warehouse/stock-out-in' }"
          >单据查询</el-breadcrumb-item
        >
        <el-breadcrumb-item v-show="type === '-5'">报损单</el-breadcrumb-item>
        <el-breadcrumb-item v-show="type === '5'">调整单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="5">
        <h2 v-show="type === '-5'">报损单</h2>
        <h2 v-show="type === '5'">调整单</h2>
      </el-col>
      <el-col :span="3" :offset="16">
        <el-button
          type="text"
          @click="$refs['edit-breakage-dialog'].show(type, 1)"
          v-if="DetailData.step < 3"
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单号">
              &nbsp;&nbsp;{{ DetailData.id
              }}<el-tag>{{ DetailData._id }}</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="人员">
              &nbsp;&nbsp;{{ DetailData._user_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="流程">
              &nbsp;&nbsp;{{ DetailData._step
              }}<el-tag size="mini">状态</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="最后更新时间">
              &nbsp;&nbsp;{{ DetailData.updated_at }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="仓库">
              &nbsp;&nbsp;<span>{{ DetailData._warehouse_name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外部单号">
              &nbsp;&nbsp;<span>{{ DetailData.no }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外部平台">
              &nbsp;&nbsp;<span>{{ DetailData.platform }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-form-item label="备注">
            &nbsp;&nbsp;{{ DetailData.remark }}
          </el-form-item>
        </el-row>
        <el-row :gutter="16">
          <el-button
            type="primary"
            size="small"
            @click="confrimwms(type, 'submit')"
            v-if="DetailData.step === 1"
            >提交审核</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="deletewms(type)"
            v-if="DetailData.step === 1"
            >删除</el-button
          >

          <el-button
            type="primary"
            size="small"
            @click="confrimwms(type, 'passReview')"
            v-if="DetailData.step === 2"
            >审核通过</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="confrimwms(type, 'notReview')"
            v-if="DetailData.step === 2"
            >审核不通过</el-button
          >

          <el-button
            type="primary"
            size="small"
            @click="confrimwms(type, 'confirm')"
            v-if="DetailData.step === 3"
            >执行确认</el-button
          >
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="4" :offset="20">
        <el-button
          type="primary"
          @click="$refs['breakageAdjust-import'].show(type, id)"
          size="small"
          v-if="DetailData.step <= 3"
          >导入明细</el-button
        >
      </el-col>
    </el-row>
    <el-row class="tables">
      <el-table stripe style="width:100%" :data="DetailData.item" border>
        <el-table-column prop="_product_mfn" label="产品编号"></el-table-column>
        <el-table-column prop="_product_name" label="品名"></el-table-column>
        <el-table-column prop="_step" label="单位"></el-table-column>
        <el-table-column prop="qty_in_ctn" label="装箱率"></el-table-column>
        <el-table-column prop="ctn" label="箱数"></el-table-column>
        <el-table-column prop="pcs" label="散装数"></el-table-column>
        <el-table-column prop="total_qty" label="总数"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                $refs['edit-breakage-detail-dialog'].show(
                  type,
                  scope.row.id,
                  scope.row._product_mfn,
                  scope.row._product_name
                )
              "
              >编辑</el-button
            >
            <el-button type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <edit-breakage-adjust-dialog
      :id="id"
      @success-create="getOutInDetail()"
      ref="edit-breakage-dialog"
    ></edit-breakage-adjust-dialog>
    <breakage-adjust-import-dialog
      ref="breakageAdjust-import"
      @sunccess-import-breakageadjuse="getOutInDetail()"
    ></breakage-adjust-import-dialog>
    <edit-breakage-adjust-detail-dialog
      :order_id="id"
      ref="edit-breakage-detail-dialog"
      @edit-detail-success="getOutInDetail()"
    ></edit-breakage-adjust-detail-dialog>
  </div>
</template>
<script>
import * as warehouseApi from '../../../api/warehouse'
import editBreakageAdjustDialog from './create-breakage-adjust-dialog'
import breakageAdjustImportDialog from './breakage-adjust-import-dialog'
import editBreakageAdjustDetailDialog from './edit-breakage-adjust-detail-dialog'
export default {
  components: {
    editBreakageAdjustDialog,
    breakageAdjustImportDialog,
    editBreakageAdjustDetailDialog
  },
  data () {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      DetailData: {}
    }
  },
  created () {
    this.getOutInDetail()
  },
  methods: {
    getOutInDetail () {
      const self = this
      warehouseApi
        .getwmsOrderDetail({ id: self.id })
        .then(data => {
          self.DetailData = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    confrimwms (type, set) {
      const self = this
      let titlelabel = {}
      if (type === '-5') {
        if (set === 'submit') {
          titlelabel = {
            title: '报损单提交审核',
            message: '提交后不可修改，确认提报财务审核？'
          }
        } else if (set === 'passReview') {
          titlelabel = {
            title: '审核通过报损单',
            message: '确认通过该报损单？'
          }
        } else if (set === 'notReview') {
          titlelabel = {
            title: '不通过报损单',
            message: '确认不通过该报损单？'
          }
        } else if (set === 'confirm') {
          titlelabel = {
            title: '确认报损单',
            message: '是否执行确认该报损单？'
          }
        }
        self
          .$confirm('提示', titlelabel)
          .then(_ => {
            warehouseApi
              .confirmBreakage({ id: self.id, set: set })
              .then(data => {
                this.getOutInDetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      } else if (type === '5') {
        if (set === 'submit') {
          titlelabel = {
            title: '调整单提交审核',
            message: '提交后不可修改，确认提报财务审核？'
          }
        } else if (set === 'passReview') {
          titlelabel = {
            title: '审核通过调整单',
            message: '确认通过该调整单？'
          }
        } else if (set === 'notReview') {
          titlelabel = {
            title: '不通过调整单',
            message: '确认不通过该调整单？'
          }
        } else if (set === 'confirm') {
          titlelabel = {
            title: '确认调整单',
            message: '是否执行确认该调整单？'
          }
        }
        self
          .$confirm('提示', titlelabel)
          .then(_ => {
            warehouseApi
              .confirmAdjust({ id: self.id, set: set })
              .then(data => {
                this.getOutInDetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      }
    },
    deletewms (type) {
      const self = this
      if (type === '-5') {
        self
          .$confirm('提示', {
            title: '报损单删除',
            message: '确认删除报损单？'
          })
          .then(_ => {
            warehouseApi
              .deleteBreakage({ id: self.id })
              .then(data => {
                this.getOutInDetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(_ => {})
      } else if (type === '5') {
        self
          .$confirm('提示', {
            title: '调整单删除',
            message: '确认删除调整单？'
          })
          .then(_ => {
            warehouseApi
              .deleteAdjust({ id: self.id })
              .then(data => {
                this.getOutInDetail()
              })
              .catch(err => {
                console.log(err)
              })
          })
          .catch(() => {})
      }
    }
  }
}
</script>
