<!-- 采购单明细页 -->
<template>
  <div class="stockdetail">
    <el-row style="margin-bottom:20px">
      <el-col :span="18">
        <el-radio-group v-model="radiovalue" size="mini" @change="changeradio">
          <el-radio-button label="价格"></el-radio-button>
          <el-radio-button label="材积"></el-radio-button>
          <el-radio-button label="标签&备注"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="2" v-show="radiovalue === '价格'">
        <el-dropdown>
          <!-- :disabled="editdisable === '1' " -->
          <el-button type="primary" size="mini">
            导入导出<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$refs['stockdetail-import'].show()"
              >导入明细</el-dropdown-item
            >
            <el-dropdown-item @click.native="downloadLink()"
              >导出明细</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2" :offset="1" v-show="radiovalue === '价格'">
        <!-- <el-button type="primary" size="mini" @click="$refs['stockdetail-resetsku'].show()">重置SKU</el-button> -->
        <el-dropdown>
          <el-button type="primary" size="mini">
            重置SKU<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="resetVendorSku"
              >重置供应商SKU</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="$refs['stockdetail-resetsku'].show()"
              >自动关联SKU</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2" v-show="radiovalue === '材积'">
        <el-button
          type="primary"
          size="mini"
          @click.native="$refs['stock-volume-update'].show()"
          >材积比对与同步</el-button
        >
      </el-col>
      <el-col :span="2" v-show="radiovalue === '标签&备注'">
        <el-button type="primary" size="mini">匹配标签唛头与配件</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        stripe
        ref="stockdetailTable"
        :summary-method="getSummaries"
        show-summary
        :data="stockdetailList"
        border
      >
        <el-table-column>
          <template slot-scope="scope">
            <el-popover
              ref="popover"
              placement="right-start"
              trigger="hover"
              :visible-arrow="false"
            >
              <img
                v-if="scope.row.product.cover"
                :src="scope.row.product.cover"
                style="width: 200px; height: 200px;"
              />
              <img
                v-else
                src="/static/no-photo.jpg"
                style="width: 200px; height: 200px;"
              />
              <img
                slot="reference"
                v-if="scope.row.product.cover"
                :src="scope.row.product.cover"
                style="width: 60px;height: 60px;border: 1px solid #F9FAFC;vertical-align: middle; margin: 5px"
              />
              <img
                slot="reference"
                v-else
                src="/static/no-photo.jpg"
                style="width: 60px;height: 60px;border: 1px solid #F9FAFC;vertical-align: middle; margin: 5px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="product.name,product.mfn"
          label="品名编号"
          width="160"
          key="2"
        >
          <template slot-scope="scope">
            {{ scope.row.product.mfn }}
            <br />
            {{ scope.row.product.name }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sku"
          label="SKU"
          width="120"
          key="3"
        ></el-table-column>
        <el-table-column
          prop="vendor_sku"
          label="供应商编号"
          width="120"
          key="4"
        ></el-table-column>
        <el-table-column
          prop="product.box_package_dimension.unit"
          label="产品单位"
          width="100"
          key="5"
        ></el-table-column>
        <!-- //价格部分 -->
        <el-table-column
          prop="qty"
          label="采购总数"
          mini-width="10%"
          key="9"
          v-if="radiovalue === '价格'"
        ></el-table-column>
        <el-table-column
          prop="qty_ctn"
          label="装箱率"
          mini-width="10%"
          key="10"
          v-if="radiovalue === '价格'"
        ></el-table-column>
        <el-table-column
          prop="ctn"
          label="箱数"
          mini-width="10%"
          key="11"
          v-if="radiovalue === '价格'"
        ></el-table-column>
        <el-table-column
          prop="sellpack"
          label="套系"
          mini-width="10%"
          key="12"
          v-if="radiovalue === '价格'"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="单价(含税)"
          width="100"
          key="13"
          v-if="radiovalue === '价格'"
        ></el-table-column>
        <el-table-column
          prop="amount"
          label="总金额"
          width="100"
          key="14"
          v-if="radiovalue === '价格'"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="100"
          key="15"
          v-if="radiovalue === '价格'"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="deletedetail(scope.row.id)"
              >删除</el-button
            >
            <el-button
              type="text"
              @click="$refs['edit-stock-detail-dialog'].show(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <!--    //材积部分 -->
        <el-table-column
          prop="product.width,product.height,product.length"
          label="产品尺寸"
          mini-width="35%"
          key="16"
          v-if="radiovalue === '材积'"
        >
          <template slot-scope="scope">
            {{ scope.row.product.length }}<br />
            {{ scope.row.product.width }}<br />
            {{ scope.row.product.height }}<br />
          </template>
        </el-table-column>
        <el-table-column
          prop="ctn_length,ctn_width,ctn_height"
          label="外箱尺寸"
          mini-width="10%"
          key="17"
          v-if="radiovalue === '材积'"
        >
          <template slot-scope="scope">
            {{ scope.row.ctn_length }}<br />
            {{ scope.row.ctn_width }}<br />
            {{ scope.row.ctn_height }}<br />
          </template>
        </el-table-column>
        <el-table-column
          prop="ctn_g_weight,ctn_weight"
          label="外箱重量"
          mini-width="10%"
          key="18"
          v-if="radiovalue === '材积'"
        >
          <template slot-scope="scope">
            (净) {{ scope.row.ctn_weight }}<br />
            (毛) {{ scope.row.ctn_g_weight }}<br />
          </template>
        </el-table-column>
        <el-table-column
          prop="box_length,box_width,box_height"
          label="内盒尺寸"
          mini-width="10%"
          key="19"
          v-if="radiovalue === '材积'"
        >
          <template slot-scope="scope">
            {{ scope.row.box_length }}<br />
            {{ scope.row.box_width }}<br />
            {{ scope.row.box_height }}<br />
          </template>
        </el-table-column>
        <el-table-column
          prop="box_g_weight,box_weight"
          label="内盒重量"
          width="100"
          key="20"
          v-if="radiovalue === '材积'"
        >
          <template slot-scope="scope">
            (净) {{ scope.row.box_g_weight }}<br />
            (毛) {{ scope.row.box_weight }}<br />
          </template>
        </el-table-column>
        <el-table-column
          prop="qty_ctn"
          label="装箱率"
          width="100"
          key="21"
          v-if="radiovalue === '材积'"
        ></el-table-column>
        <el-table-column
          label="拆解"
          width="100"
          key="22"
          v-if="radiovalue === '材积'"
        ></el-table-column>
        <el-table-column
          label="AB箱"
          width="100"
          key="23"
          v-if="radiovalue === '材积'"
        ></el-table-column>
        <!--    //标签&备注 -->
        <el-table-column
          label="标签唛头"
          mini-width="30%"
          key="24"
          v-if="radiovalue === '标签&备注'"
        ></el-table-column>
        <el-table-column
          prop="attachment"
          label="配件"
          mini-width="30%"
          key="25"
          v-if="radiovalue === '标签&备注'"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          mini-width="30%"
          key="26"
          v-if="radiovalue === '标签&备注'"
        ></el-table-column>
      </el-table>
    </el-row>
    <stock-detail-import
      :stockcode="stockcode"
      ref="stockdetail-import"
      @sunccess-import-detail="refreshdetail()"
    ></stock-detail-import>
    <stock-detail-resetsku
      ref="stockdetail-resetsku"
      :stockcode="stockcode"
      @sunccess-resetSku="refreshdetail()"
    ></stock-detail-resetsku>
    <stock-volume-update
      ref="stock-volume-update"
      :stockdetaillist="stockdetailList"
      @successvolumeupdate="refreshdetail()"
    ></stock-volume-update>
    <edit-stock-detail-dialog
      ref="edit-stock-detail-dialog"
      @edit-item-success="refreshdetail()"
    ></edit-stock-detail-dialog>
  </div>
</template>
<script>
import stockDetailImport from './stock-detail-import-dialog'
import stockDetailResetsku from './stock-detail-resetsku-dialog'
import stockVolumeUpdate from './stock-volume-update-dialog'
import editStockDetailDialog from './edit-stock-detail-dialog'
import * as stockOrderAPI from '@/api/stockOrder'
import { baseUrl } from '@/config/env'
export default {
  components: {
    stockDetailImport,
    stockDetailResetsku,
    stockVolumeUpdate,
    editStockDetailDialog
  },
  props: ['stockcode', 'stockdetailList', 'editdisable'],
  data () {
    return {
      radiovalue: '价格'
    }
  },
  methods: {
    changeradio () {
      console.log(this.radiovalue)
    },
    refreshdetail () {
      this.$emit('refreshdetail')
    },
    deletedetail (detailid) {
      const detailidformdata = new FormData()
      detailidformdata.append('id', detailid)
      this.$confirm('确认是否删除该条采购明细？')
        .then(_ => {
          stockOrderAPI
            .deleteStockDetailItem(detailidformdata)
            .then(data => {
              this.$emit('refreshdetail')
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    downloadLink () {
      const url =
        baseUrl +
        'purchase/order/detail/item/export?token=' +
        localStorage.getItem('token') +
        '&id=' +
        this.stockcode
      window.open(url)
    },
    // 重置供应商SKU
    resetVendorSku () {
      const self = this
      const dataform = new FormData()
      dataform.append('id', self.stockcode)
      self
        .$confirm('提示', {
          title: '重置供应商SKU',
          message: `该操作将自动匹配同一供应商的采购历史记录，空缺的供应商SKU将自动使用历史记录中的SKU,如果该操作后供应商SKU依旧空缺，则表明此前并未向该供应商采购过相应的产品
      确定执行重置操作？`
        })
        .then(_ => {
          stockOrderAPI
            .resetVendorSku(dataform)
            .then(data => {
              self.refreshdetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    //  汇总合计列值
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (
          column.property === 'qty' ||
          column.property === 'ctn' ||
          column.property === 'amount'
        ) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            .toFixed(2)
        } else {
          sums[index] = ''
        }
      })

      return sums
    }
  }
}
</script>
