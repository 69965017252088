<template>
  <el-dialog title="批量更新" :visible.sync="visible" width="600px">
    <el-form label-position="left">
      <el-form-item label-width="10px">
        <el-upload
          :action="importInboundOrder"
          :limit="1"
          name="file"
          :multiple="false"
          :on-success="onUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="onUploadError"
          :auto-upload="false"
          :show-file-list="true"
          ref="upload"
        >
          <el-button size="small" type="primary">选取excel文件</el-button>
        </el-upload>
      </el-form-item>

      <el-form-item label-width="10px" label>
        <p>
          务必保证格式和"采购到货入库模板"一致
          <el-link type="primary" :href="downloadTemplate" target="_blank">purchase_inbound_tpl.xls</el-link>
        </p>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button size="small" @click="hide">取 消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm">上 传</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { baseUrl } from '@/config/env.js'

export default {
  data () {
    return {
      visible: false,
      dataimportmode: 0
    }
  },

  computed: {
    importInboundOrder () {
      return (
        baseUrl +
        'purchase/tracking/bulkImport?token=' +
        localStorage.getItem('token')
      )
    },
    // 导出excel模板
    downloadTemplate () {
      return (
        baseUrl +
        'purchase/tracking/tpl?token=' +
        localStorage.getItem('token')
      )
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    handleConfirm () {
      this.$refs.upload.submit()
    },
    // 文件上传成功的回调
    onUploadSuccess (response, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (response.res === 0) {
        this.$notify({ message: response.msg, type: 'error' })
      } else {
        this.$notify({ message: response.msg, type: 'success' })
        this.$emit('change')
        this.hide()
      }
    },
    handleBeforeUpload (file) {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    // 文件上传失败的回调
    onUploadError (err, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }
      if (err) {
        this.$notify.error({ message: '导入失败，请重试！' })
      }
    }
  }
}
</script>
