import { render, staticRenderFns } from "./fba-plan-detail.vue?vue&type=template&id=540df126&"
import script from "./fba-plan-detail.vue?vue&type=script&lang=js&"
export * from "./fba-plan-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports