<template>
  <div class="tracking-list">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>交货进度</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-select
            clearable
            v-model="search.ec_id"
            placeholder="选择平台">
              <el-option
                v-for="item in ecList"
                :key="item.key"
                :value = "item.key"
                :label = "item.val"
              ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="search.warehouse_id"
            placeholder="选择仓库"
          >
            <el-option
              v-for="item in warehouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="search.ship_date"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="需求交期"
            end-placeholder="需求交期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-input
            class="search-bar"
            v-model="search.order_id"
            placeholder="采购单"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input class="search-bar" v-model="search.mfn" placeholder="MFN" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
          <el-button type="primary" @click="exportHandle()">下载</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-col :span="6" :offset="18">
        <div style="float:right">
          <!-- <el-button type="primary" @click="$refs['inbound-dialog'].show()">到货入库</el-button> -->
          <el-button
            type="primary" size="mini"
            @click="$refs['tracking-bulk-update-dialog'].show()"
            >批量更新</el-button
          >
        </div>
      </el-col>
    </el-row>
    <br />

    <el-table :data="datList" stripe border style="width: 100%">
      <el-table-column label="MFN" prop="mfn"></el-table-column>
      <el-table-column label="供应商编号" prop="vendor_sku"></el-table-column>
      <el-table-column label="条码号" prop="sku"></el-table-column>
      <el-table-column label="平台" prop="_ec"></el-table-column>
      <el-table-column label="总数/箱数/外箱装箱率/内盒装箱率" width="150">
        <template slot-scope="scope">
          <div style="margin-left: 10px;">
            <p>
              {{ scope.row.qty }}/
              {{ scope.row.ctn }}/
              {{ scope.row.qty_ctn }}/
              {{ scope.row.sellpack }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="order_id" label="采购单"></el-table-column>
      <el-table-column prop="purchase_order_no" label="商贸采购单号"></el-table-column>
      <el-table-column prop="contract_id" label="生产单号"></el-table-column>
      <el-table-column prop="purchase_date" label="下单时间"></el-table-column>
      <el-table-column prop="ship_date" label="需求交期"></el-table-column>
      <el-table-column prop="inbound_date" label="进仓日期"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs['update-tracking-dialog'].show(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[15, 20, 30, 50, 100, 200]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.per_page"
      ></el-pagination>
    </el-row>

    <tracking-detail-dialog
      ref="tracking-detail-dialog"
      :propsInfo="deailInfo"
      @change="childChange"
    />
    <inbound-dialog ref="inbound-dialog" />
    <tracking-bulk-update-dialog
      ref="tracking-bulk-update-dialog"
      @change="childChange"
    />
    <edit-remark-dialog
      ref="edit-remark"
      @editremark-success="trackingList()"
    ></edit-remark-dialog>
    <xlsx-downloader ref="xlsx-downloader-list" type="PURCHASE_TRACKING_LIST"/>
    <update-tracking-dialog
      ref="update-tracking-dialog"
      @update-success="trackingList()"
    ></update-tracking-dialog>
  </div>
</template>
<script>
import * as trackingApi from '@/api/tracking'
import trackingDetailDialog from './tracking-detail-dialog.vue'
import inboundDialog from './inbound-dialog.vue'
import trackingBulkUpdateDialog from './tracking-bulk-update-dialog'
import { mapGetters } from 'vuex'
import editRemarkDialog from './edit-remark-dialog'
import XlsxDownloader from '@/components/xlsx-downloader'
import UpdateTrackingDialog from './update-tracking-dialog'

export default {
  components: {
    trackingDetailDialog,
    inboundDialog,
    trackingBulkUpdateDialog,
    editRemarkDialog,
    XlsxDownloader,
    UpdateTrackingDialog
  },
  data () {
    return {
      // formVisible: false,
      deailInfo: {},
      datList: [],
      ecList: [],
      wareshouseList: [],
      search: {
        ec_id: '',
        warehouse_id: '',
        order_id: '',
        mfn: '',
        ship_date: [],
        page: 1,
        per_page: 15,
        total: 0
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.ecList = this.sysConfig.Common.ec
    this.warehouseList = this.sysConfig.warehouselist
    this.trackingList()
  },
  methods: {
    trackingList () {
      trackingApi.trackingList(this.search).then(res => {
        this.datList = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.per_page = res.per_page
      })
    },
    searchHandle () {
      this.trackingList()
    },

    editHandle (index, row) {
      const data = {
        id: row.id,
        order_id: row.order_id
      }
      trackingApi.trackingDeail(data).then(res => {
        this.deailInfo = res
      })
      this.$refs['tracking-detail-dialog'].show()
    },
    childChange () {
      this.formVisible = false
      this.trackingList()
    },

    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.trackingList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.per_page = pagesize
      this.trackingList()
    },
    exportHandle () {
      const params = {
        ec_id: this.search.ec_id,
        mfn: this.search.mfn,
        warehouse_id: this.search.warehouse_id,
        ship_date: this.search.ship_date,
        order_id: this.search.order_id
      }
      this.$refs['xlsx-downloader-list'].show(params)
    }
  }
}
</script>
