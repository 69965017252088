<template>
  <el-dialog
    title="编辑明细（采购直发/跨境调拨）"
    :visible.sync="visible"
    width="1280px"
    show-close
    :before-close="closeDialog"
  >
    <el-table
      stripe
      ref="edit-detail"
      :data="orderdetailItem"
      border
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      @select-all="handleSelectall"
    >
      <el-table-column
        type="selection"
        width="30"
        :selectable="checkboxflag"
        disable="true"
      >
      </el-table-column>
      <el-table-column prop="name,mfn" label="货品" width="260">
        <template slot-scope="scope">
          <product-info-in-table-cell
            :cover="scope.row.cover"
            :title="scope.row.name"
            :mfn="scope.row.mfn"
          ></product-info-in-table-cell>
        </template>
      </el-table-column>
      <el-table-column
        prop="qty_ctn"
        label="装箱率"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="ctn_width,ctn_length,ctn_height,ctn_weight"
        label="外箱材积"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.ctn_width }} * {{ scope.row.ctn_length }} *
          {{ scope.row.ctn_height }} <br />
          {{ scope.row.ctn_weight }}kg<br />
          {{ scope.row.ctn_volume_m }}m<sup>3</sup>
        </template>
      </el-table-column>
      <el-table-column prop="label" label="箱标" width="100">
        <template slot-scope="scope">
          {{ scope.row.label }} <br />
          <el-tag v-if="scope.row.sellpack > 1"
            >套{{ scope.row.sellpack }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="purchase_order_id"
        label="采购单"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="ship_date"
        label="交期"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="expected_date,expected_ctn"
        label="预计到货"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.expected_date }}/{{ scope.row.expected_ctn }}箱
        </template>
      </el-table-column>
      <el-table-column
        prop="unplanned_ctn,shipable_ctn"
        label="未排/可发"
        width="80"
      >
        <template slot-scope="scope">
          {{ scope.row.unplanned_ctn }}/{{ scope.row.shipable_ctn }}
        </template>
      </el-table-column>
      <el-table-column label="发货数" width="120">
        <template v-slot="scope">
          <el-row type="flex" align="middle">
            <div>
              <el-input-number
                controls-position="right"
                size="mini"
                v-if="scope.row.checked"
                style="width: 90px;"
                :min="
                  scope.row.ctn +
                    scope.row.planned_ctn -
                    scope.row.planned_ctn_of_all >
                  0
                    ? 1
                    : 0
                "
                :max="
                  scope.row.ctn +
                    scope.row.planned_ctn -
                    scope.row.planned_ctn_of_all
                "
                v-model="scope.row.x"
              >
              </el-input-number>
              <span v-if="!scope.row.checked">{{ scope.row.x }}</span>
            </div>
            <div style="margin-left:10px" v-show="scope.row.checked"></div>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button
        size="small"
        @click.prevent="saveDetailItem()"
        type="primary"
        :disabled="detaillist.length === 0"
        >保存编辑</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as forwardNewApi from '@/api/forwardNew'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
export default {
  components: {
    productInfoInTableCell
  },
  data () {
    return {
      visible: false,
      orderdetailItem: [],
      selectedList: [],
      selectItem: [],
      detaillist: [],
      id: ''
    }
  },
  methods: {
    checkboxflag (row, index) {
      if (row.id === 0) {
        return 1
      } else {
        return 0
      }
    },
    show (orderdata, id) {
      this.orderdetailItem = orderdata
      for (let i = 0; i < this.orderdetailItem.length; i++) {
        this.orderdetailItem[i].checked = false
      }
      this.id = id
      this.visible = true
      console.log(this.orderdetailItem)
    },
    //  编辑货代单明细
    saveDetailItem () {
      const self = this
      self.selectItem = self.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
      const jsondata = {
        id: self.id,
        item: self.selectItem
      }
      forwardNewApi
        .editforwardNewOrderDetailItem(jsondata)
        .then(data => {
          this.$emit('editsuccess')
          this.orderdetailItem = []
          this.id = ''
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    //  选中checkbox项的回调
    handleSelect (selection, row) {
      if (row.checked) {
        row.checked = false
      } else {
        row.checked = true
      }
      this.selectedList = selection
      this.selectItem = this.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
      console.log(this.selectItem)
    },
    //  全选回调函数
    handleSelectall (selection) {
      const self = this
      if (selection.length > 0) {
        for (let i = 0; i < self.orderdetailItem.length; i++) {
          self.orderdetailItem[i].checked = true
        }
      } else {
        for (let i = 0; i < self.orderdetailItem.length; i++) {
          self.orderdetailItem[i].checked = false
        }
      }
      self.selectedList = selection
      this.selectItem = this.selectedList.map(item => {
        return {
          label: item.label,
          x: item.x
        }
      })
    },
    handleSelectionChange (val) {
      this.detaillist = []
      val.forEach(item => {
        this.detaillist.push(item.label)
        console.log(item.checked)
      })
    },
    closeDialog (done) {
      this.orderdetailItem = []
      this.id = ''
      done()
    }
  }
}
</script>
