import request from '../config/request'

export const apiWarehouseLs = function (status, page = 1, perPage = 20, type) {
  return request(
    'wms/warehouse',
    'get',
    {
      status: status,
      page: page,
      perPage: perPage,
      type: type
    },
    true
  )
}

export const apiSaveWarehouse = function (dat) {
  return request('wms/warehouse/save', 'post', dat, true)
}
