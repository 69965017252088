<template>
  <div class="create-prepayment-third">
    <el-form
      label-position="left"
      label-width="100px"
      :model="prepaymentForm"
      :rules="rules"
      ref="thisForm"
    >
      <el-form-item label="处理方式">
        <el-radio v-model="radio" label="null">创建预付款</el-radio>
        <el-radio v-model="radio" label="create">创建预付款和请款单</el-radio>
        <el-radio v-model="radio" label="add"
          >创建预付款并追加至请款单</el-radio
        >
      </el-form-item>

      <!--创建预付款和请款单 -->
      <div v-if="radio == 'create'">
        <el-form-item label="支付金额">{{ paymentAmount }}</el-form-item>
        <el-form-item label="付款期限" prop="deadline">
          <el-date-picker
            v-model="prepaymentForm.deadline"
            type="date"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="收款单位" prop="bank_name">
          <el-input
            v-model="prepaymentForm.bank_name"
            style="width:350px"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bank">
          <el-input
            v-model="prepaymentForm.bank"
            style="width:350px"
          ></el-input>
        </el-form-item>
        <el-form-item label="账户" prop="bank_account">
          <el-input
            v-model="prepaymentForm.bank_account"
            style="width:350px"
          ></el-input>
        </el-form-item>
      </div>

      <!-- 创建并追加至请款单 -->
      <div v-if="radio == 'add'">
        <el-form-item>
          <el-table
            :data="paymentList"
            ref="multipleTable"
            stripe
            border
            style="width: 100%"
            highlight-current-row
            @current-change="currentSelectionChange"
          >
            <el-table-column
              prop="id"
              width="55"
              label="请款单号"
            ></el-table-column>
            <el-table-column
              prop="vendor_name"
              label="供应商"
            ></el-table-column>
            <el-table-column label="收款信息">
              <template slot-scope="scope">
                <span>{{ scope.row.bank_name }}</span>
                <br />
                <span
                  >{{ scope.row.bank_account }} （{{ scope.row.bank }}）</span
                >
              </template>
            </el-table-column>
            <el-table-column label="请款总金额">
              <template slot-scope="scope">
                <span>{{ scope.row.amount }}{{ scope.row._currency }} </span>
              </template>
            </el-table-column>
            <el-table-column prop="deadline" label="付款期限"></el-table-column>
          </el-table>
        </el-form-item>
      </div>

      <el-form-item>
        <el-button type="primary" size="small" @click="saveHandle"
          >确定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import * as purchaeFinanceApi from '@/api/purchaseFinance'

export default {
  props: ['data', 'lastPayData', 'parentForm'],
  data () {
    return {
      radio: 'null',
      paymentList: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      },
      prepaymentForm: {},

      search: this.parentForm,
      rules: {
        bank_name: [{ required: true, trigger: 'blur' }],
        bank: [{ required: true, trigger: 'blur' }],
        bank_account: [{ required: true, trigger: 'blur' }],
        deadline: [{ required: true, trigger: 'change' }]
      }
    }
  },

  watch: {
    parentForm: function (val) {
      this.search = val
    },
    lastPayData: function (val) {
      this.prepaymentForm = {
        bank: val.bank,
        bank_name: val.bank_name,
        bank_account: val.bank_account
      }
    },
    radio: function (val) {
      if (val === 'add') {
        this.getList()
      }
    }
  },
  // created() {
  //   purchaeFinanceApi.prepaymentSimple(this.search).then(res => {
  //     this.paymentList = res;
  //   });
  // },
  computed: {
    paymentAmount () {
      const excel = this.data.report.excel
      let amount = 0
      excel.forEach(i => {
        i.item.forEach(item => {
          amount += item.prepayment_amount
        })
      })
      return amount + ' ' + excel[0]._currency
    },

    mode () {
      if (this.radio === 'add') {
        return 'add'
      } else if (this.radio === 'create') {
        return 'create'
      } else {
        return null
      }
    }
  },

  methods: {
    getList () {
      if (this.paymentList.length === 0) {
        purchaeFinanceApi.prepaymentSimple(this.search).then(res => {
          this.paymentList = res
        })
      }
    },
    saveHandle () {
      const data = {
        cache: this.data.cache,
        order: this.prepaymentForm,
        mode: this.mode
      }

      if (this.radio === 'add' && !data.order.id) {
        this.$alert('请选择一个请款单！')
        return false
      }

      this.$refs.thisForm.validate(valid => {
        if (valid) {
          purchaeFinanceApi.confirmPrepaymentReport(data).then(res => {
            this.$router.push({ name: 'prepayment' })
          })
        } else {
          return false
        }
      })
    },
    currentSelectionChange (row) {
      this.prepaymentForm.id = row.id
    }
  }
}
</script>
