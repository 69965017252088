<template>
  <el-dialog
    title="SD产品信息"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="800"
  >
    <el-form size="small" label-width="100px">
      <h4>基础信息</h4>
      <el-form-item label="品名">
        &nbsp;&nbsp;{{ productdetail.name }}
      </el-form-item>
      <el-form-item label="MFN">
        &nbsp;&nbsp;{{ productdetail.mfn }}
      </el-form-item>
      <el-form-item label="品类">
        &nbsp;&nbsp;{{ productdetail.cat }}
      </el-form-item>
      <el-form-item label="主色">
        &nbsp;&nbsp;{{ productdetail.clr }}
      </el-form-item>
      <el-form-item label="主材质">
        &nbsp;&nbsp;{{ productdetail.mat }}
      </el-form-item>
      <el-form-item label="尺寸（CM）">
        &nbsp;&nbsp;{{ productdetail.length }}*{{ productdetail.width }}*{{
          productdetail.height
        }}
      </el-form-item>
      <el-form-item label="重量（KG)">
        &nbsp;&nbsp;{{ productdetail.weight }}
      </el-form-item>
    </el-form>
    <hr />
    <div class="title baseinfo">
      <h4>图片相册</h4>
      <el-row>
        <AlbumUploader v-model="productdetail.album"></AlbumUploader>
      </el-row>
      <hr />
    </div>
    <el-form size="small" label-width="100px">
      <h4>电池</h4>
      <el-form-item label="类型">
        &nbsp;&nbsp;<span v-if="productdetail.battery">{{
          productdetail.battery.type
        }}</span>
      </el-form-item>
      <el-form-item label="数量">
        &nbsp;&nbsp;<span v-if="productdetail.battery">{{
          productdetail.battery.num
        }}</span>
      </el-form-item>
      <br />
      <br />
      <h4>标签</h4>
      <el-form-item label="风格">
        &nbsp;&nbsp;<span v-if="productdetail.tag">{{
          productdetail.tag.style
        }}</span>
      </el-form-item>
      <el-form-item label="主题">
        &nbsp;&nbsp;<span v-if="productdetail.tag">{{
          productdetail.tag.theme
        }}</span>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import AlbumUploader from './album-uploader'
import { getShopifyProductDetail } from '@/api/shopify'
export default {
  components: {
    AlbumUploader
  },
  data () {
    return {
      visible: false,
      mfn: '',
      productdetail: {}
    }
  },
  methods: {
    show (mfn) {
      this.visible = true
      this.mfn = mfn
      getShopifyProductDetail({ mfn: this.mfn })
        .then(data => {
          this.productdetail = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      done()
    }
  }
}
</script>
