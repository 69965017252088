<template>
  <div>
    <h2>采购应付款</h2>
    <el-row>
      <el-form>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="ID">
              &nbsp;&nbsp;{{ paydetail.id }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="供应商">
              &nbsp;&nbsp;{{ paydetail._vendor }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="金额">
              &nbsp;&nbsp;{{ paydetail.amount }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              &nbsp;&nbsp;{{ paydetail._step }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="收款单位">
              &nbsp;&nbsp;{{ paydetail.bank_name }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开户行">
              &nbsp;&nbsp;{{ paydetail.bank }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账号">
              &nbsp;&nbsp;{{ paydetail.bank_account }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="付款期限">
              &nbsp;&nbsp;{{ paydetail.deadline }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <div>
      <el-table stripe style="width:100%" :data="paydetail.item" border>
        <el-table-column prop="purchase_id" label="采购单"></el-table-column>
        <el-table-column prop="_currency" label="币种"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column prop="_type" label="类型"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getpaymentDetail } from '@/api/financePurchaseNew'
export default {
  data () {
    return {
      paydetail: {},
      paybillid: this.$route.params.id
    }
  },
  created () {
    this.getpayBillDetail()
  },
  methods: {
    getpayBillDetail () {
      getpaymentDetail({ id: this.paybillid })
        .then(data => {
          this.paydetail = data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
