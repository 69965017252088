import request from '@/config/request'
import * as qiniu from 'qiniu-js'

const config = {}

const putExtra = {}

export const ApiQiniuService = {
  // 上传至7牛服务器
  upload (file, fileName, token, fn) {
    const ob = qiniu.upload(file, fileName, token, putExtra, config)
    const sub = ob.subscribe(fn)
    return sub
  },

  // 获取token
  getToken (dir) {
    const exp = 3600
    const params = { exp, dir }
    return request('resource/qiniu/token', 'get', params, true)
  }
}
