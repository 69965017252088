import request from '../config/request'
export const getproductCostList = data => {
  return request('finance/inventory/productCost', 'get', data, true).then(
    data => {
      return data
    }
  )
}
export const getInventoryBalanceMonthList = data => {
  return request('finance/inventory/monthly', 'get', data, true).then(data => {
    return data
  })
}
export const getInventorychangeDetailList = data => {
  return request('finance/inventory/changeDetail', 'get', data, true).then(
    data => {
      return data
    }
  )
}
