<!-- 请款审核 -->
<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>请款审核</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in sysConfig.FinancePaymentEarnestOrder.step"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        >
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <prepayment-order-list :step="tabvalue"></prepayment-order-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import prepaymentOrderList from './prepayment-orderlist'
export default {
  components: {
    prepaymentOrderList
  },
  data () {
    return {
      tabvalue: '1',
      prepaymentlist: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {},
  methods: {
    selectTabs () {
      console.log(this.tabvalue)
    }
  }
}
</script>
