<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>亚马逊</el-breadcrumb-item>
        <el-breadcrumb-item>毛利统计</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <!-- <el-row class="tab">
      <el-tabs v-model="tabvalue">
        <el-tab-pane label="明细" name="detail"> </el-tab-pane>
        <el-tab-pane label="汇总" name="collect">
                </el-tab-pane>
      </el-tabs>
    </el-row> -->
    <div
      class="p-0.5 border border-blue-400 bg-blue-200 rounded"
    >
      <i class="el-icon-warning mx-1 text-blue-500 text-base"></i>
      <span class="text-gray-500">注意：指定的月份并非对应Amazon业务时间，而是系统抓单时间，如果系统出现意外漏单或抓单中断，补抓的数据将有可能计入之后的月份。</span>
    </div>
    <el-row class="mt-2">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-date-picker
            v-model="search.yearmonth"
            type="month"
            value-format="yyyyMM"
            placeholder="选择年月"
          >
            >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-select v-model="search.ec_id" placeholder="电商" clearable>
            <el-option
              v-for="item in eclist"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-button @click="getFinanceProfitList()" :disabled="true"
            >查询</el-button
          >
        </el-form-item> -->
        <el-form-item>
          <el-button @click="downLoadLink()" type="primary">导出下载</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- <profit-stat-detail :prodetaildata="profitData" v-if="tabvalue === 'detail'"></profit-stat-detail> -->
    <!-- <profit-stat-collect v-if="tabvalue === 'collect'"></profit-stat-collect> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import profitStatDetail from './profit-stat-detail'
// import profitStatCollect from './profit-stat-collect'
import { getFinanceProfit, getAmazonEcList } from '@/api/financeAmazon'
import { baseUrl } from '@/config/env'
export default {
  // components: {
  //   profitStatDetail,
  //   profitStatCollect
  // },
  data () {
    return {
      tabvalue: 'detail',
      search: {
        yearmonth: '',
        ec_id: ''
      },
      profitData: {},
      eclist: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    const date = new Date()
    this.search.yearmonth =
      date.getFullYear().toString() + ('0' + (date.getMonth() + 1)).slice(-2)
    getAmazonEcList({ type: 1 })
      .then(data => {
        this.eclist = data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    getFinanceProfitList () {
      const jsondata = {
        month: this.search.yearmonth,
        type: this.tabvalue
      }
      getFinanceProfit(jsondata)
        .then(data => {
          this.profitData = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    downLoadLink () {
      if (!this.search.yearmonth) {
        this.$notify({
          title: '',
          message: '请选择年月',
          type: 'error'
        })
        return
      }

      const url =
        baseUrl +
        'finance/amz/profit/export?token=' +
        localStorage.getItem('token') +
        '&month=' +
        this.search.yearmonth +
        '&ec_id=' +
        this.search.ec_id
      window.open(url)
    }
  }
}
</script>
