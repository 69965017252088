<template>
  <div>
    <el-row class="content-searcha">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="search.sku"
              placeholder="SKU,多个用逗号分隔"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.mfn"
              placeholder="mfn,多个用逗号分隔"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.attribute"
              placeholder="自定义标签,多个用逗号分隔"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="search.ec_id" placeholder="请选择ec">
              <el-option
                v-for="item in ecList"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="search.tagReverse">Tag反选排除</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchProduct()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-dropdown>
          <el-button type="primary" size="small"
            >下载库存
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="downloadLink()"
              >汇总库存</el-dropdown-item
            >
            <el-dropdown-item @click.native="downloadLink('detail')"
              >库存明细</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row >
      <el-table
        stripe
        border
        width="100%"
        :data="productList"
        @select="handleSelect"
        @select-all="handleSelectall"
        height="600"
      >
        <el-table-column type="selection" width="36"></el-table-column>
        <el-table-column label="产品信息">
          <template slot-scope="scope">
            <product-info-table-cell
              :cover="scope.row._product.cover"
              :title="scope.row._product.name"
              :mfn="scope.row._product.mfn"
              v-if="scope.row._product"
            ></product-info-table-cell>
            <!-- <el-tag type="info">{{scope.row.tag}}</el-tag>
                        <el-button type="text" @click="$refs['all-add-attribute-dialog'].show(scope.row.mfn,search.ec_id)">编辑</el-button> -->
          </template>
        </el-table-column>
        <el-table-column label="sku" prop="sku">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sku }}
            </div>
            <el-tag type="info">{{ scope.row.tag }}</el-tag>
            <el-button
              type="text"
              @click="
                $refs['all-add-attribute-dialog'].show(
                  scope.row.mfn,
                  search.ec_id,
                  scope.row.tag
                )
              "
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          label="可用库存"
          prop="qty"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="总库存"
          prop="qty_total"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="采购未发"
          prop="qty_purchase"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="物流在途"
          prop="qty_transfer"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="调拨在途"
          prop="qty_on_way"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="其余库存"
          prop="qty_other"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="共享库存"
          prop="qty_share"
          min-width="30%"
        ></el-table-column>
        <el-table-column
          label="实际可用库存"
          prop="qty_available"
          min-width="30%"
        ></el-table-column>
        <el-table-column min-width="30%">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="$refs['product-detail-dialog'].show(scope.row.mfn)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 10px">
      <el-button type="primary" size="small" @click="showExportDialog()"
        >导出下载</el-button
      >
      <el-dropdown style="margin-left:20px">
        <el-button type="primary" size="small"
          >上下架
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="productUpOrDown(1)"
            >上架</el-dropdown-item
          >
          <el-dropdown-item @click.native="productUpOrDown(-1)"
            >下架</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown style="margin-left:20px">
        <el-button type="primary" size="small">
          库存借用
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="showBorrowDialog()"
            >借入库存</el-dropdown-item
          >
          <el-dropdown-item @click.native="showReturnDialog()"
            >归还库存</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown style="margin-left:20px">
        <el-button type="primary" size="small">
          设置标签
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="addAllLabel()"
            >增加标签</el-dropdown-item
          >
          <el-dropdown-item @click.native="deleteAllLabel()"
            >删除标签</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 250, 500, 1000]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <product-detail-dialog ref="product-detail-dialog"></product-detail-dialog>
    <export-product-dialog ref="export-product-dialog"></export-product-dialog>
    <borrowing-inventory-dialog
      ref="borrowing-inventory-dialog"
    ></borrowing-inventory-dialog>
    <return-the-inventory-dialog
      ref="return-the-inventory-dialog"
    ></return-the-inventory-dialog>
    <all-add-attribute-dialog
      ref="all-add-attribute-dialog"
      @add-tag-success="searchProduct()"
    ></all-add-attribute-dialog>
    <all-delete-tag-dialog
      ref="all-delete-tag-dialog"
      @delete-tag-success="searchProduct()"
    ></all-delete-tag-dialog>
  </div>
</template>
<script>
import { getSalesProductList, upOrDownProduct } from '@/api/amazon'
import { mapGetters } from 'vuex'
import productInfoTableCell from '@/components/common/product-info-table-cell'
import productDetailDialog from './product-detail-dialog'
import exportProductDialog from '@/components/amazon/export-product-dialog'
import borrowingInventoryDialog from '@/components/amazon/borrowing-inventory-dialog'
import returnTheInventoryDialog from '@/components/amazon/return-the-inventory-dialog'
import allAddAttributeDialog from '@/components/amazon/all-add-attribute-dialog'
import allDeleteTagDialog from '@/components/amazon/all-delete-tag-dialog'
import { baseUrl } from '@/config/env'
export default {
  props: ['status', 'ecList'],
  components: {
    productInfoTableCell,
    productDetailDialog,
    exportProductDialog,
    borrowingInventoryDialog,
    returnTheInventoryDialog,
    allAddAttributeDialog,
    allDeleteTagDialog
  },
  data () {
    return {
      search: {
        ec_id: 5,
        tagReverse: false
      },
      productList: [],
      pageData: {
        current_page: 1,
        per_page: 100
      },
      productMfn: []
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    status: function () {
      this.pageData.current_page = 1
      this.getProductList()
    },
    ecList: function () {
      if (this.ecList.length > 0) {
        this.search.ec_id = this.ecList[0].key
        this.pageData.current_page = 1
        this.getProductList()
      }
    }
  },
  methods: {
    getProductList () {
      const listJsondata = {
        ec_type: '5',
        ec_id: this.search.ec_id,
        sku: this.search.sku,
        mfn: this.search.mfn,
        tag: this.search.attribute,
        tagReverse: Number(this.search.tagReverse),
        status: this.status,
        page: this.pageData.current_page,
        per_page: this.pageData.per_page
      }
      getSalesProductList(listJsondata)
        .then(data => {
          this.productList = data.data
          this.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchProduct () {
      this.pageData.current_page = 1
      this.getProductList()
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getProductList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getProductList()
    },
    handleSelect (selection) {
      this.productMfn = selection.map(item => {
        return item.mfn
      })
    },
    handleSelectall (selection) {
      this.productMfn = selection.map(item => {
        return item.mfn
      })
    },
    checkSelected () {
      if (this.productMfn.length === 0) {
        this.$alert('请至少选择一款产品')
        return false
      }
      return true
    },
    showExportDialog () {
      if (this.checkSelected()) {
        this.$refs['export-product-dialog'].show(
          this.productMfn,
          this.search.ec_id
        )
      }
    },
    addAllLabel () {
      if (this.checkSelected()) {
        this.$refs['all-add-attribute-dialog'].show(
          this.productMfn,
          this.search.ec_id
        )
      }
    },
    deleteAllLabel () {
      if (this.checkSelected()) {
        this.$refs['all-delete-tag-dialog'].show(
          this.productMfn,
          this.search.ec_id
        )
      }
    },
    showBorrowDialog () {
      this.$refs['borrowing-inventory-dialog'].show(this.search.ec_id)
    },
    showReturnDialog () {
      this.$refs['return-the-inventory-dialog'].show(this.search.ec_id)
    },
    downloadLink (type) {
      if (type) {
        const url =
          baseUrl +
          'sales/product/exportInventoryDetail?token=' +
          localStorage.getItem('token') +
          '&ec_id=' +
          this.search.ec_id
        window.open(url)
      } else {
        const url =
          baseUrl +
          'sales/product/exportInventory?token=' +
          localStorage.getItem('token') +
          '&ec_id=' +
          this.search.ec_id
        window.open(url)
      }
    },
    returnInventory () {
      if (this.checkSelected()) {
        this.$confirm('提示', {
          title: '归还库存',
          message: '借出的库存将被收回，借入的库存将被归还。确定执行吗？'
        })
          .then(_ => {})
          .catch(_ => {})
      }
    },
    // 上下架
    productUpOrDown (status) {
      if (this.checkSelected()) {
        const jsondata = {
          mfn: this.productMfn.join(','),
          status: status,
          ec_id: this.search.ec_id
        }
        upOrDownProduct(jsondata)
          .then(data => {
            this.pageData.current_page = 1
            this.getProductList()
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }
}
</script>
