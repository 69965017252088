<template>
  <el-dialog
    title="添加至调拨单"
    :visible.sync="visible"
    width="800px"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-table border stripe :data="allotList" width="100%">
      <el-table-column>
        <template slot-scope="scope">
          <el-radio :label="scope.row.id + ''" v-model="radio"></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="仓库" prop="_warehouse_from">
        <template slot-scope="scope">
          {{ scope.row._warehouse_from }} ~ {{ scope.row._warehouse_to }}
        </template>
      </el-table-column>
      <el-table-column label="计划日期" prop="date"></el-table-column>
      <el-table-column label="创建日期" prop="created_at"></el-table-column>
    </el-table>
    <span slot="footer">
      <el-button type="primary" @click="confirmToPlan">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getOverseaAllotList, appendToPlan } from '@/api/warehouse'
export default {
  data () {
    return {
      visible: false,
      allotList: [],
      radio: '',
      c_id: ''
    }
  },
  methods: {
    show (cid) {
      this.getOverseaAllotList()
      this.c_id = cid
      this.visible = true
    },
    closedone (done) {
      done()
    },
    hide () {
      this.visible = false
    },
    getOverseaAllotList () {
      const jsondata = {
        step: '3',
        page: 1,
        per_page: 10000
      }
      getOverseaAllotList(jsondata)
        .then(data => {
          this.allotList = data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmToPlan () {
      const jsondata = {
        p_id: this.radio,
        c_id: this.c_id
      }
      appendToPlan(jsondata)
        .then(data => {
          this.$emit('add-succcess')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
