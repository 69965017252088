import request from '../config/request'

export const getTransportOrderList = data => {
  return request('purchase/transport/order', 'get', data)
}

export const getTransportOrderDetail = data => {
  return request('purchase/transport/order/detail', 'get', data).then(data => {
    return data
  })
}

// 编辑货代单
export const saveTransportOrderDetail = data => {
  return request('purchase/transport/order/detail/edit', 'post', data).then(
    data => {
      return data
    }
  )
}
// 删除货代单
export const deleteTransportOrderDetail = data => {
  return request('purchase/transport/order/detail/delete', 'post', data).then(
    data => {
      return data
    }
  )
}
// 删除货代单明细
export const deleteTransportOrderDetailItem = data => {
  return request(
    'purchase/transport/order/detail/deleteItem',
    'post',
    data
  ).then(data => {
    return data
  })
}
// 编辑货代单明细
export const saveTransportOrderDetailItem = data => {
  return request('purchase/transport/order/detail/saveItem', 'post', data).then(
    data => {
      return data
    }
  )
}
// 移动货代单明细至新货代单或其他货代单
export const moveTransportOrderDetailItem = data => {
  return request('purchase/transport/order/detail/moveItem', 'post', data).then(
    data => {
      return data
    }
  )
}
// 确认货代单
export const confirmTransportOrder = data => {
  return request('purchase/transport/order/detail/confirm', 'post', data).then(
    data => {
      return data
    }
  )
}
// 创建海外入库单
export const setShipment = data => {
  return request('purchase/transport/setShipment', 'post', data).then(data => {
    return data
  })
}
