<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="500px"
    :show-close="true"
    :before-close="closedone"
  >
    <el-form :inline="true" size="small" label-width="80px" ref="edit-dialog">
      <el-form-item label="品名:"> &nbsp;&nbsp;{{ name }} </el-form-item>
      <el-form-item label="MFN:"> &nbsp;&nbsp;{{ mfn }} </el-form-item>
      <el-form-item label="散装数:">
        <el-input-number v-model="pcs"> </el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import * as warehouseApi from '@/api/warehouse'
export default {
  props: ['order_id'],
  data () {
    return {
      title: '',
      name: '',
      mfn: '',
      visible: false,
      type: '',
      pcs: 0,
      id: ''
    }
  },
  methods: {
    show (type, id, name, mfn) {
      if (type === '-5') {
        this.title = '编辑报损单明细'
      } else if (type === '5') {
        this.title = '编辑调整单明细'
      }
      this.type = type
      this.id = id
      this.name = name
      this.mfn = mfn
      this.visible = true
    },
    handleConfirm () {
      const self = this
      const jsonData = {
        id: self.id,
        order_id: self.order_id,
        pcs: self.pcs
      }
      // 编辑报损单明细
      if (self.type === '-5') {
        warehouseApi
          .editBreakageDetail(jsonData)
          .then(data => {
            self.$emit('edit-detail-success')
          })
          .catch(err => {
            console.log(err)
          })
      } else if (self.type === '5') {
        //  编辑调整单明细
        warehouseApi
          .editAdjustDetail(jsonData)
          .then(data => {
            self.$emit('edit-detail-success')
          })
          .catch(err => {
            console.log(err)
          })
      }
      self.visible = false
    },
    closedone (done) {
      this.pcs = 0
      done()
    }
  }
}
</script>
