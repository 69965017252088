import request from '@/config/request'

export const getFinanceProfit = data => {
  return request('finance/profit', 'get', data, true).then(data => {
    return data
  })
}
//  财务账期列表
export const getFinanceList = data => {
  return request('finance/amz/financeList', 'get', data, true).then(data => {
    return data
  })
}
// 仅Amazon电商列表获取
export const getAmazonEcList = data => {
  return request('sales/product/getEcList', 'get', data, true).then(data => {
    return data
  })
}

export const setFinanceLiquidation = data => {
  return request('finance/amz/liqAdd', 'post', data, false).then(data => {
    return data
  })
}
