<template>
  <div class="reimbursement-index">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>采购管理</el-breadcrumb-item>
        <el-breadcrumb-item>交付待报销</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-tabs v-model="search.step" @tab-click="handleTabs">
      <el-tab-pane
        v-for="item in stepList"
        :key="item.key"
        :label="item.val"
        :name="item.key + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <el-row class="content-row">
      <el-form :inline="true">
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in vendor"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:80px;"
            v-model="search.currency"
            placeholder="币种"
          >
            <el-option
              v-for="item in currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select clearable v-model="search.type" placeholder="类型">
            <el-option
              v-for="item in typeList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            class="search-bar"
            style="width:120px;"
            v-model="search.order_id"
            placeholder="来源"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="searchHandle">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row class="content-row">
      <el-table
        :data="datList"
        stripe
        border
        ref="table"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          :selectable="pickRow"
          width="55"
        ></el-table-column>
        <el-table-column prop="id" label="交付单"></el-table-column>
        <el-table-column prop="vendor_name" label="供应商"></el-table-column>
        <el-table-column label="金额">
          <template v-slot="scope">
            <span>{{ scope.row.amount }} {{ scope.row._currency }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="_type" label="类型"></el-table-column>
        <el-table-column prop="remark" label="报销单"></el-table-column>
        <el-table-column prop="updated_at" label="最后更新"></el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <el-button type="text" @click="onEdit(scope.row.id)"
              >编辑</el-button
            >
            <!-- <el-button type="text" @click="onDelete(scope.row.id)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="content-row">
      <el-col :span="6">
        <div>
          <el-button
            v-show="search.step == 1"
            type="primary"
            @click="createOrder"
            >创建报销单</el-button
          >
          <el-button
            v-show="search.step == 1"
            type="primary"
            @click="addToOrder"
            >添加至报销单</el-button
          >
        </div>
      </el-col>
    </el-row>

    <el-row class="content-row">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="search.page"
        :page-sizes="[15, 20, 30, 50, 100, 200]"
        layout="total, sizes, prev, pager, next"
        :total="search.total"
        :page-size.sync="search.num_per_page"
      ></el-pagination>
    </el-row>

    <!-- 创建报销单 dialog-->
    <create-reimburse-order-dialog
      :ids="ids"
      :propsDat="propsDat"
      :lastRecord="lastRecord"
      @create-end="getList"
      ref="create-reimburse-order-dialog"
    />

    <!-- 添加至报销单 dialog-->
    <add-to-reimburse-order-dialog
      :ids="ids"
      :propsDat="propsDat"
      @create-end="getList"
      ref="add-to-reimburse-order-dialog"
    />
  </div>
</template>
<script>
import * as reimbursementApi from '@/api/reimbursement'
import createReimburseOrderDialog from './create-reimburse-order-dialog'
import addToReimburseOrderDialog from './add-to-reimburse-order-dialog'
import { mapGetters } from 'vuex'

export default {
  components: { createReimburseOrderDialog, addToReimburseOrderDialog },
  data () {
    return {
      ids: [],
      // selectedList: [],
      datList: [],
      vendor: [],
      currency: [],
      typeList: [],
      stepList: [],

      search: {
        vendor_id: '',
        step: '1',
        currency: '',
        page: 1,
        num_per_page: 15,
        total: 0
      },
      date: [],
      propsDat: null,
      lastRecord: null
    }
  },
  watch: {
    date: function (val) {
      if (val == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = val[0]
        this.search.end = val[1]
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  created () {
    this.setSysConfig()
    this.getList()
  },

  methods: {
    setSysConfig () {
      this.currency = this.sysConfig.Common.currency
      this.vendor = this.sysConfig.vendorList
      this.stepList = this.sysConfig.FinancePurchaseInvoice.step
      this.typeList = this.sysConfig.FinancePurchaseInvoice.type
    },
    getList () {
      reimbursementApi.reimbursement(this.search).then(res => {
        this.datList = res.data
        // 分页
        this.search.total = res.total
        this.search.page = res.current_page
        this.search.num_per_page = parseInt(res.per_page)
      })
    },
    searchHandle () {
      this.getList()
    },
    handleTabs (tab, event) {
      this.search.step = tab.name
      this.getList()
    },
    onEdit (id) {
      this.$router.push({ name: 'reimbursementDetail', query: { id: id } })
    },
    onDelete () {},
    createOrder () {
      if (this.propsDat == null) {
        this.$alert('请选择')
        return false
      }
      this.$refs['create-reimburse-order-dialog'].show()
    },
    addToOrder () {
      if (this.propsDat == null) {
        this.$alert('请选择')
        return false
      }
      this.$refs['add-to-reimburse-order-dialog'].show()
    },

    pickRow (row, index) {
      const obj = this.propsDat
      if (obj) {
        row.checked =
          obj.currency === row.currency && obj.object_id === row.object_id
        return row.checked
      }
      return true
    },

    handleSelectionChange (val) {
      // this.selectedList = val;
      if (val.length) {
        console.log(val)
        this.propsDat = {
          vendor_name: val[0].vendor_name,
          object_id: val[0].object_id,
          currency: val[0].currency,
          vendor_id: val[0].vendor_id
        }
        this.lastRecord = val[0].last_payment_record

        for (let i = 0; i < val.length; i++) {
          this.$refs.table.toggleRowSelection(
            val[i],
            this.pickRow(val[i], 0)
          )
        }
        this.ids = val.map(item => item.id)
        return true
      } else {
        this.lastRecord = null
        this.propsDat = null
        // console.log('null')
      }
    },
    // 分页
    handleCurrentChange (currentPage) {
      this.search.page = currentPage
      this.getList()
    },
    // 改变每页显示条数
    handleSizeChange (pagesize) {
      this.search.num_per_page = pagesize
      this.getList()
    }
  }
}
</script>

<style>
.content-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
