<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">财务管理</el-breadcrumb-item>
        <el-breadcrumb-item>汇率设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="conten-row">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-date-picker
              v-model="search.yearmonth"
              :picker-options="pickerOptions"
              type="month"
              value-format="yyyyMM"
              placeholder="请选择年月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getrateList()">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" :offset="4">
        <el-button
          type="primary"
          size="small"
          @click="$refs['ratecredit-dialog'].show()"
          >添加汇率</el-button
        >
      </el-col>
    </el-row>
    <el-row >
      <el-table stripe style="width:100%" :data="rateList" border>
        <el-table-column prop="_currency" label="货币"></el-table-column>
        <el-table-column prop="exchange" label="兑换"></el-table-column>
        <el-table-column prop="rate" label="汇率"></el-table-column>
        <el-table-column prop="time" label="激活日期"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                $refs['ratecredit-dialog'].show(
                  scope.row.currency,
                  scope.row.exchange
                )
              "
              >更新</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <rate-create-edit-dialog
      ref="ratecredit-dialog"
      @refreshRate="getrateList()"
    ></rate-create-edit-dialog>
  </div>
</template>
<script>
import * as rateSetApi from '../../../api/rateSet'
import rateCreateEditDialog from './rate-create-edit-dialog'
export default {
  components: {
    rateCreateEditDialog
  },
  data () {
    return {
      search: {
        yearmonth: ''
      },
      rateList: [],
      pickerOptions: {
        disabledDate: time => {
          // 设置可选择的日期为今天之后的一个月内
          const curDate = new Date().getTime()
          const day = 30 * 24 * 3600 * 1000
          const dateRegion = curDate + day
          return time.getTime() > dateRegion
        }
      }
    }
  },
  created () {
    const date = new Date()
    this.search.yearmonth =
      date.getFullYear().toString() + ('0' + (date.getMonth() + 1)).slice(-2)
    this.getrateList()
  },
  methods: {
    getrateList () {
      const self = this
      const listJsondata = {
        time: self.search.yearmonth
      }
      rateSetApi
        .getcurrencyList(listJsondata)
        .then(data => {
          console.log(data)
          self.rateList = data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
