<template>
  <el-dialog
    title="账期详情"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="600px"
  >
    <el-form label-position="left" label-width="120px">
      <el-form-item label="编号:">
        {{ formdata.FinancialEventGroupId }}
      </el-form-item>
      <el-form-item label="账期:">
        {{ formdata.FinancialEventGroupStart }}~{{
          formdata.FinancialEventGroupEnd
        }}
      </el-form-item>
      <el-form-item label="状态:">
        {{ formdata._ProcessingStatus }}
      </el-form-item>
      <el-form-item label="电商:">
        {{ formdata._ec }}
      </el-form-item>
      <el-form-item label="转账流水号:">
        {{ formdata.TraceId }}
      </el-form-item>
      <el-form-item label="转账日期:">
        {{ formdata.FundTransferDate }}
      </el-form-item>
      <el-form-item label="转账状态:">
        {{ formdata.FundTransferStatus }}
      </el-form-item>
      <el-form-item label="收款账户尾号:">
        {{ formdata.AccountTail }}
      </el-form-item>
      <el-form-item label="原始金额:">
        {{ formdata.OriginalTotal }} &nbsp;&nbsp;{{ formdata.OriginalCurrency }}
      </el-form-item>
      <el-form-item label="换汇金额:">
        {{ formdata.ConvertedTotal }} &nbsp;&nbsp;{{
          formdata.ConvertedCurrency
        }}
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      formdata: {},
      visible: false
    }
  },
  methods: {
    show (data) {
      this.formdata = data
      this.visible = true
    },
    closedone (done) {
      this.formdata = {}
      done()
    }
  }
}
</script>
