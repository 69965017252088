<template>
  <el-dialog
    title="策略切换"
    :visible.sync="visible"
    width="800px"
    :close-on-click-modal="false"
    :before-close="closedone"
  >
    <el-table
      stripe
      border
      width="100%"
      :data="strategyList"
      highlight-current-row
      @current-change="handleCurrentChange"
      ref="refreshStrategy"
    >
      <el-table-column label="ID" prop="name"></el-table-column>
      <el-table-column label="更新日期" prop="updated_at"></el-table-column>
      <el-table-column label="状态" prop="_status"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="setCurrent(scope.$index + 1, scope.row.id)"
            >选择</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button type="primary" size="small" @click="confirmRefresh()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { getstrategyList, runStrategy } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      strategyList: [],
      strategyid: ''
    }
  },
  created () {
    this.getstrategy()
  },
  methods: {
    show () {
      this.getstrategy()
      this.visible = true
    },
    getstrategy () {
      getstrategyList()
        .then(data => {
          this.strategyList = data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    setCurrent (row, id) {
      this.$refs.refreshStrategy.setCurrentRow(row)
      this.strategyid = id
    },
    confirmRefresh () {
      runStrategy({ id: this.strategyid })
        .then(data => {
          this.visible = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCurrentChange (val) {
      this.currentRow = val
    },
    closedone (done) {
      done()
    }
  }
}
</script>
<style>
.el-table__body tr.current-row > td {
  background: #f57878 !important;
}
</style>
