<template>
  <el-dialog
    title="编辑明细"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="500px"
  >
    <el-form label-position="left" label-width="120px" size="small">
      <el-form-item label="Credit">
        <el-input v-model="formdata.price" placeholder="退货价格"></el-input>
      </el-form-item>
      <el-form-item label="Reason">
        <el-input v-model="formdata.remark" placeholder="原因备注"></el-input>
      </el-form-item>
      <el-form-item label="TrackingNo">
        <el-input
          v-model="formdata.express_no"
          placeholder="物流快递单号"
        ></el-input>
      </el-form-item>
      <el-form-item label="Date">
        <el-date-picker
          v-model="formdata.refund_date"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="退货日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" type="primary" @click="save()">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { editWayfairRefund } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        _id: '',
        remark: '',
        price: '',
        express_no: '',
        refund_date: ''
      }
    }
  },
  methods: {
    show (formdata) {
      this.formdata._id = formdata._id
      this.$set(this.formdata, 'remark', formdata.remark)
      this.$set(this.formdata, 'price', formdata.price)
      this.$set(this.formdata, 'express_no', formdata.express_no)
      this.$set(this.formdata, 'refund_date', formdata.refund_date)
      //    this.formdata.remark = formdata.remark
      //    this.formdata.price = formdata.price
      //    this.formdata.express_no = formdata.express_no
      this.visible = true
    },
    save () {
      console.log(this.formdata.refund_date)
      const jsondata = {
        _id: this.formdata._id,
        remark: this.formdata.remark,
        price: this.formdata.price,
        express_no: this.formdata.express_no,
        refund_date: this.formdata.refund_date
      }
      editWayfairRefund(jsondata)
        .then(data => {
          this.$emit('edit-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    closedone (done) {
      done()
    },
    hide () {
      this.formdata = {}
      this.visible = false
    }
  }
}
</script>
