<template>
  <div>
    <el-row>
      <el-col :span="3" :offset="18">
        <el-button type="text" size="small" @click="downloadExcel()"
          >导出下载</el-button
        >
      </el-col>
    </el-row>
    <!-- :row-class-name="tableRowClassName" -->
    <el-row>
      <el-table
        stripe
        ref="warrantDetailTable"
        :data="orderdetail.item"
        border
        show-summary
        :summary-method="getSummaries"
      >
        <el-table-column prop="name,mfn" label="货品" width="220">
          <template slot-scope="scope">
            <product-info-in-table-cell
              :cover="scope.row.cover"
              :title="scope.row.name"
              :mfn="scope.row.mfn"
            ></product-info-in-table-cell>
          </template>
        </el-table-column>
        <el-table-column
          prop="qty_ctn"
          label="装箱率"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="ctn_width,ctn_length,ctn_height,ctn_weight"
          label="外箱材积"
          width="180"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.ctn_length != null">
              {{ scope.row.ctn_length }} * {{ scope.row.ctn_width }} *
              {{ scope.row.ctn_height }} <br />
              <span v-if="scope.row.ctn_g_weight != null">
                {{ scope.row.ctn_g_weight }}kg<br />
              </span>
            </span>
            {{ scope.row.ctn_volume_m }}m<sup>3</sup>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="箱标" width="120">
          <template slot-scope="scope">
            {{ scope.row.label }} <br />
            <el-tag
              v-if="
                scope.row._product_box_package_dimension &&
                  scope.row._product_box_package_dimension.sellpack > 1
              "
              >套{{ scope.row._product_box_package_dimension.sellpack }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="purchase_order_id"
          label="采购单"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="ship_date"
          label="交期"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="expected_date,expected_ctn"
          label="预计到货"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.expected_date }}/{{ scope.row.expected_ctn }}箱
          </template>
        </el-table-column>
        <el-table-column
          prop="unplanned_ctn,shipable_ctn"
          label="未排/可发"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.unplanned_ctn }}/{{ scope.row.shipable_ctn }}
          </template>
        </el-table-column>
        <el-table-column prop="x" label="发货数" width="200">
          <template slot-scope="scope">
            {{ scope.row.x }}
            <p v-if="scope.row.ctn_g_weight != null">
              {{ (scope.row.ctn_g_weight * scope.row.x) | numFilter }} kg
            </p>
            <p>{{ (scope.row.ctn_volume_m * scope.row.x) | numFilter }} ㎡</p>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row
      style="margin-top:20px; margin-bottom:30px;"
      v-show="orderdetail.step === 1"
    >
      <el-col :span="2">
        <el-button
          type="primary"
          size="small"
          @click="
            $refs['edit-forward-order-detail-dialog'].show(
              orderdetail.item,
              orderdetail.id
            )
          "
          >编辑</el-button
        >
      </el-col>
      <el-col :span="2" :offset="1">
        <el-dropdown>
          <el-button type="primary" size="small">
            移动<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="
                $refs['move-forward-order-detail-dialog'].show(
                  orderdetail.item,
                  orderdetail.id,
                  '2'
                )
              "
              >移至其他货代单</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="
                $refs['move-forward-order-detail-dialog'].show(
                  orderdetail.item,
                  orderdetail.id,
                  '1'
                )
              "
              >移至新货代单</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="2" :offset="1">
        <el-button
          type="danger"
          size="small"
          @click="
            $refs['delete-forward-order-detail-dialog'].show(
              orderdetail.item,
              orderdetail.id
            )
          "
          >删除</el-button
        >
      </el-col>
    </el-row>
    <move-forward-order-detail-dialog
      ref="move-forward-order-detail-dialog"
      @movesuccess="change"
    ></move-forward-order-detail-dialog>
    <edit-forward-order-detail-dialog
      ref="edit-forward-order-detail-dialog"
      @editsuccess="change"
    ></edit-forward-order-detail-dialog>
    <delete-forward-order-detail-dialog
      ref="delete-forward-order-detail-dialog"
      @deletesuccess="change"
    ></delete-forward-order-detail-dialog>
  </div>
</template>
<script>
import moveForwardOrderDetailDialog from './move-forward-order-detail-dialog'
import editForwardOrderDetailDialog from './edit-forward-order-detail-dialog'
import productInfoInTableCell from '@/components/common/product-info-table-cell'
import deleteForwardOrderDetailDialog from './delete-forward-order-detail-dialog'
import { baseUrl } from '@/config/env'
export default {
  props: ['orderdetail'],
  data () {
    return {}
  },
  filters: {
    numFilter (value) {
      const realVal = parseFloat(value).toFixed(2)
      return realVal
    }
  },
  components: {
    productInfoInTableCell,
    moveForwardOrderDetailDialog,
    editForwardOrderDetailDialog,
    deleteForwardOrderDetailDialog
  },
  methods: {
    getSummaries (param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (column.property === 'x') {
          if (this.orderdetail.item.length > 0) {
            const sumvolume = this.orderdetail.item.map(item => {
              return {
                id: item.id,
                weightsum: item.x * item.ctn_g_weight,
                volumesum: item.x * item.ctn_volume_m,
                case: item.x
              }
            })
            let weightcount = 0
            let volumecount = 0
            let cases = 0

            for (let i = 0; i < sumvolume.length; i++) {
              if (sumvolume[i].id === 0) {
                if (sumvolume[i].weightsum > 0) {
                  weightcount += sumvolume[i].weightsum
                }
                if (sumvolume[i].volumesum > 0) {
                  volumecount += sumvolume[i].volumesum
                }
                cases += sumvolume[i].case || 0
              }
            }
            sums[index] =
              `${cases}箱,` + weightcount.toFixed(2) + 'kg,' + volumecount.toFixed(2) + '㎡'
          } else {
            sums[index] = '0'
          }
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    change () {
      this.$emit('detail-success')
    },
    downloadExcel () {
      const url =
        baseUrl +
        'logistics/order/detail/downloads?token=' +
        localStorage.getItem('token') +
        '&id=' +
        this.orderdetail.id
      window.open(url)
    }
  }
}
</script>
<style scope>
.el-table .warning-row {
  background: rgb(228, 68, 68);
}
</style>
