<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>货品库存</el-breadcrumb-item>
        <el-breadcrumb-item>FBA Shipment</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="tabs" style="margin-top: 40px;">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in sysConfig.FBAInboundShipment.step"
          :key="item.key"
          :label="item.val"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <fba-list :step="tabvalue" @reset-success="tabvalue = 1"></fba-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import fbaList from './fba-list'
export default {
  components: {
    fbaList
  },
  data () {
    return {
      tabvalue: this.$route.query.step || '1'
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  }
}
</script>
