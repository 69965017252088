<!-- 货代单列表 -->
<template>
  <div class="forward-orderlist">
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>物流管理</el-breadcrumb-item>
        <el-breadcrumb-item>货代单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="search-bar">
      <el-col :span="18">
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="search.stockorder"
              placeholder="采购单号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="search.mfn"
              placeholder="MFN"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select clearable v-model="search.step" placeholder="请选择">
              <el-option
                v-for="item in sysConfig.PurchaseOrder.step"
                :key="item.key"
                :label="item.val"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getforwardOrderlist(search)"
              >检索</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button @click="clearSerach()">重置</el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button @click="pushforwardOrder()">查看</el-button>
          </el-form-item> -->
        </el-form>
      </el-col>
    </el-row>
    <el-row >
      <el-table stripe ref="forwardOrderTable" :data="forwordorderlist" border>
        <!-- <el-table-column  type="selection" width="36"> </el-table-column> -->
        <el-table-column prop="id" label="ID" width="160"></el-table-column>
        <el-table-column prop="name" label="名称" width="160"></el-table-column>
        <el-table-column
          prop="warehouseName"
          label="目的仓库"
          mini-width="35%"
        ></el-table-column>
        <el-table-column
          prop="_transport_type"
          label="运输方式"
          mini-width="35%"
        ></el-table-column>
        <el-table-column
          prop="date"
          label="预计发货日"
          mini-width="10%"
        ></el-table-column>
        <el-table-column
          prop="_step"
          label="状态"
          mini-width="10%"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          mini-width="10%"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="pushforwardOrder(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
  </div>
</template>
<script>
import * as forwardNewApi from '@/api/forwardNew2021'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      search: {
        stockorder: '',
        mfn: '',
        step: ''
      },
      forwordorderlist: [],
      pageDat: { per_page: 10 }
    }
  },
  created () {
    this.getforwardOrderlist()
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    getforwardOrderlist (searchdata = {}) {
      const currentPage = this.pageDat.current_page || 1
      const forwardJson = {
        order_id: searchdata.stockorder,
        mfn: searchdata.mfn,
        step: searchdata.step,
        page: currentPage,
        num_per_page: this.pageDat.per_page
      }
      forwardNewApi
        .getforwardNewOrderList(forwardJson)
        .then(data => {
          this.forwordorderlist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler () {
      this.getforwardOrderlist()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getforwardOrderlist()
    },
    clearSerach () {
      this.search = {}
    },
    pushforwardOrder (id = null) {
      this.$router.push({
        // path: '/logistmanage/forward/order',
        name: 'forwardOrderNew2021',
        query: {
          order_id: id
        }
      })
    }
  }
}
</script>
