<template>
  <div class="upload-box album-box">
    <ul class="el-upload-list el-upload-list--picture-card">
      <draggable :list="imgs">
        <li
          tabindex="0"
          class="el-upload-list__item is-success album-item"
          v-for="(li, index) in imgs"
          :key="index"
        >
          <img :src="li" alt="" class="el-upload-list__item-thumbnail" />

          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-delete">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </li>
      </draggable>

      <!-- v-for="(file, index) in onUpload.files" :key="index" -->
      <li
        tabindex="0"
        class="el-upload-list__item is-success album-item"
        v-for="file in unfinishedList"
        :key="file.file.name"
      >
        <!-- <img src="" alt="" class="el-upload-list__item-thumbnail"> -->
        <el-progress
          style="width: 70px; cursor: pointer;"
          :width="70"
          type="circle"
          :percentage="file.progress"
        ></el-progress>
        <!-- <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete">
                        <i class="el-icon-delete"></i>
                    </span>
                </span> -->
      </li>
    </ul>

    <div
      tabindex="0"
      class="el-upload el-upload--picture-card upload-item album-item"
    >
      <div class="single-wrapper">
        <i class="el-icon-plus avatar-uploader-icon u-icon "></i>
      </div>
      <input
        type="file"
        ref="input"
        multiple
        accept="image/*"
        class="el-upload__input"
      />
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  components: { draggable },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    // 允许存在的图片数量
    // 0为不限制
    max: {
      type: Number,
      default: 0
    }
  },
  model: {
    prop: 'images',
    event: 'change'
  },
  data () {
    return {
      imgs: this.images || [],
      onUpload: []
    }
  },
  watch: {
    images (nv) {
      if (!nv) {
        this.imgs = []
      } else {
        this.imgs = nv
      }
    }
  },
  computed: {
    unfinishedList () {
      return this.onUpload.filter(f => f.finish !== true)
    }
  },
  methods: {
    // handleClick() {
    //     this.$refs.input.value = null;
    //     this.$refs.input.click();
    // },
    // handleChange(e) {
    //     const files = e.target.files;
    //     console.log(files);
    //     if (!files) return;
    //     this.$set(this, 'onUpload', Array.from(files).map(f => ({ file: f, progress: 0, finish: false })));
    //     this.onUpload.forEach(f => {
    //         this.upliadFile(f);
    //     })
    //     // this.uploadFiles(files);
    // },
    // upliadFile(item) {
    //     const self = this;
    //     ApiQiniuService
    //     .getToken('product')
    //     .then(res => {
    //         let token = res.token
    //         let domain = res.domain
    //         let key = res.key
    //         var observer = {
    //             next(p) {
    //                 const pe = Math.ceil(p.total.percent);
    //                 item.progress = pe;
    //             },
    //             error(err) {
    //                 console.log(err)
    //             },
    //             complete(res) {
    //                 self.imgs.push(`${domain}${res.key}`);
    //                 item.finish = true;
    //                 self.onSuccess();
    //             }
    //         }
    //         ApiQiniuService
    //             .upload(item.file, `${key}/${item.file.name}`, token, observer);
    //     });
    // },
    // uploadFiles(files) {
    //     const self = this;
    //     ApiQiniuService
    //         .getToken('product')
    //         .then(res => {
    //             let token = res.data.token
    //             let domain = res.data.domain
    //             let key = res.data.key
    //             var observer = {
    //                 next() {},
    //                 error(err) {
    //                     console.log(err)
    //                 },
    //                 complete(res) {
    //                     self.imgs.push(`${domain}${res.key}`)
    //                     self.onSuccess()
    //                 }
    //             }
    //             ApiQiniuService
    //                 .upload(files[0], `${key}${files[0].name}`, token, observer)
    //         })
    // },
    // onSuccess() {
    //     this.$emit('change', this.imgs)
    // },
    // onRemove(i) {
    //     this.imgs.splice(i, 1)
    //     this.onSuccess()
    // }
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  width: 100%;
  .upload-item {
    .single-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
      & > .u-icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 78px;
        height: 80px;
        line-height: 80px;
      }
    }
  }
}
.album-box {
  .album-item {
    width: 80px;
    height: 80px;
  }
}
</style>
